// This is rendered in the Schedule component -- in order to attract the same window dimensions as the rota and staff-rota
import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useMemo,
  Fragment,
} from "react";
// useeffects cleaned wef 22 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import whiteGo from "../../img/general/whiteGo.svg";
import arrGrey from "../../img/general/arrGrey.svg";

import TopBar from "../TopBar";

import "../../styles/calendar.css";

import AddShiftModal2 from "../account/admin/subcomponents/AddShiftModal2";
import TeamCalendarInlet from "./TeamCalendarInlet";

const StaffCalendar = () => {
  const { mob, width } = useContext(StyleContext);
  const { setCalPage } = useContext(CalendarContext);
  const { device, setShowDayCommentModal, showMessages, showNotifications } =
    useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      device, //
      setShowDayCommentModal, //
      showMessages, //
      showNotifications, //
      setCalPage, //
      mob, //
      width, //
    }),
    [
      device, //
      setShowDayCommentModal, //
      showMessages, //
      showNotifications, //
      setCalPage, //
      mob, //
      width, //
    ]
  );

  let [loading, setLoading] = useState(true);
  document.title = "FlowRota - Staff calendar";

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  useEffect(() => {
    // clean not needed
    memoVals.setCalPage((x) => "staff-calendar");
  }, []);

  let [hoveredUserID, setHoveredUserID] = useState("");
  let [showAddLeaveOnTeam, setShowAddLeaveOnTeam] = useState(false);

  let shortenTeamNameForMobOnly = (val) => {
    if (val.length > 27) {
      return `${val.substr(0, 24)}...`;
    } else {
      return val;
    }
  };

  let [selectedType, setSelectedType] = useState("allTypes");
  let [selectedTypeIsAbsence, setSelectedTypeIsAbsence] = useState(false);
  let [leaveTypesArr, setLeaveTypesArr] = useState([]);
  let [dayLetterArr, setDayLetterArr] = useState([]);
  let [hoveredReqID, setHoveredReqID] = useState({ absence: false, reqID: "" });
  let [showClosedDay, setShowClosedDay] = useState("");

  let nowObj = new Date();
  let [selectedMy, setSelectedMy] = useState(
    `${nowObj.getMonth() < 10 ? "0" : ""}${nowObj.getMonth()}${JSON.stringify(
      nowObj.getFullYear()
    ).substr(2, 2)}`
  );

  let [currMy, setCurrMy] = useState(
    `${nowObj.getMonth() < 10 ? "0" : ""}${nowObj.getMonth()}${JSON.stringify(
      nowObj.getFullYear()
    ).substr(2, 2)}`
  );

  const targetRef = useRef(null);
  const containerRef = useRef(null);
  let nullRef = useRef();

  const handleScroll = () => {
    // if (containerRef.current && targetRef.current && currMy === selectedMy) {
    //   const scrollTop = containerRef.current.scrollLeft;
    //   const targetOffset = targetRef.current.offsetLeft;
    //   const position = targetOffset - scrollTop;
    //   containerRef.current.scrollLeft = position - (memoVals.mob ? 210 : 0);
    // } else {
    //   containerRef.current.scrollLeft = 0;
    // }
  };

  useEffect(() => {
    // clean not needed
    if (!loading) {
      handleScroll();
    }
  }, [loading, selectedMy]);
  let [myArr, setMyArr] = useState([]);
  let [teams, setTeams] = useState([]);
  let [selectedTeam, setSelectedTeam] = useState("");
  let [dayComments, setDayComments] = useState([]);

  useEffect(() => {
    // cleaned
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    if (!myArr[0]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: false,
            getMyArr: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.my1 && response.data.myArrLength) {
              if (!response.data.my2) {
                setMyArr([response.data.my1]);
              } else {
                let arr = [];
                let dateObj = new Date();
                dateObj.setMonth(parseInt(response.data.my1.substr(0, 2)));
                dateObj.setFullYear(
                  parseInt(`20${response.data.my1.substr(2, 2)}`)
                );
                for (var i = 0; i < response.data.myArrLength; i++) {
                  arr.push(
                    `${
                      dateObj.getMonth() < 10 ? "0" : ""
                    }${dateObj.getMonth()}${JSON.stringify(
                      dateObj.getFullYear()
                    ).substr(2, 2)}`
                  );
                  dateObj.setMonth(dateObj.getMonth() + 1);
                }

                setMyArr(arr);
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (!teams[0]) {
      axios
        .post(
          `${serverURL}/get-teams-user-manages`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (!response.data.array || response.data.array.length === 0) {
              axios
                .post(
                  `${serverURL}/get-individuals-user-manages`,
                  {
                    someData: 1,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setIndividuals(response.data.usersArr);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
              //   setShowIndiOnlymodal(true);
            }
            setTeams(response.data.array);
            if (response.data.array[0]) {
              setSelectedTeam(response.data.array[0].teamID);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, []);

  let [breakTeams, setBreakTeams] = useState(false);
  let teamsBar = useRef();

  useEffect(() => {
    // clean not needed
    if (!memoVals.mob && teams[0]) {
      let done = false;
      if (window.innerWidth < 970) {
        if (teams.length > 3) {
          done = true;
          setBreakTeams(true);
        } else {
          setBreakTeams(false);
        }
      }

      if (window.innerWidth < 1070) {
        if (teams.length > 4) {
          done = true;

          setBreakTeams(true);
        } else {
          if (!done) {
            setBreakTeams(false);
          }
        }
      }

      if (window.innerWidth < 1270) {
        if (teams.length > 5) {
          done = true;

          setBreakTeams(true);
        } else {
          if (!done) {
            done = true;

            setBreakTeams(false);
          }
        }
      }

      if (teams.length > 7) {
        done = true;

        setBreakTeams(true);
      } else {
        if (!done) {
          setBreakTeams(false);
        }
      }
    }
  }, [teams, memoVals.width]);
  let shortenJt = (jt) => {
    if (jt && jt.length > 40) {
      return `${jt.substr(0, 23)}...`;
    } else {
      return jt;
    }
  };

  let generateTeamsSelectBar = teams
    .sort((a, b) => {
      // Sort by 'primary' in descending order (true first)
      if (a.primary && !b.primary) {
        return -1;
      }
      if (!a.primary && b.primary) {
        return 1;
      }

      // If 'primary' values are the same, sort alphabetically by 'teamName'
      return a.teamName.localeCompare(b.teamName);
    })

    .map((team, i) => {
      if (memoVals.mob || breakTeams) {
        return (
          <option key={team.teamID} value={team.teamID}>
            {shortenTeamNameForMobOnly(team.teamName)}
          </option>
        );
      } else {
        return (
          <Fragment>
            {" "}
            <div
              key={team.teamID}
              onClick={() => {
                if (selectedTeam !== team.teamID) {
                  // if (!loading) {
                  setSelectedTeam(team.teamID);

                  // }

                  // axios request to get team shifts
                }
              }}
              className={`teamNameAbsence ${
                selectedTeam === team.teamID ? "absenceTeamSelected" : ""
              } `}
            >
              {team.teamName}
            </div>
            {i !== teams.length - 1 ? (
              <div className="subHeadSplit inlineTeamSplit"></div>
            ) : (
              ""
            )}
          </Fragment>
        );
      }
    });

  let generateMys = myArr.map((my) => {
    return (
      <option value={my} key={my}>
        {dateStringer.printMonthAndYearFromMy(
          my,
          (memoVals.mob && window.innerWidth > 475) ||
            (!memoVals.mob && window.innerWidth > 940)
            ? true
            : false,
          (memoVals.mob && window.innerWidth > 475) ||
            (!memoVals.mob && window.innerWidth > 940)
            ? true
            : false
        )}
      </option>
    );
  });

  return (
    <div
      className={`userShiftsPage ${
        !memoVals.mob ? "desktopTeamSchedHeight" : "mobTeamShiftBgColour"
      }`}
    >
      {" "}
      <div className="blackCornerBg"></div>
      <div
        className={`userShiftsPageUpperContainer purpleBg ${
          memoVals.mob ? "teamHeaderColours" : ""
        }`}
      >
        {!memoVals.mob ? <TopBar teamSchedule={true} /> : ""}
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
        <p
          className={`addShiftBtnOnTeamShifts
           ${loading ? "disableAddBtn" : ""}
        ${
          (memoVals.showNotifications || memoVals.showMessages) && memoVals.mob
            ? "none"
            : ""
        }`}
          onClick={() => {
            if (!loading) {
              //   setAddShiftPage(0);
              //   setAddShiftOrigin("onTeam");
              //   setShowTilOrShiftPopUp(false);
              setShowAddLeaveOnTeam(true);
              //   setShowTilOrShiftPopUp(true);
            }
          }}
        >
          +
        </p>
        {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
        <div
          className={`userShiftsUpperInnerContainer ${
            memoVals.mob ? "mobUpper" : ""
          }`}
        >
          <div
            className={`teamShiftsTitleContainer ${
              memoVals.mob
                ? "teamShiftsTitleContainerMob"
                : "desktopTeamRotaTitleBlock"
            }`}
          >
            <div
              className={`teamShiftsTitle ${
                memoVals.mob
                  ? "colourWhite teamShiftsTitleMob"
                  : `headerTitleSize staffCalDesktopTitle ${
                      breakTeams ? "staffCalBreakTeams" : ""
                    } headerTitleColourMgr`
              } `}
            >
              {!memoVals.mob ? <div className="staffTitleLeftBlob"></div> : ""}
              Staff calendar
            </div>
          </div>

          <NavLink
            to="/calendar"
            onClick={() => {
              memoVals.setCalPage((x) => "calendar");
            }}
          >
            <div
              className={`staffRotaBtn ${
                memoVals.mob ? "staffRotaBtnMob" : ""
              } blueColours`}
            >
              My calendar{" "}
              <img
                src={whiteGo}
                alt="Staff rota"
                className="staffRotaWhiteGoImg"
              />{" "}
            </div>
          </NavLink>
        </div>{" "}
        {!memoVals.mob && !breakTeams ? (
          <div
            className={`shiftsTeamBar ${
              !memoVals.mob ? "desktopStaffCalTeamsMenu" : ""
            }`}
          >
            {generateTeamsSelectBar}
          </div>
        ) : (
          ""
        )}
        {memoVals.mob || breakTeams ? (
          // <select
          //   className={`teamRotaTeamSelectDropdown ${
          //     breakTeams && !memoVals.mob ? "breakTeamsPushDown" : ""
          //   } ${memoVals.mob ? "mobTeamSelectLeft" : ""}`}
          //   ref={teamsBar}
          //   onChange={(e) => {
          //     setSelectedTeam(e.target.value);
          //     setLoading(true);
          //   }}
          // >
          //   {generateTeamsSelectBar}
          // </select>
          <select
            className={`teamRotaTeamSelectDropdown teamRotaTeamSelectDropdownStaff ${
              breakTeams && !memoVals.mob
                ? `breakTeamsPushDown ${
                    window.innerWidth > 910
                      ? "teamRotaTeamSelectDropdownStaffWider910"
                      : ""
                  }`
                : "teamRotaTeamSelectDropdownStaffMob"
            }`}
            ref={teamsBar}
            value={selectedTeam}
            onChange={(e) => {
              setSelectedTeam(e.target.value);
              setLoading(true);
              // setState({ loading: true });
              // memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
              // setSelectedHorizAdd("");
              // setSelectedHorizAddShift("");
              // setShowTools(false);
            }}
          >
            {generateTeamsSelectBar}
          </select>
        ) : (
          ""
        )}
        {/* <img src={navyFade} className="fadedPngOverTeams" alt="fade" /> */}
      </div>
      <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg">
        <div className="adminCurve"></div>
      </div>{" "}
      <div
        className={`shiftsPageFilterBarRota noPadLeft teamSchedBar teamShiftsSectionTeamOnly ${
          !memoVals.mob ? "borderLeftGrey" : "mobRotaTopLine"
        } noBorderRight`}
      >
        <div
          className={`teamSchedFilterBarLeft ${
            memoVals.mob ? "paddingLeft2" : ""
          }`}
        >
          <img
            src={arrGrey}
            alt="Left"
            className={`leftMonWeek ${
              selectedMy === myArr[0] || loading ? "fadeSelMonWeekBtn" : ""
            } ${memoVals.mob ? "none" : ""}`}
            onClick={() => {
              if (selectedMy !== myArr[0]) {
                let ind = myArr.indexOf(selectedMy);
                setSelectedMy(myArr[ind - 1]);
              }
            }}
          />
          <select
            className={`monthDropdown ${
              selectedMy === currMy ? "colour00aaff" : ""
            } ${
              (memoVals.mob && window.innerWidth > 475) ||
              (!memoVals.mob && window.innerWidth > 940)
                ? "x92020121113"
                : ""
            }`}
            value={selectedMy}
            onChange={(e) => {
              setSelectedMy(e.target.value);
            }}
          >
            {generateMys}
          </select>{" "}
          <img
            src={arrGrey}
            alt="Right"
            className={`rightMonWeek ${
              selectedMy === myArr[myArr.length - 1] || loading
                ? "fadeSelMonWeekBtn"
                : ""
            }`}
            onClick={() => {
              if (selectedMy !== myArr[myArr.length - 1]) {
                let ind = myArr.indexOf(selectedMy);
                setSelectedMy(myArr[ind + 1]);
              }
            }}
          />
        </div>{" "}
        <div
          className={`teamSchedFilterBarRight ${
            loading ? "loadingSchedFade" : ""
          }`}
        >
          {!memoVals.mob && selectedType === "allTypes" ? (
            <div className="staffCalKeyDiv">
              <div
                className="staffCalKeyItem"
                onClick={() => {
                  setSelectedType("absenceOnly");
                }}
              >
                <div className="keyItemBlobAbsence"></div>
                <p className="keyItemTxt">Absent leave</p>
              </div>
              <div
                className="staffCalKeyItem"
                onClick={() => {
                  setSelectedType("leaveOnly");
                }}
              >
                <div className="keyItemBlobLeave"></div>
                <p className="keyItemTxt">Requested leave</p>
              </div>
            </div>
          ) : (
            ""
          )}
          {!memoVals.mob && selectedType !== "allTypes" ? (
            <div className="staffCalKeyDiv">
              <div
                className="staffCalKeyItem"
                onClick={() => {
                  setSelectedType("allTypes");
                }}
              >
                <div className="keyItemBlobAbsence"></div>
                <div className="keyItemBlobLeave marginLeft5px"></div>
                <p className="keyItemTxt">Show all types</p>
              </div>
            </div>
          ) : (
            ""
          )}
          <select
            className={`staffCalTypeDropdown ${
              selectedType !== "allTypes" ? "colour00aaff" : ""
            } ${selectedTypeIsAbsence ? "colour516b8b" : ""}`}
            value={selectedType}
            onChange={(e) => {
              if (e.target.value === "--") {
                setSelectedType("leaveOnly");
              } else if (e.target.value === "---") {
                setSelectedType("absenceOnly");
              } else {
                let checkAbs = leaveTypesArr.filter((item) => {
                  return item.leaveTypeID === e.target.value;
                });

                if (checkAbs[0] && checkAbs[0].abs) {
                  setSelectedTypeIsAbsence(true);
                } else {
                  setSelectedTypeIsAbsence(false);
                }
                setSelectedType(e.target.value);
              }
            }}
          >
            {leaveTypesArr.map((item, indx) => {
              return (
                <option key={indx} value={item.leaveTypeID}>
                  {dateStringer.shorten(item.name, 26)}
                </option>
              );
            })}
          </select>{" "}
        </div>
      </div>{" "}
      <div className="shiftTilFilterHolder"></div>
      <div
        className={`filterSplitterContainer ${
          !memoVals.mob ? "borderLeftGrey" : ""
        }`}
      >
        <div className={`${!memoVals.mob ? "borderLeftGrey" : ""}`}></div>
      </div>
      <div
        className={`teamSchedulePage bgMgrGrey ${
          !memoVals.mob ? "desktopTeamSchedHeight" : ""
        }`}
      >
        {/* new content */}

        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading"
            className="teamSchedLoadingBallsImg"
          />
        ) : (
          ""
        )}

        <div
          className={`teamSchedContainer ${
            !memoVals.mob
              ? "teamSchedDesktop whiteBg"
              : `teamCalContainerMob ${
                  memoVals.device.ios ? "teamCalContainerMobIos" : ""
                }`
          } ${!memoVals.mob ? "borderLeftGrey_" : ""} ${
            loading ? "none" : ""
          } ${memoVals.device.vhIssue ? "vhIssue" : ""}`}
        >
          <TeamCalendarInlet
            isMgr={true}
            setSelectedTeam={setSelectedTeam}
            setSelectedMy={setSelectedMy}
            loading={loading}
            selectedMy={selectedMy}
            selectedTeam={selectedTeam}
            mob={memoVals.mob}
            setLoading={setLoading}
            setShowDayCommentModal={setShowDayCommentModal}
            setMyArr={setMyArr}
            myArr={myArr}
            leaveTypesArr={leaveTypesArr}
            setLeaveTypesArr={setLeaveTypesArr}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            showClosedDay={showClosedDay}
            setShowClosedDay={setShowClosedDay}
          />{" "}
        </div>
        {/* end new content */}
      </div>
      {showClosedDay ? (
        <div
          className="indiOnlyUnderlay"
          onClick={() => {
            setShowClosedDay("");
          }}
        >
          <div className="overlapsRenewalModalBox">
            <div className="overlapsRenTxt">{showClosedDay}</div>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowClosedDay("");
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {renderLeaveReqModal()} */}
      {showAddLeaveOnTeam ? (
        <AddShiftModal2
          teamID={selectedTeam}
          type="leave"
          setShowAddLeaveOnTeam={setShowAddLeaveOnTeam}
          loadFromReqsPage={
            new Date().getTime() <
            dateStringer.createStringFromTimestamp(new Date().getTime())
          }
        />
      ) : (
        ""
      )}
      {/* modals can go here */}
    </div>
  );
};

export default StaffCalendar;
