import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";
import websiteURL from "../websiteURL";

import navyClose from "../img/general/navyClose.svg";

import bin143051 from "../img/general/bin143051.svg";
import makeOpenArr from "../img/general/makeOpenArr.svg";

import popup from "../img/general/popupNavy.svg";

import unitTick from "../img/general/unitTick.svg";
import unitCross from "../img/general/unitCross.svg";

import checkboxInstall1 from "../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../img/general/checkboxInstall2purp.svg";
import rotaTeam from "../img/general/rotaTeam.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import rotaPerson from "../img/general/rotaPerson.svg";
import rotaCoffee from "../img/general/rotaCoffee.svg";
import rotaTag from "../img/general/rotaTag.svg";
import greyArr2 from "../img/general/greyArr2.svg";

import closeGrey from "../img/general/closeGrey.svg";
import blueLoader from "../img/general/blueLoader.gif";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import "../styles/calendar.css";

const DeclinedShiftModal = () => {
  const {
    showDeclinedShiftModal,
    setShowDeclinedMShiftModal,
    device,
    countData,
    setCountData,
    removeDeclinedItemID,
    setRemoveDeclinedItemID,
    setIndicate,
    setOpenManagerShiftModal,
    setShowReqMgrModal,
    showReqMgrModal,
    showUserHoursModal,
    setShowUserHoursModal,
  } = useContext(DataContext);
  const { setUpdateInfo, updateInfo, setShowOpenShiftModal } =
    useContext(CalendarContext);
  const { mobModal, mob } = useContext(StyleContext);
  // const { currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      mobModal,
      mob,
      showDeclinedShiftModal,
      setShowDeclinedMShiftModal,
      device,
      removeDeclinedItemID,
      setRemoveDeclinedItemID,
      setShowOpenShiftModal,
      setIndicate,
      setOpenManagerShiftModal,
      setShowReqMgrModal,
      showReqMgrModal,
      showUserHoursModal,
      setShowUserHoursModal,
    }),
    [
      mobModal,
      mob,
      device,
      showDeclinedShiftModal,
      setShowDeclinedMShiftModal,
      removeDeclinedItemID,
      setRemoveDeclinedItemID,
      setShowOpenShiftModal,
      setIndicate,
      setOpenManagerShiftModal,
      setShowReqMgrModal,
      showReqMgrModal,
      showUserHoursModal,
      setShowUserHoursModal,
    ]
  );

  let [saveLoading, setSaveLoading] = useState(false);

  let closeModal = () => {
    memoVals.setShowDeclinedMShiftModal((x) => "");
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    });
  }, []);
  const cancelSource1 = axios.CancelToken.source();

  let [teamName, setTeamName] = useState("");
  let [startDs, setStartDs] = useState("");
  let [endDs, setEndDs] = useState("");
  let [loading, setLoading] = useState(true);
  let [type, setType] = useState("");
  let [reason, setReason] = useState("");
  let [otherUsers, setOtherUsers] = useState([]);
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [jobTitle, setJobTitle] = useState("");
  let [profPicUrl, setProfPicUrl] = useState("");
  let [tags, setTags] = useState([]);
  let [brk, setBrk] = useState(0);
  let [actionLoading, setActionLoading] = useState(false);
  let [makeOpen, setMakeOpen] = useState(false);
  let [reassign, setReassign] = useState(false);
  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-declined-shift-data`,
        {
          itemID: memoVals.showDeclinedShiftModal,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setStartDs(response.data.startDs);
          setEndDs(response.data.endDs);
          setTeamName(response.data.teamName);
          setType(response.data.type);
          setReason(response.data.reason);
          setOtherUsers(response.data.otherUsers);
          setFName(response.data.fName);
          setLName(response.data.lName);
          setJobTitle(response.data.jobTitle);
          setTags(response.data.tags || []);
          setBrk(response.data.brk || 0);
          setProfPicUrl(response.data.profPicUrl);
          setLoading(false);
        }
        if (response.data.message === "ticket does not exist") {
          closeModal();
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`bulkEditLimitUnderlay ${
        memoVals.showReqMgrModal ? "invis" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      <div
        className={`addLeaveModalBox ${
          memoVals.mobModal
            ? "addLeaveModalBoxMob mobModalShoulder slideUp"
            : "zoomIn"
        } ${memoVals.device.ios ? "paddingBottom20" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {/* END OF OVERLAPS RENEWAL MODAL */}
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddLeaveModal"></div>
        )}
        <div
          className="addLeaveModalBoxHeader spaceBetween"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={navyClose}
            alt="Close"
            className="addLeaveModalHeaderboxCloseImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p className="addLeaveModalHeaderBoxTitle">Declined item</p>
          {/* <img
            src={navyClose}
            alt="Close"
            className="addLeaveModalHeaderboxCloseImg invis"
          />{" "} */}
        </div>
        {loading ? (
          <div
            className={`editShiftModalBody addLeaveModalBody ${
              !memoVals.mobModal ? "desktopAddLeaveModalBody" : ""
            } centerLoadingDeclined`}
          >
            <img
              src={horizontalBalls}
              alt="Loading"
              className="notifReqsLoadingImg"
            />
          </div>
        ) : (
          <div
            className={`editShiftModalBody addLeaveModalBody ${
              !memoVals.mobModal ? "desktopAddLeaveModalBody" : ""
            } decShBody ${reassign ? "decShBodyReassign" : ""}`}
            onClick={() => {}}
          >
            <div className="decShUpper">
              {/* <div className="teamShRightBlock"> */}

              <div className="decShUserRightWrapper">
                <p className="decShUserRightTitle">
                  {type === "overtime" || type === "til" ? "Overtime" : "Shift"}{" "}
                  declined by
                </p>
                <div className="decShUserBlock">
                  <img
                    src={profPicUrl || profilePicturePlaceholder}
                    alt={fName}
                    className="decShUserPP"
                  />{" "}
                  <div className="decShUserRight">
                    <p className="decShUserUpper">
                      {fName}{" "}
                      {`${fName} ${lName}`.length > 19 ? lName[0] : lName}
                    </p>
                    <p className="decShUserJt">
                      {dateStringer.shorten(jobTitle, 52) || "No job title"}
                    </p>
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="absTimelineVal absTimelineValReqs marginRight0 marginTopMinus32">
                {" "}
                <div className={`shItemTimes shItemTimesDecSh`}>
                  <p className="shItemTimeRow shItemTimeRowDecSh">
                    {dateStringer.dsToTimeStrip(startDs, true)}
                  </p>
                  <div className="shItemTimeRowSplitter"></div>
                  <p className="shItemTimeRow shItemTimeRowDecSh">
                    {dateStringer.dsToTimeStrip(endDs, true)}
                  </p>
                </div>
                <div className={`absCalDiv`}>
                  <div className="absCalDivUpper">
                    <div className="absDivCalNip"></div>
                    <div className="absDivCalNip"></div>
                  </div>
                  <div
                    className={`absCalDivLower absCalDivLowerAllTime absCalDivLowerAllTimeDecSh`}
                  >
                    {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                    <p className="kkk1">{parseInt(startDs.substr(9, 2))}</p>
                    <p className="kkk2">
                      {dateStringer.getThreeDayLettersFromDs(startDs)}
                    </p>{" "}
                    <p className="kkk3">
                      {dateStringer.getMonthThreeLettersFromDs(startDs)}
                      {/* {unit.startDs.substr(1, 4)} */}
                    </p>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="decShTeamBlock">
              <img src={rotaTeam} alt={teamName} className="decShTeamNameImg" />
              <p className="decShTeamName">
                {dateStringer.shorten(teamName, 22)}
              </p>
            </div>
            <div className="decShTeamBlock">
              <img src={rotaCoffee} alt={brk} className="decShTeamNameImg" />
              <p className="decShTeamName">
                {dateStringer.formatMinsDurationToHours(brk)}
              </p>
            </div>
            {tags[0] ? (
              <div className="decShTeamBlock">
                <img
                  src={rotaTag}
                  alt={brk}
                  className="decShTeamNameImg decShTeamNameImgTag"
                />
                <div className="decShTeamName">
                  {tags.map((tag, i) => {
                    return (
                      <p
                        className={`decShTag ${i === 0 ? "decShTagFirst" : ""}`}
                        key={i}
                      >
                        {tag}
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}

            {reason ? <p className="decShReasonTitle">Reason</p> : ""}
            {reason ? <p className="decShReason">{reason}</p> : ""}

            <div className="decShActionSplitter"></div>
            <p
              className={`decShUserRightTitle decShActionTxt ${
                reassign ? "decShActionTxtReassign" : ""
              }`}
            >
              {reassign ? `Re-assign to:` : "Action"}
            </p>

            {reassign && (
              <div className="reassignTitleKey">
                <p className="reassignTitleKeyLeft">xx</p>
                <p className="reassignTitleKeyRight">
                  {dateStringer.printedDateFromDs(startDs)}:
                </p>
              </div>
            )}
            {reassign ? (
              <div className="reassignDiv slideUp">
                {otherUsers.map((user, i) => {
                  return (
                    <div
                      className={`reassignUserRow ${
                        i === otherUsers.length - 1 ? "noBorder" : ""
                      }`}
                      index={user.userID}
                    >
                      <div className="reassignUserRowLeft">
                        {" "}
                        <div className="reassignUserRowLeftUpper">
                          {" "}
                          <img
                            src={user.profPicUrl || profilePicturePlaceholder}
                            alt={user.fName}
                            className="reassignUserImg"
                          />
                          <div className="reassignUserNameJt">
                            <p className="reassignUserNameUpper">
                              {user.fName}{" "}
                              {`${user.fName} ${user.lName}`.length > 14
                                ? user.lName[0]
                                : user.lName}
                            </p>
                            <p className="reassignUserNameLower">
                              {dateStringer.shorten(
                                user.jobTitle || "No job title",
                                24
                              )}
                            </p>
                          </div>
                        </div>{" "}
                        <div className="reassignUserRowLeftLower">
                          <p
                            className="viewWeekHoursBtn"
                            onClick={() => {
                              console.log({
                                show: true,
                                userID: user.userID,
                                monWeek: dateStringer.getMonWeekOfDs(startDs),
                              });
                              memoVals.setShowUserHoursModal((x) => {
                                return {
                                  show: true,
                                  userID: user.userID,
                                  monWeek: dateStringer.getMonWeekOfDs(startDs),
                                };
                              });
                            }}
                          >
                            View hours{" "}
                            <img src={popup} className="wkHRpopupImg" />
                          </p>
                        </div>
                      </div>
                      <div className="reassignUserRowSplitter"></div>
                      <div className="reassignUserRowRight">
                        {user.shiftsAndTils.map((sh, i) => {
                          return (
                            <div
                              className={`reassignShUnit ${
                                sh.canManage ? "rassignShUnitClickable" : ""
                              }`}
                              key={i}
                              onClick={() => {
                                if (sh.canManage) {
                                  memoVals.setOpenManagerShiftModal((x) => {
                                    return {
                                      shiftID:
                                        sh.type === "shift" ? sh.itemID : "",
                                      tilID:
                                        sh.type === "til" ||
                                        sh.type === "overtime"
                                          ? sh.itemID
                                          : "",
                                      fName: user.fName,
                                      lName: user.lName,
                                      openedFromShiftsPage: false,
                                      type: sh.type,
                                    };
                                  });
                                }
                              }}
                            >
                              <div className="reassignShUnitStrip">
                                <p className="reassignShUnitStripTop">
                                  {sh.type === "shift"
                                    ? "Shift"
                                    : sh.type === "overtime"
                                    ? "Overtime (paid)"
                                    : "Overtime (lieu)"}
                                </p>
                                <p className="reassignShUnitStripBottom">
                                  {dateStringer.dsToTimeStrip(sh.start, true)} -{" "}
                                  {dateStringer.dsToTimeStrip(sh.end, true)}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                        {user.leavesAndAbsences.map((req, i) => {
                          return (
                            <div
                              className={`reassignShUnit ${
                                req.isAbsence
                                  ? "reassignShUnitAbsence"
                                  : "reassignShUnitLeave"
                              } ${
                                req.canManage ? "rassignShUnitClickable" : ""
                              }`}
                              key={i}
                              onClick={() => {
                                if (req.canManage) {
                                  memoVals.setShowReqMgrModal((x) => req.reqID);
                                }
                              }}
                            >
                              <div className="reassignShUnitStrip">
                                <p className="reassignShUnitStripTop">
                                  {dateStringer.shorten(req.leaveTypeName, 18)}
                                </p>
                                <p className="reassignShUnitStripBottom">
                                  {req.firstHalfOff
                                    ? "First half off"
                                    : req.lastHalfOff
                                    ? "Last half off"
                                    : "Full day off"}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                        {user.nonWorkingDay && (
                          <p className="decShNwDayTxt">
                            {user.fName} is not contracted to work this day
                          </p>
                        )}
                        <p
                          className={`assignToUserBtn lightBlueColours_ ${
                            actionLoading ? "disabled opac05" : ""
                          }`}
                          onClick={() => {
                            setActionLoading(true);
                            axios
                              .post(
                                `${serverURL}/assign-declined-shift`,
                                {
                                  userID: user.userID,
                                  itemID: memoVals.showDeclinedShiftModal,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setRemoveDeclinedItemID(
                                    memoVals.showDeclinedShiftModal
                                  );
                                  setCountData({
                                    declined: countData.declined - 1,
                                  });
                                  closeModal();
                                  memoVals.setIndicate((x) => {
                                    return {
                                      show: true,
                                      message: `${
                                        type === "shift" ? "Shift" : "Overtime"
                                      } assigned to ${user.fName}`,
                                      colour: "green",
                                      duration: 4000,
                                      ts: new Date().getTime(),
                                    };
                                  });
                                }
                              });
                          }}
                        >
                          Assign{" "}
                          <img
                            src={greyArr2}
                            alt="Assign"
                            className="assignWhiteGo"
                          />
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={`decShBtns ${
                  actionLoading ? "disabled opac05" : ""
                }`}
              >
                <p
                  className="decShBtn"
                  onClick={() => {
                    setActionLoading(true);
                    axios
                      .post(
                        `${serverURL}/disregard-declined-shift`,
                        {
                          itemID: memoVals.showDeclinedShiftModal,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setCountData({
                            declined: countData.declined - 1,
                          });
                          setRemoveDeclinedItemID(
                            memoVals.showDeclinedShiftModal
                          );
                          closeModal();
                        }
                      });
                  }}
                >
                  Disregard{" "}
                  <img
                    src={bin143051}
                    alt="Disregard"
                    className="decShBtnDisregard"
                  />
                </p>{" "}
                <div className="decDisregardSplit"></div>
                <p
                  className="decShBtn"
                  onClick={() => {
                    setReassign(true);
                  }}
                >
                  Re-assign{" "}
                  <img
                    src={rotaPerson}
                    alt="Disregard"
                    className="decShBtnDisregard"
                  />
                </p>
                <p
                  className="decShBtn"
                  onClick={() => {
                    setMakeOpen(true);
                  }}
                >
                  Open up
                  <img
                    src={makeOpenArr}
                    alt="Disregard"
                    className="decShBtnDisregard decShBtnDisregardOpenUp"
                  />
                </p>
              </div>
            )}
          </div>
        )}
        <div className="addLeaveToUserModalFooter flexDirectionRow">
          <p
            className="closeClockOnDiv"
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>

          {saveLoading ? (
            <img
              src={blueLoader}
              alt="Loading"
              className="addLeaveLoadingImg"
            />
          ) : (
            <p></p>
          )}
        </div>
      </div>
      {makeOpen ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureMakeOpen(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Turn this declined {type === "shift" ? "shift" : "overtime"} into
              an open {type === "shift" ? "shift" : "overtime"} for anybody else
              in {teamName} to claim?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setActionLoading(true);
                  setMakeOpen(false);
                  axios
                    .post(
                      `${serverURL}/make-declined-shift-open`,
                      {
                        itemID: memoVals.showDeclinedShiftModal,

                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setRemoveDeclinedItemID(
                          memoVals.showDeclinedShiftModal
                        );
                        setCountData({
                          declined: countData.declined - 1,
                        });
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: `${
                              type === "shift" ? "Shift" : "Overtime"
                            } made open to ${teamName}`,
                            colour: "green",
                            duration: 4000,
                            ts: new Date().getTime(),
                          };
                        });
                        memoVals.setShowOpenShiftModal((x) => {
                          return {
                            ds: response.data.ds,
                            openID: response.data.openID,
                            new: false,
                            teamID: response.data.teamID,
                          };
                        });

                        closeModal();
                        // setSingleDay(state.dayN);
                        // memoVals.setOpenShiftConvert((x) => {
                        //   return {
                        //     direction: "toOpen",
                        //     openID: response.data.openID,
                        //     ds: response.data.ds,
                        //     startDs: response.data.startDs,
                        //     endDs: response.data.endDs,
                        //     teamID: response.data.teamID,
                        //     tags: response.data.tags,
                        //   };
                        // });

                        // setUpdate2(!update2);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes - open
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setMakeOpen(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DeclinedShiftModal;
