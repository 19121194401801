import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import { CalendarContext } from "../contexts/CalendarContext";

import { NavLink } from "react-router-dom";
import "../styles/calendar.css";
import CheckUser from "../tools/CheckUser";
import dateStringer from "../tools/dateStringer";

import serverURL from "../serverURL";
import logo from "../img/general/logo.svg";

import Navbar from "./Navbar";
import { nextTick } from "process";

const ResetPassword = () => {
  const { calPage, setCalPage, userAbsenceArr, setUserAbsencesArr } =
    useContext(CalendarContext);
  // const {} = useContext(UserContext);

  const { mob } = useContext(StyleContext);

  let [fName, setFName] = useState("");
  let [resetID, setResetID] = useState("");
  let [expired, setExpired] = useState(false);
  let [noParams, setNoParams] = useState(false);
  let [emailVal, setEmailVal] = useState("");
  let [requested, setRequested] = useState(false);
  let [emailDoesNotExist, setEmailDoesNotExist] = useState(false);
  let [done, setDone] = useState(false);
  useEffect(() => {
    document.title = "Reset your FlowRota password";
  }, []);
  let savePass = () => {
    if (done) {
      window.location.href = "/signin";
    } else {
      if (passValue && passValue.length > 6 && resetID) {
        axios
          .post(
            `${serverURL}/save-new-password`,
            {
              password: passValue,
              resetID,
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            console.log(response);
            if (response.data.message === "success") {
              setDone(true);
            }

            if (response.data.invalid) {
              setDone(false);
              setNoParams(true);
            }
          });
      }
    }
  };

  let next = () => {
    if (emailVal && emailVal.includes("@")) {
      axios
        .post(
          `${serverURL}/request-password-reset-email`,
          {
            email: emailVal,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.message === "doesNotExist") {
            setEmailDoesNotExist(true);
            console.log("DEOS NOT EXIT");
          }
          if (response.data.message === "success") {
            setRequested(true);
            setHideResend(true);
            setEmailDoesNotExist(false);
            setTimeout(() => {
              setHideResend(false);
            }, 3000);
          }
        });
    }
  };

  let enterFct = (e) => {
    if (e.key === "Enter") {
      savePass();
    }
  };

  let enterFctNext = (e) => {
    if (e.key === "Enter") {
      next();
    }
  };

  useEffect(() => {
    var elements = document.getElementsByClassName("flowWidget");

    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.visibility = "hidden";
      }
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (
      params &&
      dateStringer.cleanParam(params.resetID) &&
      dateStringer.cleanParam(params.fName)
    ) {
      setFName(dateStringer.cleanParam(params.fName));
      setResetID(dateStringer.cleanParam(params.resetID));
    } else {
      setNoParams(true);
    }
  }, []);

  useEffect(() => {}, []);

  let [passValue, setPassValue] = useState("");

  let [hideResend, setHideResend] = useState(false);

  return (
    <div className="resetPasswordPage">
      <img src={logo} alt="FlowRota logo" className="resetPassLogo" />
      {!expired && !noParams ? (
        <p className="resetPassTitle">
          {done
            ? "Your password has been successfully changed"
            : `Hi, ${fName}. Enter your new password.`}
        </p>
      ) : (
        ""
      )}

      {!done && !expired && !noParams && !requested ? (
        <input
          type="password"
          className="resetPassInput"
          value={passValue}
          onChange={(e) => {
            setPassValue(e.target.value);
          }}
          onKeyPress={(e) => {
            enterFct(e);
          }}
        />
      ) : (
        ""
      )}

      {!expired && !noParams && !requested ? (
        <p
          className="requestNewPassBtn"
          onClick={() => {
            savePass();
          }}
        >
          {done ? "Sign in" : "Save"}
        </p>
      ) : (
        ""
      )}
      {(!done && expired) || (noParams && !requested) ? (
        <p className="resetPassTitle">
          To reset your password, first enter your email address associated with
          your FlowRota account
        </p>
      ) : (
        ""
      )}
      {(!done && expired) || (noParams && !requested) ? (
        <input
          value={emailVal}
          type="text"
          className="resetPassInputEmail"
          onChange={(e) => {
            setEmailVal(e.target.value);
          }}
          onKeyPress={(e) => {
            enterFctNext(e);
          }}
        />
      ) : (
        ""
      )}

      {!done && emailDoesNotExist ? (
        <p className="emailDoesNotExistTxt">
          That email isn't registered with FlowRota 🤔
        </p>
      ) : (
        ""
      )}

      {(!done && expired) || (noParams && !requested) ? (
        <p
          className="requestNewPassBtn"
          onClick={() => {
            next();
          }}
        >
          Next
        </p>
      ) : (
        ""
      )}

      {!done && requested ? (
        <p className="resetPassTitle">
          Check your emails for an email we have sent to you with a link to
          reset your password
        </p>
      ) : (
        ""
      )}

      {!done && requested && !hideResend ? (
        <p
          className="requestNewPassBtn"
          onClick={() => {
            if (emailVal && emailVal.includes("@")) {
              axios
                .post(
                  `${serverURL}/request-password-reset-email`,
                  {
                    email: emailVal,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  console.log(response);

                  if (response.data.message === "success") {
                    setRequested(true);
                    setHideResend(true);
                    setEmailDoesNotExist(false);
                    setTimeout(() => {
                      setHideResend(false);
                    }, 10000);
                  }
                });
            }
          }}
        >
          Re-send email
        </p>
      ) : (
        ""
      )}
    </div>
    // <div className={`calendarComponent ${mob ? "mobCalComponent" : ""}`}>
    //   <Navbar />
    //   <CalendarSubBar />

    //   <div
    //     className={`rotaPage ${
    //       !mob ? "pageLeft240pxDesktop" : "mobHeightMyRota"
    //     }`}
    //   >
    //     {!mob ? <TopBar /> : ""}
    //     <div className="myRotaMainUpper">
    //       <br />
    //       <br />
    //       hello
    //     </div>
    //     <div className={`rotaBody ${mob ? "mobRotaBodyHeight" : ""}`}>
    //       calendar goes here
    //     </div>
    //   </div>
    // </div>
  );
};

export default ResetPassword;
