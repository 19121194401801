import React, { useContext, useState, useEffect, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import dateStringer from "../../../tools/dateStringer";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { UserContext } from "../../../contexts/UserContext";
import { AdminContext } from "../../../contexts/AdminContext";

import { StyleContext } from "../../../contexts/StyleContext";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import popup from "../../../img/general/popup.svg";
import whiteTick from "../../../img/general/whiteTick.svg";

import serverURL from "../../../serverURL";

// import special days subcomponent
import SpecialDays from "./subcomponents/SpecialDays";
import { DataContext } from "../../../contexts/DataContext";

// Ignore public holidays
// if disabled, show: region, rate, business shut, pay even if shut
// aka Public Holidays tab in admin in react
const ClosedDays = ({ sureDelete, setSureDelete }) => {
  const { setCheckUserState, checkUserState, currencySymbol } =
    useContext(UserContext);
  const { setShowClosedDaysHistory, device } = useContext(DataContext);
  const {
    enablePublicHols,
    setEnablePublicHols,
    closedPublicHols,
    setClosedPublicHols,
    payPublicHols,
    setPayPublicHols,
    publicHolExclusions,
    setAllowSwaps,
    setSelfGenSched,
    setDailyRates,

    monRate,
    setMonRate,
    tueRate,
    setTueRate,
    wedRate,
    setWedRate,
    thuRate,
    setThuRate,
    friRate,
    setFriRate,
    satRate,
    setSatRate,
    sunRate,
    setSunRate,
    monClosed,
    setMonClosed,
    tueClosed,
    setTueClosed,
    wedClosed,
    setWedClosed,
    thuClosed,
    setThuClosed,
    friClosed,
    setFriClosed,
    satClosed,
    setSatClosed,
    sunClosed,
    setSunClosed,

    setPublicHolExclusions,
    setSpecialDaysData,
  } = useContext(AdminContext);

  const { setAccNav, mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      checkUserState,
      currencySymbol,
      enablePublicHols,
      setEnablePublicHols,
      closedPublicHols,
      setClosedPublicHols,
      payPublicHols,
      setPayPublicHols,
      publicHolExclusions,
      setPublicHolExclusions,
      setShowClosedDaysHistory,
      setSpecialDaysData,
      mob,
      device,
    }),
    [
      checkUserState,
      currencySymbol,
      enablePublicHols,
      setEnablePublicHols,
      closedPublicHols,
      setClosedPublicHols,
      payPublicHols,
      setPayPublicHols,
      publicHolExclusions,
      setPublicHolExclusions,
      setSpecialDaysData,
      setShowClosedDaysHistory,
      mob,
      device,
    ]
  );

  let [publicHolsRegion, setPublicHolsRegion] = useState("Englang & Wales");
  let [publicHolsRate, setPublicHolsRate] = useState(1);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [nightShiftRate, setNightShiftRate] = useState("1");

  useEffect(() => {
    if (dataLoaded) {
      setLoading(false);
    }
  }, [dataLoaded]);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    setAccNav("admin");
  }, []);

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/day-rules";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-public-holiday-admin-data`,
        { someData: 1 },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDailyRates(response.data.dailyRates);

          setNightShiftRate(response.data.nightShiftRate || 1);

          response.data.closedDays.forEach((cd) => {
            if (cd.day === 0) {
              setSunClosed(cd.closed);
            } else if (cd.day === 1) {
              setMonClosed(cd.closed);
            } else if (cd.day === 2) {
              setTueClosed(cd.closed);
            } else if (cd.day === 3) {
              setWedClosed(cd.closed);
            } else if (cd.day === 4) {
              setThuClosed(cd.closed);
            } else if (cd.day === 5) {
              setFriClosed(cd.closed);
            } else if (cd.day === 6) {
              setSatClosed(cd.closed);
            }
          });

          let filterMon = response.data.dailyRates.filter((item) => {
            return item.day === "mon";
          });
          setMonRate(filterMon[0].rate);

          let filterTue = response.data.dailyRates.filter((item) => {
            return item.day === "tue";
          });
          setTueRate(filterTue[0].rate);

          let filterWed = response.data.dailyRates.filter((item) => {
            return item.day === "wed";
          });
          setWedRate(filterWed[0].rate);

          let filterThu = response.data.dailyRates.filter((item) => {
            return item.day === "thu";
          });
          setThuRate(filterThu[0].rate);

          let filterFri = response.data.dailyRates.filter((item) => {
            return item.day === "fri";
          });
          setFriRate(filterFri[0].rate);

          let filterSat = response.data.dailyRates.filter((item) => {
            return item.day === "sat";
          });
          setSatRate(filterSat[0].rate);

          let filterSun = response.data.dailyRates.filter((item) => {
            return item.day === "sun";
          });
          setSunRate(filterSun[0].rate);
          memoVals.setEnablePublicHols((x) => response.data.enablePublicHols);
          memoVals.setClosedPublicHols(
            (x) => response.data.closedDuringPublicHols
          );
          memoVals.setPayPublicHols((x) => response.data.payPublicHols);
          setPublicHolsRegion(response.data.region);
          memoVals.setPublicHolExclusions(
            (x) => response.data.availablePublicHols
          );
          setPublicHolsRate(response.data.publicHolsRate);

          axios
            .get(`${serverURL}/get-special-days`, {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource2.token,
            })
            .then((response) => {
              if (!response.data.error) {
                let dataToAdd = [];
                response.data.specialDaysArray.forEach((item) => {
                  if (item.date) {
                    dataToAdd.push({
                      name: item.name,
                      _id: item._id,
                      date: item.date,
                      rate: item.rate,
                      companyClosed: item.companyClosed,
                      closedPay: item.closedPay,
                      tsForOrder: dateStringer.createTimestampFromString(
                        item.date
                      ),
                    });
                  }
                });
                memoVals.setSpecialDaysData((x) => dataToAdd);
                setDataLoaded(true);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, []);

  let [highlightDays, setHighlightDays] = useState(false);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (
      window.location.href.includes("highlight") ||
      (params && dateStringer.cleanParam(params.days))
    ) {
      setHighlightDays(true);

      if (params && params.highlight) {
        window.history.pushState({}, document.title, window.location.pathname);
      }
    }

    return () => {
      setHighlightDays(false);
    };
  }, [setHighlightDays]);

  let generatePublicHolExclusions =
    memoVals.publicHolExclusions &&
    memoVals.publicHolExclusions.map((excl, i) => {
      return (
        <div key={i} className="exclusionUnit" key={excl.title}>
          <p className="exclusionTitle">{excl.title}</p>
          <div
            className="excluKnobIgnoreContainer"
            onClick={() => {
              axios
                .post(
                  `${serverURL}/add-or-remove-public-hol-exclusion`,
                  {
                    addOrRemove: excl.enabled ? "add" : "remove",
                    title: excl.title,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    let newArr = [];
                    memoVals.publicHolExclusions.forEach((ph) => {
                      if (ph.title === excl.title) {
                        newArr.push({
                          title: excl.title,
                          enabled: excl.enabled ? false : true,
                        });
                      } else {
                        newArr.push(ph);
                      }
                    });

                    memoVals.setPublicHolExclusions((x) => newArr);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            {/*  KNOB BEGIN */}
            <div className="knobContainer exclusionKnob">
              <div
                className={`switchKnob ${excl.enabled ? "knobOn" : "knobOff"}`}
              ></div>
              <div className="knobBg"></div>
            </div>
            {/*  KNOB END */}
            <p
              className={`${
                excl.enabled ? "ignoreExcluActive" : "ignoreExcluInactive"
              }`}
            >
              {excl.enabled ? "Active" : "Inactive"}
            </p>
          </div>
        </div>
      );
    });

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob
          ? `AdminSettingsContainerMob ${
              memoVals.device.ios ? "AdminSettingsContainerMobIos" : ""
            }`
          : "borderRightGrey"
      }`}
    >
      <div className="whiteGradientAdmin"></div>

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          <div
            className={`AdminRowContainer absenceAdminRow ${
              memoVals.mob ? "mobileAdminRow" : ""
            } ${highlightDays ? "hightlightDays" : ""}`}
          >
            <div className="settingsRow">
              <div>
                <p className="absenceRowMainText">
                  Day rates &amp; closed days
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Define the rates of pay for each day, and whether or not your
                  company is closed. You can still schedule shifts on closed
                  days.
                </p>
                <p
                  className="closedDaysHistoryBtn"
                  onClick={() => {
                    memoVals.setShowClosedDaysHistory((x) => true);
                  }}
                >
                  History <img src={popup} className="closedDaysHistoryPopUp" />
                </p>
              </div>
            </div>
            {/* <div className="dailyRatesWrapper"> */}
            <div className="dailyRatesDataContainer">
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Monday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={monRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "mon" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setMonRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <div className="openClosedTxtContainer">
                    <p
                      className="closedDayTxt"
                      onClick={() => {
                        let today = new Date();
                        today.setHours(0, 0, 0, 0);
                        axios
                          .post(
                            `${serverURL}/edit-closed-day`,
                            {
                              day: 1,
                              data: !monClosed,
                              ds: dateStringer.createStringFromTimestamp(
                                today.getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data) {
                              if (response.data.message === "success") {
                                setMonClosed(!monClosed);
                                setCheckUserState({
                                  checklistDone: response.data.checklistDone,
                                });
                              }
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >{`${monClosed ? "Closed" : "Open"}`}</p>
                  </div>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 1,
                          data: !monClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setMonClosed(!monClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !monClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow ">
                <p className="dailyPayRateTxt">Tuesday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={tueRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "tue" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setTueRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 2,
                            data: !tueClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setTueClosed(!tueClosed);
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${tueClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 2,
                          data: !tueClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setTueClosed(!tueClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !tueClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Wednesday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={wedRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "wed" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setWedRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 3,
                            data: !wedClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setWedClosed(!wedClosed);
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${wedClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}

                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 3,
                          data: !wedClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setWedClosed(!wedClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !wedClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Thursday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={thuRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "thu" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setThuRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 4,
                            data: !thuClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setThuClosed(!thuClosed);
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${thuClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 4,
                          data: !thuClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setThuClosed(!thuClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !thuClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Friday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={friRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "fri" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setFriRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 5,
                            data: !friClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setFriClosed(!friClosed);
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${friClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 5,
                          data: !friClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setFriClosed(!friClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !friClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Saturday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={satRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "sat" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setSatRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 6,
                            data: !satClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                              setSatClosed(!satClosed);
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${satClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 6,
                          data: !satClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setSatClosed(!satClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !satClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
              <div className="dailyRateRowSplitter"></div>
              <div className="dailyPayRateRow">
                <p className="dailyPayRateTxt">Sunday</p>
                <select
                  className="dailyPayRateDropdown"
                  value={sunRate}
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/change-daily-rate`,
                        { data: e.target.value, day: "sun" },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setSunRate(val);
                          setCheckUserState({
                            checklistDone: response.data.checklistDone,
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>

                <div className="openClosedTxtContainer">
                  <p
                    className="closedDayTxt"
                    onClick={() => {
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      axios
                        .post(
                          `${serverURL}/edit-closed-day`,
                          {
                            day: 0,
                            data: !sunClosed,
                            ds: dateStringer.createStringFromTimestamp(
                              today.getTime()
                            ),
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data) {
                            if (response.data.message === "success") {
                              setSunClosed(!sunClosed);
                              setCheckUserState({
                                checklistDone: response.data.checklistDone,
                              });
                            }
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >{`${sunClosed ? "Closed" : "Open"}`}</p>
                </div>
                {/*  KNOB BEGIN */}
                <div
                  className="knobContainer knobContainerSettings  knobContainer knobContainerSettings CompanyClosed"
                  onClick={() => {
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    axios
                      .post(
                        `${serverURL}/edit-closed-day`,
                        {
                          day: 0,
                          data: !sunClosed,
                          ds: dateStringer.createStringFromTimestamp(
                            today.getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data) {
                          if (response.data.message === "success") {
                            setSunClosed(!sunClosed);
                            setCheckUserState({
                              checklistDone: response.data.checklistDone,
                            });
                          }
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div
                    className={`switchKnob ${
                      !sunClosed ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>{" "}
            </div>

            {highlightDays && (
              <p
                className="saveCheckListDaysBtn lilacColours"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/check-done`,
                      {
                        number: "7",
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        // action data here
                        setCheckUserState({
                          checklistDone: response.data.checklistDone,
                        });
                        setHighlightDays(false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Complete checklist action{" "}
                <img
                  src={whiteTick}
                  alt="Complete"
                  className="compChTickWhiteImg"
                />
              </p>
            )}
            {/* </div> */}
          </div>
          {/* - - - -  */}{" "}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow pHolRegionRow">
              <div className="rowWithSubTextNightShift">
                <p className="pHolRegTitleTxt">Night shift rate</p>
                <p className="absenceRowSubText overtimeRowSubText noPaddingLeft">
                  Night shift rates will override any rates set above. Each
                  shift has the option of being flagged as a night shift or day
                  shift and mangers can manually amend the rate regardless of
                  night shift status.
                </p>
              </div>
              <div>
                {/* <PublicHolsList
                publicHolsRegion={publicHolsRegion}
                setPublicHolsRegion={setPublicHolsRegion}
              /> */}
                <select
                  className="publicHolsDropDown nightShiftRateDrop"
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/amend-night-shift-rate`,
                        {
                          rate: parseFloat(val),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setNightShiftRate(response.data.nightShiftRate);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  value={nightShiftRate}
                  placeholder={nightShiftRate}
                >
                  <option value="0">0x</option>

                  <option value="0.25">0.25x</option>

                  <option value="0.5">0.5x</option>

                  <option value="0.75">0.75x</option>

                  <option value="1">1x</option>

                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          <div
            className={`${
              memoVals.enablePublicHols || !memoVals.enablePublicHols
                ? `AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
                    memoVals.mob ? "mobileAdminRow" : ""
                  }`
                : "none"
            } `}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-public-hols-company-closed`,
                  { data: !memoVals.closedPublicHols },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    memoVals.setClosedPublicHols((x) => response.data.data);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">
                  Closed during{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holidays
                </p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Is{" "}
                  {memoVals.checkUserState.companyName || "your organisation"}{" "}
                  closed on{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                  holidays? If so, FlowRota won't schedule staff on these days.{" "}
                  <br />
                  <br />
                  <span className="makeBold">Paid days off</span> will be
                  created for employees who are contracted to work on a day that
                  falls on a closed{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holiday
                  (when generating weekly rotas).
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer  ">
                  <div
                    className={`switchKnob ${
                      memoVals.closedPublicHols ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          <div
            className={`${
              memoVals.closedPublicHols
                ? `AdminRowContainer absenceAdminRow clickShiftRowAdmin ${
                    memoVals.mob ? "mobileAdminRow" : ""
                  }`
                : "none"
            }`}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/toggle-pay-public-hols`,
                  { data: !memoVals.payPublicHols },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    axios
                      .post(
                        `${serverURL}/get-public-holiday-admin-data`,
                        { someData: 1 },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          memoVals.setEnablePublicHols(
                            (x) => response.data.enablePublicHols
                          );
                          memoVals.setClosedPublicHols(
                            (x) => response.data.closedDuringPublicHols
                          );
                          memoVals.setPayPublicHols(
                            (x) => response.data.payPublicHols
                          );
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            <div className="settingsRow">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Public holidays are paid</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  Are your employees (whose contracted days fall on{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"}{" "}
                  holidays) paid even if{" "}
                  {memoVals.checkUserState.companyName || "your organisation"}{" "}
                  is shut? For example, if an employee is contracted to work
                  Mondays, but{" "}
                  {memoVals.checkUserState.companyName || "your business"} is
                  shut due to it being a{" "}
                  {memoVals.currencySymbol === "£" ? "bank" : "public"} holiday,
                  will the employee still be paid?
                </p>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                <div className="knobContainer  ">
                  <div
                    className={`switchKnob ${
                      memoVals.payPublicHols ? "knobOn" : "knobOff"
                    }`}
                  ></div>

                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow pHolRegionRow">
              <p className="pHolRegTitleTxt">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holidays
                region
              </p>
              <div>
                {/* <PublicHolsList
                publicHolsRegion={publicHolsRegion}
                setPublicHolsRegion={setPublicHolsRegion}
              /> */}
                <select
                  className="publicHolsDropDown"
                  onChange={(e) => {
                    let val = e.target.value;
                    axios
                      .post(
                        `${serverURL}/select-public-hols-region`,
                        {
                          data: val,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setPublicHolsRegion(response.data.publicHolsRegion);

                          axios
                            .post(
                              `${serverURL}/get-available-public-hols`,
                              {
                                someData: 1,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setPublicHolExclusions(
                                  (x) => response.data.newArr
                                );
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                  value={publicHolsRegion}
                  placeholder={publicHolsRegion}
                >
                  <option value="None">Please choose</option>
                  <option value="England &amp; Wales">
                    England &amp; Wales
                  </option>
                  <option value="Scotland">Scotland</option>
                  <option value="Northern Ireland">Northern Ireland</option>
                </select>
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow pHolRegionRow">
              <p className="pHolRegTitleTxt">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holiday
                rate
              </p>
              <div>
                <select
                  className="publicHolsDropDown rateDropDownShortened"
                  onChange={(e) => {
                    setPublicHolsRate(e.target.value);

                    axios
                      .post(
                        `${serverURL}/public-hol-rate`,
                        {
                          data: e.target.value,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.error) {
                          console.log("Could not update public hols rate");
                        } else {
                          console.log(
                            "updated the pHols rate region successfully"
                          );
                        }
                      });
                  }}
                  value={publicHolsRate}
                  placeholder={publicHolsRate}
                >
                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                  <option value="2.25">2.25x</option>
                  <option value="2.5">2.5x</option>
                  <option value="2.75">2.75x</option>
                  <option value="3">3x</option>
                  <option value="3.25">3.25x</option>
                  <option value="3.5">3.5x</option>
                  <option value="3.75">3.75x</option>
                  <option value="4">4x</option>
                </select>
              </div>
            </div>
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <p className="pubHolExclusionTitle">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holidays
              </p>
              <div className="holExclusionsContainer">
                {generatePublicHolExclusions}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer ${
              memoVals.mob ? "mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow">
              <p className="settingTitleWithQuery">Special days</p>
            </div>

            <div className="specialDaysHolder">
              <SpecialDays
                sureDelete={sureDelete}
                setSureDelete={setSureDelete}
              />
            </div>

            {/* <SpecialDays /> */}
          </div>
          {/* ROW ------------------------------------------------------------------------ */}
          <br />
        </div>
      )}
    </div>
  );
};

export default ClosedDays;
