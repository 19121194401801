import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo,
  useReducer,
} from "react";
// cleaned wed 24 jul 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23
// image ready 4 oct 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import dateStringer from "../../../tools/dateStringer";
import serverURL from "../../../serverURL";
import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";
import "../../../styles/calendar.css";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import shiftBlock from "../../../img/general/shiftBlockNavy.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import rotaTeam from "../../../img/general/rotaTeam.svg";
import rotaClock from "../../../img/general/rotaClock.svg";
import rotaZzz from "../../../img/general/rotaZzz.svg";
import rotaTag from "../../../img/general/rotaTag.svg";
import editNavy from "../../../img/general/editNavy.svg";
import rotaReq from "../../../img/general/rotaReq.svg";
import rotaCoffee from "../../../img/general/rotaCoffee.svg";
import navyScissors from "../../../img/general/navyScissors.svg";
import rotaDur from "../../../img/general/rotaDur.svg";
import commentRota from "../../../img/general/commentRota.svg";
import greenTick2 from "../../../img/general/greenTick2.svg";
import thickCross from "../../../img/general/thickCrossNotif.svg";
import greyBin from "../../../img/general/greyBin.svg";

import whitecross from "../../../img/general/whitecross.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import shiftChangeArrowGreen from "../../../img/general/shiftChangeArrowGreen.svg";
import shiftChangeArrowRed from "../../../img/general/shiftChangeArrowRed.svg";
import blueLoader from "../../../img/general/blueLoader.gif";
import checkboxInstall1 from "../../../img/general/checkboxInstall1.svg";
import checkboxInstall2 from "../../../img/general/checkboxInstall2.svg";
import greyTick from "../../../img/general/greyTick.svg";
import unitTick from "../../../img/general/unitTick.svg";
import darkRedCross from "../../../img/general/darkRedCross.svg";
import rotaClock2 from "../../../img/general/rotaClock2.svg";
import rotaCoffee2 from "../../../img/general/rotaCoffee2.svg";
import filterWhite from "../../../img/general/filterWhite.svg";
import greyThreeDots from "../../../img/general/greyThreeDots.svg";
import time from "../../../img/general/shiftBlock.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import arrGrey from "../../../img/general/arrGrey.svg";
import rotaCross from "../../../img/general/rotaCross.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import navyClose from "../../../img/general/navyClose.svg";

import doubleTick from "../../../img/general/doubleTick.svg";
import lightBlueQuestionMark from "../../../img/general/lightBlueQuestionMark.svg";
import teamIcon from "../../../img/general/teamIcon2.svg";
import historyClockWhite from "../../../img/general/historyClockWhite.svg";

const UserShifts = ({ sideBoxData, my, setMy }) => {
  let todayMidnight = new Date();
  todayMidnight.setHours(0, 0, 0, 0);
  let [today00Ts, setToday00Ts] = useState(todayMidnight.getTime());
  let [todayMy, setTodayMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );

  let [currMy, setCurrMy] = useState(
    dateStringer.getMyFromTs(new Date().getTime())
  );
  let [showPre, setShowPre] = useState(false);
  let [preQty, setPreQty] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 39) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyClose}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaZzz}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greyBin}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={thickCross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greenTick2}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={commentRota}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={navyScissors}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCoffee}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={historyClockWhite}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editNavy}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={teamIcon}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaTeam}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTag}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaReq}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftBlock}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={arrGrey}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lightBlueQuestionMark}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={doubleTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="Shift Change Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowGreen}
        alt="Shift Change Arrow Green"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrowRed}
        alt="Shift Change Arrow Red"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Blue Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall1}
        alt="Checkbox Install 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkboxInstall2}
        alt="Checkbox Install 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyTick}
        alt="Grey Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="Unit Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={darkRedCross}
        alt="Dark Red Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock2}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee2}
        alt="Rota Coffee 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyThreeDots}
        alt="Grey Three Dots"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={time}
        alt="Time"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const { setCalPage, updateChanges, setUpdateChanges, reverse } =
    useContext(CalendarContext);

  const {
    updateShifts,
    setUpdateShifts,
    setShowMyShiftModal,
    availableMonths,
    setPage,
    device,
    countMyChanges,
    setCountMyChanges,
    showChanges,
    setShowChanges,
    removeMyChangeEditID,
    updateMyShiftsAndTils,
    notYetAccepted,
    setNotYetAccepted,
    showYetToAcceptOnly,
    setShowYetToAcceptOnly,
    acceptedShiftID,
    setAcceptedShiftID,
    declinedShiftID,
    setDeclinedShiftID,
    setIndicate,
    countData,
    setCountData,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      updateShifts,
      setUpdateShifts,
      setShowMyShiftModal,
      availableMonths,
      setPage,
      device,
      countMyChanges,
      setCountMyChanges,
      showChanges,
      setShowChanges,
      removeMyChangeEditID,
      updateMyShiftsAndTils,
      notYetAccepted,
      setNotYetAccepted,
      showYetToAcceptOnly,
      setShowYetToAcceptOnly,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      setIndicate,
      mob,
      full,
      fullButNarrow,
      setCalPage,
      updateChanges,
      setUpdateChanges,
      reverse,
    }),
    [
      updateShifts,
      setUpdateShifts,
      setShowMyShiftModal,
      availableMonths,
      setPage,
      device,
      countMyChanges,
      setCountMyChanges,
      showChanges,
      setShowChanges,
      removeMyChangeEditID,
      updateMyShiftsAndTils,
      notYetAccepted,
      setNotYetAccepted,
      showYetToAcceptOnly,
      setShowYetToAcceptOnly,
      acceptedShiftID,
      setAcceptedShiftID,
      declinedShiftID,
      setDeclinedShiftID,
      setIndicate,
      mob,
      full,
      fullButNarrow,
      setCalPage,
      updateChanges,
      setUpdateChanges,
      reverse, //
    ]
  );

  useEffect(() => {
    if (typeof memoVals.showChanges === "boolean" && memoVals.showChanges) {
      memoVals.setShowChanges((x) => false);
    }

    if (memoVals.showYetToAcceptOnly) {
      memoVals.setShowYetToAcceptOnly((x) => false);
    }
  }, []);

  // shifts, team-shifts, clock-ons
  let [loadChange, setLoadChange] = useState(false);

  // shifts, swaps, stats
  useEffect(() => {
    // clean not needed
    if (memoVals.showChanges) {
      // memoVals.setShowChanges(x =>false);
    }
  }, []);

  let [allShiftsAndTils, setAllShiftsAndTils] = useState([]);
  let [finalPreTodayIndex, setFinalPreTodayIndex] = useState(-1);
  useEffect(() => {
    if (my === todayMy) {
      let preFilt = allShiftsAndTils.filter((x) => {
        return x.midnightTimestamp < today00Ts;
      });
      setPreQty(preFilt.length);
    }
  }, [allShiftsAndTils, my]);
  useEffect(() => {
    if (memoVals.acceptedShiftID) {
      let newArr = allShiftsAndTils.map((x) => {
        if (
          (x.data.shiftID && x.data.shiftID === memoVals.acceptedShiftID) ||
          (x.data.tilID && x.data.tilID === memoVals.acceptedShiftID)
        ) {
          return Object.assign({}, x, {
            accepted: dateStringer.createStringFromTimestamp(
              new Date().getTime()
            ),
          });
        }
        return x;
      });
      setAllShiftsAndTils(newArr);
      memoVals.setNotYetAccepted((x) => memoVals.notYetAccepted - 1);

      memoVals.setAcceptedShiftID((x) => "");
    }

    if (memoVals.declinedShiftID) {
      let newArr = [];
      allShiftsAndTils.forEach((x) => {
        if (x.data.shiftID && x.data.shiftID === memoVals.declinedShiftID) {
        } else if (x.data.tilID && x.data.tilID === memoVals.declinedShiftID) {
        } else {
          newArr.push(x);
        }
      });
      setAllShiftsAndTils(newArr);
      memoVals.setNotYetAccepted((x) => memoVals.notYetAccepted - 1);
      memoVals.setDeclinedShiftID((x) => "");
    }
  }, [memoVals.acceptedShiftID, memoVals.declinedShiftID]);
  let [showFilter, setShowFilter] = useState(false);
  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);
  let [sureCancel, setSureCancel] = useState({ show: false });
  let [newChanges, setNewChanges] = useState(false);

  let [changesData, setChangesData] = useState([]);
  let [undecidedFinalIndex, setUndecidedFinalIndex] = useState(-1);

  let [showGhostModal, setShowGhostModal] = useState(false);
  let [ghostTxt, setGhostTxt] = useState("");

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.changes) {
      console.log("params: ", params);
      memoVals.setShowChanges((x) => true);
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }

    if (params && params.toaccept) {
      memoVals.setShowYetToAcceptOnly((x) => true);
    }

    if (params && params.shiftID && params.type && !params.mgr) {
      if (dateStringer.cleanParam(params.type) === "unknown") {
        axios
          .post(
            `${serverURL}/check-shift-til`,
            {
              shiftID: dateStringer.cleanParam(params.shiftID),
            },

            {
              withCredentials: true,
              credentials: "include",
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: response.data.type,
                  unitID: dateStringer.cleanParam(params.shiftID),
                  otherUser: dateStringer.cleanParam(params.other),
                };
              });
            }
          });
      } else {
        memoVals.setShowMyShiftModal((x) => {
          return {
            unitType: dateStringer.cleanParam(params.type),
            unitID: dateStringer.cleanParam(params.shiftID),
            showNotes: dateStringer.cleanParam(params.notes),
            otherUser: dateStringer.cleanParam(params.other),
          };
        });
      }
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let [dsData, setDsData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      todayDs: "",
      yesterdayDs: "",
      tomorrowDs: "",
    }
  );

  useEffect(() => {
    setLoading(true);
    const cancelSource1 = axios.CancelToken.source();

    let todayDateObj = new Date();
    todayDateObj.setHours(0, 0, 0, 0);
    let yesterday = new Date(todayDateObj.getTime());
    yesterday.setDate(yesterday.getDate() - 1);

    let tomorrow = new Date(todayDateObj.getTime());
    tomorrow.setDate(tomorrow.getDate() + 1);

    setDsData({
      todayDs: dateStringer.createStringFromTimestamp(todayDateObj.getTime()),
      yesterdayDs: dateStringer.createStringFromTimestamp(yesterday.getTime()),
      tomorrowDs: dateStringer.createStringFromTimestamp(tomorrow.getTime()),
    });

    let m = my.substr(0, 2);
    let y = my.substr(2, 2);

    if (m === "00" && y === "00") {
      let today = new Date();
      m = today.getMonth();
      y = today.getFullYear();
    }

    if (!memoVals.showChanges) {
      axios
        .post(
          `${serverURL}/get-my-shifts-and-til`,
          {
            month: parseInt(m),
            year: parseInt(y),
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            showYetToAcceptOnly,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setNotYetAccepted((x) => response.data.notYetAccepted);
            setAllShiftsAndTils(response.data.data);
            if (dataLoaded) {
              setDataLoaded(false);
            }
            setDataLoaded(true);
            memoVals.setCountMyChanges((x) => response.data.countMyChanges);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [
    memoVals.updateShifts,
    memoVals.showChanges,
    memoVals.updateMyShiftsAndTils,
    memoVals.showYetToAcceptOnly,
  ]);

  let changesScrollBox = useRef();
  useEffect(() => {
    if (changesScrollBox.current && memoVals.showChanges) {
      changesScrollBox.current.scrollTop = 0;

      // setTimeout(() => {
      //   changesScrollBox.current.scrollTo({
      //     top: 0, // Scroll to the bottom of the div
      //     behavior: "smooth", // Smooth scroll behavior
      //   });
      // }, 1);
    }
  }, [memoVals.showChanges]);
  let hoursScrollBox = useRef();
  useEffect(() => {
    // clean not needed
    hoursScrollBox.current.scrollTop = 0;
  }, [my]);

  let generateMonthOptions = memoVals.availableMonths.map((month, i) => {
    let formatMonth = "Jan";
    let formatYear = month.substr(2, 2);
    if (month.substr(0, 2) === "00") {
      formatMonth = "January";
    }
    if (month.substr(0, 2) === "01") {
      formatMonth = "February";
    }
    if (month.substr(0, 2) === "02") {
      formatMonth = "March";
    }
    if (month.substr(0, 2) === "03") {
      formatMonth = "April";
    }
    if (month.substr(0, 2) === "04") {
      formatMonth = "May";
    }
    if (month.substr(0, 2) === "05") {
      formatMonth = "June";
    }
    if (month.substr(0, 2) === "06") {
      formatMonth = "July";
    }
    if (month.substr(0, 2) === "07") {
      formatMonth = "August";
    }
    if (month.substr(0, 2) === "08") {
      formatMonth = "September";
    }
    if (month.substr(0, 2) === "09") {
      formatMonth = "October";
    }
    if (month.substr(0, 2) === "10") {
      formatMonth = "November";
    }
    if (month.substr(0, 2) === "11") {
      formatMonth = "December";
    }

    return (
      <option key={i} value={month}>
        {formatMonth} 20{formatYear}
      </option>
    );
  });

  allShiftsAndTils.sort(function (a, b) {
    //   if (!memoVals.reverse) {
    //     return b.startTs - a.startTs;
    //   } else {
    return a.startTs - b.startTs;
    //   }
  });

  useEffect(() => {
    // clean not needed
    document.title = "My hours - FlowRota";

    memoVals.setCalPage((x) => "shifts");
    if (memoVals.showChanges) {
      memoVals.setPage((x) => "reqs");
    } else {
      memoVals.setPage((x) => "shifts");
    }
  }, []);

  // let renderMyShiftModal = () => {
  //   if (showMyShiftModal) {
  //     return <MyShiftModal unitType={itemType} unitID={itemID} />;
  //   }
  // };

  // ------------------------------------------------------------------------
  let [loadingCancel, setLoadingCancel] = useState(false);

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  let generateMyShiftsAndTils = React.useMemo(() => {
    return allShiftsAndTils.map((unit, i) => {
      // if (unit.preSplit) {
      // return <div className="preSplitter preSplitterMyHours" key={i}></div>;
      // } else {
      if (
        my !== todayMy ||
        (my === todayMy && (unit.midnightTimestamp >= today00Ts || showPre))
      ) {
        let click = () => {
          if (!unit.data.ghost) {
            if (unit.type === "shift") {
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: "shift",
                  unitID: unit.data.shiftID,
                };
              });
            } else {
              memoVals.setShowMyShiftModal((x) => {
                return {
                  unitType: unit.type,
                  unitID: unit.data.tilID,
                };
              });
            }
          } else {
            // unit is ghost
            if (unit.type === "shift") {
              axios
                .post(
                  `${serverURL}/get-ghost-shift-info`,
                  {
                    ds: `${unit.data.start.substr(0, 11)}H00M00`,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setGhostTxt(response.data.result);
                    setShowGhostModal(true);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              // is til
              axios
                .post(
                  `${serverURL}/get-ghost-shift-info`,
                  {
                    ds: `${unit.data.ds.substr(0, 11)}H00M00`,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setGhostTxt(response.data.result);
                    setShowGhostModal(true);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        };

        let dataRows = 0;
        if (unit.data.tags && unit.data.tags[0]) {
          dataRows++;
        }

        if (unit.data.selfLoggedAbsence || unit.data.absent) {
          dataRows++;
        }

        if (unit.data.loggedDs) {
          dataRows++;
        }
        if (unit.outstandingChanges || unit.takeTilRequestMade) {
          dataRows++;
        }
        return (
          <div
            className={`dvStateHighlight dvStateHighlightShift ${
              !memoVals.mob ? "absShiftItemDesktop" : "absShiftItemMob"
            } newMyAbsShFlex ${finalPreTodayIndex === i ? "finalPreSh" : ""} ${
              dataRows === 0
                ? "dataRows0"
                : dataRows === 1
                ? "dataRows1"
                : dataRows === 2
                ? "dataRows2"
                : dataRows === 3
                ? "dataRows3"
                : dataRows === 4
                ? "dataRows4"
                : ""
            }`}
            key={i}
            onClick={() => {
              click();
            }}
          >
            <div
              className={`newMyAbsShLeft ${
                memoVals.mob ? "newMyAbsShLeftMob" : ""
              }`}
            >
              {/* {dataRows} */}
              {/* {unit.requireAccept && !unit.accepted && (
                <div className="myHoursNeedAcc"></div>
              )}
              {unit.requireAccept && !unit.accepted ? (
                ""
              ) : (
                <img
                  src={shiftBlock}
                  alt="User shift"
                  className={`openShiftBlockDayViewImg openShiftBlockDayViewImgShift x3289478222 ${
                    dataRows === 0
                      ? "dataRows0img"
                      : dataRows === 1
                      ? "dataRows1img"
                      : dataRows === 2
                      ? "dataRows2img"
                      : dataRows === 3
                      ? "dataRows3img"
                      : dataRows === 4
                      ? "dataRows4img"
                      : ""
                  }`}
                />
              )} */}
              <div className="dvStateHighlightedNote">
                <div className={`dvShiftNameRow`}>
                  <img
                    src={shiftBlock}
                    className="dvShPP"
                    alt={unit.teamName}
                  />{" "}
                  <p className={`dvShName userShItemTxtTitle`}>
                    {unit.type === "shift"
                      ? "Shift"
                      : unit.type === "til"
                      ? "Overtime (time in lieu)"
                      : "Overtime (paid)"}
                  </p>
                </div>
                <p className="dvStateHighlightedNoteName">
                  <img
                    src={rotaDate}
                    alt="Rota clock inline"
                    className="rotaClockOpenDvInlineImg x2975755567"
                  />
                  <span className="colourOOaaff marginRight4px x2948598226665">
                    {dateStringer
                      .createStringFromTimestamp(unit.startTs)
                      .substr(0, 11) === dsData.todayDs.substr(0, 11)
                      ? " Today"
                      : dateStringer
                          .createStringFromTimestamp(unit.startTs)
                          .substr(0, 11) === dsData.yesterdayDs.substr(0, 11)
                      ? " Yesterday"
                      : dateStringer
                          .createStringFromTimestamp(unit.startTs)
                          .substr(0, 11) === dsData.tomorrowDs.substr(0, 11)
                      ? " Tomorrow"
                      : ""}
                  </span>{" "}
                  {dateStringer.printedDateFromDs(
                    unit.type === "shift" ? unit.data.start : unit.data.ds
                  )}
                </p>
                {/* {open.tags[0] && (
            <div className="dayViewOpenTagsRow">
              <div className="dvOpenTag lilacColours_">
                {open.tags[0]}
              </div>
              {open.tags.length > 1 && (
                <div className="dvOpenTag lilacColours_">
                  +{open.tags.length - 1}
                </div>
              )}
            </div>
          )} */}
                <div className={`dvShiftNameRow `}>
                  <img
                    src={unit.data.ghost ? rotaZzz : rotaClock}
                    className="dvShPP"
                    alt={unit.fName}
                  />{" "}
                  {unit.data.ghost ? (
                    <p className="dvShName colour00aaff">
                      Organisation is closed
                    </p>
                  ) : (
                    <p className="dvShName colour143051_">
                      {unit.type === "shift"
                        ? dateStringer.dsToTimeStrip(unit.data.start)
                        : dateStringer.dsToTimeStrip(
                            `${unit.data.ds.substr(
                              0,
                              11
                            )}H${unit.data.start.substr(
                              0,
                              2
                            )}M${unit.data.start.substr(3, 2)}`
                          )}{" "}
                      -{" "}
                      {unit.type === "shift"
                        ? dateStringer.dsToTimeStrip(unit.data.end)
                        : dateStringer.dsToTimeStrip(
                            `${unit.data.ds.substr(
                              0,
                              11
                            )}H${unit.data.end.substr(
                              0,
                              2
                            )}M${unit.data.end.substr(3, 2)}`
                          )}
                      {unit.tmw ? " +1d" : ""}
                    </p>
                  )}
                </div>
                <div className={`dvShiftNameRow`}>
                  <img
                    src={unit.data.ghost ? rotaClock : rotaTeam}
                    className="dvShPP"
                    alt={unit.teamName}
                  />{" "}
                  <p
                    className={`dvShName ${
                      unit.data.ghost ? "colour143051" : "bbcada"
                    }`}
                  >
                    {unit.data.ghost
                      ? `Paid for ${dateStringer.formatMinsDurationToHours(
                          unit.durMins
                        )}`
                      : dateStringer.shorten(unit.teamName, 24) ||
                        "Unknown team"}
                  </p>
                </div>
                {unit.data.tags && unit.data.tags.length > 0 && (
                  <div className={`dvShiftNameRow `}>
                    <img src={rotaTag} className="dvShPP" alt="Tags" />{" "}
                    <div className="myHoursTagsInline">
                      {/* {unit.data.tags.map((tag, tagInd) => {
                          return ( */}
                      <div
                        className={`shTagInline myRotaTag x23784575 ${
                          unit.data.tags.length === 1 ? "noBorder" : ""
                        }`}
                      >
                        {dateStringer.shorten(unit.data.tags[0], 25)}
                      </div>
                      {unit.data.tags.length > 1 && (
                        <div
                          className={`shTagInline myRotaTag x23784575 noBorder`}
                        >
                          +{unit.data.tags.length - 1}
                        </div>
                      )}
                      {/* );
                        })} */}
                    </div>
                  </div>
                )}
                {(unit.data.selfLoggedAbsence || unit.data.absent) && (
                  <div className={`dvShiftNameRow `}>
                    <img src={rotaCross} className="dvShPP" alt="Tags" />{" "}
                    {unit.data.absent ? (
                      <p className="dvShName ba0000">
                        Absent
                        {unit.data.partialAbsent
                          ? `: ${unit.data.partialAbsentStart} - ${unit.data.partialAbsentEnd}`
                          : ""}
                      </p>
                    ) : (
                      <p className="dvShName ba0000">Reported absent</p>
                    )}
                  </div>
                )}
                {unit.data.loggedDs && (
                  <div className={`dvShiftNameRow `}>
                    <img
                      src={editNavy}
                      className="dvShPP dvShPPEditNavy"
                      alt="Tags"
                    />{" "}
                    <p className="dvShName">
                      Logged times: {unit.data.loggedStart} -{" "}
                      {unit.data.loggedEnd}{" "}
                    </p>
                  </div>
                )}
                {(unit.outstandingChanges || unit.takeTilRequestMade) && (
                  <div className={`dvShiftNameRow `}>
                    <img
                      src={
                        unit.outstandingChanges
                          ? editNavy
                          : unit.tilApproved
                          ? greenTick2
                          : rotaReq
                      }
                      className={`dvShPP ${
                        unit.outstandingChanges ? "dvShPPEditNavy" : ""
                      }`}
                      alt="Tags"
                    />{" "}
                    <p
                      className={`dvShName maxW180 ${
                        unit.outstandingChanges
                          ? ""
                          : unit.tilApproved
                          ? "ccApp"
                          : ""
                      }`}
                    >
                      {unit.outstandingChanges
                        ? unit.drop
                          ? "Requested to drop"
                          : `Change requested`
                        : `${dateStringer.formatMinsDurationToShortHours(
                            unit.tilMins
                          )} TOIL ${
                            unit.tilApproved ? "approved" : "requested"
                          }`}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="myShRightDiv">
              <div
                className={`newMyAbsShRightSec tilShiftDurationTxtTeam  absentButPaid ${
                  memoVals.mob ? "newMyAbsShRightSecMob" : ""
                } shiftItemDurMins ${unit.data.ghost ? "invis" : ""}`}
              >
                {dateStringer.formatMinsDurationToHours(unit.durMins)}
              </div>
              {unit.requireAccept && !unit.accepted && <br />}
              {unit.requireAccept && !unit.accepted && (
                <p
                  className="accShUserNewBtn blueColours"
                  onClick={(e) => {
                    e.stopPropagation();
                    let nowDs = dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    );
                    axios
                      .post(
                        `${serverURL}/accept-shift`,
                        {
                          shiftID: unit.data.shiftID || unit.data.tilID,
                          type: unit.type,
                          nowDs,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let newArr = allShiftsAndTils.map((x) => {
                            if (x.preSplit) {
                              return x;
                            }
                            if (
                              (x.data.shiftID &&
                                x.data.shiftID === unit.data.shiftID) ||
                              (x.data.tilID && x.data.tilID === unit.data.tilID)
                            ) {
                              return Object.assign({}, x, {
                                accepted: nowDs,
                              });
                            }
                            return x;
                          });

                          setAllShiftsAndTils(newArr);
                          setCountData({
                            shiftAccepts: countData.shiftAccepts - 1,
                          });
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: `${
                                unit.type === "til"
                                  ? "Lieu shift"
                                  : unit.type === "overtime"
                                  ? "Overtime"
                                  : "Shift"
                              } accepted`,
                              duration: 4000,
                              colour: "green",
                            };
                          });
                        }
                      });
                  }}
                >
                  Accept
                </p>
              )}
            </div>
          </div>
        );
      }
      // }
    });
  }, [
    showPre,
    allShiftsAndTils,
    memoVals.reverse,
    showOvertimes,
    showTils,
    showShifts,
    memoVals.mob,
    today00Ts,
    dsData,
  ]);

  useEffect(() => {
    if (memoVals.showYetToAcceptOnly) {
      if (!showShifts) {
        setShowShifts(true);
      }

      if (!showOvertimes) {
        setShowOvertimes(true);
      }

      if (!showTils) {
        setShowTils(true);
      }

      if (showFilter) {
        setShowFilter(false);
      }
    }
  }, [memoVals.showYetToAcceptOnly]);

  // ------------------------------------------------------------------------
  let [changesLoading, setChangesLoading] = useState(true);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (memoVals.showChanges) {
      setLoading(true);
      setChangesLoading(true);

      let m = my.substr(0, 2);
      let y = my.substr(2, 2);

      if (m === "00" && y === "00") {
        let today = new Date();
        m = today.getMonth();
        y = today.getFullYear();
      }

      axios
        .post(
          `${serverURL}/get-all-my-shift-changes`,
          {
            m,
            y,
            // showLatest: true,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // let needsAck = [];
            // let doesNotNeedAck = [];
            // response.data.data.forEach((obj) => {
            //   if (obj.requiresAck) {
            //     needsAck.push(obj);
            //   } else {
            //     doesNotNeedAck.push(obj);
            //   }
            // });

            // needsAck.sort(function (a, b) {
            //   return b.timestamp - a.timestamp;
            // });

            // doesNotNeedAck.sort(function (a, b) {
            //   return b.timestamp - a.timestamp;
            // });
            // setChangesData(
            let arr = response.data.data.sort((a, b) => {
              return b.timestamp - a.timestamp;
            });
            // );

            let pending = [];
            let nonPending = [];
            arr.forEach((x) => {
              if (!x.approved && !x.declined) {
                pending.push(x);
              } else {
                nonPending.push(x);
              }
            });

            if (pending[0] && nonPending[0]) {
              setUndecidedFinalIndex(pending.length - 1);
              if (!memoVals.mob) {
                pending.push({ split: true });
              }
            }
            setChangesData([...pending, ...nonPending]);

            setTimeout(() => {
              setChangesLoading(false);
            }, 500);
            setLoading(false);
            // nathan
            setAllShiftsAndTils([]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showChanges, my, memoVals.removeMyChangeEditID]);

  let pendingsExist = () => {
    let pend = 0;

    changesData.forEach((x) => {
      if (!x.approved && !x.declined && !x.split) {
        pend++;
      }
    });
    return pend;
  };

  let [selectedChangeID, setSelectedChangeID] = useState("");

  let generateChanges = changesLoading
    ? ""
    : changesData.map((change, i) => {
        if (
          change.split &&
          changesData.filter((x) => {
            return !x.approved && !x.declined && !x.split;
          })[0] &&
          !memoVals.mob
        ) {
          return (
            <div
              className="pendingTsSplitter marginTop23"
              key={i}
              onClick={() => {
                console.log(
                  "---",
                  changesData.filter((x) => {
                    return !x.approved && !x.declined && !x.split;
                  })
                );
              }}
            ></div>
          );
        } else {
          if (change.requested) {
            return (
              <div
                className={`newShChangeRow ${
                  i === undecidedFinalIndex && memoVals.mob
                    ? "newShChangeRowSplitFinalItem"
                    : ""
                }`}
              >
                {change.drop ? (
                  <div className="newShChangeRowTopRow">
                    <img
                      src={thickCross}
                      alt="Shift change"
                      className="newShChangeRowTopImg"
                    />
                    Request to drop{" "}
                    {change.newType === "shift" ? "shift" : "overtime"}
                  </div>
                ) : (
                  <div className="newShChangeRowTopRow">
                    <img
                      src={editNavy}
                      alt="Shift change"
                      className="newShChangeRowTopImg"
                    />
                    {change.newType === "shift" ? "Shift" : "Overtime"} change
                    request
                  </div>
                )}
                <div className="newShChangeRowContent">
                  <div
                    className={`newShChContentLeft ${
                      change.declined ? "greyscale" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType:
                            change.oldType === "shift" ? "shift" : "til",
                          unitID: change.shiftID,
                        };
                      });
                    }}
                  >
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaDate}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                    </div>
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaClock}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {change.oldStart[0] === "Y"
                        ? `${change.oldStart.substr(
                            12,
                            2
                          )}:${change.oldStart.substr(15, 2)}`
                        : change.oldStart}{" "}
                      -{" "}
                      {change.oldEnd[0] === "Y"
                        ? `${change.oldEnd.substr(
                            12,
                            2
                          )}:${change.oldEnd.substr(15, 2)}`
                        : change.oldEnd}
                    </div>
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaCoffee}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {dateStringer.formatMinsDurationToHours(change.oldBrk)}
                    </div>
                  </div>
                  <img
                    src={`${
                      change.requested
                        ? `${
                            !change.declined && change.approved
                              ? shiftChangeArrowGreen
                              : `${
                                  change.declined
                                    ? shiftChangeArrowRed
                                    : shiftChangeArrow
                                }`
                          }`
                        : shiftChangeArrow
                    }`}
                    alt="arrow"
                    className={`newShChangeRowArrowImg ${
                      change.drop ? "invis" : ""
                    }`}
                  />

                  {change.drop && change.coverUserID ? (
                    <div className="mgrShRowFwdUser mgrShRowFwdUserMyShiftChanes">
                      <img
                        src={
                          change.coverProfPicUrl || profilePicturePlaceholder
                        }
                        alt={change.coverFName}
                        className="coverUserImgMgrhImg"
                      />
                      <p>
                        You put {change.coverFName} {change.coverLName[0]}{" "}
                        forward to cover it
                      </p>
                    </div>
                  ) : (
                    <div
                      className={`newShChContentRight ${
                        change.declined ? "greyscale" : ""
                      } ${change.drop ? "invis" : ""}`}
                      onClick={() => {
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType:
                              change.oldType === "shift" ? "shift" : "til",
                            unitID: change.shiftID,
                          };
                        });
                      }}
                    >
                      <div
                        className={`newShContentRightDataRow ${
                          change.newDate &&
                          change.newDate.substr(0, 11) !==
                            change.oldDate.substr(0, 11)
                            ? "newShContentRightDataRowChanged"
                            : ""
                        }`}
                      >
                        {dateStringer.printedDateWithYrFromDs(
                          change.newDate ? change.newDate : ""
                        )}
                        <img
                          src={rotaDate}
                          alt="Shift change"
                          className="newShContentRightDataImg"
                        />
                      </div>
                      <div
                        className={`newShContentRightDataRow ${
                          (change.newDate &&
                            change.oldStart !== change.newStart) ||
                          change.oldEnd !== change.newEnd
                            ? "newShContentRightDataRowChanged"
                            : ""
                        }`}
                      >
                        {change.newStart} - {change.newEnd}
                        <img
                          src={rotaClock}
                          alt="Shift change"
                          className="newShContentRightDataImg"
                        />
                      </div>
                      <div
                        className={`newShContentRightDataRow ${
                          change.newDate && change.newBrk !== change.oldBrk
                            ? "newShContentRightDataRowChanged"
                            : ""
                        }`}
                      >
                        {dateStringer.formatMinsDurationToHours(change.newBrk)}
                        <img
                          src={rotaCoffee}
                          alt="Shift change"
                          className="newShContentRightDataImg"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="newShChangeRowLower">
                  <div className="newShChangeRowLowerLeft">
                    <p
                      className={`newShChangeDs ${
                        change.approved || change.declined ? "invis" : ""
                      }`}
                    >
                      Requested{" "}
                      {dateStringer.printedDateWithTimeFromDs(
                        dateStringer.createStringFromTimestamp(change.timestamp)
                      )}
                    </p>

                    {change.note && (
                      <p className="shChNote">
                        <img
                          src={commentRota}
                          alt="Note"
                          className="shChNoteImg"
                        />
                        {change.note}
                      </p>
                    )}
                  </div>
                  {change.approved ? (
                    <p className="newShChangeApproved">Approved</p>
                  ) : change.declined ? (
                    <p className="newShChangeDeclined">
                      Declined{" "}
                      <span
                        className="removeDeclinedShCh"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/remove-shift-change-request`,
                              {
                                editID: change.editID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                // memoVals.setUpdateChanges( x=>!memoVals.updateChanges);
                                // remove change from changesData
                                let newArr = [];
                                changesData.forEach((c) => {
                                  if (c.editID !== change.editID) {
                                    newArr.push(c);
                                  }
                                });

                                let needsAck = [];
                                let doesNotNeedAck = [];
                                newArr.forEach((obj) => {
                                  if (obj.requiresAck) {
                                    needsAck.push(obj);
                                  } else {
                                    doesNotNeedAck.push(obj);
                                  }
                                });

                                needsAck.sort(function (a, b) {
                                  return b.timestamp - a.timestamp;
                                });

                                doesNotNeedAck.sort(function (a, b) {
                                  return b.timestamp - a.timestamp;
                                });

                                if (needsAck[0] && doesNotNeedAck[0]) {
                                  setUndecidedFinalIndex(needsAck.length - 1);
                                  if (!memoVals.mob) {
                                    needsAck.push({ split: true });
                                  }
                                }

                                setChangesData([
                                  ...needsAck,
                                  ...doesNotNeedAck,
                                ]);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Remove{" "}
                        <img
                          src={greyBin}
                          alt="Remove"
                          className="removeDeclinedShBin"
                        />
                      </span>
                    </p>
                  ) : (
                    <p
                      className={`newShChangeCancelBtn ${
                        change.drop ? "newShChangeCancelBtnDrop" : ""
                      }`}
                      onClick={() => {
                        // if (!change.isTakeTil) {
                        // setLoadingCancel(true);
                        setSureCancel({
                          show: true,
                          change: change.newType,
                          shiftID: change.shiftID,
                          correspondingEditID: change.editID,
                          til: false,
                          drop: change.drop,
                        });
                        // }

                        // if (change.isTakeTil) {
                        //   // setLoadingCancel(true);
                        //   setSureCancel({
                        //     show: true,
                        //     takeTilID: change.takeTilID,
                        //     type: change.type,
                        //     shiftID: change.shiftID,
                        //     til: true,
                        //     editID: change.editID,
                        //   });
                        // }
                      }}
                    >
                      Cancel
                    </p>
                  )}
                </div>
              </div>
            );
          }

          if (change.isTakeTil) {
            let tilString = dateStringer.generateTilShiftString(
              change.newStart,
              change.newEnd,
              change.oldStart,
              change.oldEnd,
              false,
              false,
              change.type,
              true
            );
            // ttr here
            return (
              <div
                className={`newShChangeRow ${
                  i === undecidedFinalIndex && memoVals.mob
                    ? "newShChangeRowSplitFinalItem"
                    : ""
                }`}
              >
                <div className="newShChangeRowTopRow">
                  <img
                    src={rotaReq}
                    alt="Shift change"
                    className="newShChangeRowTopImg x239848943"
                  />
                  {tilString.includes("Whole")
                    ? `Whole ${change.type} off as TOIL`
                    : `${dateStringer.formatMinsDurationToHours(
                        change.tilMins
                      )} of your ${change.type} off as TOIL`}
                </div>
                <div className="newShChangeRowContent">
                  <div
                    className={`newShChContentLeft ${
                      change.declined ? "greyscale" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType:
                            change.oldType === "shift" ? "shift" : "til",
                          unitID: change.shiftID,
                        };
                      });
                    }}
                  >
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaDate}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                    </div>
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaClock}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {change.oldStart[0] === "Y"
                        ? `${change.oldStart.substr(
                            12,
                            2
                          )}:${change.oldStart.substr(15, 2)}`
                        : change.oldStart}{" "}
                      -{" "}
                      {change.oldEnd[0] === "Y"
                        ? `${change.oldEnd.substr(
                            12,
                            2
                          )}:${change.oldEnd.substr(15, 2)}`
                        : change.oldEnd}
                    </div>
                    <div className="newShContentLeftDataRow toilColour00AAFF">
                      <img
                        src={navyScissors}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      {change.newStart} - {change.newEnd}
                    </div>
                    <div className="newShContentLeftDataRow">
                      <img
                        src={rotaDur}
                        alt="Shift change"
                        className="newShContentLeftDataImg"
                      />{" "}
                      <span className="toilColour00AAFF x23982984">
                        {dateStringer.formatMinsDurationToHours(change.tilMins)}
                      </span>
                      {change.approved ? " used " : " to use "}
                      from TOIL balance
                    </div>
                  </div>
                  <p></p>
                </div>
                <div className="newShChangeRowLower">
                  <div className="newShChangeRowLowerLeft">
                    <p
                      className={`newShChangeDs ${
                        change.approved || change.declined ? "invis" : ""
                      }`}
                    >
                      Requested{" "}
                      {dateStringer.printedDateWithTimeFromDs(
                        dateStringer.createStringFromTimestamp(change.timestamp)
                      )}
                    </p>

                    {change.note && (
                      <p className="shChNote">
                        <img
                          src={commentRota}
                          alt="Note"
                          className="shChNoteImg"
                        />
                        {change.note}
                      </p>
                    )}
                  </div>
                  {change.approved ? (
                    <p className="newShChangeApproved">Approved</p>
                  ) : change.declined ? (
                    <p className="newShChangeDeclined">
                      Declined{" "}
                      <span
                        className="removeDeclinedShCh"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/remove-shift-change-request`,
                              {
                                takeTilID: change.takeTilID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                // memoVals.setUpdateChanges( x=>!memoVals.updateChanges);
                                // remove change from changesData
                                let newArr = [];
                                changesData.forEach((c) => {
                                  if (c.takeTilID !== change.takeTilID) {
                                    newArr.push(c);
                                  }
                                });

                                let needsAck = [];
                                let doesNotNeedAck = [];
                                newArr.forEach((obj) => {
                                  if (obj.requiresAck) {
                                    needsAck.push(obj);
                                  } else {
                                    doesNotNeedAck.push(obj);
                                  }
                                });

                                needsAck.sort(function (a, b) {
                                  return b.timestamp - a.timestamp;
                                });

                                doesNotNeedAck.sort(function (a, b) {
                                  return b.timestamp - a.timestamp;
                                });

                                if (needsAck[0] && doesNotNeedAck[0]) {
                                  setUndecidedFinalIndex(needsAck.length - 1);
                                  if (!memoVals.mob) {
                                    needsAck.push({ split: true });
                                  }
                                }

                                setChangesData([
                                  ...needsAck,
                                  ...doesNotNeedAck,
                                ]);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Remove{" "}
                        <img
                          src={greyBin}
                          alt="Remove"
                          className="removeDeclinedShBin"
                        />
                      </span>
                    </p>
                  ) : (
                    <p
                      className={`newShChangeCancelBtn `}
                      onClick={() => {
                        // if (!change.isTakeTil) {
                        // setLoadingCancel(true);
                        // setSureCancel({
                        //   show: true,
                        //   change: change.newType,
                        //   shiftID: change.shiftID,
                        //   correspondingEditID: change.editID,
                        //   til: false,
                        // });
                        // }

                        // if (change.isTakeTil) {
                        //   // setLoadingCancel(true);
                        setSureCancel({
                          show: true,
                          takeTilID: change.takeTilID,
                          type: change.type,
                          shiftID: change.shiftID,
                          til: true,
                          editID: change.editID,
                          drop: change.drop,
                        });
                        // }
                      }}
                    >
                      Cancel
                    </p>
                  )}
                </div>
              </div>
            );
          }

          if (1 + 1 === 5) {
            return (
              <div
                key={i}
                className={` ${changesLoading ? "none" : ""} ${
                  selectedChangeID === change.editID
                    ? "shiftChangeRow selectedShiftChangeRow"
                    : "shiftChangeRow"
                } ${change.editID.includes("not an edit") ? "" : ""} ${
                  change.oldStart === ":" ? "none" : ""
                } ${change.requiresAck ? "changeRequiresAckBorder" : ""} ${
                  change.declined ? "changeDeclinedBorder_" : ""
                } ${change.approved ? "changeApprovedBorder_" : ""} ${
                  !change.approved && !change.declined
                    ? "mgrReqItemPendingBorder whiteBg"
                    : ""
                } ${memoVals.mob ? "borderRadius10" : ""}`}
                onClick={() => {
                  // if (change.editID === selectedChangeID) {
                  //   setSelectedChangeID("");
                  // } else {
                  //   setSelectedChangeID(change.editID);
                  // }
                }}
              >
                <div className="shiftChangeTitleRow">
                  <p
                    className={`${
                      change.isTakeTil || change.tilEdit
                        ? "none"
                        : "shiftChangeType"
                    }`}
                  >
                    {`${
                      change.requested && !change.tilEdit
                        ? "You requested a change to your "
                        : "Your "
                    }`}
                    <span className="shiftTypeSpan">
                      {change.oldType === "shift"
                        ? "shift"
                        : `${
                            change.oldType === "overtime"
                              ? "overtime"
                              : "overtime (time in lieu)"
                          }`}
                    </span>{" "}
                    {`${change.requested ? "" : "was changed"}`}
                  </p>
                  <p
                    className={`${
                      change.isTakeTil && change.approved && !change.tilEdit
                        ? "shiftChangeType"
                        : "none"
                    }`}
                  >
                    Your request to take{" "}
                    <span className="shiftTypeSpan">TOIL</span> on your
                    <span className="shiftTypeSpan">
                      {change.type === "shift" ? " shift" : " overtime"}
                    </span>{" "}
                    was <span className="approvedChangeSpan">approved</span> by{" "}
                    <span className="shiftTypeSpan">{change.decidedBy}</span>
                  </p>
                  <p
                    className={`${
                      change.isTakeTil && change.declined && !change.tilEdit
                        ? "shiftChangeType"
                        : "none"
                    }`}
                  >
                    Your request to take{" "}
                    <span className="shiftTypeSpan">TOIL</span> on your
                    <span className="shiftTypeSpan">
                      {change.type === "shift" ? " shift" : " overtime"}
                    </span>{" "}
                    was <span className="declinedChangeSpan">declined</span> by{" "}
                    <span className="shiftTypeSpan">{change.decidedBy}</span>
                  </p>
                  <p
                    className={`${
                      change.isTakeTil &&
                      !change.declined &&
                      !change.approved &&
                      !change.tilEdit
                        ? "shiftChangeType"
                        : "none"
                    }`}
                  >
                    You requested to take{" "}
                    <span className="shiftTypeSpan">TOIL</span> on your
                    <span className="shiftTypeSpan">
                      {change.type === "shift" ? " shift" : " overtime"}
                    </span>{" "}
                  </p>

                  <p
                    className={`${change.tilEdit ? "shiftChangeType" : "none"}`}
                  >
                    The times of your TOIL taken on your{" "}
                    <span className="shiftTypeSpan">
                      {change.type === "shift" ? " shift" : " overtime"}
                    </span>{" "}
                    have been changed
                  </p>
                </div>
                <div className="changeTitleRowSubBar">
                  <p className="shiftChangeMadeDate">
                    {dateStringer.printedDateFromDs(
                      dateStringer.createStringFromTimestamp(change.timestamp)
                    )}
                    , {dateStringer.tsToHHMM(change.timestamp)}
                  </p>
                  <div
                    className={`${
                      change.requested
                        ? `${
                            change.approved && !change.declined
                              ? "changeStatusApproved"
                              : `${
                                  change.declined
                                    ? "changeStatusDeclined"
                                    : "changeStatus"
                                }`
                          } `
                        : "none"
                    } noBorderRight`}
                  >
                    {`${
                      change.approved && !change.declined
                        ? "Approved"
                        : `${change.declined ? "Declined" : "Pending"}`
                    }`}
                    {change.requested && change.approved ? (
                      <img
                        src={unitTick}
                        alt="Approved"
                        className="changeStatusIconImg"
                      />
                    ) : (
                      ""
                    )}
                    {change.requested && change.declined ? (
                      <img
                        src={darkRedCross}
                        alt="Declined"
                        className="changeStatusIconImg"
                      />
                    ) : (
                      ""
                    )}
                    {change.requested &&
                    !change.declined &&
                    !change.approved ? (
                      <img
                        src={greyThreeDots}
                        alt="Declined"
                        className="changeStatusIconImg"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className={`${
                      !change.approved && !change.declined
                        ? "changeStatus"
                        : `${
                            change.approved
                              ? "changeStatusApproved"
                              : "changeStatusDeclined"
                          }`
                    } ${change.isTakeTil ? "" : "none"} noBorderRight`}
                  >
                    {change.approved
                      ? "Approved"
                      : `${change.declined ? "Declined" : "Pending"}`}
                    {change.isTakeTil &&
                    !change.declined &&
                    !change.approved ? (
                      <img
                        src={greyThreeDots}
                        alt="Declined"
                        className="changeStatusIconImg"
                      />
                    ) : (
                      <img
                        src={change.approved ? unitTick : darkRedCross}
                        alt="Approved"
                        className="changeStatusIconImg"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${change.tilEdit ? "tilEditContainer" : "none"}`}
                >
                  {/*  -START----------------------------------------------------------------------------------------------------------- */}

                  <div
                    className={` ${change.tilEdit ? "changeRowMain" : "none"}`}
                  >
                    <div
                      className={`changeRowLeft ${
                        !memoVals.mob ? "changeRowLeftDesktop" : ""
                      }`}
                      onClick={() => {
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType: change.type === "shift" ? "shift" : "til",
                            unitID: change.shiftID,
                          };
                        });
                      }}
                    >
                      <p className="oldShiftDate textAlignLeft">
                        {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                      </p>
                      <p className="oldShiftTimes textAlignLeft">
                        {change.oldTilStart} - {change.oldTilEnd}
                      </p>
                      <p className="oldShiftBrk">Old TOIL</p>
                    </div>
                    <div className="changeRowMiddle">
                      <img
                        src={shiftChangeArrowGreen}
                        alt="arrow"
                        className="changeShiftArrowImg"
                      />
                    </div>
                    <div
                      className={`changeRowRight ${
                        !memoVals.mob ? "changeRowRightDesktop" : ""
                      }`}
                      onClick={() => {
                        console.log("oldType:... ", change.oldType);
                        memoVals.setShowMyShiftModal((x) => {
                          return {
                            unitType:
                              change.oldType === "shift" ? "shift" : "til",
                            unitID: change.shiftID,
                          };
                        });
                      }}
                    >
                      <p
                        className={`oldShiftDate ${
                          change.newDate &&
                          change.newDate.substr(0, 11) !==
                            change.oldDate.substr(0, 11)
                            ? "highlightedChangeElement"
                            : ""
                        }`}
                      >
                        {dateStringer.printedDateWithYrFromDs(
                          change.newDate ? change.newDate : ""
                        )}
                      </p>

                      <p className="oldShiftTimes">
                        <span
                          className={`${
                            change.newTilStart !== change.oldTilStart &&
                            change.newTilEnd !== change.oldTilEnd
                              ? "highlightedChangeElement"
                              : ""
                          }`}
                        >
                          <span
                            className={`${
                              change.newTilStart !== change.oldTilStart &&
                              change.newTilEnd === change.oldTilEnd
                                ? "highlightedChangeElement"
                                : ""
                            }`}
                          >
                            {change.newTilStart}
                          </span>{" "}
                          -{" "}
                          <span
                            className={`${
                              change.newTilEnd !== change.oldTilEnd &&
                              change.newTilStart === change.oldTilStart
                                ? "highlightedChangeElement"
                                : ""
                            }`}
                          >
                            {change.newTilEnd}
                          </span>
                        </span>
                      </p>
                      <p className="oldShiftBrk">New TOIL</p>
                    </div>
                  </div>

                  {/*  ------------------------------------------------------------------------------------------------------------ */}
                </div>
                <div
                  className={`changeRowMain ${
                    change.requested && change.declined ? "declinedChange" : ""
                  } ${change.tilEdit || change.oldStart === ":" ? "none" : ""}`}
                >
                  <div
                    className={`changeRowLeft ${
                      !memoVals.mob ? "changeRowLeftDesktop" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType: change.type === "shift" ? "shift" : "til",
                          unitID: change.shiftID,
                        };
                      });
                    }}
                  >
                    <p className="oldShiftDate textAlignLeft">
                      {dateStringer.printedDateWithYrFromDs(change.oldDate)}
                    </p>
                    <p className="oldShiftTimes textAlignLeft">
                      {change.oldStart[0] === "Y"
                        ? `${change.oldStart.substr(
                            12,
                            2
                          )}:${change.oldStart.substr(15, 2)}`
                        : change.oldStart}{" "}
                      -{" "}
                      {change.oldEnd[0] === "Y"
                        ? `${change.oldEnd.substr(
                            12,
                            2
                          )}:${change.oldEnd.substr(15, 2)}`
                        : change.oldEnd}
                    </p>
                    <p className="oldShiftBrk">{change.oldBrk}m break</p>
                  </div>
                  <div className="changeRowMiddle">
                    <img
                      src={`${
                        change.requested
                          ? `${
                              !change.declined && change.approved
                                ? shiftChangeArrowGreen
                                : `${
                                    change.declined
                                      ? shiftChangeArrowRed
                                      : shiftChangeArrow
                                  }`
                            }`
                          : shiftChangeArrow
                      }`}
                      alt="arrow"
                      className="changeShiftArrowImg"
                    />
                  </div>
                  <div
                    className={`changeRowRight ${
                      !memoVals.mob ? "changeRowRightDesktop" : ""
                    }`}
                    onClick={() => {
                      memoVals.setShowMyShiftModal((x) => {
                        return {
                          unitType:
                            change.oldType === "shift" ? "shift" : "til",
                          unitID: change.shiftID,
                        };
                      });
                    }}
                  >
                    <p
                      className={`oldShiftDate ${
                        change.newDate &&
                        change.newDate.substr(0, 11) !==
                          change.oldDate.substr(0, 11)
                          ? "highlightedChangeElement"
                          : ""
                      }`}
                    >
                      {dateStringer.printedDateWithYrFromDs(
                        change.newDate ? change.newDate : ""
                      )}
                    </p>
                    <div
                      className={`${
                        change.isTakeTil ? "tilTimesStrip" : "none"
                      }`}
                    >
                      <p className="tilStripTitle">TOIL: </p>
                      <p
                        className="oldShiftTimes highlighedTilTimes"
                        // {`oldShiftTimes
                        // ${
                        //   change.oldStart !== change.newStart ||
                        //   change.oldEnd !== change.newEnd
                        //     ? "highlightedChangeElement"
                        //     : ""
                        // }
                        // `}
                      >
                        {change.newStart} - {change.newEnd}
                      </p>
                    </div>
                    <p
                      className={`${
                        change.isTakeTil
                          ? "none"
                          : `oldShiftTimes
                ${
                  change.oldStart !== change.newStart ||
                  change.oldEnd !== change.newEnd
                    ? "highlightedChangeElement"
                    : ""
                }
                `
                      }`}
                    >
                      <span className="">{change.newStart}</span> -{" "}
                      <span className="">{change.newEnd}</span>
                    </p>
                    <p
                      className={`${
                        change.isTakeTil
                          ? `tilNewShiftTime ${
                              change.newStart !== change.oldStart &&
                              change.newEnd !== change.oldEnd
                                ? "smallerTilTwoShifts"
                                : ""
                            }`
                          : "none"
                      }`}
                    >
                      {dateStringer.generateTilShiftString(
                        change.newStart,
                        change.newEnd,
                        change.oldStart,
                        change.oldEnd,
                        false,
                        false,
                        change.type,
                        true
                      )}
                    </p>
                    <p
                      className={`${
                        change.isTakeTil
                          ? "none"
                          : `tilNewShiftTime ${
                              change.newBrk !== change.oldBrk
                                ? "highlightedChangeElement"
                                : ""
                            }`
                      }`}
                    >
                      {change.newBrk}m break
                    </p>
                  </div>
                </div>
                {(change.approved && !change.isChange) ||
                (change.requested && change.declined) ||
                (change.isTakeTil && change.declined) ? (
                  ""
                ) : (
                  <div
                    className="deleteChangeUnit"
                    onClick={() => {
                      console.log({ change });
                    }}
                  >
                    <div className="deleteChangeUnitLeft"></div>
                    <div
                      className="deleteAndViewBtns"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div
                        className={`${
                          loadingCancel
                            ? "cancelChangeLoadingImgContainer"
                            : "none"
                        }`}
                      >
                        <img
                          className="cancelChangeLoaderImg"
                          alt="Loading"
                          src={blueLoader}
                        />
                      </div>
                      <p
                        className={`${
                          !change.approved && !change.declined
                            ? "deleteChangeBtn"
                            : "deleteChangeBtn invisDeleteChangeBtn"
                        } ${loadingCancel ? "none" : ""}`}
                        onClick={() => {
                          if (
                            !change.approved &&
                            !change.declined &&
                            !change.isTakeTil
                          ) {
                            // setLoadingCancel(true);
                            setSureCancel({
                              show: true,
                              change: change.newType,
                              shiftID: change.shiftID,
                              correspondingEditID: change.editID,
                              til: false,
                            });
                          }

                          if (change.isTakeTil) {
                            // setLoadingCancel(true);
                            setSureCancel({
                              show: true,
                              takeTilID: change.takeTilID,
                              type: change.type,
                              shiftID: change.shiftID,
                              til: true,
                              editID: change.editID,
                            });
                          }
                        }}
                      >
                        Cancel request
                      </p>
                      <img
                        src={blueLoader}
                        className={`${
                          loadChange ? "loadChangeLoaderImg" : "none"
                        }`}
                        alt="loading"
                      />
                      {change.read && change.read[0] === "Y" ? (
                        <div className="acknowledgedTxt">
                          Acknowledged{" "}
                          <img
                            src={greyTick}
                            alt="Acknowledged"
                            className="ackTickImg"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          }
        }
      });

  let printMonth = (my) => {
    let m = my.substr(0, 2);
    if (m === "00") {
      return "January";
    }
    if (m === "01") {
      return "February";
    }
    if (m === "02") {
      return "March";
    }
    if (m === "03") {
      return "April";
    }
    if (m === "04") {
      return "May";
    }
    if (m === "05") {
      return "June";
    }
    if (m === "06") {
      return "July";
    }
    if (m === "07") {
      return "August";
    }
    if (m === "08") {
      return "September";
    }
    if (m === "09") {
      return "October";
    }
    if (m === "10") {
      return "November";
    }
    if (m === "11") {
      return "December";
    }
  };

  // master
  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      {" "}
      <div className="blackCornerBg"></div>
      {/* insert header here */}
      <ShiftsHeader my={my} setMy={setMy} />
      <div className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}>
        <div
          className={`desktopMasterMain ${
            !memoVals.full && !memoVals.mob ? "midContentWidth" : ""
          } ${memoVals.mob ? "mob100Witdth whiteMobMyShBg" : ""} `}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar2" : ""
            } ${!memoVals.mob && "borderLeftGrey"} ${
              !memoVals.full ? "noBorderRight" : ""
            } noBorderBottom`}
          >
            <div
              className={`myCalDropAndRightBtnContainer ${
                memoVals.mob
                  ? memoVals.showYetToAcceptOnly
                    ? ""
                    : "marginTop8"
                  : ""
              }`}
            >
              {memoVals.showYetToAcceptOnly ? (
                <p
                  className={`closeYetToAcceptBtn `}
                  onClick={() => {
                    memoVals.setShowYetToAcceptOnly((x) => false);
                    memoVals.setNotYetAccepted((x) => allShiftsAndTils.length);
                  }}
                >
                  Shifts to accept{" "}
                  <img
                    src={whitecross}
                    alt="Close"
                    className="closeYetToAccCrossBtn"
                  />
                </p>
              ) : (
                <div className="flexStartUserSh">
                  <select
                    className={`myReqsYearDropdown ${
                      memoVals.mob ? "myReqsYearDropdownMob" : ""
                    } myCalMonthsDropSize ${
                      currMy === my ? "colour00aaff" : ""
                    } ${
                      memoVals.showChanges
                        ? "makeFilter InvisAndInactive invis"
                        : ""
                    }`}
                    value={my}
                    onChange={(e) => {
                      setLoading(true);
                      setMy(e.target.value);
                      if (finalPreTodayIndex !== -1) {
                        setFinalPreTodayIndex(-1);
                      }
                      memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                    }}
                  >
                    {generateMonthOptions}
                  </select>{" "}
                  <img
                    src={arrGrey}
                    alt="Right"
                    className={`rightMonWeek ${
                      memoVals.mob ? "rightMonWeekMob" : ""
                    } ${
                      loading
                        ? "fadeSelMonWeekBtn"
                        : my ===
                          memoVals.availableMonths[
                            memoVals.availableMonths.length - 1
                          ]
                        ? "none"
                        : ""
                    } ${showChanges ? "invis" : ""}`}
                    onClick={() => {
                      // code here
                      let ind = memoVals.availableMonths.indexOf(my);
                      if (memoVals.availableMonths[ind + 1]) {
                        setMy(memoVals.availableMonths[ind + 1]);
                        if (finalPreTodayIndex !== -1) {
                          setFinalPreTodayIndex(-1);
                        }
                        memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            {/* <div className="shiftsPageFilterBarLeft">
              {memoVals.showYetToAcceptOnly ? (
                <p
                  className="closeYetToAcceptBtn"
                  onClick={() => {
                    memoVals.setShowYetToAcceptOnly((x) => false);
                    memoVals.setNotYetAccepted((x) => allShiftsAndTils.length);
                  }}
                >
                  Shifts to accept{" "}
                  <img
                    src={whitecross}
                    alt="Close"
                    className="closeYetToAccCrossBtn"
                  />
                </p>
              ) : (
                <select
                  className={`${
                    memoVals.showChanges
                      ? "makeFilter InvisAndInactive invis"
                      : ""
                  } shiftsTilMonthDropdown`}
                  value={my}
                  onChange={(e) => {
                    setLoading(true);
                    memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                    setMy(e.target.value);
                  }}
                >
                  {generateMonthOptions}
                </select>
              )}
            </div> */}
            <div className="shiftsPageFilterBarRight">
              <div className="myHoursUpperRight">
                <div
                  className={`myHoursUpperRightLeft ${
                    countData.outstandingChanges <= 0
                      ? "paddingLeftRight9px"
                      : "colour143051_"
                  } ${showChanges ? "myHoursUpperRightSel" : ""} ${
                    showFilter ? "none" : ""
                  }`}
                  onClick={() => {
                    setShowChanges(!showChanges);
                    if (!showChanges && showFilter) {
                      setShowFilter(false);
                    }
                    setShowOvertimes(true);
                    setShowTils(true);
                    setShowShifts(true);
                  }}
                >
                  Requests{" "}
                  {showChanges ? (
                    <img
                      src={navyClose}
                      alt="Close requests"
                      className="closeSmallReqsUpperRightImg"
                    />
                  ) : countData.outstandingChanges > 0 && !showFilter ? (
                    <span className="userOutstandChNum colorWhite">
                      {countData.outstandingChanges > 9
                        ? "9+"
                        : countData.outstandingChanges}
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </div>
                <div
                  className={`myHoursUpperRightRight ${
                    showFilter ? "myHoursUpperRightRightActive" : ""
                  }`}
                  onClick={() => {
                    setShowFilter(!showFilter);
                    if (!showFilter && showChanges) {
                      setShowChanges(false);
                    }
                    setShowOvertimes(true);
                    setShowTils(true);
                    setShowShifts(true);
                  }}
                >
                  Filter
                  {showFilter ? (
                    <img
                      src={navyClose}
                      alt="Close filter"
                      className="closeSmallReqsUpperRightImg closeFiltBtnIcon"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* 
              <div
                className={`${
                  // selectedDay !== "allDays" ||
                  // selectedName !== "allNames" ||
                  !showOvertimes ||
                  !showShifts ||
                  !showTils ||
                  memoVals.showChanges ||
                  memoVals.showYetToAcceptOnly
                    ? "invis"
                    : `changesNoChangesBtn ${
                        showFilter ? "makeFilterInvisAndInactive" : ""
                      }`
                } none`}
                onClick={() => {
                  // setNewChanges(!newChanges);
                  setSelectedChangeID("");
                  if (changesData.length === 0) {
                    setChangesLoading(true);

                    memoVals.setUpdateChanges((x) => !memoVals.updateChanges);
                  }
                  if (undecidedFinalIndex !== -1) {
                    setUndecidedFinalIndex(-1);
                  }
                  memoVals.setShowChanges((x) => !memoVals.showChanges);

                  memoVals.setPage((x) => "reqs");
                }}
              >
                Requests
                {countData.outstandingChanges > 0 ? (
                  <span className="userOutstandChNum">
                    {countData.outstandingChanges > 9
                      ? "9+"
                      : countData.outstandingChanges}
                  </span>
                ) : (
                  ""
                )}{" "}
              </div>
              <div
                className={`${
                  memoVals.showChanges
                    ? `showChangesBtnActive ${
                        memoVals.mob || memoVals.full
                          ? "showChangesBtnActiveMobFull"
                          : ""
                      }`
                    : "none"
                }`}
                onClick={() => {
                  // setNewChanges(!newChanges);
                  if (undecidedFinalIndex !== -1) {
                    setUndecidedFinalIndex(-1);
                  }
                  memoVals.setShowChanges((x) => !memoVals.showChanges);
                  setLoading(true);
                  // if (memoVals.showChanges) {
                  // memoVals.setPage( x=>"hours");
                  // } else {
                  memoVals.setPage((x) => "shifts");
                  // }
                }}
              >
                Requests{" "}
                <img
                  className={`${
                    memoVals.showChanges ? "closeChangesImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div>
              <div
                className={`${
                  showFilter ? "shiftTilFilterBtnActive" : "shiftTilFilterBtn"
                } ${
                  memoVals.showChanges || memoVals.showYetToAcceptOnly
                    ? "makeFilterInvisAndInactive maxW58px"
                    : ""
                }`}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <img
                  src={filterWhite}
                  alt="Close filter"
                  className={`filtImg ${!showFilter ? "x19831" : ""}`}
                />{" "}
                <img
                  className={`${
                    showFilter ? "closeFilterImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div> */}
            </div>
          </div>
          <div className="shiftTilFilterHolder">
            <div
              className={`${
                showFilter
                  ? `shfitTilFilterBtnsContainer ${
                      memoVals.mob ? "noBorder" : ""
                    }`
                  : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilter ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtimes{" "}
                <img
                  src={showOvertimes ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Shifts in lieu{" "}
                <img
                  src={showTils ? checkboxInstall2 : checkboxInstall1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div>
          {/* <div
            className={`filterSplitterContainer ${
              !memoVals.mob && "borderLeftGrey"
            } ${!memoVals.full ? "noBorderRight" : ""}`}
          >
            <div className="filterSplitter"></div>
          </div> */}
          {/* - - - - */}
          <div
            className={`${
              memoVals.showChanges
                ? `availabilityBodyContainer changesOverflow ${
                    !memoVals.mob ? "borderLeftGrey" : ""
                  } ${!memoVals.full ? "noBorderRight" : ""}`
                : "none"
            } ${
              memoVals.mob
                ? `mobChangesContainer ${
                    memoVals.device.ios ? "mobChangesContainerIos" : ""
                  }`
                : ""
            }`}
            ref={changesScrollBox}
          >
            <img
              src={horizontalBalls}
              className={`${changesLoading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            {/* <p className={`${changesLoading ? "none" : "showingChangesFor"}`}>
              Showing changes made to shifts in {printMonth(my)}
            </p> */}
            {!loading && pendingsExist() && !changesLoading ? (
              <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                Not yet approved{" "}
                <span className="pendingChNum">{pendingsExist()}</span>
              </p>
            ) : (
              ""
            )}

            {(changesData && changesData[0]) || loading ? (
              generateChanges
            ) : (
              <div className="noShiftsTxtContainer noUpcomingReqs">
                <div className="noShiftsSideBar"></div>

                <p
                  className={`noShiftsTxt noUpcChangesTxt ${
                    changesLoading ? "none" : ""
                  }`}
                >
                  Any change requests that you make to your upcoming shifts will
                  appear here
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            )}
          </div>
          <div
            ref={hoursScrollBox}
            className={`${
              memoVals.showChanges ? "none" : "myHoursContainer"
              // `shiftsBodyContainer ${
              //     !memoVals.mob ? "shiftsBodyContainerDesktop" : ""
              //   }`
            } ${!memoVals.mob ? "borderLeftGrey" : ""}  ${
              memoVals.mob
                ? `mobMyHoursContainer ${
                    showFilter
                      ? `showFilterMobMyHoursContainer ${
                          memoVals.device.ios
                            ? "showFilterMobMyHoursContainerIos"
                            : ""
                        }`
                      : memoVals.device.ios
                      ? "mobMyHoursContainerIos"
                      : ""
                  }`
                : `${showFilter ? "showFilterDesktopMyHoursContainer" : ""}`
            } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
              !memoVals.full ? "noBorderRight" : ""
            }`}
          >
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            {!loading && !showPre && my === todayMy && preQty > 0 && (
              <p
                className="showPreShBtn navyColours_"
                onClick={() => {
                  let preFilt = allShiftsAndTils.filter((x) => {
                    return x.midnightTimestamp < today00Ts;
                  });
                  let postFilt = allShiftsAndTils.filter((x) => {
                    return x.midnightTimestamp >= today00Ts;
                  });

                  if (preFilt[0]) {
                    setFinalPreTodayIndex(preFilt.length - 1);
                  }
                  setAllShiftsAndTils([
                    ...preFilt,
                    // { preSplit: true },
                    ...postFilt,
                  ]);

                  setShowPre(true);
                }}
              >
                Show previous shifts this month{" "}
                <img
                  src={historyClockWhite}
                  alt="Previous shifts"
                  className="historyClockImg"
                />
              </p>
            )}

            {loading ? (
              ""
            ) : allShiftsAndTils[0] ? (
              generateMyShiftsAndTils
            ) : (
              <div
                className={`noShiftsTxtContainer noUpcomingReqs ${
                  memoVals.mob ? "x1984971" : ""
                }`}
              >
                <div className="noShiftsSideBar"></div>

                <p
                  className={`noShiftsTxt marginTop0 ${
                    memoVals.mob ? "noShiftsTxtHoursMob" : ""
                  }`}
                >
                  No shifts or overtime
                  {showYetToAcceptOnly ? "s to accept" : ""} in{" "}
                  {dateStringer.printMonthAndYearFromMy(my, true, true)}
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            )}
          </div>
          {/* {renderMyShiftModal()} */}
          <div
            className={`${
              showGhostModal ? "editShiftRequestModalUnderlay" : "none"
            }`}
            onClick={() => {
              setShowGhostModal(false);
            }}
          >
            <div
              className="ghostModalBox"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {ghostTxt}
              <div
                className="closeGhostModalBtn"
                onClick={() => {
                  setShowGhostModal(false);
                }}
              >
                Close
              </div>
            </div>
          </div>
        </div>
        {!memoVals.mob ? (
          <div className="desktopMasterSecondary">
            <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      {sureCancel.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setSureCancel({ show: false });
          }}
        >
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              {sureCancel.til
                ? "Are you sure you want to cancel this TOIL request?"
                : `Are you sure you want to cancel this ${
                    sureCancel.drop ? "drop" : "change"
                  } request?`}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  if (sureCancel.til) {
                    setLoadingCancel(true);
                    axios
                      .post(
                        `${serverURL}/remove-til-request`,
                        {
                          takeTilID: sureCancel.takeTilID,
                          type: sureCancel.type,
                          shiftID: sureCancel.shiftID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          console.log(response.data);
                          // remove change from changesData
                          setSureCancel({ show: false });
                          let newArr = [];
                          changesData.forEach((c) => {
                            if (c.editID !== sureCancel.editID) {
                              newArr.push(c);
                            }
                          });

                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges - 1,
                          });
                          let needsAck = [];
                          let doesNotNeedAck = [];
                          newArr.forEach((obj) => {
                            if (obj.requiresAck) {
                              needsAck.push(obj);
                            } else {
                              doesNotNeedAck.push(obj);
                            }
                          });

                          needsAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });

                          if (needsAck[0] && doesNotNeedAck[0]) {
                            setUndecidedFinalIndex(needsAck.length - 1);
                            if (!memoVals.mob) {
                              needsAck.push({ split: true });
                            }
                          }

                          doesNotNeedAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });
                          setChangesData([...needsAck, ...doesNotNeedAck]);
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges - 1,
                          });
                          setLoadingCancel(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    setLoadingCancel(true);

                    axios
                      .post(
                        `${serverURL}/cancel-shift-change-request`,
                        {
                          change: sureCancel.change,
                          shiftID: sureCancel.shiftID,
                          correspondingEditID: sureCancel.correspondingEditID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          // memoVals.setUpdateChanges( x=>!memoVals.updateChanges);
                          setSureCancel({ show: false });
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges - 1,
                          });
                          // remove change from changesData
                          let newArr = [];
                          changesData.forEach((c) => {
                            if (c.editID !== sureCancel.correspondingEditID) {
                              newArr.push(c);
                            }
                          });

                          let needsAck = [];
                          let doesNotNeedAck = [];
                          newArr.forEach((obj) => {
                            if (obj.requiresAck) {
                              needsAck.push(obj);
                            } else {
                              doesNotNeedAck.push(obj);
                            }
                          });

                          needsAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });

                          doesNotNeedAck.sort(function (a, b) {
                            return b.timestamp - a.timestamp;
                          });

                          if (needsAck[0] && doesNotNeedAck[0]) {
                            setUndecidedFinalIndex(needsAck.length - 1);
                            if (!memoVals.mob) {
                              needsAck.push({ split: true });
                            }
                          }

                          setChangesData([...needsAck, ...doesNotNeedAck]);
                          setCountData({
                            outstandingChanges:
                              countData.outstandingChanges - 1,
                          });
                          setLoadingCancel(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureCancel({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default UserShifts;
