import React, { useState, useContext, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";

import axios from "axios";

import logoicon from "../../img/signIn/smalllogo.png";
import download from "../../img/signIn/download.svg";
import eye from "../../img/signIn/eye.svg";
import logo from "../../img/general/logo.svg";

import "../../styles/signup.css";

import { UserContext } from "../../contexts/UserContext";
import serverURL from "../../serverURL";

import CheckUser from "../../tools/CheckUser";
import dateStringer from "../../tools/dateStringer";
import appURL from "../../appURL";

const Signin = () => {
  const { setCheckUserState } = useContext(UserContext);
  let [showCheckEmail, setShowCheckEmail] = useState(false);
  let [showExpired, setShowExpired] = useState(false);
  let [emailNotVerified, setEmailNotVerified] = useState(false);
  let [hideLink, setHideLink] = useState(false);
  let [twoFactor, setTwoFactor] = useState(false);
  let [twoFactorInput, setTwoFactorInput] = useState("");
  let [signInLoading, setSignInLoading] = useState(false);
  // is the form filled?

  // value of email input field
  const [emailInput, setEmailInput] = useState("");

  // value of password input field
  const [passInput, setPassInput] = useState("");

  // state for the show password button to change the password input type
  const [showPassword, setShowPassword] = useState(false);

  // state to show invalid signin texts
  const [wrongEmail, setWrongEmail] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  function getDeviceName() {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows Phone/i.test(userAgent)) {
      return "Windows Phone";
    } else if (/Windows/i.test(userAgent)) {
      return "Windows PC";
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
      return "Macintosh";
    } else if (/Linux|X11/i.test(userAgent)) {
      return "Linux";
    } else {
      return "Unknown Device";
    }
  }

  const [ipAddress, setIpAddress] = useState("");
  let [location, setLocation] = useState("Unknown location");

  useEffect(() => {
    var elements = document.getElementsByClassName("flowWidget");

    if (elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.visibility = "hidden";
      }
    }
    // Function to fetch IP address

    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);

        const locationApi = await fetch(`https://ip-api.com/json/${data.ip}`);
        const data2 = await locationApi.json();

        setLocation(data2.city);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []);

  let enterFct = (e, btn) => {
    if (e.key === "Enter" || btn) {
      setSignInLoading(true);
      axios
        .post(
          `${serverURL}/signin`,
          {
            email: emailInput,
            password: passInput,
            client: "React",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            deviceName: getDeviceName(),
            ip: ipAddress,
            location: location,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          // console.log(response.data.email);
          setTimeout(() => {
            setSignInLoading(false);
          }, 2000);

          if (response.data.message === "2fa") {
            setTwoFactor(true);
          } else {
            if (response.data.error === "incorrect email") {
              console.log("email not found");
              setWrongEmail(true);
              setWrongPass(false);
            } else {
            }
            if (response.data.error === "incorrect password") {
              console.log("wrong password");
              setWrongEmail(false);
              setWrongPass(true);
            } else if (response.data.loggedIn) {
              setCheckUserState({ validUser: true });
              if (window.location.href.includes("contact")) {
                window.location.href = "/whatsapp-messenger";
              } else {
                if (response.data.user.permissions === 1) {
                  // window.location.href = "/team-rota";
                  window.location.href = "/calendar";
                } else {
                  window.location.href = "/calendar";
                }
              }
            }

            if (response.data.error === "Client not found") {
              window.location.reload();
            }
          }
        });
    }
  };

  let [validForString, setValidForString] = useState(
    dateStringer.secsToString(180)
  );
  useEffect(() => {
    if (twoFactor) {
      let secs = 3 * 60;

      setInterval(() => {
        secs--;
        if (secs === 1) {
          window.location.reload();
        } else {
          setValidForString(dateStringer.secsToString(secs));
        }
      }, 1000);
    }
  }, [twoFactor]);

  let enterFctCode = (e, btn) => {
    if (e.key === "Enter" || btn) {
      setSignInLoading(true);
      console.log({ twoFactorInput });
      axios
        .post(
          `${serverURL}/signin`,
          {
            email: emailInput,
            password: passInput,
            client: "React",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            deviceName: getDeviceName(),
            ip: ipAddress,
            location: location,
            twoFactorCode: twoFactor ? parseInt(twoFactorInput) : false,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          // console.log(response.data.email);
          setTimeout(() => {
            setSignInLoading(false);
          }, 2000);

          if (response.data.message === "2fa") {
            setTwoFactor(true);
          } else {
            if (response.data.error === "incorrect email") {
              console.log("email not found");
              setWrongEmail(true);
              setWrongPass(false);
            } else {
            }
            if (response.data.error === "incorrect password") {
              console.log("wrong password");
              setWrongEmail(false);
              setWrongPass(true);
            } else if (response.data.loggedIn) {
              setCheckUserState({ validUser: true });
              if (window.location.href.includes("contact")) {
                window.location.href = "/whatsapp-messenger";
              } else {
                if (response.data.user.permissions === 1) {
                  // window.location.href = "/team-rota";
                  window.location.href = "/calendar";
                } else {
                  window.location.href = "/calendar";
                }
              }
            }

            if (response.data.error === "Client not found") {
              window.location.reload();
            }
          }
        });
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    // clean not needed

    if (
      params &&
      dateStringer.cleanParam(params.code) &&
      dateStringer.cleanParam(params.userID)
    ) {
      // check login link
      console.log("CHECK LINK HERE");
      // http://127.0.0.1:3000/signin?code=5722a025-5618-44b6-97ff-9b33a8657744&userID=83a5e0c7-17f0-497b-919e-5b040edaab1e
      axios
        .post(
          `${serverURL}/login-via-link`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            deviceName: getDeviceName(),
            code: dateStringer.cleanParam(params.code),
            userID: dateStringer.cleanParam(params.userID),
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            ip: dateStringer.cleanParam(params.ip),
            deviceName: getDeviceName(),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.loggedIn) {
            window.history.pushState(
              {},
              document.title,
              window.location.pathname
            );

            window.location.href = `${appURL}/calendar`;
            console.log("Logged in via link -- cookie received");
          }
          if (response.data.message === "expired") {
            window.history.pushState(
              {},
              document.title,
              window.location.pathname
            );

            setShowExpired(true);
          }
        });
    } else {
    }
  }, []);

  CheckUser(false, true);

  // render
  return (
    <div className="signUpBackground">
      {/* <div className="downloadBar">
        <div className="downloadBarContainer">
          <img src={download} alt="download pwa" id="mobIcon" />
          <p className="downloadBarText">Install FlowRota on your smartphone</p>
        </div>
      </div> */}

      <div className="signUpContainer">
        <div className="signinUpper">
          <p className="signInTitle">
            Sign in to FlowRota{" "}
            <img src={logo} alt="FlowRota" className="signInLogoImg" />
          </p>
          {/* <img className="signUpLogo" alt="FlowRota logo" src={logoicon} /> */}
          <form className="signUpForm">
            {twoFactor ? (
              <div className="signUpInputs">
                <p className="signInInputTitle signInInputTitleCode">
                  Check your emails for{" "}
                  <span className="twoFactorEmailSpan">{emailInput}</span> for a
                  login code.
                </p>
                <input
                  type="number"
                  value={twoFactorInput}
                  placeholder="Login code"
                  id="code"
                  name="code"
                  className="signinInput signUpEmailInput codeInput"
                  onKeyPress={(e) => {
                    enterFctCode(e, false);
                  }}
                  onChange={(e) => {
                    let val = e.target.value;
                    setTwoFactorInput(val);
                  }}
                />
                <p className="signInInputTitle signInInputTitleCode signInInputTitleCode2">
                  Valid for{" "}
                  <span className="twoFactorEmailSpan">{validForString}</span>.
                </p>
              </div>
            ) : (
              <div className="signUpInputs">
                <p className="signInInputTitle">Email</p>
                <input
                  type="email"
                  placeholder="email"
                  id="email"
                  name="email"
                  className="signinInput signUpEmailInput"
                  onKeyPress={(e) => {
                    enterFct(e, false);
                  }}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                    if (hideLink) {
                      setHideLink(false);
                    }
                  }}
                />
                <br />
                <p className={`${wrongEmail ? "wrongSignInPass" : "none"}`}>
                  Hmm, that email isn't registered...
                </p>
                <p className="signInInputTitle x20483085">Password</p>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  id="password"
                  placeholder="password"
                  name="password"
                  onKeyPress={(e) => {
                    enterFct(e, false);
                  }}
                  onChange={(e) => {
                    setPassInput(e.target.value);
                  }}
                ></input>
                <img
                  className="showPassword"
                  src={eye}
                  alt="show password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />{" "}
                <br />
                <p className={`${wrongPass ? "wrongSignInPass" : "none"}`}>
                  Wrong password
                </p>
                <div className="forgotContainer">
                  <NavLink to="/reset-password">
                    <p className="forgotPass">Forgotten password&nbsp;&nbsp;</p>
                  </NavLink>
                </div>
              </div>
            )}
            <br /> <br />
            {/* <NavLink to='/account-admin-general'> */}{" "}
            <p
              className={`signinBtn2 ${
                signInLoading ||
                (twoFactor &&
                  (twoFactorInput < 100000 || twoFactorInput > 999999))
                  ? "disable"
                  : ""
              }`}
              onClick={(event) => {
                if (twoFactor) {
                  enterFctCode("", true);
                } else {
                  enterFct("", true);
                }
              }}
            >
              Sign in
            </p>
            {/* </NavLink> */}
            <br />
            <p
              className={`emailLogInLinkBtn ${
                emailInput &&
                emailInput.includes("@") &&
                emailInput.includes(".") &&
                emailInput.length < 999
                  ? ""
                  : "invis"
              } ${hideLink ? "invis" : ""}`}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/request-email-login-link`,
                    {
                      email: emailInput,

                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                      deviceName: getDeviceName(),
                      ip: ipAddress,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (
                      response.data.message.includes("found") ||
                      response.data.message.includes("invalid")
                    ) {
                      setWrongEmail(true);
                    }
                    if (response.data.message === "success") {
                      setShowCheckEmail(true);
                      setHideLink(true);
                    }
                    if (response.data.message === "email not verified") {
                      setEmailNotVerified(true);
                    }
                  });
              }}
            >
              Email me a one-click login link{" "}
            </p>
          </form>
        </div>
        <div className="signinLower">
          <div className="signinDivider"></div>
          <div className="signinFooter">
            <NavLink to="/signup">
              <p className="signInPrivacy">Sign up</p>
            </NavLink>
            <a
              href="https://flowrota.com/privacy"
              className="signInPrivacy"
              target="_blank"
            >
              Privacy & Terms
            </a>
          </div>
        </div>
      </div>
      {showCheckEmail ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {/* {JANET} */}
              Check your email for a one-click login link. This will expire in 2
              minutes.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowCheckEmail(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showExpired ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {/* {JANET} */}
              The link you have used has expired. Please request another one.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowExpired(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {emailNotVerified ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              The email you have entered is not yet verified. <br />
              <br />
              For security, FlowRota is unable to send a login link to email
              addresses that are not vertfied.
              <br />
              <br />
              Log in with your email and password and then verify your email
              address.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setEmailNotVerified(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Signin;
