import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";

import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";

import serverURL from "../serverURL";

import close from "../img/modals/close.svg";

import dateStringer from "../tools/dateStringer.js";

const AddAbsenceModal = ({
  teamID,
  type,
  setShowAddShift,
  setShowAddTilModal,
  setShowAddLeaveOnTeam,
  loadFromReqsPage,
  absence,
}) => {
  const {
    setShowEditShiftModal,
    setShowAddLeaveModal,
    setShowAddAbsenceModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowEditShiftModal,
      setShowAddLeaveModal,
      mob,
      mobModal,
      setShowAddAbsenceModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowEditShiftModal, //
      setShowAddLeaveModal, //
      mob, //
      mobModal, //
      setShowAddAbsenceModal,
      modalOpen,
      setModalOpen,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [userID, setUserID] = useState("");

  let [shiftTeam, setShiftTeam] = useState(teamID === "allStaff" ? "" : teamID);
  let [teamsUserIsPartOf, setTeamsUserIsPartOf] = useState([]);
  let [dateInput, setDateInput] = useState(
    loadFromReqsPage
      ? dateStringer.createDateInputFromDateString(loadFromReqsPage)
      : ""
  );
  let [dateValid, setDateValid] = useState(false);
  let [tooEarly, setTooEarly] = useState(false);
  let [selectedLeaveTypeID, setSelectedLeaveTypeID] = useState("annLeave");
  let [leaveTypes, setLeaveTypes] = useState([]);

  let [users, setUsers] = useState([]);
  let [shifts, setShifts] = useState([]);

  let [today00, setToday00] = useState(
    `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`
  );
  let [tom00, setTom00] = useState("");

  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    // get team users

    let dateObj = new Date(dateStringer.createTimestampFromString(today00));
    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    setTom00(dateStringer.createStringFromTimestamp(dateObj.getTime()));

    axios
      .post(
        `${serverURL}/initialise-add-absence`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((resp) => {
        if (resp.data.message === "success") {
          setUsers(resp.data.getUsers);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  try {
    const inputDate = document.getElementById("addShiftDateInput");

    if (inputDate && !memoVals.mob) {
      inputDate.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "date") {
            this.showPicker();
          }
        } catch (error) {
          console.error("Error in inputDate focus event listener:", error);
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up inputDate:", error);
  }

  let [loading, setLoading] = useState(false);
  let getDayString = (di) => {
    let dateObj = new Date(
      dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(di)
      )
    );

    if (dateObj.getDay() === 0) {
      return "Sun";
    }

    if (dateObj.getDay() === 1) {
      return "Mon";
    }

    if (dateObj.getDay() === 2) {
      return "Tue";
    }

    if (dateObj.getDay() === 3) {
      return "Wed";
    }

    if (dateObj.getDay() === 4) {
      return "Thu";
    }

    if (dateObj.getDay() === 5) {
      return "Fri";
    }

    if (dateObj.getDay() === 6) {
      return "Sat";
    }
  };

  let getName = (userID) => {
    if (userID) {
      let filteredUser = users.filter((item) => item.userID === userID);
      if (filteredUser[0]) {
        return filteredUser[0].fName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  let generateUsersForAddShiftSelection = useMemo(() => {
    users.sort(function (a, b) {
      return a.fName.localeCompare(b.fName);
    });
    return users.map((usr) => {
      if ((type === "leave" && usr.managesPteam) || type !== "leave")
        return (
          <option key={usr.userID} value={usr.userID}>
            {usr.fName} {usr.lName}
          </option>
        );
    });
  }, [users]);

  let shortenTeamName = (text) => {
    if (text.length > 22) {
      return `${text.substr(0, 18)}...`;
    } else {
      return text;
    }
  };

  let generateTeamsAvailableForUser = useMemo(() => {
    return teamsUserIsPartOf.map((team) => {
      return (
        <option key={team.teamID} value={team.teamID}>
          {shortenTeamName(team.teamName)}
        </option>
      );
    });
  }, [teamsUserIsPartOf]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      if (type === "shift") {
        if (setShowAddShift) {
          setShowAddShift(false);
        } else {
          memoVals.setShowAddAbsenceModal((x) => false);
        }
      } else {
        if (type === "leave") {
          if (setShowAddLeaveOnTeam) {
            setShowAddLeaveOnTeam(false);
          } else {
            memoVals.setShowAddAbsenceModal((x) => false);
          }
        } else {
          if (setShowAddTilModal) {
            setShowAddTilModal(false);
          } else {
            memoVals.setShowAddAbsenceModal((x) => false);
          }
        }
      }
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="editShiftModalUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        memoVals.setShowAddAbsenceModal((x) => false);
      }}
    >
      <div
        className={`addShiftModalBox ${
          type === "leave" ? `addShiftLeaveOnlyBox` : ""
        } ${memoVals.mobModal ? "mobModalShoulder slideUp" : ""} ${
          memoVals.device.ios ? "paddingBottom20" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddShiftModal2"></div>
        )}
        <div
          className="addShiftModalHeader"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg"
            onClick={() => {
              memoVals.setShowAddAbsenceModal((x) => false);
            }}
          />
          <p
            className="addShiftHeaderTitle addShiftTitle"
            onClick={() => {
              if (memoVals.mobModal) {
                if (type === "shift") {
                  if (setShowAddShift) {
                    setShowAddShift(false);
                  } else {
                    memoVals.setShowAddAbsenceModal((x) => false);
                  }
                } else {
                  if (type === "leave") {
                    if (setShowAddLeaveOnTeam) {
                      setShowAddLeaveOnTeam(false);
                    } else {
                      memoVals.setShowAddAbsenceModal((x) => false);
                    }
                  } else {
                    if (setShowAddTilModal) {
                      setShowAddTilModal(false);
                    } else {
                      memoVals.setShowAddAbsenceModal((x) => false);
                    }
                  }
                }
              }
            }}
          >
            Record absence
          </p>
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg invis"
          />
        </div>

        <div className={`addAbsMidContainer alignMiddlePage0 `}>
          <p className="addShiftInputLabel">Staff member</p>
          <select
            className="addShiftOnWhichUserDropdown"
            value={userID}
            onChange={(e) => {
              let val = e.target.value;

              if (val) {
                axios
                  .post(
                    `${serverURL}/initialise-add-absence`,
                    {
                      userID: val,
                      nowDs: dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      ),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setUserID(val);

                      setShifts(response.data.shifts);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              } else {
                setShifts([]);
              }
            }}
          >
            <option value="">Select employee</option>
            {generateUsersForAddShiftSelection}
          </select>
          {/* {type === "leave" ? "" : <div className="addShiftDivider"></div>} */}
          <p className={`addShiftInputLabel ${userID ? "" : "opacity4"}`}>
            Mark a scheduled shift as absent
          </p>

          <div className="absShiftBoxSelect">
            {!shifts[0] ? (
              <p className="noAbsShTxt">
                {!userID
                  ? "Select an employee first"
                  : "No recent / upcoming shifts"}
              </p>
            ) : (
              shifts.map((sh, i) => {
                return (
                  <div
                    key={i}
                    className="markAbsShiftItem"
                    onClick={() => {
                      memoVals.setShowEditShiftModal((x) => {
                        return {
                          itemID: sh.shiftID || sh.tilID,
                          itemType: sh.shiftID ? "shift" : sh.tilType,

                          itemUserID: userID,
                          makeAbsent: true,
                        };
                      });
                      setShowAddAbsenceModal(false);
                    }}
                  >
                    {" "}
                    <div className="markAbsShLeft">
                      <p className="markAbsShType1">
                        {sh.startDs.substr(0, 11) === today00.substr(0, 11)
                          ? "Today"
                          : sh.startDs.substr(0, 11) === tom00.substr(0, 11)
                          ? "Tomorrow"
                          : ""}
                      </p>
                      <p className="markAbsShType">
                        {dateStringer.printedDateFromDs(sh.startDs)}
                      </p>
                    </div>
                    <div className="markAbsShRight">
                      <p className="markAbsShDate">
                        {sh.shiftID
                          ? "Shift"
                          : sh.tilType === "til"
                          ? "Overtime (time in lieu)"
                          : "Overtime"}{" "}
                      </p>

                      <p className="markAbsShTime">
                        {dateStringer.dsToTimeStrip(sh.startDs)} -{" "}
                        {dateStringer.dsToTimeStrip(sh.endDs)}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        <p
          className={`addShiftInputLabel x38492749272 ${
            userID ? "" : "opacity4"
          } ${!userID ? "invis" : ""}`}
        >
          Or
        </p>
        {userID && (
          <p
            className={`markAbsPeriodBtn `}
            onClick={() => {
              setShowAddAbsenceModal(false);
              memoVals.setShowAddLeaveModal((x) => {
                return {
                  show: true,
                  startDs: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),
                  userID: userID,
                  leaveTypeID: "",
                  fName: getName(userID),
                  reqID: "", // if editing existing one
                  editing: false,
                  editStartDs: "",
                  editEndDs: "",
                  ongoing: false,
                  toEndOngoing: false,
                  isAbsence: true,
                };
              });
            }}
          >
            Add absence leave period
          </p>
        )}

        <div className={`addShiftFooter `}>
          <p></p>
          <p
            className={`closeEditShiftBtn`}
            onClick={() => {
              setShowAddAbsenceModal(false);
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddAbsenceModal;
