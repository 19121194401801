import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useReducer,
} from "react";
// Cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { NavLink } from "react-router-dom";

import serverURL from "../../../../serverURL";
import imageCompression from "browser-image-compression";

import "../../../../styles/newUser.css";

import dateStringer from "../../../../tools/dateStringer";
import ClockDetails from "./ClockDetails";

import editUserProPic from "../../../../img/general/editUserProPic.png";
import popup from "../../../../img/general/popupNavy.svg";
import unarchive from "../../../../img/general/unarchive.svg";
import close from "../../../../img/modals/close.svg";
import threeDots from "../../../../img/general/threeDots.svg";
import whitebin from "../../../../img/general/whitebin.svg";
import whiteTick from "../../../../img/general/whiteTick.svg";
import chat from "../../../../img/general/chat.svg";
import greyClose from "../../../../img/general/greyClose.svg";
import editGrey from "../../../../img/general/editGrey.svg";
import blueLoader from "../../../../img/general/blueLoader.gif";
import bin1 from "../../../../img/general/bin1.svg";
import unitTick from "../../../../img/general/unitTick.svg";
import greyCross from "../../../../img/general/greyCross.svg";
import whitePlus from "../../../../img/general/whitePlus.svg";
import editUserEdit1 from "../../../../img/general/editUserEdit1.svg";
import profilePicturePlaceholder from "../../../../img/general/profilePicturePlaceholder.svg";
import unclockedCheck from "../../../../img/general/checkboxInstall4.svg";
import clock from "../../../../img/general/rotaClock2.svg";
import cal from "../../../../img/general/unclockCal.svg";
import download from "../../../../img/general/download.svg";
import todo from "../../../../img/general/todo.svg";
import greenTick from "../../../../img/general/greenTick.svg";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";
import { UserContext } from "../../../../contexts/UserContext";

import "../../../../styles/datepicker.css";
import "../../../../styles/subcomponents/specialDays.css";
import "../../../../styles/subcomponents/editUser.css";
import "../../../../styles/dropdowns.css";
import "../../../../styles/settings.css";

import Initials from "../../../Initials";

import UserAllowances from "../subcomponents/UserAllowances";
import MpwTimelineInlet from "../subcomponents/MpwTimelineInlet";
import EditUserHistoryModal from "../../../account/admin/EditUserHistoryModal";
import appURL from "../../../../appURL";

// import EditUserHistoryModal from "";

const EditUser = ({
  userID,
  straightToAllowances,
  straightToTeams,
  straightToData,
  checkPay,
  checkHours,
}) => {
  const { currencySymbol, checkUserState } = useContext(UserContext);
  const {
    showEditUser,
    setShowEditUser,
    isTeamNew,
    changesMadeToWorkingPattern,
    setChangesMadeVar,
    setChangesMade,
    setUpdateSched,
    showMpwTimeline,
    setShowMpwTimeline,
    allowsUpdated,
    setAllowsUpdated,
    setShowMessages,
    showMessages,
    device,
    setShowClosedDaysHistory,
    setUpdateSingleUserInUsers,
    setIndicate,
    employeeCount,
    setEmployeeCount,
    showClosedDaysHistory,
    setUpdateUserAdmin,
    showEditShiftModal,
    setShowEditShiftModal,
    updateTabData,
    showReqMgrModal,
    setShowReqMgrModal,
    reqIDToLoadWhenClosingEditUser,
    showUpdatedReqsModal,
    updatedDashData,
    setUpdatedDashData,
    setUpdateSingleUserAllowances,
    showConvertToilModal,
    modalOpen,
    setModalOpen,
    setViewAbsenceUserTimeline,
    setShowMgrAvailModal,
    setShowUserHoursModal,
    showUserHoursModal,
  } = useContext(DataContext);

  const { editSched, setEditSched, setEditFixedVar, adminPage, mob, mobModal } =
    useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setViewAbsenceUserTimeline,
      showEditUser, //
      setShowEditUser, //
      isTeamNew, //
      changesMadeToWorkingPattern, //
      setChangesMadeVar, //
      setChangesMade, //
      setUpdateSched, //
      showMpwTimeline, //
      setShowMpwTimeline, //
      allowsUpdated, //
      setAllowsUpdated, //
      setShowMessages, //
      showMessages, //
      device, //
      setShowClosedDaysHistory,
      setUpdateSingleUserInUsers,
      setIndicate,
      employeeCount,
      setEmployeeCount,
      showClosedDaysHistory,
      setUpdateUserAdmin,
      showEditShiftModal,
      setShowEditShiftModal,
      updateTabData,
      showReqMgrModal,
      setShowReqMgrModal,
      reqIDToLoadWhenClosingEditUser,
      showUpdatedReqsModal,
      updatedDashData,
      setUpdatedDashData,
      currencySymbol,
      checkUserState,
      editSched,
      setEditSched,
      setEditFixedVar,
      adminPage,
      mob,
      mobModal,
      setUpdateSingleUserAllowances,
      showConvertToilModal,
      modalOpen,
      setModalOpen,
      setShowMgrAvailModal,
      setShowUserHoursModal,
      showUserHoursModal,
    }),
    [
      setViewAbsenceUserTimeline,
      showEditUser, //
      setShowEditUser, //
      isTeamNew, //
      changesMadeToWorkingPattern, //
      setChangesMadeVar, //
      setChangesMade, //
      setUpdateSched, //
      showMpwTimeline, //
      setShowMpwTimeline, //
      allowsUpdated, //
      setAllowsUpdated, //
      setShowMessages, //
      showMessages, //
      device, //
      setShowClosedDaysHistory, //
      setUpdateSingleUserInUsers, //
      setIndicate, //
      employeeCount, //
      setEmployeeCount, //
      showClosedDaysHistory, //
      setUpdateUserAdmin, //
      showEditShiftModal, //
      setShowEditShiftModal, //
      updateTabData, //
      showReqMgrModal, //
      setShowReqMgrModal, //
      reqIDToLoadWhenClosingEditUser, //
      showUpdatedReqsModal, //
      updatedDashData, //
      setUpdatedDashData, //
      currencySymbol, //
      checkUserState, //
      editSched, //
      setEditSched, //
      setEditFixedVar, //
      adminPage, //
      mob, //
      mobModal, //
      setUpdateSingleUserAllowances, //
      showConvertToilModal, //
      modalOpen,
      setModalOpen,
      setShowMgrAvailModal,
      setShowUserHoursModal,
      showUserHoursModal,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [sureRemoveFromTeam, setSureRemoveFromTeam] = useState({ show: false });

  let [notYetVerif, setNotYetVerif] = useState(false);
  let [editUserState, setEditUserState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pin: "",
      proPicURL: "",
      allowData: {},
      managesOwnTeam: false,
      managesOwnTeams: false,
      zones: [],
      clockOnAllowed: false,
      payClocked: false,
      autoApproveClock: false,
      allowInfo: {},
      fvHrs: 0,
      fvMins: 0,
      contractedMpw: 0,
      isAdmin: false,
      isSelf: false,
      absenceScore: 0,
      availableZones: [],
      allowSelfClockApprove: false,
      teamsArray: [],
      primaryTeamFigured: "",
      editUserUserId: "",
      editFName: "",
      editLName: "",
      editEmail: "",
      editEmpNum: "",
      editJobTitle: "",
      editPrimaryTeam: "",
      editStartDate: "",
      offerRegen: false,
      editReqClockout: false,
      workingPatternTarget: "",
      userLoading: true,
      loaded: false,
      tempHourlyRate: 1,
      bank: false,
      generating: false,
      editUserStartDate: "Y19700M00D01H00M00",
      loading: false,
      monPower: false,
      monStart: "",
      monEnd: "",
      monBrk: 0,
      monDur: 0,
      tuePower: false,
      tueStart: "",
      tueEnd: "",
      tueBrk: 0,
      tueDur: 0,
      wedPower: false,
      wedStart: "",
      wedEnd: "",
      wedBrk: 0,
      wedDur: 0,
      thuPower: false,
      thuStart: "",
      thuEnd: "",
      thuBrk: 0,
      thuDur: 0,
      friPower: false,
      friStart: "",
      friEnd: "",
      friBrk: 0,
      friDur: 0,
      satPower: false,
      satStart: "",
      satEnd: "",
      satBrk: 0,
      satDur: 0,
      sunPower: false,
      sunStart: "",
      sunEnd: "",
      sunBrk: 0,
      sunDur: 0,
      salaried: false,
      nonWorkingDays: [],
      enableGPS: false,
    }
  );
  let [companyName, setCompanyName] = useState("the company");

  let [historicHoursData, setHistoricHoursData] = useState([]);
  let [historyAnnLeaveMins, setHistoryAnnLeaveMins] = useState(0);
  let [historyPaidAbsenceMins, setHistoryPaidAbsenceMins] = useState(0);
  let [historyUnpaidAbsenceMins, setHistoryUnpaidAbsenceMins] = useState(0);
  let [historicMpw, setHistoricMpw] = useState(0);
  let [historyStartDs, setHistoryStartDs] = useState("");
  let [historyEndDs, setHistoryEndDs] = useState("");
  let [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  let [tabLoading, setTabLoading] = useState(true);
  let [allowancesChanged, setAllowancesChanged] = useState(false);
  let [activated, setActivated] = useState(true);
  let [fsChanged, setFsChanged] = useState(false);
  let [fsSaved, setFsSaved] = useState(false);
  let [fs, setFs] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      monStart: "",
      monEnd: "",
      monBrk: 0,
      monDurMins: 0,
      monNight: false,
      tueStart: "",
      tueEnd: "",
      tueBrk: 0,
      tueDurMins: 0,
      tuenNight: false,

      wedStart: "",
      wedEnd: "",
      wedBrk: 0,
      wedDurMins: 0,
      wedNight: false,
      thuStart: "",
      thuEnd: "",
      thuBrk: 0,
      thuDurMins: 0,
      thuNight: false,
      friStart: "",
      friEnd: "",
      friBrk: 0,
      friDurMins: 0,
      friNight: false,
      satStart: "",
      satEnd: "",
      satBrk: 0,
      satDurMins: 0,
      satNight: false,
      sunStart: "",
      sunEnd: "",
      sunBrk: 0,
      sunDurMins: 0,
      sunNight: false,
    }
  );

  let [invalidUser, setInvalidUser] = useState(false);

  let [showCheckList, setShowCheckList] = useState(false);
  let [showMePay, setShowMePay] = useState(checkPay ? true : false);
  let [showMeHours, setShowMeHours] = useState(checkHours ? true : false);
  let [showMeAllowances, setShowMeAllowances] = useState(false);
  let [showHistory, setShowHistory] = useState(false);
  let [fsQty, setFsQty] = useState(0);
  useEffect(() => {
    let mins = 0;
    if (
      fs.monStart &&
      fs.monStart.length === 5 &&
      fs.monEnd &&
      fs.monEnd.length === 5
    ) {
      let monStartTs = dateStringer.HHMMToMsToday(fs.monStart);
      let monEndTs = dateStringer.HHMMToMsToday(fs.monEnd);

      if (monEndTs <= monStartTs) {
        let dateObj = new Date(monEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        monEndTs = dateObj.getTime();
      }

      let grossMs = monEndTs - monStartTs;
      if (typeof parseInt(fs.monBrk) === "number") {
        grossMs -= fs.monBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "mon", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.tueStart &&
      fs.tueStart.length === 5 &&
      fs.tueEnd &&
      fs.tueEnd.length === 5
    ) {
      let tueStartTs = dateStringer.HHMMToMsToday(fs.tueStart);
      let tueEndTs = dateStringer.HHMMToMsToday(fs.tueEnd);
      if (tueEndTs <= tueStartTs) {
        let dateObj = new Date(tueEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        tueEndTs = dateObj.getTime();
      }

      let grossMs = tueEndTs - tueStartTs;
      if (typeof parseInt(fs.tueBrk) === "number") {
        grossMs -= fs.tueBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "tue", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.wedStart &&
      fs.wedStart.length === 5 &&
      fs.wedEnd &&
      fs.wedEnd.length === 5
    ) {
      let wedStartTs = dateStringer.HHMMToMsToday(fs.wedStart);
      let wedEndTs = dateStringer.HHMMToMsToday(fs.wedEnd);
      if (wedEndTs <= wedStartTs) {
        let dateObj = new Date(wedEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        wedEndTs = dateObj.getTime();
      }

      let grossMs = wedEndTs - wedStartTs;
      if (typeof parseInt(fs.wedBrk) === "number") {
        grossMs -= fs.wedBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "wed", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.thuStart &&
      fs.thuStart.length === 5 &&
      fs.thuEnd &&
      fs.thuEnd.length === 5
    ) {
      let thuStartTs = dateStringer.HHMMToMsToday(fs.thuStart);
      let thuEndTs = dateStringer.HHMMToMsToday(fs.thuEnd);
      if (thuEndTs <= thuStartTs) {
        let dateObj = new Date(thuEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        thuEndTs = dateObj.getTime();
      }

      let grossMs = thuEndTs - thuStartTs;
      if (typeof parseInt(fs.thuBrk) === "number") {
        grossMs -= fs.thuBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "thu", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.friStart &&
      fs.friStart.length === 5 &&
      fs.friEnd &&
      fs.friEnd.length === 5
    ) {
      let friStartTs = dateStringer.HHMMToMsToday(fs.friStart);
      let friEndTs = dateStringer.HHMMToMsToday(fs.friEnd);
      if (friEndTs <= friStartTs) {
        let dateObj = new Date(friEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        friEndTs = dateObj.getTime();
      }

      let grossMs = friEndTs - friStartTs;
      if (typeof parseInt(fs.friBrk) === "number") {
        grossMs -= fs.friBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "fri", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.satStart &&
      fs.satStart.length === 5 &&
      fs.satEnd &&
      fs.satEnd.length === 5
    ) {
      let satStartTs = dateStringer.HHMMToMsToday(fs.satStart);
      let satEndTs = dateStringer.HHMMToMsToday(fs.satEnd);
      if (satEndTs <= satStartTs) {
        let dateObj = new Date(satEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        satEndTs = dateObj.getTime();
      }

      let grossMs = satEndTs - satStartTs;
      if (typeof parseInt(fs.satBrk) === "number") {
        grossMs -= fs.satBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "sat", grossMs: grossMs / 1000 / 60 });
    }

    if (
      fs.sunStart &&
      fs.sunStart.length === 5 &&
      fs.sunEnd &&
      fs.sunEnd.length === 5
    ) {
      let sunStartTs = dateStringer.HHMMToMsToday(fs.sunStart);
      let sunEndTs = dateStringer.HHMMToMsToday(fs.sunEnd);
      if (sunEndTs <= sunStartTs) {
        let dateObj = new Date(sunEndTs);
        dateObj.setDate(dateObj.getDate() + 1);
        sunEndTs = dateObj.getTime();
      }

      let grossMs = sunEndTs - sunStartTs;
      if (typeof parseInt(fs.sunBrk) === "number") {
        grossMs -= fs.sunBrk * 1000 * 60;
      }
      mins += grossMs / 1000 / 60;
      console.log({ day: "sun", grossMs: grossMs / 1000 / 60 });
    }
    setFsQty(mins);
  }, [fs]);

  useEffect(() => {
    setFsChanged(true);
  }, [fs]);
  useEffect(() => {
    setFsChanged(false);
  }, [editUserState]);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 23) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={editUserProPic}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={todo}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockedCheck}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unarchive}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={threeDots}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={chat}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={download}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyClose}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unitTick}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitePlus}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editUserEdit1}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={clock}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={cal}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [toggleEditName, setToggleEditName] = useState(false);
  let [toggleEditJt, setToggleEditJt] = useState(false);

  let [toggleEditStartDate, setToggleEditStartDate] = useState(false);
  let [newStartDateInput, setNewStartDateInput] = useState("");

  let [emailVerified, setEmailVerified] = useState(false);

  let [updateBgUsers, setUpdateBgUsers] = useState(false); // will update background users in the admin users section
  let [adding, setAdding] = useState(false); // if true, this is a new user that is not yet created

  let [straightToAllowancesDone, setStraightToAllowancesDone] = useState(false);
  let [straightToTeamDone, setStraightToTeamDone] = useState(false);
  let [straightToDataDone, setStraightToDataDone] = useState(false);

  let [editUserPage, setEditUserPage] = useState(
    window.location.href.includes("dat-a") ? "Data" : "Details"
  );

  let [regenNeeded, setRegenNeeded] = useState(false);

  let [toggleEditEmail, setToggleEditEmail] = useState(false);
  let [emailTarget, setEmailTarget] = useState("");

  let [toggleEditEmployeeNumber, setToggleEditEmployeeNumber] = useState(false);
  let [employeeNumberTarget, setEmployeeNumberTarget] = useState("");

  let [toggleChangePrimaryTeam, setToggleChangePrimaryTeam] = useState(false);

  let [toggleHourlyRate, setToggleHourlyRate] = useState(false);
  let [hourlyRateTarget, setHourlyRateTarget] = useState(0);

  let [toggleEditWorkingPattern, setToggleEditWorkingPattern] = useState(false);

  let [checkAllowances, setCheckAllowances] = useState(false);

  let [proPicLoading, setProPicLoading] = useState(false);

  let [whoCanManageUserClocks, setWhoCanManageUserClocks] = useState([]); // not requests, but clocks etc

  let [showIsSelfNotAdminModal, setShowIsSelfNotAdminModal] = useState(false);

  let [savedFv, setSavedFv] = useState(false);
  let [salLoading, setSalLoading] = useState(false);

  let [sureRemove, setSureRemove] = useState(false);

  let [dataMonthArr, setDataMonthArr] = useState([]);
  let [selectedDataMinTs, setSelectedDataMinTs] = useState(false);
  let [dataMinTs, setDataMinTs] = useState(0);
  let [dataMaxTs, setDataMaxTs] = useState(0);
  let [minDateInput, setMinDateInput] = useState("");
  let [maxDateInput, setMaxDateInput] = useState("");

  let [dataArr, setDataArr] = useState([]);
  let [dataObj, setDataObj] = useState({});

  let [dataType, setDataType] = useState("all"); // all, hours, leave, absence
  let [dataHoursFilter, setDataHoursFilter] = useState([
    "shifts",
    "til",
    "overtime",
  ]);

  useEffect(() => {
    if (!dataHoursFilter.includes("shifts")) {
      setDataHoursFilter([...dataHoursFilter, "shifts"]);
    }
    if (!dataHoursFilter.includes("til")) {
      setDataHoursFilter([...dataHoursFilter, "til"]);
    }
    if (!dataHoursFilter.includes("overtime")) {
      setDataHoursFilter([...dataHoursFilter, "overtime"]);
    }
  }, [dataType]);

  useEffect(() => {
    if (dataArr[0]) {
      let cost = 0;
      let durMins = 0;
      let lateCount = 0;
      let lateDurMins = 0;
      let paidMins = 0;
      let unpaidMins = 0;

      dataArr.forEach((xx) => {
        if (
          (xx.shiftID && dataType === "all") ||
          (xx.shiftID &&
            dataType === "hours" &&
            dataHoursFilter.includes("shifts"))
        ) {
          if (typeof xx.cost === "number") {
            cost += xx.cost;
          }
          if (typeof xx.durMins === "number") {
            durMins += xx.durMins;
          }

          if (xx.lateDurMins && typeof xx.lateDurMins === "number") {
            lateDurMins += xx.lateDurMins;
            lateCount++;
          }
        }

        if (
          (xx.type === "til" && dataType === "all") ||
          ((xx.type === "til" || xx.inLieu) &&
            dataType === "hours" &&
            dataHoursFilter.includes("til"))
        ) {
          if (typeof xx.cost === "number") {
            cost += xx.cost;
          }
          if (typeof xx.durMins === "number") {
            durMins += xx.durMins;
          }

          if (xx.lateDurMins && typeof xx.lateDurMins === "number") {
            lateDurMins += xx.lateDurMins;
            lateCount++;
          }
        }

        if (
          (xx.type === "overtime" && dataType === "all") ||
          (xx.type === "overtime" &&
            dataType === "hours" &&
            dataHoursFilter.includes("overtime"))
        ) {
          if (typeof xx.cost === "number") {
            cost += xx.cost;
          }
          if (typeof xx.durMins === "number") {
            durMins += xx.durMins;
          }

          if (xx.lateDurMins && typeof xx.lateDurMins === "number") {
            lateDurMins += xx.lateDurMins;
            lateCount++;
          }
        }
        if (
          (xx.type === "leave" && dataType === "all") ||
          (xx.type === "leave" && dataType === "leave")
        ) {
          if (typeof xx.cost === "number") {
            // if (xx.cost && xx.unpaid) {
            // it will be salaried

            // cost -= xx.cost;
            // } else {
            cost += xx.cost;
            // }
          }
          if (typeof xx.durMins === "number") {
            durMins += xx.durMins;
            if (xx.unpaid) {
              unpaidMins += xx.durMins;
            } else {
              paidMins += xx.durMins;
            }
          }
        }
        if (
          (xx.type === "absence" && dataType === "all") ||
          (xx.type === "absence" && dataType === "absence")
        ) {
          if (typeof xx.cost === "number") {
            // if (xx.cost && xx.unpaid) {
            //   // it will be salaried
            //   cost -= xx.cost;
            // } else {
            cost += xx.cost;
            // }
          }
          if (typeof xx.durMins === "number") {
            durMins += xx.durMins;
            if (xx.unpaid) {
              unpaidMins += xx.durMins;
            } else {
              paidMins += xx.durMins;
            }
          }
        }
      });
      setDataObj({
        cost,
        durMins,
        lateCount,
        lateDurMins,
        paidMins,
        unpaidMins,
      });
    }
  }, [dataArr, dataType, dataHoursFilter]);

  useEffect(() => {
    if (editUserPage === "Data") {
      console.log({ minDateInput, maxDateInput });
      if (
        minDateInput &&
        minDateInput.length === 10 &&
        maxDateInput &&
        maxDateInput.length === 10
      ) {
        const cancelSource1 = axios.CancelToken.source();
        if (!tabLoading) {
          setTabLoading(true);
        }
        axios
          .post(
            `${serverURL}/get-user-tab-data`,
            {
              userID: userID,
              shifts: true,
              leave: true,
              absence: true,
              startDs: dateStringer.createStringFromDateInput(minDateInput),
              endDs: dateStringer.createStringFromDateInput(maxDateInput),
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response && response.data && response.data.message) {
              // set the editUser states
              if (response.data.message === "success") {
                setDataArr(response.data.output);
                setTabLoading(false);
                // let output = response.data.output;
                // let cost = 0;
                // let durMins = 0;
                // let lateCount = 0;
                // let lateDurMins = 0;
                // output.forEach((xx) => {
                //   if (xx.shiftID || xx.tilID) {
                //     if (typeof xx.cost === "number") {
                //       cost += xx.cost;
                //     }
                //     if (typeof xx.durMins === "number") {
                //       durMins += xx.durMins;
                //     }

                //     if (xx.lateDurMins && typeof xx.lateDurMins === "number") {
                //       lateDurMins += xx.lateDurMins;
                //       lateCount++;
                //     }
                //   }
                // });
                // setDataObj({ cost, durMins, lateCount, lateDurMins });

                // let cost = 0;
                // let hours = 0;
                // let lateCount = 0;
                // let lateDurMins = 0;
                // response.data.output.forEach((xx) => {
                //   if (xx.shiftID || xx.tilID) {
                //     console.log("...", xx.cost);
                //     if (typeof xx.cost === "number") {
                //       cost += xx.cost;
                //     }
                //     if (typeof xx.durMins === "number") {
                //       hours.push(xx.durMins);
                //     }
                //     if (xx.lateDurMins && typeof xx.lateDurMins === "number") {
                //       lateDurMins += xx.lateDurMins;
                //     }
                //   }
                // });
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [minDateInput, maxDateInput, memoVals.updateTabData]);

  let formatFsTime = (val) => {
    if (val && val.length === 4) {
      return `${val.substr(0, 2)}:${val.substr(2, 2)}`;
    }
    if (val && val.length === 5) {
      return val;
    } else {
      return "";
    }
  };

  let setFsFormat = (obj) => {
    if (obj) {
      setFs({
        monStart: formatFsTime(obj.monStart),
        monEnd: formatFsTime(obj.monEnd),
        monBrk: obj.monBrk,
        monDurMins: obj.monDuration,
        monNight: obj.monNight,

        tueStart: formatFsTime(obj.tuesStart),
        tueEnd: formatFsTime(obj.tuesEnd),
        tueBrk: obj.tuesBrk,
        tueDurMins: obj.tuesDuration,
        tueNight: obj.tuesNight,

        wedStart: formatFsTime(obj.wedStart),
        wedEnd: formatFsTime(obj.wedEnd),
        wedBrk: obj.wedBrk,
        wedDurMins: obj.wedDuration,
        wedNight: obj.wedNight,

        thuStart: formatFsTime(obj.thursStart),
        thuEnd: formatFsTime(obj.thursEnd),
        thuBrk: obj.thursBrk,
        thuDurMins: obj.thursDuration,
        thuNight: obj.thursNight,

        friStart: formatFsTime(obj.friStart),
        friEnd: formatFsTime(obj.friEnd),
        friBrk: obj.friBrk,
        friDurMins: obj.friDuration,
        friNight: obj.friNight,

        satStart: formatFsTime(obj.satStart),
        satEnd: formatFsTime(obj.satEnd),
        satBrk: obj.satBrk,
        satDurMins: obj.satDuration,
        satNight: obj.satNight,

        sunStart: formatFsTime(obj.sunStart),
        sunEnd: formatFsTime(obj.sunEnd),
        sunBrk: obj.sunBrk,
        sunDurMins: obj.sunDuration,
        sunNight: obj.sunNight,
      });
    }
  };

  let [allowMgrToAddNewUsers, setAllowMgrToAddNewUsers] = useState(false);
  let [allowsUpdatedLocal, setAllowsUpdatedLocal] = useState(false);
  let [showMore, setShowMore] = useState(false);
  let [editPeriodSalaried, setEditPeriodSalaried] = useState(false);
  let [wPattLoading, setWPattLoading] = useState(false);
  let [showEditProPicModal, setShowEditProPicModal] = useState(false);
  let [suppressCheckList, setSuppressCheckList] = useState(true);
  let [checkListDone, setCheckListDone] = useState([]);
  let [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  let [destroy, setDestroy] = useState(false); // tick  box when removing user

  let showMePayRef = useRef();
  let showMeHoursRef = useRef();
  useEffect(() => {
    if (detailsContainerRefTeams.current) {
      setTimeout(() => {
        if (showMePay && showMePayRef && showMePayRef.current) {
          // Scroll the container to the target element
          showMePayRef.current.scrollIntoView({ behavior: "smooth" });
        }
        if (showMeHours && showMeHoursRef && showMeHoursRef.current) {
          // Scroll the container to the target element
          showMeHoursRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 750);
    }
  }, [showMePay, showMeHours]);

  let updateCheckList = (item) => {
    let undo = false;
    if (item === "pay" && checkListDone.includes("pay")) {
      undo = true;
    }
    if (item === "hours" && checkListDone.includes("hours")) {
      undo = true;
    }
    if (item === "allowances" && checkListDone.includes("allowances")) {
      undo = true;
    }
    if (item === "workableDays" && checkListDone.includes("workableDays")) {
      undo = true;
    }

    axios
      .post(
        `${serverURL}/update-edit-user-checklist`,
        {
          userID: userID,
          item,
          undo,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (Array.isArray(response.data.checkList)) {
            setCheckListDone(response.data.checkList);
          }
        }
      });
  };

  let generateWhoCanManageClocks = whoCanManageUserClocks.map((item) => {
    return (
      <p
        key={`${item.fName}${item.lName}`}
        className="pTeamMgrName whoCanManageUserClockRow"
      >
        - {item.fName} {item.lName}
      </p>
    );
  });

  let [allowSelfHoursApprove, setAllowSelfHoursApprove] = useState([]);

  useEffect(() => {
    if (editUserPage === "Allowances") {
      setCheckAllowances(false);
    }

    if (userID && editUserPage === "Details") {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-user`,
          {
            userID: userID,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response && response.data && response.data.message) {
            if (response.data.message === "user invalid") {
              setInvalidUser(true);
            }
            // set the editUser states
            if (response.data.message === "success") {
              setAllowSelfHoursApprove(response.data.allowSelfHoursApprove);
              setActivated(response.data.activated);
              setHistoricHoursData(response.data.historicHoursData || []);
              setHistoryAnnLeaveMins(response.data.historyAnnLeaveMins);
              setHistoryPaidAbsenceMins(response.data.historyPaidAbsenceMins);
              setHistoryUnpaidAbsenceMins(
                response.data.historyUnpaidAbsenceMins
              );
              setHistoryEndDs(response.data.historyEndDs);
              setHistoryStartDs(response.data.historyStartDs);
              setHistoricMpw(response.data.historicMpw);
              let allowDataPurg = {};

              if (response.data.allowanceInfo) {
                allowDataPurg = {
                  totalAllowMins: response.data.allowanceInfo.totalAllowMins,
                  used: response.data.allowanceInfo.usedMins,
                };
              }
              if (response.data.isMgr && response.data.allowMgrToAddNewUsers) {
                setAllowMgrToAddNewUsers(true);
              }

              // setFs(response.data.fixedSchedData);
              setFsFormat(response.data.fixedSchedData);
              setEmailVerified(response.data.emailVerified);
              setCompanyName(response.data.companyName);
              setFs({
                monNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.monNight,
                tueNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.tuesNight,
                wedNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.wedNight,
                thuNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.thursNight,
                friNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.friNight,
                satNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.satNight,
                sunNight:
                  response.data.fixedSchedData &&
                  response.data.fixedSchedData.sunNight,
              });
              setEditUserState({
                pin: response.data.pin,
                isMgr: response.data.isMgr,
                proPicURL: response.data.proPicURL || "",
                primaryTeamID: response.data.primaryTeamID,
                allowData: allowDataPurg,
                managesOwnTeam: response.data.managesOwnTeam,
                managesOwnTeams: response.data.managesOwnTeams,
                zones: response.data.zones,
                clockOnAllowed: response.data.clockOnAllowed,
                payClocked: response.data.payClocked,
                autoApproveClock: response.data.autoApproveClock,
                allowInfo: response.data.allowData,
                fvHrs:
                  response.data.workingPattern === "fixedVariable"
                    ? response.data.fixVarHrs
                    : 0,
                fvMins:
                  response.data.workingPattern === "fixedVariable"
                    ? response.data.fixVarMins
                    : 0,
                contractedMpw: response.data.contractedMpw,
                isAdmin: response.data.isAdmin,
                isSelf: response.data.isSelf,
                absenceScore: response.data.absencePercentage,
                availableZones: response.data.availableZones,
                autoApproveClock: response.data.autoApproveClock,
                allowSelfClockApprove: response.data.allowSelfClockApprove,
                teamsArray: response.data.teamsArray,
                primaryTeamFigured: response.data.primaryTeamName,
                editUserUserId: response.data.userID,
                editFName: response.data.fName,
                editLName: response.data.lName,
                editEmail: response.data.email,
                editEmpNum: response.data.employeeNumber,
                editJobTitle: response.data.jobTitle,
                editPrimaryTeam: response.data.primaryTeamName,
                editStartDate: response.data.startDate,
                offerRegen: response.data.offerRegen,
                editReqClockout: response.data.reqClockOn,
                workingPatternTarget: response.data.workingPattern,
                monPower:
                  response.data.editMstaH && response.data.editMstaM
                    ? true
                    : false,
                monStart: `${response.data.editMstaH}:${response.data.editMstaM}`,
                monEnd: `${response.data.editMendH}:${response.data.editMendM}`,
                monBrk: `${response.data.editMbrk}`,
                monDur: `${
                  response.data.fixedScheduleDurationArray.monDur || 0
                }`,
                tuePower:
                  response.data.editTstaH && response.data.editTstaM
                    ? true
                    : false,
                tueStart: `${response.data.editTstaH}:${response.data.editTstaM}`,
                tueEnd: `${response.data.editTendH}:${response.data.editTendM}`,
                tueBrk: `${response.data.editTbrk}`,
                tueDur: `${
                  response.data.fixedScheduleDurationArray.tueDur || 0
                }`,
                wedPower:
                  response.data.editWstaH && response.data.editWstaM
                    ? true
                    : false,
                wedStart: `${response.data.editWstaH}:${response.data.editWstaM}`,
                wedEnd: `${response.data.editWendH}:${response.data.editWendM}`,
                wedBrk: `${response.data.editWbrk}`,
                wedDur: `${
                  response.data.fixedScheduleDurationArray.wedDur || 0
                }`,
                thuPower:
                  response.data.editTHstaH && response.data.editTHstaM
                    ? true
                    : false,
                thuStart: `${response.data.editTHstaH}:${response.data.editTHstaM}`,
                thuEnd: `${response.data.editTHendH}:${response.data.editTHendM}`,
                thuBrk: `${response.data.editTHbrk}`,
                thuDur: `${
                  response.data.fixedScheduleDurationArray.thuDur || 0
                }`,
                friPower:
                  response.data.editFstaH && response.data.editFstaM
                    ? true
                    : false,
                friStart: `${response.data.editFstaH}:${response.data.editFstaM}`,
                friEnd: `${response.data.editFendH}:${response.data.editFendM}`,
                friBrk: `${response.data.editFbrk}`,
                friDur: `${
                  response.data.fixedScheduleDurationArray.friDur || 0
                }`,
                satPower:
                  response.data.editSstaH && response.data.editSstaM
                    ? true
                    : false,
                satStart: `${response.data.editSstaH}:${response.data.editSstaM}`,
                satEnd: `${response.data.editSendH}:${response.data.editSendM}`,
                satBrk: `${response.data.editMbrk}`,
                satDur: `${
                  response.data.fixedScheduleDurationArray.satDur || 0
                }`,
                sunPower:
                  response.data.editSUstaH && response.data.editSUstaM
                    ? true
                    : false,
                sunStart: `${response.data.editSUstaH}:${response.data.editSUstaM}`,
                sunEnd: `${response.data.editSUendH}:${response.data.editSUendM}`,
                sunBrk: `${response.data.editSUbrk}`,
                sunDur: `${
                  response.data.fixedScheduleDurationArray.sunDur || 0
                }`,
                loaded: true,
                tempHourlyRate: response.data.hourlyRate,
                bank: response.data.bank,
                editUserStartDate: dateStringer.createTimestampFromString(
                  response.data.startDate
                ),
                loading: false,
                userLoading: false,
                salaried: response.data.salaried,
                salary: response.data.salary || 0,
                tempHourlyRate: response.data.salaried
                  ? response.data.salary
                  : response.data.hourlyRate,
                nonWorkingDays: response.data.nonWorkingDays,
                enableGPS: response.data.enableGPS,
                removed: response.data.removed,
                removedDs: response.data.removedDs,
                maxEndDs: response.data.maxEndDs,
              });
              setSuppressCheckList(response.data.suppressCheckList);

              if (Array.isArray(response.data.checkList)) {
                setCheckListDone(response.data.checkList);

                if (
                  !response.data.suppressCheckList &&
                  response.data.checkList.length < 3
                ) {
                  if (
                    !response.data.activated &&
                    !memoVals.showEditUser.checkPay &&
                    !memoVals.showEditUser.checkHours
                  ) {
                    setShowCheckList(true);
                  }
                }
              }

              if (straightToAllowances && !straightToAllowancesDone) {
                setEditUserPage("Allowances");
                setStraightToAllowancesDone(true);
              }
              if (straightToTeams && !straightToTeamDone) {
                setEditUserPage("Teams");
                setStraightToTeamDone(true);
              }
              if (straightToData && !straightToDataDone) {
                setEditUserPage("Data");
                setStraightToDataDone(true);
                setDataMonthArr(memoVals.showEditUser.monthArr);
                setSelectedDataMinTs(memoVals.showEditUser.selectedStartTs);
                setMinDateInput(memoVals.showEditUser.minDateInput);
                setMaxDateInput(memoVals.showEditUser.maxDateInput);
              }
              setDataLoaded(true);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [editUserPage]);

  let [showHourlyRateTimeline, setShowHourlyRateTimeline] = useState(false);
  let [hourlyRateTimeline, setHourlyRateTimeline] = useState([]);

  let [availableIndividuals, setAvailableIndividuals] = useState([]);

  let [toggleEditNameAndJt, setToggleEditNameAndJt] = useState(false);
  let [firstNameTarget, setFirstNameTarget] = useState("");
  let [lastNameTarget, setLastNameTarget] = useState("");
  let [jobTitleTarget, setJobTitleTarget] = useState("");
  let [hourlyRateTimelineConflict, setHourlyRateTimelineConflict] =
    useState(false);

  let timelineStartRef = useRef();
  let timelineEndRef = useRef();
  let [addTimelineObj, setAddTimelineObj] = useState({});

  let [addIndi, setAddIndi] = useState(false);

  useEffect(() => {
    if (addTimelineObj.startDate) {
      setTempStart(
        dateStringer.createDateInputFromDateString(addTimelineObj.startDate)
      );
      timelineStartRef.current.value =
        dateStringer.createDateInputFromDateString(addTimelineObj.startDate);

      timelineStartRef.current.value =
        dateStringer.createDateInputFromDateString(addTimelineObj.endDate);
      setTempEnd(
        dateStringer.createDateInputFromDateString(addTimelineObj.endDate)
      );
    }
  }, [addTimelineObj]);

  let isEndDateValid = (startOrEnd) => {
    let temp = startOrEnd === "start" ? tempStart : tempEnd;
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let endObj = new Date(
      dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(temp)
      )
    );

    if (endObj.getTime() >= today.getTime()) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    memoVals.setShowMpwTimeline((x) => false);
    memoVals.setChangesMade((x) => false);
    memoVals.setChangesMadeVar((x) => false);
  }, [memoVals.showEditUser]);

  let [editTimelineObjArr, setEditTimelineObjArr] = useState([]);
  let [editTimelineStart, setEditTimelineStart] = useState("");
  let [editTimelineEnd, setEditTimelineEnd] = useState("");
  let [startValid, setStartValid] = useState(false);
  let [endValid, setEndValid] = useState(false);
  let [startDateIsAfterWarning, setStartDateIsAfterWarning] = useState(false);
  let [editTimelineHourlyRate, setEditTimelineHourlyRate] = useState();
  let [tempStart, setTempStart] = useState("");
  let [tempEnd, setTempEnd] = useState("");
  let [tempRate, setTempRate] = useState(0);

  let [sureRemoveAllPeriods, setSureRemoveAllPeriods] = useState(false);
  let [sureRemoveAllPeriods2, setSureRemoveAllPeriods2] = useState(false);

  let [overlappingHourlyRates, setOverlappingHourlyRates] = useState([]);

  let [toggleFv, setToggleFv] = useState(0);

  let [showVerifModal, setShowVerifModal] = useState(false);

  // generate the hourlyRateTimeline
  function compare(a, b) {
    if (b.startTs < a.startTs) {
      return -1;
    }
    if (b.startTs > a.startTs) {
      return 1;
    }
    return 0;
  }
  hourlyRateTimeline.sort(compare);

  let isOverlap = () => {
    let overlap = false;
    let startTs = dateStringer.createTimestampFromString(
      dateStringer.createStringFromDateInput(tempStart)
    );
    let endTs = dateStringer.createTimestampFromString(
      dateStringer.createStringFromDateInput(tempEnd)
    );

    hourlyRateTimeline.forEach((obj) => {
      if (obj.objID !== editTimelineObjArr[0]) {
        // console.log(obj);
        let startObj = new Date(
          dateStringer.createTimestampFromString(obj.startDate)
        );
        let endObj = new Date(
          dateStringer.createTimestampFromString(obj.endDate)
        );

        if (startTs >= startObj.getTime() && startTs <= endObj.getTime()) {
          overlap = true;
        }

        if (endTs >= startObj.getTime() && endTs <= endObj.getTime()) {
          overlap = true;
        }
      }
    });

    return overlap;
  };
  let determineLastDate = () => {
    let arrayOfEndDates = [];
    hourlyRateTimeline.forEach((per) => {
      arrayOfEndDates.push(dateStringer.createTimestampFromString(per.endDate));
    });
    arrayOfEndDates.sort((a, b) => b - a);
    let lastObjEndDate = arrayOfEndDates[0];

    let dateObj = new Date(lastObjEndDate);
    dateObj.setHours(12, 0, 0, 0);
    dateObj.setDate(dateObj.getDate() + 1);

    let m = "";
    if (dateObj.getMonth() === 0) {
      m = "January";
    } else if (dateObj.getMonth() === 1) {
      m = "February";
    } else if (dateObj.getMonth() === 2) {
      m = "March";
    } else if (dateObj.getMonth() === 3) {
      m = "April";
    } else if (dateObj.getMonth() === 4) {
      m = "May";
    } else if (dateObj.getMonth() === 5) {
      m = "June";
    } else if (dateObj.getMonth() === 6) {
      m = "July";
    } else if (dateObj.getMonth() === 7) {
      m = "August";
    } else if (dateObj.getMonth() === 8) {
      m = "September";
    } else if (dateObj.getMonth() === 9) {
      m = "October";
    } else if (dateObj.getMonth() === 10) {
      m = "November";
    } else if (dateObj.getMonth() === 11) {
      m = "December";
    }

    return `From ${dateObj.getDate()} ${m} ${dateObj.getFullYear()}, ${
      editUserState.editFName
    } will earn ${memoVals.currencySymbol}${editUserState.tempHourlyRate} per ${
      editUserState.salaried ? "year" : "hour"
    }.`;
  };

  let generateHourlyRateTimeline = hourlyRateTimeline.map((period) => {
    return (
      <div key={period.objID} onClick={() => {}}>
        <p
          className={`${
            overlappingHourlyRates.includes(period.objID)
              ? "overlapsMpwUnitTxt"
              : "none"
          }`}
        >
          Overlaps another period
        </p>
        <div
          className={`hourlyRateTimelineUnit ${
            editTimelineObjArr.includes(period.objID)
              ? "selectedHourlyRateTimelineUnit"
              : ""
          }`}
          onClick={() => {}}
          key={period.objID}
        >
          <div
            className={`${
              editTimelineObjArr.includes(period.objID)
                ? "none"
                : "hourlyRateTimeinlineUnitContent"
            }`}
          >
            <div className="hourlyRateTimelineLeftSide">
              <p className="hourlyRateLeftSideTxt">
                <span
                  className={`${
                    dateStringer.createTimestampFromString(period.startDate) >
                    dateStringer.createTimestampFromString(period.endDate)
                      ? "startAfterEndMpwUnitSpanStart"
                      : ""
                  }`}
                >
                  {dateStringer.createShortStripFromDateString(
                    period.startDate
                  )}
                </span>{" "}
                <span className="spanHourlyRateTimelineToSpan">to</span>{" "}
                {dateStringer.createShortStripFromDateString(period.endDate)}
              </p>
              <div className="hourlyRateLeftDivider"></div>
              <p className="hourlyRateLeftSideTxt">
                {memoVals.currencySymbol}
                {period.salaried
                  ? period.salary || 0
                  : period.hourlyRate || 0}{" "}
                <span className="spanHourlyRateTimelineToSpan">
                  {period.salaried ? "annual salary" : "per hour"}
                </span>
              </p>
            </div>

            <div className="hourlyRateTimelineRightSide">
              <img
                src={editGrey}
                alt="edit"
                className="editHourlyRateTimelineImg"
                onClick={() => {
                  setEditTimelineStart("");
                  setEditTimelineObjArr([period.objID]);
                  let dateInputStringStart =
                    dateStringer.createDateInputFromDateString(
                      period.startDate
                    );
                  setTempStart(dateInputStringStart);
                  setEditTimelineStart(period.startDate);
                  setEditPeriodSalaried(period.salaried);

                  let dateInputStringEnd =
                    dateStringer.createDateInputFromDateString(period.endDate);
                  setTempEnd(dateInputStringEnd);
                  setTempRate(
                    period.salaried ? period.salary : period.hourlyRate
                  );
                  setEditTimelineHourlyRate(period.hourlyRate);
                  setEditTimelineEnd(period.endDate);
                }}
              />
            </div>
          </div>

          {/* selected to edit */}
          <div
            className={`${
              editTimelineObjArr.includes(period.objID)
                ? "hourlyRateTimeinlineUnitContent"
                : "none"
            }`}
          >
            <div className="hourlyRateTimelineLeftSideSelected">
              <div className="hourlyRateSelectedRow">
                <p className="hourlyRateFromToTxt">
                  From{" "}
                  <span
                    className={`${
                      isEndDateValid("start")
                        ? "none"
                        : "noLaterThanYesterdaySpan"
                    }`}
                  >
                    must be in the past
                  </span>
                </p>
                <input
                  ref={timelineStartRef}
                  className={`${
                    editTimelineObjArr.includes(period.objID)
                      ? `${
                          startValid
                            ? "editPeriodDateInput editPeriodValid"
                            : "editPeriodDateInput"
                        }`
                      : "none"
                  }`}
                  type="date"
                  value={tempStart}
                  onChange={(e) => {
                    setTempStart(e.target.value);

                    if (
                      e.target.value.length === 10 &&
                      e.target.value[0] === "2"
                    ) {
                      setStartValid(true);

                      setEditTimelineStart(
                        dateStringer.createStringFromDateInput(e.target.value)
                      );
                    } else {
                      setStartValid(false);
                    }
                  }}
                ></input>
              </div>
              <div className="hourlyRateLeftDivider"></div>
              <div className="hourlyRateSelectedRow">
                <p className="hourlyRateFromToTxt">
                  until{" "}
                  <span
                    className={`${
                      isEndDateValid("end")
                        ? "none"
                        : "noLaterThanYesterdaySpan"
                    }`}
                  >
                    must be in the past
                  </span>
                </p>

                <input
                  ref={timelineEndRef}
                  className={`${
                    editTimelineObjArr.includes(period.objID)
                      ? `${
                          endValid
                            ? "editPeriodDateInput editPeriodValid editPeriodSpaceUnder"
                            : "editPeriodDateInput editPeriodSpaceUnder"
                        }`
                      : "none"
                  }`}
                  type="date"
                  value={tempEnd}
                  onChange={(e) => {
                    setTempEnd(e.target.value);

                    if (
                      e.target.value.length === 10 &&
                      e.target.value[0] === "2"
                    ) {
                      setEndValid(true);

                      setEditTimelineEnd(
                        `${dateStringer
                          .createStringFromDateInput(e.target.value)
                          .substr(0, 11)}H23M59`
                      );

                      let startTs =
                        dateStringer.createTimestampFromString(
                          editTimelineStart
                        );
                      let endTs = dateStringer.createTimestampFromString(
                        dateStringer.createStringFromDateInput(e.target.value)
                      );

                      if (startTs > endTs) {
                        setStartDateIsAfterWarning(true);
                      } else {
                        setStartDateIsAfterWarning(false);
                      }
                    } else {
                      setEndValid(false);
                    }
                  }}
                ></input>
              </div>
              <div className="hourlyRateLeftDivider"></div>
              <p className={`${isOverlap() ? "overlapWarnInline" : "none"}`}>
                Dates overlap another period
              </p>
              <div
                className={`${
                  isOverlap() ? "none" : "hourlyRateSelectedRowRateContainer"
                }`}
              >
                <input
                  type="number"
                  id="hourlyRateInput"
                  value={tempRate}
                  className="hourlyRateInput
                      editHourlyRate "
                  onChange={(e) => {
                    setTempRate(e.target.value);
                    setEditTimelineHourlyRate(e.target.value);
                  }}
                ></input>
                <div className="perHourOrSalaryDiv">
                  <div
                    className="hrSalRow"
                    onClick={() => {
                      setEditPeriodSalaried(!editPeriodSalaried);
                    }}
                  >
                    {/* ipad */}
                    <div className="hrlySalTickBox">
                      {editPeriodSalaried ? (
                        ""
                      ) : (
                        <img
                          src={whiteTick}
                          alt="Per hour, salary"
                          className="hrlySalTickBoxImg"
                        />
                      )}
                    </div>
                    <p className="spanHourlyRateTimelineToSpanEdit">per hour</p>
                  </div>

                  <div
                    className="hrSalRow"
                    onClick={() => {
                      setEditPeriodSalaried(!editPeriodSalaried);
                    }}
                  >
                    {/* ipad */}
                    <div className="hrlySalTickBox">
                      {editPeriodSalaried ? (
                        <img
                          src={whiteTick}
                          alt="Per hour, salary"
                          className="hrlySalTickBoxImg"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <p className="spanHourlyRateTimelineToSpanEdit">
                      annual salary
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="hourlyRateTimelineRightSide">
              <div className="selectedHourlyRateContainer">
                <img
                  src={bin1}
                  alt="Remove"
                  className={`binCircleTimelineImg ${
                    period.initial ? "none" : ""
                  }`}
                  onClick={() => {
                    // make request
                    if (!period.initial) {
                      axios
                        .post(
                          `${serverURL}/delete-user-hourly-rate-timeline-object`,
                          {
                            userID: editUserState.editUserUserId,
                            objID: period.objID,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setEditTimelineObjArr([""]);
                            setHourlyRateTimeline(
                              response.data.hourlyRateTimeline
                            );
                          } else {
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                />

                <p
                  // src={saveDayHours}
                  // alt="Save"
                  className={`${
                    isEndDateValid("start") &&
                    isEndDateValid("end") &&
                    !isOverlap() &&
                    editTimelineObjArr.includes(period.objID)
                      ? `${
                          startValid && endValid && !startDateIsAfterWarning
                            ? "saveEditUserValBtn"
                            : "saveEditUserValBtn"
                        }`
                      : "none"
                  }`}
                  onClick={() => {
                    if (isEndDateValid("start") && isEndDateValid("end")) {
                      let newArr = [];
                      editTimelineObjArr.forEach((obj) => {
                        if (obj === period.objID) {
                        } else {
                          newArr.push(obj);
                        }
                        setEditTimelineObjArr(newArr);

                        // make the timeline object
                        let startTs = dateStringer.createTimestampFromString(
                          dateStringer.createStringFromDateInput(tempStart)
                        );
                        let endDs = editTimelineEnd;

                        let timelineObj = {
                          startDate:
                            // editTimelineStart ||
                            dateStringer.createStringFromDateInput(tempStart),
                          endDate:
                            // editTimelineEnd ||
                            dateStringer.createStringFromDateInput(tempEnd),
                          hourlyRate: editPeriodSalaried
                            ? 0
                            : editTimelineHourlyRate || tempRate,
                          objID: period.objID,
                          startTs: startTs,
                          salaried: editPeriodSalaried,
                          salary: editPeriodSalaried
                            ? parseInt(editTimelineHourlyRate || tempRate)
                            : 0,
                        };

                        // make request
                        axios
                          .post(
                            `${serverURL}/update-user-hourly-rate-timeline-object`,
                            {
                              userID: editUserState.editUserUserId,
                              data: timelineObj,
                              objID: period.objID,
                              newObj: period.new,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.status === 200) {
                              let conflict = false;
                              let overlaps = [];
                              response.data.hourlyRateTimeline.forEach(
                                (rate) => {
                                  let start =
                                    dateStringer.createTimestampFromString(
                                      rate.startDate
                                    );
                                  let end =
                                    dateStringer.createTimestampFromString(
                                      rate.endDate
                                    );

                                  if (start > end) {
                                    conflict = true;
                                  }

                                  response.data.hourlyRateTimeline.forEach(
                                    (rate2) => {
                                      if (rate2.objID !== rate.objID) {
                                        let start2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.startDate
                                          );
                                        let end2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.endDate
                                          );

                                        if (
                                          (start >= start2 && start <= end2) ||
                                          (end <= end2 && end >= start2)
                                        ) {
                                          if (!overlaps.includes(rate.objID)) {
                                            overlaps.push(rate.objID);
                                          }
                                        }
                                      }
                                    }
                                  );
                                }
                              );

                              setOverlappingHourlyRates(overlaps);
                              setHourlyRateTimelineConflict(conflict);
                              setHourlyRateTimeline(
                                response.data.hourlyRateTimeline
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                        // }
                      });
                    }
                  }}
                >
                  Save
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          {/* end of selected to edit */}
        </div>
        <p
          className={`${
            dateStringer.createTimestampFromString(period.startDate) >
            dateStringer.createTimestampFromString(period.endDate)
              ? "endDateAfterStartMpwUnit"
              : "none"
          }`}
        >
          Start date is after end
        </p>
      </div>
    );
  });

  // reset scroll on DetailsContainer div
  useEffect(() => {
    document.getElementById("DetailsContainer").scrollTop = 0;
    setShowHourlyRateTimeline(false);
  }, [memoVals.showEditUser]);

  // disable the back button -- not working but editTeam one does
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
      // memoVals.setShowEditUser(x =>false);
      closeModal();
      setEditUserPage("Details");
    });
  }, [memoVals.adminPage]);

  // store the teams object array

  // convert teamsArrayObj to array
  let teamsArrayConverted = Object.values(editUserState.teamsArray || []);

  let generateTeamsList = teamsArrayConverted.map((item) => {
    return (
      <option key={item.teamID} value={item.teamID}>
        {item.teamName}
      </option>
    );

    // }
  });

  let closeEditUserAndUpdateAllUsers = (userID) => {
    memoVals.setShowEditUser((x) => false);
    //   // closeModal();
  };

  let [sureExit, setSureExit] = useState(false);

  let getAbsenceScore = (perc) => {
    if (perc > 99) {
      return "score100";
    }
    if (perc > 90) {
      return "score95";
    }
    if (perc > 85) {
      return "score90";
    }
    if (perc > 80) {
      return "score85";
    }
    if (perc > 75) {
      return "score80";
    }
    if (perc > 70) {
      return "score75";
    }
    if (perc > 65) {
      return "score70";
    }
    if (perc > 60) {
      return "score65";
    }
    if (perc > 55) {
      return "score60";
    }
    if (perc > 50) {
      return "score55";
    }
    if (perc > 45) {
      return "score50";
    }
    if (perc > 40) {
      return "score45";
    }
    if (perc > 35) {
      return "score40";
    }
    if (perc > 30) {
      return "score35";
    }
    if (perc > 25) {
      return "score30";
    }
    if (perc > 20) {
      return "score25";
    }
    if (perc > 15) {
      return "score20";
    }
    if (perc > 10) {
      return "score15";
    }
    if (perc > 5) {
      return "score10";
    }
    if (perc > 0) {
      return "score05";
    }

    if (perc <= 0) {
      return "score0";
    }
  };

  let formatEmail = (val) => {
    if (val.length > 20) {
      return `${val.substr(0, 19)}...`;
    } else {
      return val;
    }
  };

  let [availableTeams, setAvailableTeams] = useState([]);
  let [partOfTeams, setPartOfTeams] = useState([]);
  let [mgrOfTeams, setMgrOfTeams] = useState([]);
  let [managesOwnPrimaryTeam, setManagesOwnPrimaryTeam] = useState(false);
  let [allowSelfApprove, setAllowSelfApprove] = useState(false);
  let [primaryTeamID, setPrimaryTeamID] = useState("");
  let [updateTeams, setUpdateTeams] = useState(false);
  let [toggleAddTeam, setToggleAddTeam] = useState(false);
  let [toggleAddTeamMgr, setToggleAddTeamMgr] = useState(false);
  let [pTeamMgrs, setPTeamMgrs] = useState([]);

  let [teamlessID, setTeamlessID] = useState("");

  let generatePTeamMgrs = pTeamMgrs.map((item, inx) => {
    return (
      <p key={inx} className="pTeamMgrName">
        - {item.fName} {item.lName}{" "}
        {item.admin ? (
          <span className="tMgrAdminSpan">FlowRota admin</span>
        ) : (
          ""
        )}
      </p>
    );
  });

  let pTeamName = (tID) => {
    let filt = partOfTeams.filter((item) => item.teamID === tID);
    if (filt[0]) {
      return filt[0].teamName;
    }
  };

  let generateAvailableTeams = availableTeams.map((t) => {
    let alreadyAdded = false;
    partOfTeams.forEach((item) => {
      if (item.teamID === t.teamID || t.teamless) {
        alreadyAdded = true;
      }
    });

    let valid = true;

    if (!alreadyAdded && (valid || editUserState.isAdmin)) {
      return (
        <option key={t.teamID} value={t.teamID}>
          {t.teamName}
        </option>
      );
    }
  });

  let generateAvailableTeamsMgr = availableTeams.map((t) => {
    if (!t.teamless) {
      let alreadyAdded = false;
      mgrOfTeams.forEach((item) => {
        if (item.teamID === t.teamID) {
          alreadyAdded = true;
        }
      });

      if (!alreadyAdded) {
        return (
          <option key={t.teamID} value={t.teamID}>
            {t.teamName}
          </option>
        );
      }
    }
  });

  availableIndividuals.sort(function (a, b) {
    return a.fName.localeCompare(b.fName);
  });

  let generateAvailableIndividuals = availableIndividuals.map((i) => {
    return (
      <option value={i.userID} key={i.userID}>
        {i.fName} {i.lName}
      </option>
    );
  });
  let generateAvailableTeamsForPrimary = availableTeams.map((t) => {
    // if (!t.teamless) {
    let valid = true;

    if (valid || editUserState.isAdmin) {
      return (
        <option key={t.teamID} value={t.teamID}>
          {t.teamName}
        </option>
      );
    }
    // }
  });
  let generatePartOfTeams = React.useMemo(() => {
    return partOfTeams.map((t) => {
      return (
        <div
          key={t.teamID}
          className={`editUserTeamUnit ${
            t.sort === 0 ? "editUserTeamPrimaryUnit" : ""
          }`}
        >
          <div className="editUserTeamUnitName">
            {t.sort === 0 ? (
              <p className="primInlineTitle">Primary team</p>
            ) : (
              ""
            )}
            <p>{t.teamName}</p>
          </div>
          {editUserPage === "Teams" ? (
            <img
              src={greyCross}
              alt="remove"
              className={`${
                t.teamless
                  ? "removeTeamUnitCrossImgBlank"
                  : "removeTeamUnitCrossImg"
              } ${memoVals.checkUserState.permissions > 1 ? "none" : ""}`}
              onClick={() => {
                if (!t.teamless) {
                  setSureRemoveFromTeam({
                    show: true,
                    teamName: t.teamName,
                    teamID: t.teamID,
                  });
                }
              }}
            />
          ) : t.teamID === editUserState.primaryTeamID ? (
            <p className="partOfPrim partOfPrim2">Primary</p>
          ) : (
            <p className="partOfPrim"></p>
          )}
        </div>
      );
    });
  }, [partOfTeams]);

  let [fixedSchedDayNEdited, setFixedSchedDayNEdited] = useState([]);

  let generateMgrOfTeams = mgrOfTeams.map((t) => {
    let ghostMgr = true;
    partOfTeams.forEach((item) => {
      if (item.teamID === t.teamID) {
        ghostMgr = false;
      }
    });

    return (
      <div
        key={t.teamID}
        className={`editUserTeamUnit ${ghostMgr ? "ghostMgrOf" : ""} ${
          t.sort === 0 ? "editUserTeamPrimaryUnit" : ""
        } teamMgrColor`}
      >
        <div className="editUserTeamUnitName">
          {ghostMgr ? (
            <p className="primInlineTitle">Manager only (not a member)</p>
          ) : (
            ""
          )}
          <p>{t.teamName}</p>
          {/* {ghostMgr ? (
            <p className="primInlineTitle">Manager only (not a member)</p>
          ) : (
            ""
          )}
          <p>{t.teamName}</p> */}
        </div>

        {/* <p className="editUserTeamUnitName">{t.teamName}</p> */}
        <img
          src={greyCross}
          alt="remove"
          className={`removeTeamUnitCrossImg ${
            memoVals.checkUserState.permissions > 1 ? "none" : ""
          }`}
          onClick={() => {
            axios
              .post(
                `${serverURL}/demote-team-manager`,
                {
                  teamID: t.teamID,
                  userID: editUserState.editUserUserId,
                  client: "React",
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  setUpdateBgUsers(true);
                  memoVals.setUpdatedDashData((x) => !memoVals.updatedDashData);

                  memoVals.setUpdateSched((x) => true);
                  setUpdateTeams(!updateTeams);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        />
      </div>
    );
  });

  let [mbrMgrOfTeams, setMbrMgrOfTeams] = useState([]);
  let [allowSelfClockApprove, setAllowSelfClockApprove] = useState([]);

  useEffect(() => {
    if (editUserState.editUserUserId) {
      if (
        !partOfTeams[0] ||
        // editUserPage === "Details" ||
        editUserPage === "Teams"
      ) {
        const cancelSource1 = axios.CancelToken.source();
        console.log({ partOfTeams });

        setToggleAddTeam(false);
        setToggleChangePrimaryTeam(false);
        setToggleAddTeamMgr(false);
        axios
          .post(
            `${serverURL}/get-user-teams`,
            { userID: editUserState.editUserUserId },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              response.data.availableTeams.forEach((t) => {
                if (t.teamless) {
                  setTeamlessID(t.teamID);
                }
              });
              setAvailableTeams(response.data.availableTeams);
              let bothMgrMgr = [];
              response.data.mgrOfTeams?.forEach((t) => {
                let mgrOfFilt = response.data.partOfTeams?.filter((x) => {
                  return x.teamID === t.teamID;
                });

                if (mgrOfFilt[0]) {
                  bothMgrMgr.push(mgrOfFilt[0]);
                }
              });

              setMbrMgrOfTeams(bothMgrMgr);
              setAllowSelfClockApprove(response.data.allowSelfClockApprove);
              // setTimeout(() => {
              setPartOfTeams(response.data.partOfTeams);
              // }, 3000);
              setMgrOfTeams(response.data.mgrOfTeams);
              setManagesOwnPrimaryTeam(response.data.managesOwnPrimaryTeam);
              setAllowSelfApprove(response.data.selfApprove);
              setPrimaryTeamID(response.data.primaryTeamID);
              setPTeamMgrs(response.data.mgrNames);

              if (updateBgUsers) {
                let pTeamName = "";
                response.data.partOfTeams.forEach((t) => {
                  if (t.teamID === response.data.primaryTeamID) {
                    pTeamName = t.teamName;
                  }
                });

                let teamStringsArr = [];
                response.data.partOfTeams.forEach((t) => {
                  teamStringsArr.push(t.teamName);
                });

                memoVals.setUpdateUserAdmin((x) => {
                  return {
                    userID: editUserState.editUserUserId,
                    primaryTeamID: response.data.primaryTeamID,
                    primaryTeamName: pTeamName,
                    teams: teamStringsArr.sort((a, b) => {
                      return a.localeCompare(b);
                    }),
                  };
                });
              }
              setWhoCanManageUserClocks(
                response.data.whoCanManageUserClocks || []
              );
            }
          })
          .catch((err) => {
            console.error(err);
          });
        return () => {
          cancelSource1.cancel("Component unmounted");
        };
      }
    }
  }, [editUserPage]);

  useEffect(() => {
    if (editUserState.editUserUserId) {
      const cancelSource1 = axios.CancelToken.source();
      // console.log({ partOfTeams });
      axios
        .post(
          `${serverURL}/get-user-teams`,
          { userID: editUserState.editUserUserId },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            response.data.availableTeams.forEach((t) => {
              if (t.teamless) {
                setTeamlessID(t.teamID);
              }
            });
            setAvailableTeams(response.data.availableTeams);
            let bothMgrMgr = [];
            response.data.mgrOfTeams?.forEach((t) => {
              let mgrOfFilt = response.data.partOfTeams?.filter((x) => {
                return x.teamID === t.teamID;
              });

              if (mgrOfFilt[0]) {
                bothMgrMgr.push(mgrOfFilt[0]);
              }
            });

            setMbrMgrOfTeams(bothMgrMgr);
            setAllowSelfClockApprove(response.data.allowSelfClockApprove);

            console.log("______", response.data.partOfTeams);
            setPartOfTeams(response.data.partOfTeams);
            setMgrOfTeams(response.data.mgrOfTeams);
            setManagesOwnPrimaryTeam(response.data.managesOwnPrimaryTeam);
            setAllowSelfApprove(response.data.selfApprove);
            setPrimaryTeamID(response.data.primaryTeamID);
            setPTeamMgrs(response.data.mgrNames);

            if (updateBgUsers) {
              let pTeamName = "";
              response.data.partOfTeams.forEach((t) => {
                if (t.teamID === response.data.primaryTeamID) {
                  pTeamName = t.teamName;
                }
              });

              let teamStringsArr = [];
              response.data.partOfTeams.forEach((t) => {
                teamStringsArr.push(t.teamName);
              });

              memoVals.setUpdateUserAdmin((x) => {
                return {
                  userID: editUserState.editUserUserId,
                  primaryTeamID: response.data.primaryTeamID,
                  primaryTeamName: pTeamName,
                  teams: teamStringsArr.sort((a, b) => {
                    return a.localeCompare(b);
                  }),
                };
              });
            }
            setWhoCanManageUserClocks(
              response.data.whoCanManageUserClocks || []
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [updateTeams]);

  useEffect(() => {
    let factory = (start, end, brk, dayN) => {
      let startObj = new Date();
      let endObj = new Date(startObj.getTime());
      startObj.setHours(parseInt(start.substr(0, 2)));
      startObj.setMinutes(parseInt(start.substr(3, 2)));

      endObj.setHours(parseInt(end.substr(0, 2)));
      endObj.setMinutes(parseInt(end.substr(3, 2)));

      if (endObj.getTime() <= startObj.getTime()) {
        endObj.setDate(endObj.getDate() + 1);
      }

      let durMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60 - brk;

      if (dayN === 0) {
        setEditUserState({ sunDur: durMins });
      }
      if (dayN === 1) {
        setEditUserState({ monDur: durMins });
      }
      if (dayN === 2) {
        setEditUserState({ tueDur: durMins });
      }
      if (dayN === 3) {
        setEditUserState({ wedDur: durMins });
      }
      if (dayN === 4) {
        setEditUserState({ thuDur: durMins });
      }
      if (dayN === 5) {
        setEditUserState({ friDur: durMins });
      }
      if (dayN === 6) {
        setEditUserState({ satDur: durMins });
      }
    };

    factory(
      editUserState.monStart,
      editUserState.monEnd,
      editUserState.monBrk,
      1
    );
    factory(
      editUserState.tueStart,
      editUserState.tueEnd,
      editUserState.tueBrk,
      2
    );
    factory(
      editUserState.wedStart,
      editUserState.wedEnd,
      editUserState.wedBrk,
      3
    );
    factory(
      editUserState.thuStart,
      editUserState.thuEnd,
      editUserState.thuBrk,
      4
    );
    factory(
      editUserState.friStart,
      editUserState.friEnd,
      editUserState.friBrk,
      5
    );
    factory(
      editUserState.satStart,
      editUserState.satEnd,
      editUserState.satBrk,
      6
    );
    factory(
      editUserState.sunStart,
      editUserState.sunEnd,
      editUserState.sunBrk,
      0
    );
  }, [
    editUserState.monStart,
    editUserState.monEnd,
    editUserState.monBrk,
    editUserState.monPower,
    editUserState.tueStart,
    editUserState.tueEnd,
    editUserState.tueBrk,
    editUserState.tuePower,
    editUserState.wedStart,
    editUserState.wedEnd,
    editUserState.wedBrk,
    editUserState.wedPower,
    editUserState.thuStart,
    editUserState.thuEnd,
    editUserState.thuBrk,
    editUserState.thuPower,
    editUserState.friStart,
    editUserState.friEnd,
    editUserState.friBrk,
    editUserState.friPower,
    editUserState.satStart,
    editUserState.satEnd,
    editUserState.satBrk,
    editUserState.satPower,
    editUserState.sunStart,
    editUserState.sunEnd,
    editUserState.sunBrk,
    editUserState.sunPower,
  ]);

  let fixedDaySchedValid = (dayN) => {
    if (dayN === 1) {
      if (
        editUserState.monStart.length === 5 &&
        editUserState.monEnd.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 2) {
      if (
        editUserState.tueStart.length === 5 &&
        editUserState.tueStart.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 3) {
      if (
        editUserState.wedStart.length === 5 &&
        editUserState.wedStart.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 4) {
      if (
        editUserState.thuStart.length === 5 &&
        editUserState.thuEnd.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 5) {
      if (
        editUserState.friStart.length === 5 &&
        editUserState.friEnd.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 6) {
      if (
        editUserState.satStart.length === 5 &&
        editUserState.satEnd.length === 5
      ) {
        return true;
      }
    }

    if (dayN === 0) {
      if (
        editUserState.sunStart.length === 5 &&
        editUserState.sunEnd.length === 5
      ) {
        return true;
      }
    }
  };

  let printJoinedYearsAgo = (ds) => {
    if (typeof ds === "number") {
      ds = dateStringer.createStringFromTimestamp(ds);
    }
    if (ds && ds[0] === "Y") {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let dsObj = new Date(dateStringer.createTimestampFromString(ds));
      let days = Math.round((today.getTime() - dsObj.getTime()) / 8640000);

      let monthDiff = (dateFrom, dateTo) => {
        return (
          dateTo.getMonth() -
          dateFrom.getMonth() +
          12 * (dateTo.getFullYear() - dateFrom.getFullYear())
        );
      };

      let totMonths = monthDiff(dsObj, today);
      let y = Math.floor(totMonths / 12);
      let m = totMonths % 12;

      if (totMonths > 12) {
        if (m === 0) {
          return `Joined ${y} years ago`;
        } else {
          return `Joined ${y}y, ${m}m ago`;
        }
      } else {
        if (totMonths === 12) {
          return `Joined 1 year ago`;
        } else {
          if (totMonths < 1) {
            let days = Math.round(
              (today.getTime() - dsObj.getTime()) / 86400000
            );
            if (days < 0) {
              return `Joins in ${
                days === -1 ? "tomorrow" : `${Math.abs(days)} days`
              }`;
            }
            return `Joined ${days} days ago`;
          } else {
            if (totMonths < 0) {
              return `Joins in ${Math.abs(totMonths)}m`;
            }
            return `Joined ${totMonths}m ago`;
          }
        }
      }
    }
  };

  let detailsContainerRef = useRef();
  let detailsContainerRefTeams = useRef();
  useEffect(() => {
    if (editUserPage === "Details") {
      console.log("SCROLL DETAILS TO TOP");
      if (detailsContainerRef.current) {
        detailsContainerRef.current.scrollTop = 0;
      }
    }

    if (editUserPage === "Teams") {
      console.log("SCROLL TEAMS TO TOP");

      if (detailsContainerRefTeams.current) {
        detailsContainerRefTeams.current.scrollTop = 0;
      }
    }
  }, [editUserPage]);

  let [checkAllowModal, setCheckAllowModal] = useState(false);

  let getAllowance = (allowObj) => {
    let tot = allowObj.totalAllowMins;
    let used = allowObj.used;
    if (tot && used) {
      return (used / tot) * 100;
    } else {
      return 0;
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [showFsChangedModal, setShowFsChangedModal] = useState(false);
  let closeModal = (ignoreFs) => {
    if (allowancesChanged) {
      memoVals.setUpdatedDashData((x) => !memoVals.updatedDashData);
      memoVals.setUpdateSingleUserAllowances(editUserState.editUserUserId); // josh
      console.log(
        "!!! updatin singleUserAllowances: ",
        editUserState.editUserUserId
      );
    }
    if (memoVals.reqIDToLoadWhenClosingEditUser) {
      memoVals.setShowReqMgrModal(
        (x) => memoVals.reqIDToLoadWhenClosingEditUser
      );
    }
    console.log("CLOSING MODAL", { fsChanged });
    if (fsChanged && !ignoreFs) {
      setShowFsChangedModal(true);
    } else {
      if (checkAllowances) {
        setCheckAllowModal(true);
        // reset modal position here
        setModalPosition({
          bottom: "0%",
          left: "0%",
        });
      } else {
        if (allowsUpdatedLocal) {
          memoVals.setAllowsUpdated((x) => !memoVals.allowsUpdated);
        }
        if (memoVals.changesMadeToWorkingPattern) {
          console.log("open regen modal, before firing the rest");
        } else {
          if (memoVals.isTeamNew) {
          }
          setEditUserPage("Details");

          memoVals.setEditSched((x) => false);
          setSureExit(false);
          memoVals.setEditFixedVar((x) => false);
          closeEditUserAndUpdateAllUsers(editUserState.editUserUserId);
          setEditUserPage("Details");
          if (editUserState.removed) {
            setEditUserState({ removed: false, removedDs: "" });
          }
          setEditUserState({ teamsArray: [] });
        }
      }
    }
  };
  let editWorkPattern = (workPattern) => {
    // setWPattLoading(true);
    setEditUserState({
      workingPatternTarget: workPattern,
    });
    axios
      .post(
        `${serverURL}/edit-user-working-pattern`,
        {
          userID: editUserState.editUserUserId,
          newData: workPattern,
          ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        setCheckAllowances(true);
        memoVals.setUpdateSched((x) => true);
        if (!checkListDone.includes("hours")) {
          setCheckListDone([...checkListDone, "hours"]);
        }
        setFs({
          monStart: "",
          monEnd: "",
          monBrk: 0,
          monDur: 0,
          tuePower: false,
          tueStart: "",
          tueEnd: "",
          tueBrk: 0,
          tueDur: 0,
          wedPower: false,
          wedStart: "",
          wedEnd: "",
          wedBrk: 0,
          wedDur: 0,
          thuPower: false,
          thuStart: "",
          thuEnd: "",
          thuBrk: 0,
          thuDur: 0,
          friPower: false,
          friStart: "",
          friEnd: "",
          friBrk: 0,
          friDur: 0,
          satPower: false,
          satStart: "",
          satEnd: "",
          satBrk: 0,
          satDur: 0,
          sunPower: false,
          sunStart: "",
          sunEnd: "",
          sunBrk: 0,
          sunDur: 0,
        });

        setEditUserState({
          workingPatternTarget: response.data.workingPattern,
          fvHrs: response.data.fixedVarHrs,
          fvMins: response.data.fixedVarMins,
          contractedMpw: response.data.newContractedMpw,
          nonWorkingDays: response.data.nonWorkingDays,
        });

        let sched = response.data;

        if (sched.monStart) {
          setEditUserState({
            monPower: true,
            monStart: `${sched.monStart.substr(0, 2)}:${sched.monStart.substr(
              2,
              2
            )}`,
            monEnd: `${sched.monEnd.substr(0, 2)}:${sched.monEnd.substr(2, 2)}`,
            monBrk: sched.monBrk,
          });
        }

        if (sched.tueStart) {
          setEditUserState({
            tuePower: true,
            tueStart: `${sched.tueStart.substr(0, 2)}:${sched.tueStart.substr(
              2,
              2
            )}`,
            tueEnd: `${sched.tueEnd.substr(0, 2)}:${sched.tueEnd.substr(2, 2)}`,
            tueBrk: sched.tueBrk,
          });
        }
        if (sched.wedStart) {
          setEditUserState({
            wedPower: true,
            wedStart: `${sched.wedStart.substr(0, 2)}:${sched.wedStart.substr(
              2,
              2
            )}`,
            wedEnd: `${sched.wedEnd.substr(0, 2)}:${sched.wedEnd.substr(2, 2)}`,
            wedBrk: sched.wedBrk,
          });
        }
        if (sched.thuStart) {
          setEditUserState({
            thuPower: true,
            thuStart: `${sched.thuStart.substr(0, 2)}:${sched.thuStart.substr(
              2,
              2
            )}`,
            thuEnd: `${sched.thuEnd.substr(0, 2)}:${sched.thuEnd.substr(2, 2)}`,
            thuBrk: sched.thuBrk,
          });
        }
        if (sched.friStart) {
          setEditUserState({
            friPower: true,
            friStart: `${sched.friStart.substr(0, 2)}:${sched.friStart.substr(
              2,
              2
            )}`,
            friEnd: `${sched.friEnd.substr(0, 2)}:${sched.friEnd.substr(2, 2)}`,
            friBrk: sched.friBrk,
          });
        }
        if (sched.satStart) {
          setEditUserState({
            satPower: true,
            satStart: `${sched.satStart.substr(0, 2)}:${sched.satStart.substr(
              2,
              2
            )}`,
            satEnd: `${sched.satEnd.substr(0, 2)}:${sched.satEnd.substr(2, 2)}`,
            satBrk: sched.satBrk,
          });
        }
        if (sched.sunStart) {
          setEditUserState({
            sunPower: true,
            sunStart: `${sched.sunStart.substr(0, 2)}:${sched.sunStart.substr(
              2,
              2
            )}`,
            sunEnd: `${sched.sunEnd.substr(0, 2)}:${sched.sunEnd.substr(2, 2)}`,
            sunBrk: sched.sunBrk,
          });
        }
        // setWPattLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let [itemOpen, setItemOpen] = useState(false); // if true, a data item is open, so hide the editusermodal
  useEffect(() => {
    if (itemOpen && !memoVals.showEditShiftModal && !memoVals.showReqMgrModal) {
      setItemOpen(false);
    }
  }, [memoVals.showEditShiftModal]);

  useEffect(() => {
    if (fs.monNight) {
      setFs({ monNight: false });
    }
  }, [fs.monStart, fs.monEnd]);

  useEffect(() => {
    if (fs.tueNight) {
      setFs({ tueNight: false });
    }
  }, [fs.tueStart, fs.tueEnd]);

  useEffect(() => {
    if (fs.wedNight) {
      setFs({ wedNight: false });
    }
  }, [fs.wedStart, fs.wedEnd]);

  useEffect(() => {
    if (fs.thuNight) {
      setFs({ thuNight: false });
    }
  }, [fs.thuStart, fs.thuEnd]);

  useEffect(() => {
    if (fs.friNight) {
      setFs({ friNight: false });
    }
  }, [fs.friStart, fs.friEnd]);

  useEffect(() => {
    if (fs.satNight) {
      setFs({ satNight: false });
    }
  }, [fs.satStart, fs.satEnd]);

  useEffect(() => {
    if (fs.sunNight) {
      setFs({ sunNight: false });
    }
  }, [fs.sunStart, fs.sunEnd]);
  let [cannotRemoveYourself, setCannotRemoveYourself] = useState(false);
  let [isAdminModal, setIsAdminModal] = useState({ show: false });
  // master return
  if (memoVals.showEditUser && !memoVals.showMessages) {
    return (
      <div
        className={`editUserUnderlay ${
          memoVals.showClosedDaysHistory ||
          memoVals.showEditShiftModal ||
          memoVals.showUpdatedReqsModal[0] ||
          memoVals.showReqMgrModal
            ? "invis"
            : ""
        }`}
        onClick={() => {
          closeModal();
        }}
      >
        <div
          className={`editUserModalBox ${
            editUserState.removed ? "archivedEditUser" : ""
          } ${
            memoVals.mobModal
              ? `mobEditUser ${memoVals.device.ios ? "mobEditUserIos" : ""}`
              : `desktopEditUser ${
                  showCheckList && !memoVals.mob && !loading
                    ? "editUserModalWithCheckList"
                    : ""
                }`
          } ${memoVals.device.vhIssue ? "vhIssueEditUser" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={memoVals.mobModal ? modalPosition : null}
          ref={modalRef}
        >
          {/* header */}
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperEditUser"></div>
          )}
          <div
            className="editUserModalHeader"
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="headerSideHolder">
              <img
                src={close}
                // src={mob ? namedDropped : close}
                className={`closeEditUserImg left ${loading ? "none" : ""} ${
                  memoVals.mobModal ? "closeEditUserImgMob" : ""
                }`}
                alt="Close"
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
            <div
              onClick={() => {
                console.log({ fs });
              }}
              className={`
                editUserNameTitle ${
                  memoVals.mobModal ? "editUserNameTitleMobModal" : ""
                }`}
            >
              {loading ? (
                <img
                  src={blueLoader}
                  alt="Loading"
                  className="editUserHorizLoadingImg"
                />
              ) : (
                editUserState.editFName
              )}{" "}
              {loading
                ? ""
                : suppressCheckList || checkListDone.length === 3
                ? editUserState.editLName
                : editUserState.editLName[0]}{" "}
              {/* {mob ? "mob" : "desktop"} */}
            </div>

            <div
              className={`editUserCheckListBtn ${
                memoVals.mobModal ? "editUserCheckListBtnMob" : ""
              } ${
                suppressCheckList || checkListDone.length === 3 ? "invis" : ""
              } ${showCheckList ? "checkListActive" : ""}`}
              onClick={() => {
                setShowCheckList(true);
              }}
            >
              <img
                src={todo}
                alt="Todo"
                className={`todoCheckImg ${showCheckList ? "invis" : ""}`}
              />{" "}
              {!memoVals.mobModal ? "Todo" : ""} {checkListDone.length} / 3
            </div>
          </div>

          {/* edit user menu */}
          <div
            className={`${
              loading
                ? "editUserModalMenu disableEditUserMenu"
                : "editUserModalMenu"
            }`}
          >
            <p
              className={`${
                editUserPage === "Details"
                  ? "editUserMenuItem editUserMenuItemSelected"
                  : `editUserMenuItem ${
                      !memoVals.mob ? "editUserMenuItemDesktopOnly" : ""
                    }`
              } ${window.innerWidth < 390 ? "smallEditUserTitle" : ""}`}
              onClick={() => {
                setEditUserPage("Details");
              }}
            >
              Details
            </p>
            <p
              className={`${
                editUserPage === "Teams"
                  ? "editUserMenuItem editUserMenuItemSelected"
                  : `editUserMenuItem ${
                      !memoVals.mob ? "editUserMenuItemDesktopOnly" : ""
                    }`
              }
              ${window.innerWidth < 390 ? "smallEditUserTitle" : ""}`}
              onClick={() => {
                setEditUserPage("Teams");
              }}
            >
              Teams
            </p>
            <p
              className={`${
                editUserPage === "Allowances"
                  ? "editUserMenuItem editUserMenuItemSelected"
                  : `editUserMenuItem ${
                      !memoVals.mob ? "editUserMenuItemDesktopOnly" : ""
                    }`
              } ${window.innerWidth < 390 ? "smallEditUserTitle" : ""}`}
              onClick={() => {
                if (memoVals.changesMadeToWorkingPattern) {
                  console.log("open regen modal, before firing the rest");
                  // setOpenChangesModal(true);
                } else {
                  setEditUserPage("Allowances");
                  memoVals.setEditSched((x) => false);
                }
              }}
            >
              Allowances
            </p>
            <p
              className={`${
                editUserPage === "Clock"
                  ? "editUserMenuItem editUserMenuItemSelected"
                  : `editUserMenuItem ${
                      !memoVals.mob ? "editUserMenuItemDesktopOnly" : ""
                    }`
              } ${window.innerWidth < 390 ? "smallEditUserTitle" : ""}`}
              onClick={() => {
                setEditUserPage("Clock");
                memoVals.setEditSched((x) => false);
              }}
            >
              Clock
            </p>
            <p
              className={`${
                editUserPage === "Data"
                  ? "editUserMenuItem editUserMenuItemSelected"
                  : `editUserMenuItem ${
                      !memoVals.mob ? "editUserMenuItemDesktopOnly" : ""
                    }`
              } ${window.innerWidth < 390 ? "smallEditUserTitle" : ""}`}
              onClick={() => {
                let startDs = `${editUserState.editStartDate.substr(
                  0,
                  8
                )}D01H00M00`;

                let endDs = editUserState.maxEndDs;

                console.log("--- ", startDs, endDs);

                if (
                  startDs &&
                  endDs &&
                  startDs[0] === "Y" &&
                  endDs[0] === "Y"
                ) {
                  let minTs = dateStringer.createTimestampFromString(startDs);
                  let maxTs = dateStringer.createTimestampFromString(endDs);
                  // console.log({ minTs, maxTs });

                  let monthsArray = [];
                  let currentDate = new Date(minTs);

                  // Loop through each month until currentDate is after maxTs
                  while (currentDate <= maxTs) {
                    // Get the start and end timestamps for the current month
                    const startOfMonth = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      1
                    );
                    const endOfMonth = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth() + 1,
                      0,
                      23,
                      59,
                      59
                    );

                    console.log({ startOfMonth, endOfMonth });

                    // Create the month object with the desired format
                    let nowTs = new Date().getTime();
                    const monthObject = {
                      startTs: startOfMonth.getTime(),
                      endTs: endOfMonth.getTime(),
                      current:
                        nowTs >= startOfMonth.getTime() &&
                        nowTs <= endOfMonth.getTime(),
                      startDs: dateStringer.createStringFromTimestamp(
                        startOfMonth.getTime()
                      ),
                      endDs: dateStringer.createStringFromTimestamp(
                        endOfMonth.getTime()
                      ),

                      title: `${startOfMonth
                        .toLocaleString("default", {
                          month: "short",
                        })
                        .substr(0, 3)} ${startOfMonth.getFullYear()}`,
                    };

                    // Push the month object into the monthsArray
                    monthsArray.push(monthObject);

                    // Move to the next month
                    currentDate.setMonth(currentDate.getMonth() + 1);
                  }
                  setDataMonthArr(monthsArray);

                  let filt = monthsArray.filter((x) => {
                    return x.current;
                  });

                  console.log({ filt });
                  let val = filt[0] ? filt[0].startTs : 0;
                  console.log({ val });
                  setSelectedDataMinTs(filt[0] ? filt[0].startTs : 0);

                  setMinDateInput(
                    dateStringer.createDateInputFromDateString(
                      filt[0] ? filt[0].startDs : ""
                    )
                  );
                  setMaxDateInput(
                    dateStringer.createDateInputFromDateString(
                      filt[0] ? filt[0].endDs : ""
                    )
                  );
                }
                setEditUserPage("Data");
                memoVals.setEditSched((x) => false);
              }}
            >
              Data
            </p>
          </div>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
          {memoVals.showMpwTimeline ? (
            <MpwTimelineInlet
              userID={editUserState.editUserUserId}
              year="all"
              fName={editUserState.editFName}
              editUserState={editUserState}
            />
          ) : (
            ""
          )}
          {/* DETAILS: Edit User Main body */}

          <div
            className={`${
              editUserPage === "Details" ? "editUserMainBodyContainer" : "none"
            } ${!memoVals.mobModal ? "x23o3io311" : ""}`}
          >
            <div
              className={`${loading ? "loadingEditUserWhiteSplash" : "none"}`}
            >
              <div className="editUserLoadBar1"></div>
              <div className="editUserLoadBar2"></div>
              <div className="editUserLoadBar3"></div>
              <div className="editUserLoadBar1"></div>
              <div className="editUserLoadBar2"></div>
              <div className="editUserLoadBar3"></div>
              <div className="editUserLoadBar1"></div>
              <div className="editUserLoadBar2"></div>
              <div className="editUserLoadBar3"></div>
            </div>
            <div
              ref={detailsContainerRef}
              className={`${loading ? "none" : "DetailsContainer"}`}
              id="DetailsContainer"
            >
              {editUserState.removed ? (
                <p className="editUsrArchivedSubTxt">
                  Archived{" "}
                  {dateStringer.printedDateFromDs(
                    editUserState.removedDs,
                    false,
                    true
                  )}
                </p>
              ) : (
                ""
              )}
              {/* user top profile section */}
              {!activated ? (
                memoVals.checkUserState.userID ===
                editUserState.editUserUserId ? (
                  <div className="editUserInactivated">
                    You need to activate your account with the email sent to you
                    when you created your FlowRota account.
                    <br />
                    <span
                      className="editUserResendSpan"
                      onClick={() => {
                        setNotYetVerif(true);
                      }}
                    >
                      Re-send email
                    </span>
                    .
                  </div>
                ) : (
                  <div className="editUserInactivated">
                    {editUserState.editFName} {editUserState.editLName} needs to
                    activate their account with the email sent to them when they
                    were added to FlowRota.
                  </div>
                )
              ) : (
                ""
              )}
              <div className="editUserRow2">
                <div className="editUserPicDiv">
                  {" "}
                  <img
                    className={`editUserProPicIcon ${
                      editUserState.removed ? "removedProPic" : ""
                    }`}
                    src={
                      editUserState.proPicURL
                        ? editUserState.proPicURL
                        : profilePicturePlaceholder
                    }
                    alt="Profile picture"
                  />
                  <div className="proPicChangeForm">
                    <img
                      src={editUserEdit1}
                      className={`editProPicPencilIcon ${
                        proPicLoading ? "invis" : ""
                      }`}
                      onClick={() => {
                        setShowEditProPicModal(true);
                      }}
                    />
                  </div>
                </div>
                <div className="editUserRowScores">
                  <div
                    className={`editUserAbsenceRow ${
                      editUserState.removed ? "defaultCursor" : ""
                    }`}
                    onClick={() => {
                      memoVals.setViewAbsenceUserTimeline((x) => {
                        return {
                          show: true,
                          userID: editUserState.editUserUserId,
                          year: parseInt(1111),
                          mgr: false,
                        };
                      });
                    }}
                  >
                    <div
                      className={`absenceScoreBar ${
                        editUserState.removed ? "greyScore" : ""
                      } ${getAbsenceScore(
                        isNaN(100 - editUserState.absenceScore)
                          ? 100
                          : 100 - editUserState.absenceScore
                      )}`}
                    ></div>
                  </div>
                  <div className="absenceBarTxtDiv">
                    <p
                      className={`editUserAbsenceYear ${
                        memoVals.mobModal ? "fontSize12" : "fontSize14"
                      }`}
                    >
                      Attendance this year
                    </p>
                    <p className="editUserAbsencePercent">
                      {100 - editUserState.absenceScore === Infinity
                        ? "+100"
                        : isNaN(100 - editUserState.absenceScore)
                        ? 100
                        : 100 - editUserState.absenceScore}
                      %
                    </p>
                  </div>
                  <div
                    className={`editUserAllowBarRow ${
                      editUserState.removed ? "defaultCursor" : ""
                    }`}
                    onClick={() => {
                      if (!editUserState.removed) {
                        setEditUserPage("Allowances");
                      }
                    }}
                  >
                    <div
                      className={`absenceScoreBar ${
                        editUserState.removed ? "greyScore" : ""
                      } ${getAbsenceScore(
                        getAllowance(editUserState.allowData)
                      )}`}
                    ></div>
                  </div>
                  <div
                    className="allowBarTxtDiv"
                    onClick={() => {
                      setEditUserPage("Allowances");
                    }}
                  >
                    <p
                      className={`editUserAbsenceYear ${
                        memoVals.mobModal ? "fontSize12" : "fontSize14"
                      }`}
                    >
                      Allowance used this year
                    </p>
                    <p className="editUserAbsencePercent">
                      {Math.round(getAllowance(editUserState.allowData)) ===
                      Infinity
                        ? "+100"
                        : Math.round(getAllowance(editUserState.allowData))}
                      %
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={`editUserRow2 ${
                  window.innerWidth <= 440 && toggleEditName
                    ? "editUserRow2NameUnder440"
                    : ""
                }`}
              >
                <div className={`editUserRow2Left`}>
                  <p className="editUserRowTitle2">Name</p>
                  {toggleEditName ? (
                    <div
                      className={`toggleUserNameInputs ${
                        window.innerWidth <= 440 ? "nameInputsUnder440" : ""
                      }`}
                    >
                      <input
                        className={`editUserValInput editUserNameInputSize ${
                          window.innerWidth <= 440
                            ? "editUserNameInputSizeUnder440"
                            : ""
                        }`}
                        placeholder="First name"
                        type="text"
                        value={firstNameTarget}
                        onChange={(e) => {
                          setFirstNameTarget(e.target.value);
                        }}
                      ></input>
                      <input
                        className={`editUserValInput editUserNameInputSize2 ${
                          window.innerWidth <= 440
                            ? "editUserNameInputSize2Under440"
                            : ""
                        }`}
                        placeholder="Last name"
                        type="text"
                        value={lastNameTarget}
                        onChange={(e) => {
                          setLastNameTarget(e.target.value);
                        }}
                      ></input>
                    </div>
                  ) : (
                    <p className="editUserRowValue2">
                      {editUserState.editFName} {editUserState.editLName}
                    </p>
                  )}
                </div>
                <div className="editUserRow2Right">
                  {toggleEditName ? (
                    <div className="saveEditUserBlock">
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt"
                        onClick={() => {
                          setToggleEditName(false);
                        }}
                      />
                      <p
                        onClick={() => {
                          if (firstNameTarget && lastNameTarget) {
                            setToggleEditName(false);
                            axios
                              .post(
                                `${serverURL}/edit-user-name`,
                                {
                                  userID: editUserState.editUserUserId,
                                  fName: firstNameTarget,
                                  lName: lastNameTarget,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );

                                  memoVals.setUpdateSched((x) => true);
                                  setUpdateBgUsers(true);
                                  // setToggleEditName(false);
                                  memoVals.setUpdateUserAdmin((x) => {
                                    return {
                                      userID: editUserState.editUserUserId,
                                      fName: firstNameTarget,
                                      lName: lastNameTarget,
                                    };
                                  });
                                  memoVals.setUpdateSingleUserInUsers(
                                    (x) => editUserState.editUserUserId
                                  );
                                  setEditUserState({
                                    editFName: response.data.fName,
                                    editLName: response.data.lName,
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`saveNameAndJtBtn ${
                          !firstNameTarget || !lastNameTarget
                            ? "invalidEditUserTopDetails"
                            : ""
                        }`}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <img
                      src={editUserEdit1}
                      alt="Edit"
                      className={`${
                        toggleEditName ? "none" : "editUserEditImg"
                      }`}
                      onClick={() => {
                        setFirstNameTarget(editUserState.editFName);
                        setLastNameTarget(editUserState.editLName);
                        setToggleEditName(!toggleEditName);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={`editUserRow2`}>
                <div className="editUserRow2Left">
                  <p className="editUserRowTitle2">
                    Email address{" "}
                    <span
                      className={`unverifEmailTxt makeBold ${
                        emailVerified ? "cursorDefault" : ""
                      }`}
                      onClick={() => {
                        if (!emailVerified) {
                          setShowVerifModal(true);
                        }
                      }}
                    >
                      {emailVerified ? "[Verified]" : "[Not verified]"}
                    </span>
                  </p>
                  {toggleEditEmail ? (
                    <div className={`toggleUserNameInputs`}>
                      <input
                        className={`editUserValInput editJtSize ${
                          window.innerWidth < 440 ? "editJtSizeUnder440" : ""
                        } `}
                        placeholder="Job title"
                        type="text"
                        value={emailTarget}
                        onChange={(e) => {
                          setEmailTarget(e.target.value);
                        }}
                      ></input>
                    </div>
                  ) : (
                    <p className="editUserRowValue2">
                      {editUserState.editEmail.length > 29 && memoVals.mobModal
                        ? `${editUserState.editEmail.substr(0, 27)}...`
                        : dateStringer.shorten(
                            editUserState.editEmail,
                            46
                          )}{" "}
                    </p>
                  )}
                </div>
                <div className="editUserRow2Right">
                  {toggleEditEmail ? (
                    <div className="saveEditUserBlock">
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt"
                        onClick={() => {
                          setToggleEditEmail(false);
                        }}
                      />
                      <p
                        onClick={() => {
                          if (
                            emailTarget.includes(".") &&
                            emailTarget.includes("@") &&
                            emailTarget !== editUserState.editEmail
                          ) {
                            axios
                              .post(
                                `${serverURL}/edit-user-email`,
                                {
                                  userID: editUserState.editUserUserId,
                                  data: emailTarget,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setToggleEditEmail(false);
                                  setEmailVerified(false);

                                  setEditUserState({
                                    editEmail: response.data.email,
                                  });
                                }
                                if (
                                  response.data.message === "already exists"
                                ) {
                                  setEmailAlreadyExists(true);
                                  setEmailTarget(editUserState.editEmail);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`saveNameAndJtBtn ${
                          !emailTarget ||
                          emailTarget === editUserState.editEmail
                            ? "invalidEditUserTopDetails"
                            : ""
                        }`}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <img
                      src={editUserEdit1}
                      alt="Edit"
                      className={`${
                        toggleEditEmail ? "none" : "editUserEditImg"
                      }`}
                      onClick={() => {
                        setEmailTarget(editUserState.editEmail);
                        setToggleEditEmail(!toggleEditEmail);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={`editUserRow2`}>
                <div className="editUserRow2Left">
                  <p className="editUserRowTitle2">Job title</p>
                  {toggleEditJt ? (
                    <div className={`toggleUserNameInputs`}>
                      <input
                        className={`editUserValInput editJtSize ${
                          window.innerWidth < 440 ? "editJtSizeUnder440" : ""
                        } `}
                        placeholder="Job title"
                        type="text"
                        value={jobTitleTarget}
                        onChange={(e) => {
                          setJobTitleTarget(e.target.value);
                        }}
                      ></input>
                    </div>
                  ) : (
                    <p className="editUserRowValue2">
                      {editUserState.editJobTitle.length > 29 &&
                      memoVals.mobModal
                        ? `${editUserState.editJobTitle.substr(0, 27)}...`
                        : dateStringer.shorten(
                            editUserState.editJobTitle,
                            46
                          )}{" "}
                    </p>
                  )}
                </div>
                <div className="editUserRow2Right">
                  {toggleEditJt ? (
                    <div className="saveEditUserBlock">
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt"
                        onClick={() => {
                          setToggleEditJt(false);
                        }}
                      />
                      <p
                        onClick={() => {
                          setToggleEditJt(false);
                          axios
                            .post(
                              `${serverURL}/edit-user-jt`,
                              {
                                userID: editUserState.editUserUserId,
                                jt: jobTitleTarget,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setUpdatedDashData(
                                  (x) => !memoVals.updatedDashData
                                );

                                memoVals.setUpdateSched((x) => true);
                                setUpdateBgUsers(true);
                                memoVals.setUpdateUserAdmin((x) => {
                                  return {
                                    userID: editUserState.editUserUserId,
                                    jt: jobTitleTarget,
                                  };
                                });
                                memoVals.setUpdateSingleUserInUsers(
                                  (x) => editUserState.editUserUserId
                                );
                                setEditUserState({
                                  editJobTitle: response.data.jt,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                        className={`saveNameAndJtBtn ${
                          !jobTitleTarget ? "invalidEditUserTopDetails" : ""
                        }`}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <img
                      src={editUserEdit1}
                      alt="Edit"
                      className={`${toggleEditJt ? "none" : "editUserEditImg"}`}
                      onClick={() => {
                        setJobTitleTarget(editUserState.editJobTitle);
                        setToggleEditJt(!toggleEditJt);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={`editUserRow2`}>
                <div className="editUserRow2Left">
                  <p className="editUserRowTitle2">Staff number</p>
                  {toggleEditEmployeeNumber ? (
                    <div className={`toggleUserNameInputs`}>
                      <input
                        className={`editUserValInput editJtSize ${
                          window.innerWidth < 440 ? "editJtSizeUnder440" : ""
                        } `}
                        placeholder="Staff number"
                        type="text"
                        value={employeeNumberTarget}
                        onChange={(e) => {
                          setEmployeeNumberTarget(e.target.value);
                        }}
                      ></input>
                    </div>
                  ) : (
                    <p className="editUserRowValue2">
                      {editUserState.editEmpNum || "None set"}{" "}
                    </p>
                  )}
                </div>
                <div className="editUserRow2Right">
                  {toggleEditEmployeeNumber ? (
                    <div className="saveEditUserBlock">
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt"
                        onClick={() => {
                          setToggleEditEmployeeNumber(false);
                        }}
                      />
                      <p
                        onClick={() => {
                          setToggleEditEmployeeNumber(false);
                          false;
                          axios
                            .post(
                              `${serverURL}/edit-user-employee-number`,
                              {
                                userID: editUserState.editUserUserId,
                                newData: employeeNumberTarget,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  editEmpNum: response.data.employeeNumber,
                                });
                                setToggleEditEmployeeNumber(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                        className={`saveNameAndJtBtn ${
                          !employeeNumberTarget
                            ? "invalidEditUserTopDetails"
                            : ""
                        }`}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <img
                      src={editUserEdit1}
                      alt="Edit"
                      className={`${
                        toggleEditEmployeeNumber ? "none" : "editUserEditImg"
                      }`}
                      onClick={() => {
                        setEmployeeNumberTarget(editUserState.editEmpNum);
                        setToggleEditEmployeeNumber(true);
                      }}
                    />
                  )}
                </div>
              </div>

              {/* CLOCK DATA WENT HERE */}
              {/* ------- IS NOT ADMIN, IS SELF MODAL */}
              <div
                className={`${
                  showIsSelfNotAdminModal
                    ? "invalidClockShiftModalUnderlay"
                    : "none"
                }`}
                onClick={(e) => {
                  e.stopPropagation();

                  setShowIsSelfNotAdminModal(false);
                }}
              >
                <div
                  className="invalidClockShiftModalBody"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <p className="unableToEditTxt">
                    Sorry, these settings are only editable by system
                    administrators or another manager of your primary team.
                  </p>

                  <p
                    className="closeInvalidShiftModalBtn"
                    onClick={() => {
                      setShowIsSelfNotAdminModal(false);
                    }}
                  >
                    Understood
                  </p>
                </div>
              </div>
              {/* -------- END OF IS NOT ADMIN, IS SELF MODAL */}
              <div
                className={`editUserRow salariedRow ${
                  showMePay ? "showMeRow" : ""
                } ${
                  checkUserState.permissions !== 1 && editUserState.isSelf
                    ? "greyscale"
                    : ""
                }`}
                ref={showMePay ? showMePayRef : null}
              >
                <div className="salariedRowTop">
                  <p className="editUserRowTitle2">Pay details</p>
                  <div
                    className="userHRateHistoryBtn"
                    onClick={() => {
                      if (!showHourlyRateTimeline) {
                        setSureRemoveAllPeriods2(false);
                        setSureRemoveAllPeriods(false);
                        setHourlyRateTimelineConflict(false);
                        axios
                          .post(
                            `${serverURL}/get-user-hourly-rate-timeline`,
                            { userID: editUserState.editUserUserId },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              let conflict = false;
                              let overlaps = [];
                              response.data.hourlyRateTimeline.forEach(
                                (rate) => {
                                  let start =
                                    dateStringer.createTimestampFromString(
                                      rate.startDate
                                    );
                                  let end =
                                    dateStringer.createTimestampFromString(
                                      rate.endDate
                                    );

                                  if (start > end) {
                                    conflict = true;
                                  }

                                  response.data.hourlyRateTimeline.forEach(
                                    (rate2) => {
                                      if (rate2.objID !== rate.objID) {
                                        let start2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.startDate
                                          );
                                        let end2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.endDate
                                          );

                                        if (
                                          (start >= start2 && start <= end2) ||
                                          (end <= end2 && end >= start2)
                                        ) {
                                          if (!overlaps.includes(rate.objID)) {
                                            overlaps.push(rate.objID);
                                          }
                                        }
                                      }
                                    }
                                  );
                                }
                              );

                              setOverlappingHourlyRates(overlaps);
                              setHourlyRateTimelineConflict(conflict);
                              setHourlyRateTimeline(
                                response.data.hourlyRateTimeline
                              );
                              setShowHourlyRateTimeline(
                                !showHourlyRateTimeline
                              );
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      } else {
                        setShowHourlyRateTimeline(!showHourlyRateTimeline);
                      }
                    }}
                  >
                    History{" "}
                    <img
                      src={popup}
                      className="editUserPopUpIcon"
                      alt="Pop up"
                    />
                  </div>
                </div>

                <div className="salariedRowLower">
                  <div className="salariedToggleAndInputLeftWrap">
                    <div
                      className="salariedToggleDiv"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          setSalLoading(true);
                          setToggleHourlyRate(false);
                          setEditUserState({
                            salaried: !editUserState.salaried,
                          });
                          let today00 = new Date();
                          today00.setHours(0, 0, 0, 0);

                          axios
                            .post(
                              `${serverURL}/toggle-user-salaried`,
                              {
                                userID: editUserState.editUserUserId,
                                data: !editUserState.salaried,
                                todayDs: dateStringer.createStringFromTimestamp(
                                  today00.getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  salaried: response.data.salaried,
                                  hourlyRate: response.data.hourlyRate || 0,
                                  salary: response.data.salary || 0,
                                  tempHourlyRate: response.data.salaried
                                    ? response.data.salary
                                    : response.data.hourlyRate,
                                });
                                memoVals.setUpdateSched((x) => true);
                                setSalLoading(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <div
                        className={`salariedToggleRow ${
                          editUserState.salaried
                            ? "selectedSalariedToggleRow"
                            : ""
                        }`}
                      >
                        {/*  KNOB BEGIN */}
                        <div className="editDayFxSchedKnob salariedKnob1">
                          <div className="knobContainer knobContainerEditUser">
                            <div
                              className={`switchKnob ${
                                editUserState.salaried ? "knobOn" : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                        </div>
                        {/*  KNOB END */}
                        <p
                          className={`salariedToggleTitle ${
                            editUserState.salaried ? "salariedSel" : ""
                          }`}
                        >
                          Salaried
                        </p>
                      </div>

                      <div
                        className={`salariedToggleRow ${
                          editUserState.salaried
                            ? ""
                            : "selectedSalariedToggleRow"
                        }`}
                      >
                        {/*  KNOB BEGIN */}
                        <div
                          className="editDayFxSchedKnob salariedKnob"
                          onClick={() => {}}
                        >
                          <div className="knobContainer knobContainerEditUser">
                            <div
                              className={`switchKnob ${
                                !editUserState.salaried ? "knobOn" : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                        </div>
                        {/*  KNOB END */}
                        <p
                          className={`salariedToggleTitle ${
                            editUserState.salaried ? "" : "salariedSel"
                          }`}
                        >
                          Hourly
                        </p>
                      </div>
                    </div>
                    <div className="salariedInputCol">
                      <p className="editUserEditTitle hourlyRateTitle">
                        {editUserState.salaried ? "Salary" : "Hourly rate"}{" "}
                        {editUserState.salaried ? (
                          <span className="annualSalarySpan">/ yr</span>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="hourlyRateInclLoaderDiv">
                        {salLoading ? (
                          <img
                            src={blueLoader}
                            alt="Loading"
                            className="loadingSalGif"
                          />
                        ) : (
                          <p
                            className={`${
                              toggleHourlyRate ? "none" : "hourlyRateValueSaved"
                            }`}
                          >
                            {editUserState.tempHourlyRate > 0
                              ? `${memoVals.currencySymbol}${editUserState.tempHourlyRate}`
                              : "-"}{" "}
                          </p>
                        )}
                      </div>
                      <input
                        className={`${
                          toggleHourlyRate
                            ? "editUserEmailValInput hourlyRateInputter"
                            : "none"
                        }`}
                        type="number"
                        value={hourlyRateTarget}
                        onChange={(e) => {
                          setHourlyRateTarget(parseInt(e.target.value));
                        }}
                      ></input>
                    </div>
                  </div>
                  <div className="salariedEditSaveCol">
                    <img
                      src={editGrey}
                      alt="Edit"
                      className={`${
                        toggleHourlyRate ? "none" : "editUserValEditIcon"
                      }`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          setHourlyRateTarget(editUserState.tempHourlyRate);
                          setToggleHourlyRate(true);
                        }
                      }}
                    />
                    <p
                      className={`${
                        toggleHourlyRate ? "saveEditUserValBtn" : "none"
                      }`}
                      onClick={() => {
                        let todayObj = new Date();
                        todayObj.setHours(0, 0, 0, 0);
                        axios
                          .post(
                            `${serverURL}/change-user-hourly-rate`,
                            {
                              salaried: editUserState.salaried ? true : false,
                              userID: editUserState.editUserUserId,
                              data: hourlyRateTarget,
                              todayDs: dateStringer.createStringFromTimestamp(
                                todayObj.getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              if (!checkListDone.includes("pay")) {
                                setCheckListDone([...checkListDone, "pay"]);
                              }

                              setEditUserState({
                                tempHourlyRate: editUserState.salaried
                                  ? response.data.salary
                                  : response.data.newHourlyRate,
                              });

                              setShowHourlyRateTimeline(false);
                              memoVals.setUpdateSched((x) => true);
                              setToggleHourlyRate(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      Save
                    </p>
                  </div>
                </div>
              </div>
              {/* hourly rate row */}
              {/* old code went here */}
              {/* - - -  schedule history timeline */}
              {/* --------- */}

              {/*  new working pattern row - - - -  */}

              <div
                className={`wPattEditUserRow ${
                  memoVals.mob ? "paddingTopWPatt" : ""
                } ${
                  !showMeHours &&
                  (editUserState.workingPatternTarget === "zero" ||
                    editUserState.workingPatternTarget === "fixedVariable")
                    ? "noBorder"
                    : ""
                } ${
                  showMeHours
                    ? `showMeRow ${
                        editUserState.workingPatternTarget === "fixedVariable"
                          ? "showMeRowFv"
                          : ""
                      }`
                    : ""
                } ${
                  checkUserState.permissions !== 1 && editUserState.isSelf
                    ? "greyscale"
                    : ""
                }`}
                ref={showMeHours ? showMeHoursRef : null}
              >
                <div className="salariedRowTop wPattTopRow">
                  <p className="editUserRowTitle2">Work pattern</p>
                  <div
                    className="userHRateHistoryBtn"
                    onClick={() => {
                      if (
                        checkUserState.permissions !== 1 &&
                        editUserState.isSelf
                      ) {
                        setShowIsSelfNotAdminModal(true);
                      } else {
                        memoVals.setShowMpwTimeline(
                          (x) => !memoVals.showMpwTimeline
                        );
                      }
                    }}
                  >
                    History{" "}
                    <img
                      src={popup}
                      className="editUserPopUpIcon"
                      alt="Pop up"
                    />
                  </div>
                </div>
                <div className="editUserRowTitleAndInput">
                  <div
                    className={`editUserRow2WPattDiv ${
                      memoVals.mobModal ? "editUserRow2WPattDivMob" : ""
                    }`}
                  >
                    <div className="wPattToggleDiv">
                      <div
                        className={`salariedToggleRow salariedToggleRowFirst ${
                          editUserState.workingPatternTarget ===
                          "fixedScheduled"
                            ? "selectedSalariedToggleRow"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            checkUserState.permissions !== 1 &&
                            editUserState.isSelf
                          ) {
                            setShowIsSelfNotAdminModal(true);
                          } else {
                            editWorkPattern("fixedScheduled");
                          }
                        }}
                      >
                        {/*  KNOB BEGIN */}
                        <div className="editDayFxSchedKnob salariedKnob1">
                          <div className="knobContainer knobContainerEditUser">
                            <div
                              className={`switchKnob ${
                                editUserState.workingPatternTarget ===
                                "fixedScheduled"
                                  ? "knobOn"
                                  : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                        </div>
                        {/*  KNOB END */}
                        <p
                          className={`salariedToggleTitle workingPattInlineTxt ${
                            editUserState.workingPatternTarget ===
                            "fixedScheduled"
                              ? "salariedSel"
                              : ""
                          }`}
                        >
                          Fixed hours, fixed shifts
                        </p>
                      </div>

                      <div
                        className={`salariedToggleRow ${
                          editUserState.workingPatternTarget === "fixedVariable"
                            ? "selectedSalariedToggleRow"
                            : ""
                        } `}
                        onClick={() => {
                          if (
                            checkUserState.permissions !== 1 &&
                            editUserState.isSelf
                          ) {
                            setShowIsSelfNotAdminModal(true);
                          } else {
                            editWorkPattern("fixedVariable");
                          }
                        }}
                      >
                        {/*  KNOB BEGIN */}
                        <div className="editDayFxSchedKnob salariedKnob">
                          <div className="knobContainer knobContainerEditUser">
                            <div
                              className={`switchKnob ${
                                editUserState.workingPatternTarget ===
                                "fixedVariable"
                                  ? "knobOn"
                                  : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                        </div>
                        {/*  KNOB END */}
                        <p
                          className={`salariedToggleTitle workingPattInlineTxt ${
                            editUserState.workingPatternTarget ===
                            "fixedVariable"
                              ? "salariedSel"
                              : ""
                          }`}
                        >
                          Fixed hours, varied shifts
                        </p>
                      </div>
                      <div
                        className={`salariedToggleRow ${
                          editUserState.workingPatternTarget === "zero"
                            ? "selectedSalariedToggleRow"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            checkUserState.permissions !== 1 &&
                            editUserState.isSelf
                          ) {
                            setShowIsSelfNotAdminModal(true);
                          } else {
                            editWorkPattern("zero");
                          }
                        }}
                      >
                        {/*  KNOB BEGIN */}
                        <div className="editDayFxSchedKnob salariedKnob">
                          <div className="knobContainer knobContainerEditUser">
                            <div
                              className={`switchKnob ${
                                editUserState.workingPatternTarget === "zero"
                                  ? "knobOn"
                                  : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                        </div>
                        {/*  KNOB END */}
                        <p
                          className={`salariedToggleTitle workingPattInlineTxt ${
                            editUserState.workingPatternTarget === "zero"
                              ? "salariedSel"
                              : ""
                          }`}
                        >
                          No fixed hours (zero hours)
                        </p>
                      </div>
                    </div>
                  </div>

                  <p
                    className={`${
                      toggleEditWorkingPattern
                        ? "workingPatternExplain invisWPattExplain"
                        : "workingPatternExplain"
                    } ${
                      memoVals.mobModal ? "workingPatternExplainMobModal" : ""
                    }`}
                  >
                    {editUserState.workingPatternTarget === "fixedScheduled"
                      ? `Set the scheduled hours ${editUserState.editFName} works each week in their shift pattern section below. FlowRota can schedule shifts when a weekly rota is generated. Managers can still add additional shifts and overtime.`
                      : `${
                          editUserState.workingPatternTarget === "fixedVariable"
                            ? `Set how many weekly hours ${editUserState.editFName} is contracted for below. The shift timings may vary each week and they can work and be paid extra for overtime.`
                            : `${editUserState.editFName} works on a casual / zero-hours basis.`
                        }`}
                  </p>

                  {editUserState.workingPatternTarget === "fixedScheduled" ? (
                    <div
                      className={`fixedSchedInputTable ${
                        memoVals.mobModal ? "fixedSchedInputTableMobModal" : ""
                      }`}
                    >
                      <div className="fsTableTitleRow">
                        <p
                          className={`fsTitleTxt fsTitleTxtDay ${
                            memoVals.mobModal ? "fsTitleTxtDayMob" : ""
                          }`}
                        >
                          Day
                        </p>
                        <p
                          className={`fsTitleTxt fsTitleTxtStart ${
                            memoVals.mobModal ? "fsTitleTxtStartMobModal" : ""
                          }`}
                        >
                          Start
                        </p>
                        <p
                          className={`fsTitleTxt fsTitleTxtEnd ${
                            memoVals.mobModal ? "fsTitleTxtEndMobModal" : ""
                          }`}
                        >
                          End
                        </p>
                        <p
                          className={`fsTitleTxt fsTitleTxtEndBreak  ${
                            memoVals.mobModal
                              ? "fsTitleTxtEndBreakMobModal"
                              : ""
                          }`}
                        >
                          Break (m)
                        </p>
                        <p
                          className={`fsTitleTxt fsTitleTxtEndWorkable ${
                            memoVals.mobModal
                              ? "fsTitleTxtEndWorkableMobModal"
                              : ""
                          }`}
                        >
                          Workable
                        </p>
                      </div>
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.monStart.length === 5 && fs.monEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Mon" : "Monday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.monStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ monStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.monEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ monEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.monBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ monBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.monStart.length === 5 &&
                                fs.monEnd.length === 5
                              ) {
                                setFs({ monStart: "", monEnd: "", monBrk: 0 });
                              } else {
                                setFs({
                                  monStart: "09:00",
                                  monEnd: "17:00",
                                  monBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.monStart.length === 5 && fs.monEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.monStart.length === 5 && fs.monEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.monNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>{" "}
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ monNight: !fs.monNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.monNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.tueStart.length === 5 && fs.tueEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Tue" : "Tuesday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.tueStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ tueStart: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.tueEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ tueEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.tueBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ tueBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.tueStart.length === 5 &&
                                fs.tueEnd.length === 5
                              ) {
                                setFs({ tueStart: "", tueEnd: "", tueBrk: 0 });
                              } else {
                                setFs({
                                  tueStart: "09:00",
                                  tueEnd: "17:00",
                                  tueBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.tueStart.length === 5 && fs.tueEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.tueStart.length === 5 && fs.tueEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.tueNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ tueNight: !fs.tueNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.tueNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.wedStart.length === 5 && fs.wedEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Wed" : "Wednesday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.wedStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ wedStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.wedEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ wedEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.wedBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ wedBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.wedStart.length === 5 &&
                                fs.wedEnd.length === 5
                              ) {
                                setFs({ wedStart: "", wedEnd: "", wedBrk: 0 });
                              } else {
                                setFs({
                                  wedStart: "09:00",
                                  wedEnd: "17:00",
                                  wedBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.wedStart.length === 5 && fs.wedEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.wedStart.length === 5 && fs.wedEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.wedNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ wedNight: !fs.wedNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.wedNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.thuStart.length === 5 && fs.thuEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Thu" : "Thursday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.thuStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ thuStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.thuEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ thuEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.thuBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ thuBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.thuStart.length === 5 &&
                                fs.thuEnd.length === 5
                              ) {
                                setFs({ thuStart: "", thuEnd: "", thuBrk: 0 });
                              } else {
                                setFs({
                                  thuStart: "09:00",
                                  thuEnd: "17:00",
                                  thuBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.thuStart.length === 5 && fs.thuEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.thuStart.length === 5 && fs.thuEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.thuNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ thuNight: !fs.thuNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.thuNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.friStart.length === 5 && fs.friEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Fri" : "Friday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.friStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ friStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.friEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ friEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.friBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ friBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.friStart.length === 5 &&
                                fs.friEnd.length === 5
                              ) {
                                setFs({ friStart: "", friEnd: "", friBrk: 0 });
                              } else {
                                setFs({
                                  friStart: "09:00",
                                  friEnd: "17:00",
                                  friBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.friStart.length === 5 && fs.friEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.friStart.length === 5 && fs.friEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.friNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ friNight: !fs.friNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.friNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.satStart.length === 5 && fs.satEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Sat" : "Saturday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.satStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ satStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.satEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ satEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.satBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ satBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.satStart.length === 5 &&
                                fs.satEnd.length === 5
                              ) {
                                setFs({ satStart: "", satEnd: "", satBrk: 0 });
                              } else {
                                setFs({
                                  satStart: "09:00",
                                  satEnd: "17:00",
                                  satBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.satStart.length === 5 && fs.satEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.satStart.length === 5 && fs.satEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.satNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ satNight: !fs.satNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.satNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}
                      <div className="fsTableDataRow fsTableRow1">
                        <p
                          className={`fsTableDayTxt ${
                            fs.sunStart.length === 5 && fs.sunEnd.length === 5
                              ? ""
                              : "unworkFsDay"
                          } ${memoVals.mobModal ? "fsTableDayTxtMob" : ""}`}
                        >
                          {memoVals.mobModal ? "Sun" : "Sunday"}
                        </p>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.sunStart}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ sunStart: enteredTime });
                                console.log(enteredTime);
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="time"
                            value={fs.sunEnd}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              const enteredTime = e.target.value;

                              // Regular expression to match valid 24-hour time format (HH:mm)
                              const timePattern =
                                /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/;

                              if (
                                timePattern.test(enteredTime) ||
                                enteredTime === ""
                              ) {
                                // If the entered time is valid or an empty string, update the state
                                setFs({ sunEnd: enteredTime });
                              }
                            }}
                            className={`fsTimeInput ${
                              memoVals.mobModal
                                ? `fsTimeInputMobModal ${
                                    memoVals.device.ios
                                      ? "fsTimeInputMobModalIos"
                                      : ""
                                  }`
                                : ""
                            }`}
                          ></input>
                        </div>
                        <div
                          className={`fsTableTimeInputWrap ${
                            memoVals.mobModal
                              ? `fsTableTimeInputWrapMobModal ${
                                  memoVals.device.ios
                                    ? "fsTableTimeInputWrapMobModalIos"
                                    : ""
                                }`
                              : ""
                          }`}
                        >
                          <input
                            type="number"
                            value={fs.sunBrk}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setFs({ sunBrk: e.target.value });
                            }}
                            className="fsBrkInput"
                          ></input>
                        </div>
                        <div
                          className={`fsDayPower ${
                            memoVals.mobModal ? "fsDayPowerMobModal" : ""
                          }`}
                        >
                          <img
                            src={unclockedCheck}
                            alt="Day enabled"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              if (
                                fs.sunStart.length === 5 &&
                                fs.sunEnd.length === 5
                              ) {
                                setFs({ sunStart: "", sunEnd: "", sunBrk: 0 });
                              } else {
                                setFs({
                                  sunStart: "09:00",
                                  sunEnd: "17:00",
                                  sunBrk: 60,
                                });
                              }
                            }}
                            className={`fsDayPowerImg ${
                              fs.sunStart.length === 5 && fs.sunEnd.length === 5
                                ? ""
                                : "unworkableFs"
                            }`}
                          />
                        </div>
                      </div>
                      {fs.sunStart.length === 5 && fs.sunEnd.length === 5 && (
                        <div
                          className={`nightShiftEditRow ${
                            memoVals.mobModal ? "nightShiftEditRowMob" : ""
                          }`}
                        >
                          <p
                            className={`nightShiftEditTitle ${
                              fs.sunNight ? "" : "bbcada"
                            }`}
                          >
                            Night rate
                          </p>
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer nightShiftEditUserKnobContainer">
                            <div
                              className="knobContainer knobContainerEditUser"
                              onClick={() => {
                                if (
                                  checkUserState.permissions !== 1 &&
                                  editUserState.isSelf
                                ) {
                                  setShowIsSelfNotAdminModal(true);
                                }
                                setFs({ sunNight: !fs.sunNight });
                              }}
                            >
                              <div
                                className={`switchKnob switchKnobNight ${
                                  fs.sunNight ? "knobOn" : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg knobBgNight"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      )}

                      <div className="saveFsRow">
                        <p className="fsHoursQty">
                          {dateStringer.formatMinsDurationToHours(fsQty)} per
                          week
                        </p>
                        {!fsSaved ? (
                          <p
                            className={`saveFsBtn ${fsChanged ? "" : "invis"}`}
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                setFsChanged(false);

                                axios
                                  .post(
                                    `${serverURL}/update-user-fixed-sched`,
                                    {
                                      // dayN: dayN,
                                      fsData: fs,
                                      userID: editUserState.editUserUserId,

                                      ds: dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                    },
                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      // setFs(response.data.fixedSchedData);
                                      if (!checkListDone.includes("hours")) {
                                        setCheckListDone([
                                          ...checkListDone,
                                          "hours",
                                        ]);
                                      }
                                      setFsFormat(response.data.fixedSchedData);
                                      memoVals.setIndicate((x) => {
                                        return {
                                          show: true,
                                          message: `${dateStringer.possession(
                                            editUserState.editFName
                                          )} hours updated`,
                                          colour: "green",
                                          duration: 4000,
                                        };
                                      });
                                      setCheckAllowances(true);
                                      memoVals.setUpdateSched((x) => true);
                                      setFsSaved(true);
                                      setTimeout(() => {
                                        setFsSaved(false);
                                      }, 3000);
                                      let sched = response.data.fixedSchedData;
                                      setEditUserState({
                                        contractedMpw:
                                          response.data.contractedMpw,
                                      });
                                      let newArr = [];
                                      fixedSchedDayNEdited.forEach((n) => {
                                        if (n !== dayN) {
                                          newArr.push(n);
                                        }
                                      });
                                      setFixedSchedDayNEdited(newArr);
                                    } else {
                                      setFsChanged(true);
                                      memoVals.setIndicate((x) => {
                                        return {
                                          show: true,
                                          message: `Failed to update hours`,
                                          colour: "red",
                                          duration: 4000,
                                        };
                                      });
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }
                            }}
                          >
                            Save
                          </p>
                        ) : (
                          <p className="fsSavedTxt">Saved</p>
                        )}
                      </div>
                      {editUserState.workingPatternTarget ===
                      "fixedScheduled" ? (
                        <div className="saveFsRow">
                          <p
                            className="userHRateHistoryBtn x9101pp"
                            onClick={() => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              } else {
                                memoVals.setShowClosedDaysHistory(
                                  (x) => editUserState.editUserUserId
                                );
                              }
                            }}
                          >
                            Workable days history
                            <img
                              src={popup}
                              alt="Closed days timeline"
                              className="editUserPopUpIcon"
                            />
                          </p>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {/*  */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`editUserValDivWPattOnly ${
                    toggleEditWorkingPattern ? "editWPatternSaveContainer" : ""
                  }`}
                ></div>
              </div>
              {editUserState.workingPatternTarget === "fixedVariable" ? (
                <div
                  className={`fvContainer ${
                    showMeHours ? "fvContainerShowMe" : ""
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  <div className="editUserFVContainer">
                    <p className="fvPerweekTxt">Contracted weekly hours</p>
                    <div className="editUserFvBox">
                      <div className="editUserFVleft">
                        <div className="editUserFVrow">
                          <input
                            type="number"
                            className="fixedVarHMnumInput"
                            value={editUserState.fvHrs}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setSavedFv(false);
                              setToggleFv(true);
                              setEditUserState({ fvHrs: e.target.value });
                            }}
                          ></input>
                          <p className="editUserFVHoursMinsTxt">hours</p>
                        </div>
                        <div className="editUserFVrow">
                          <input
                            type="number"
                            className="fixedVarHMnumInput"
                            value={editUserState.fvMins}
                            onChange={(e) => {
                              if (
                                checkUserState.permissions !== 1 &&
                                editUserState.isSelf
                              ) {
                                setShowIsSelfNotAdminModal(true);
                              }
                              setToggleFv(true);
                              setSavedFv(false);

                              setEditUserState({ fvMins: e.target.value });
                            }}
                          ></input>
                          <p className="editUserFVHoursMinsTxt">minutes</p>
                        </div>
                      </div>
                      <p
                        className={`${toggleFv ? "saveFVrowBtn" : "none"}`}
                        onClick={() => {
                          if (
                            checkUserState.permissions !== 1 &&
                            editUserState.isSelf
                          ) {
                            setShowIsSelfNotAdminModal(true);
                          } else {
                            if (
                              typeof editUserState.fvHrs === "number" &&
                              editUserState.fvHrs > 0 &&
                              typeof editUserState.fvMins === "number" &&
                              editUserState.fvMins > 0
                            ) {
                              // set to zero instead
                              console.log("SETTING TO ZERO AS 0 HOURS PASSED");
                            } else {
                              axios
                                .post(
                                  `${serverURL}/update-user-fixed-var`,
                                  {
                                    userID: editUserState.editUserUserId,
                                    hours: parseInt(editUserState.fvHrs),
                                    mins: parseInt(editUserState.fvMins),
                                    ds: dateStringer.createStringFromTimestamp(
                                      new Date().getTime()
                                    ),
                                  },
                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    if (!checkListDone.includes("hours")) {
                                      setCheckListDone([
                                        ...checkListDone,
                                        "hours",
                                      ]);
                                    }
                                    setCheckAllowances(true);
                                    memoVals.setUpdateSched((x) => true);

                                    setEditUserState({
                                      fvHrs: response.data.hours,
                                      fvMins: response.data.mins,
                                      contractedMpw:
                                        response.data.contractedMpw,
                                    });

                                    setSavedFv(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                            setToggleFv(false);
                          }
                        }}
                      >
                        {" "}
                        Save
                      </p>
                      {savedFv ? (
                        <div className="savedFv">
                          Saved{" "}
                          <img
                            src={unitTick}
                            className="savedFvTickImg"
                            alt="Saved"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}

              {editUserState.workingPatternTarget !== "fixedScheduled" &&
              editUserState.nonWorkingDays ? (
                <div
                  className={`nonWorkingDaysDiv ${
                    showMeHours ? "showMeRow showMeDays" : ""
                  } ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "greyscale"
                      : ""
                  }`}
                >
                  <p className="editUserEditTitle">
                    {editUserState.editFName}
                    {editUserState.editFName[editUserState.editFName - 1] ===
                    "s"
                      ? "'"
                      : "'s"}{" "}
                    workable days
                  </p>

                  <p className="nonWorkableDaysExplain">
                    FlowRota will display to managers which days{" "}
                    {editUserState.editFName} does not work when building rotas.
                  </p>

                  <div className="editUserClosedDaysTimelineBtnHolder">
                    <p></p>
                    <p
                      className="userHRateHistoryBtn"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          console.log("--- ", editUserState.editUserUserId);
                          memoVals.setShowClosedDaysHistory(
                            (x) => editUserState.editUserUserId
                          );
                        }
                      }}
                    >
                      History
                      <img
                        src={popup}
                        alt="Closed days timeline"
                        className="editUserPopUpIcon"
                      />
                    </p>
                  </div>

                  <div className="userNonWorkingDayDiv">
                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(1)) {
                              if (d !== 1) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(1)) {
                            arr.push(1);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Mon</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(1)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(2)) {
                              if (d !== 2) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(2)) {
                            arr.push(2);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Tue</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(2)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(3)) {
                              if (d !== 3) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(3)) {
                            arr.push(3);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Wed</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(3)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(4)) {
                              if (d !== 4) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(4)) {
                            arr.push(4);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Thu</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(4)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(5)) {
                              if (d !== 5) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(5)) {
                            arr.push(5);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Fri</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(5)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(6)) {
                              if (d !== 6) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(6)) {
                            arr.push(6);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Sat</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(6)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>

                    <div
                      className="userNonWorkingDayUnit"
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          let arr = [];
                          editUserState.nonWorkingDays.forEach((d) => {
                            if (editUserState.nonWorkingDays.includes(0)) {
                              if (d !== 0) {
                                arr.push(d);
                              }
                            } else {
                              arr.push(d);
                            }
                          });

                          if (!editUserState.nonWorkingDays.includes(0)) {
                            arr.push(0);
                          }
                          axios
                            .post(
                              `${serverURL}/edit-user-non-working-days`,
                              {
                                dayNumArr: arr,
                                userID: editUserState.editUserUserId,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setEditUserState({
                                  nonWorkingDays: response.data.nonWorkingDays,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <p className="userNonWorkDayTitle">Sun</p>
                      {/*  KNOB BEGIN */}
                      <div className="editUserReqClockOutKnobContainer usernonWorkKnob">
                        <div className="knobContainer knobContainerEditUser">
                          <div
                            className={`switchKnob ${
                              !editUserState.nonWorkingDays.includes(0)
                                ? "knobOn"
                                : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                      </div>
                      {/*  KNOB END */}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div
                className={`editUserRow2 noBorder ${
                  checkUserState.permissions !== 1 && editUserState.isSelf
                    ? "greyscale"
                    : ""
                }`}
              >
                <div className={`editUserRow2Left`}>
                  <p className="editUserRowTitle2">
                    Date {editUserState.editFName} joined {companyName}
                  </p>
                  {toggleEditStartDate ? (
                    <div className={`toggleUserNameInputs `}>
                      <input
                        className={`editUserValInput editUserNameInputSize x101081812d`}
                        placeholder="First name"
                        type="date"
                        value={newStartDateInput}
                        onChange={(e) => {
                          if (
                            checkUserState.permissions !== 1 &&
                            editUserState.isSelf
                          ) {
                            setShowIsSelfNotAdminModal(true);
                          } else {
                            setNewStartDateInput(e.target.value);
                          }
                        }}
                      ></input>
                    </div>
                  ) : (
                    <p className="editUserRowValue2 x23983929211">
                      {dateStringer.printedDateFromDs(
                        editUserState.editStartDate,
                        true
                      )}
                    </p>
                  )}
                </div>

                <div className="editUserRow2Right">
                  {toggleEditStartDate ? (
                    <div className="saveEditUserBlock">
                      <img
                        src={greyClose}
                        alt="Cancel"
                        className="cancelEditNameJt"
                        onClick={() => {
                          setToggleEditStartDate(false);
                        }}
                      />
                      <p
                        onClick={() => {
                          if (
                            newStartDateInput &&
                            newStartDateInput.length === 10
                          ) {
                            setToggleEditStartDate(false);
                            axios
                              .post(
                                `${serverURL}/edit-user-start-date`,
                                {
                                  userID: editUserState.editUserUserId,
                                  ds: dateStringer.createStringFromDateInput(
                                    newStartDateInput
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  memoVals.setUpdatedDashData(
                                    (x) => !memoVals.updatedDashData
                                  );
                                  setEditUserState({
                                    // jt: jobTitleTarget,
                                    editStartDate: response.data.startDate,
                                  });

                                  // memoVals.setUpdateSingleUserInUsers(x => (
                                  //   editUserState.editUserUserId
                                  // );
                                  // setEditUserState({
                                  //   editFName: response.data.fName,
                                  //   editLName: response.data.lName,
                                  //   // editJobTitle: response.data.jt,
                                  //   // editUserStartDate:
                                  //   //   dateStringer.createTimestampFromString(
                                  //   //     response.data.startDate
                                  //   //   ),
                                  //   // editStartDate:
                                  //   //   dateStringer.createTimestampFromString(
                                  //   //     response.data.startDate
                                  //   //   ),
                                  // });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                        className={`saveNameAndJtBtn ${
                          newStartDateInput.length !== 10 ||
                          parseInt(newStartDateInput.substr(0, 4)) < 1960
                            ? "invalidEditUserTopDetails"
                            : ""
                        }`}
                      >
                        Save
                      </p>
                    </div>
                  ) : (
                    <img
                      src={editUserEdit1}
                      alt="Edit"
                      className={`${
                        toggleEditStartDate ? "none" : "editUserEditImg"
                      }`}
                      onClick={() => {
                        if (
                          checkUserState.permissions !== 1 &&
                          editUserState.isSelf
                        ) {
                          setShowIsSelfNotAdminModal(true);
                        } else {
                          setNewStartDateInput(
                            dateStringer.createDateInputFromDateString(
                              editUserState.editStartDate
                            )
                          );
                          setToggleEditStartDate(!toggleEditStartDate);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="saveFsRow histoDataRow">
                <p
                  className="userHRateHistoryBtn editUserhistDataBtn"
                  onClick={() => {
                    if (
                      checkUserState.permissions !== 1 &&
                      editUserState.isSelf
                    ) {
                      setShowIsSelfNotAdminModal(true);
                    } else {
                      setShowHistory(true);
                    }
                  }}
                >
                  Historical data
                  <img
                    src={popup}
                    alt="Closed days timeline"
                    className="editUserPopUpIcon"
                  />
                </p>{" "}
              </div>
            </div>
          </div>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

          {/* TEAMS: Edit User Main body */}
          <div
            className={`${
              editUserPage === "Teams" ? "editUserMainBodyContainer" : "none"
            } ${!memoVals.mobModal ? "x23o3io311" : ""}`}
          >
            <div
              className="EditUserTeamsContainer"
              ref={detailsContainerRefTeams}
            >
              <div className="editUserTeamRow">
                <div className="editUserRowPartOfTeamsRow">
                  {/* teamhouse */}
                  <p className="editUserEditTitle">
                    {editUserState.editFName}'
                    {editUserState.editFName[
                      editUserState.editFName.length - 1
                    ] === "s"
                      ? ""
                      : "s"}{" "}
                    teams
                  </p>

                  <div className="partOfTeamsDiv">{generatePartOfTeams}</div>
                  {memoVals.checkUserState.permissions === 1 ? (
                    <div
                      className={`${
                        toggleAddTeam ? "none" : "toggleAddTeamBtn"
                      }`}
                      onClick={() => {
                        setToggleAddTeam(true);
                      }}
                    >
                      {" "}
                      <p className="addToOtherTeamBtnTxt">Add to a team</p>
                      <p className="addToOtherTeamBtnTxtPlus">+</p>
                    </div>
                  ) : (
                    <p className="askAdminToManageTeam">
                      Ask an admin if you want to add {editUserState.editFName}{" "}
                      to another team
                    </p>
                  )}
                  <select
                    className={`${
                      toggleAddTeam ? "toggleAddTeamDropdown" : "none"
                    }`}
                    defaultValue="Select team"
                    onChange={(e) => {
                      if (e.target.value !== "default-team-drop") {
                        axios
                          .post(
                            `${serverURL}/add-user-to-team`,
                            {
                              teamID: e.target.value,
                              userID: editUserState.editUserUserId,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setUpdateBgUsers(true);

                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );
                              setUpdateTeams(!updateTeams);
                              memoVals.setUpdateSched((x) => true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    <option value="default-team-drop">Select team</option>
                    {generateAvailableTeams}
                  </select>
                </div>
              </div>
              {/* - - - - - -  */}
              <div className="editUserTeamRow editUserPTeamRow">
                <div className="editUserRowTitleAndInput">
                  <p className="editUserEditTitle">Primary team</p>

                  {memoVals.checkUserState.permissions === 1 ? (
                    <select
                      className="changePrimaryTeamDropdown"
                      value={primaryTeamID}
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val) {
                          axios
                            .post(
                              `${serverURL}/edit-user-primary-team`,
                              {
                                userID: editUserState.editUserUserId,
                                newData: val,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setUpdatedDashData(
                                  (x) => !memoVals.updatedDashData
                                );

                                setUpdateBgUsers(true);
                                setUpdateTeams(!updateTeams);

                                memoVals.setUpdateSched((x) => true);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }}
                    >
                      <option value="">-- Select a team</option>
                      {generateAvailableTeamsForPrimary}
                    </select>
                  ) : (
                    <p className="nonAdminPteam">
                      {pTeamName(editUserState.primaryTeamID)}
                    </p>
                  )}
                  <div className="editUserPTeamExplain">
                    {teamlessID === primaryTeamID
                      ? `As ${
                          editUserState.editFName
                        } doesn't have a primary team, only admins will be able to approve ${
                          editUserState.editFName
                        }${
                          editUserState.editFName[0] === "s" ? "'" : "'s"
                        } requests.`
                      : ""}
                    {teamlessID !== primaryTeamID ? (
                      <span>
                        {editUserState.editFName}
                        {editUserState.editFName[
                          editUserState.editFName.length - 1
                        ] === "s"
                          ? "'"
                          : "'s"}{" "}
                        leave and absence periods may only be managed by the
                        managers of {editUserState.editFName}
                        {editUserState.editFName[
                          editUserState.editFName.length - 1
                        ] === "s"
                          ? "'"
                          : "'s"}{" "}
                        primary team or FlowRota administrators:
                      </span>
                    ) : (
                      ""
                    )}
                    <br />
                    <div className="generatePTeamMgrs">{generatePTeamMgrs}</div>
                  </div>
                </div>
              </div>
              {/*  new row */}
              <div className="editUserTeamRow">
                <div className="editUserRowPartOfTeamsRow">
                  {/* teamhouse */}
                  <p className="editUserEditTitle">
                    Teams {editUserState.editFName} manages
                  </p>
                  {/* <div className="editUserPTeamExplain">
                    <br />
                    Team for which {editUserState.editFName} will be able to
                    manage clock cards, shifts and overtime submissions.
                    <br />
                  </div> */}
                  <div
                    className={`${
                      mgrOfTeams.length > 0 ? "partOfTeamsDiv" : "none"
                    }`}
                  >
                    {generateMgrOfTeams}
                  </div>
                  <div
                    className={`${
                      mgrOfTeams.length > 0 ? "none" : "partOfTeamsDiv"
                    }`}
                  >
                    <span className="doesNotManageTeamSpan">
                      {editUserState.editFName} does not manage any teams.
                    </span>
                  </div>

                  {memoVals.checkUserState.permissions === 1 ? (
                    <div
                      className={`${
                        toggleAddTeamMgr ? "none" : "toggleAddTeamBtn"
                      }`}
                      onClick={() => {
                        setToggleAddTeamMgr(true);
                      }}
                    >
                      {" "}
                      <p className="addToOtherTeamBtnTxt">Add team to manage</p>
                      <p className="addToOtherTeamBtnTxtPlus">+</p>
                    </div>
                  ) : (
                    <p className="askAdminToManageTeam">
                      Ask an admin if you want to add {editUserState.editFName}{" "}
                      to another team
                    </p>
                  )}
                  <select
                    className={`${
                      toggleAddTeamMgr ? "toggleAddTeamDropdown" : "none"
                    }`}
                    defaultValue="Select team"
                    onChange={(e) => {
                      if (e.target.value !== "default-team-drop") {
                        axios
                          .post(
                            `${serverURL}/make-user-team-manager`,
                            {
                              userID: editUserState.editUserUserId,
                              teamID: e.target.value,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setUpdateBgUsers(true);
                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );

                              setUpdateTeams(!updateTeams);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    <option value="default-team-drop">Select team</option>
                    {generateAvailableTeamsMgr}
                  </select>
                </div>
              </div>
              {/* end of row */}

              {editUserState.isAdmin ? (
                <div
                  className={`${
                    managesOwnPrimaryTeam
                      ? `editUserTeamRow ${
                          !memoVals.mob && !editUserState.isAdmin
                            ? "hoverRowColour"
                            : ""
                        }`
                      : "none"
                  }`}
                  onClick={() => {
                    if (!editUserState.isAdmin) {
                      axios
                        .post(
                          `${serverURL}/toggle-allow-self-approve`,
                          {
                            userID: editUserState.editUserUserId,
                            data: !allowSelfApprove,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setUpdateTeams(!updateTeams);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else {
                      setIsAdminModal({
                        show: true,
                        name: editUserState.editFName,
                      });
                    }
                  }}
                >
                  <div className="editUserRowTitleAndInput">
                    <p className="editUserEditTitle">
                      Allow {editUserState.editFName} to add and approve their
                      own leave requests
                    </p>
                    {/* <br /> */}
                    <p className="editUserPTeamExplain x893298292">
                      {editUserState.editFName} is both a member and manager of
                      their primary team, {pTeamName(primaryTeamID)}. Enable
                      this setting to allow {editUserState.editFName} to manage
                      their own leave requests by being able to create, amend
                      and approve them
                      {/* <br />
                      <br /> */}
                    </p>
                  </div>
                  <div className="editUserValDiv">
                    {/*  KNOB BEGIN */}
                    <div className="editUserReqClockOutKnobContainer clockInKnob selfApproveKnob">
                      <div className="knobContainer knobContainerEditUser">
                        <div
                          className={`switchKnob ${
                            allowSelfApprove || editUserState.isAdmin
                              ? "knobOn"
                              : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* end of row */}
              {/* new row ---------------- */}
              {/* lenovo */}
              {mbrMgrOfTeams[0] ? (
                <div
                  className={`editUserRow approveClockEditUserRow ${
                    checkUserState.permissions !== 1 && editUserState.isSelf
                      ? "notAdminIsSelf"
                      : ""
                  } approveOwnClocksRow`}
                >
                  <div
                    className={`autoApproveClockDiv ${
                      !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
                    }`}
                  >
                    <p className="editUserEditTitle">
                      Allow {editUserState.editFName} to amend and approve their
                      own shift logs and changes.
                    </p>{" "}
                    <div className="amendOwnClocksRow">
                      {editUserState.editFName} is both a member and manager of
                      the following team{mbrMgrOfTeams?.length === 1 ? "" : "s"}
                      . Use the settings below to configure whether they can
                      amend and approve their own timesheets, self-logged
                      absences, TOIL requests, shift changes and swaps
                      {mbrMgrOfTeams?.length > 1
                        ? " within each team."
                        : "."}{" "}
                      {/* {generateManagesOwnTeams} */}
                      <br /> <br />
                      {mbrMgrOfTeams?.length > 1 ? "For each team, i" : "I"}f
                      unselected,{" "}
                      {dateStringer.possession(editUserState.editFName)} shift
                      changes, timesheets and absence logs will need to be
                      approved by another manager of{" "}
                      {mbrMgrOfTeams?.length > 1 ? "the" : "their"}
                      team (or an employee with FlowRota administrator
                      permissions).
                    </div>
                    {mbrMgrOfTeams?.map((x, i) => {
                      return (
                        <div
                          className={`mbrMgrOfClockRow ${
                            i === 0 ? "mbrMgrOfClockRowFirst" : ""
                          }`}
                          key={x.teamID}
                          onClick={() => {
                            if (
                              checkUserState.permissions !== 1 &&
                              editUserState.isSelf
                            ) {
                              setShowIsSelfNotAdminModal(true);
                            } else {
                              //axios here
                              // lebanon
                              if (editUserState.isAdmin) {
                                setIsAdminModal({
                                  show: true,
                                  name: editUserState.editFName,
                                });
                              } else {
                                axios
                                  .post(
                                    `${serverURL}/toggle-mgr-can-manage-own-team-hours`,
                                    {
                                      userID: editUserState.editUserUserId,
                                      teamID: x.teamID,
                                      add: allowSelfHoursApprove.includes(
                                        x.teamID
                                      )
                                        ? false
                                        : true,
                                      pull: allowSelfHoursApprove.includes(
                                        x.teamID
                                      )
                                        ? true
                                        : false,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setAllowSelfHoursApprove(
                                        response.data.allowSelfHoursApprove
                                      );
                                    }
                                  });
                              }
                              // axios
                              //   .post(
                              //     `${serverURL}/toggle-allow-self-clock-approve`,
                              //     {
                              //       userID: unitUserID,
                              //       data: allowSelfClockApprove?.includes(
                              //         x.teamID
                              //       )
                              //         ? false
                              //         : true,
                              //       teamID: x.teamID,
                              //     },
                              //     {
                              //       withCredentials: true,
                              //       credentials: "include",
                              //     }
                              //   )
                              //   .then((response) => {
                              //     if (response.data.message === "success") {
                              //       setAllowSelfClockApprove(
                              //         response.data.data
                              //       );
                              //     }
                              //   })
                              //   .catch((err) => {
                              //     console.error(err);
                              //   });
                            }
                          }}
                        >
                          <p className="mbrMgrOfClockRowTitle">{x.teamName}</p>{" "}
                          {/*  KNOB BEGIN */}
                          <div className="editUserReqClockOutKnobContainer clockInKnob selfApproveKnob">
                            <div className="knobContainer knobContainerEditUser allowSelfAppClockKnobContainer">
                              <div
                                className={`switchKnob ${
                                  allowSelfHoursApprove?.includes(x.teamID) ||
                                  editUserState.isAdmin
                                    ? "knobOn"
                                    : "knobOff"
                                }`}
                              ></div>
                              <div className="knobBg"></div>
                            </div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/*  END OF rowv ----------- */}
              {/* {editUserState.isAdmin && 4 + 4 === 9 ? (
                <div
                  className={`${
                    editUserState.isMgr
                      ? "editUserTeamRow hoverRowColour "
                      : "none"
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/toggle-allow-mgr-to-add-new-users`,
                        {
                          userID: editUserState.editUserUserId,
                          data: !allowMgrToAddNewUsers,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setAllowMgrToAddNewUsers(!allowMgrToAddNewUsers);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div className="editUserRowTitleAndInput">
                    <p className="editUserEditTitle">
                      Allow {editUserState.editFName} to add new employees to
                      FlowRota
                    </p>
                    <br />
                    <p className="editUserPTeamExplain">
                      Allow {editUserState.editFName} to be able to add users to
                      FlowRota. This will affect your monthly payments.
                    </p>
                  </div>
                  <div className="editUserValDiv">
                    <div className="editUserReqClockOutKnobContainer clockInKnob selfApproveKnob">
                      <div className="knobContainer knobContainerEditUser">
                        <div
                          className={`switchKnob ${
                            allowMgrToAddNewUsers ? "knobOn" : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

          {/* ALLOWANCES: Edit User Main body */}
          {editUserPage === "Allowances" ? (
            <div
              className={`${
                editUserPage === "Allowances"
                  ? "editUserMainBodyContainer"
                  : "none"
              } ${!memoVals.mobModal ? "x23o3io311" : ""}`}
            >
              <UserAllowances
                userID={editUserState.editUserUserId}
                fName={editUserState.editFName}
                setAllowsUpdatedLocal={setAllowsUpdatedLocal}
                editUserPage={editUserPage}
                setShowMpwTimeline={setShowMpwTimeline}
                setAllowancesChanged={setAllowancesChanged}
                showMeAllowances={showMeAllowances}
                setCheckListDone={setCheckListDone}
                checkListDone={checkListDone}
                setShowIsSelfNotAdminModal={setShowIsSelfNotAdminModal}
                editUserState={editUserState}
              />
            </div>
          ) : (
            ""
          )}

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

          {/* DETAILS: Edit User Main body */}
          {/* DATA CONTAINER */}
          <div
            className={`${
              editUserPage === "Data" ? "editUserMainBodyContainer" : "none"
            } ${!memoVals.mobModal ? "x23o3io311" : ""}`}
          >
            {" "}
            {/* <div className="allowancesSelectContainer">hello there</div> */}
            <div className="EditUserTeamsContainer x99281">
              {/* Data container */}
              <div
                className={`editUserDataDateRowTop ${
                  editUserState.removed ? "removedUserPaddingLeftRight" : ""
                }`}
              >
                <div className={`editUserDataDateRow `}>
                  {" "}
                  <div className="editUserDataDateLeft">
                    <div className="editUserDataDateBlock">
                      <p className="editUserDataDateTitle">First date</p>
                      <input
                        type="date"
                        className="editUserDataDateInput"
                        value={minDateInput}
                        onChange={(e) => {
                          setMinDateInput(e.target.value);
                          if (e.target.value.length === 10) {
                            let ts = dateStringer.createTimestampFromString(
                              dateStringer.createStringFromDateInput(
                                e.target.value
                              )
                            );
                            if (selectedDataMinTs !== "-") {
                              setSelectedDataMinTs("-");
                            }
                            setDataMinTs(ts);
                          }
                        }}
                      ></input>
                    </div>
                    <div className="editUserDataDateBlock editUserDataDateBlock2">
                      <p className="editUserDataDateTitle">Last date</p>
                      <input
                        value={maxDateInput}
                        onChange={(e) => {
                          setMaxDateInput(e.target.value);
                          if (e.target.value.length === 10) {
                            let ts = dateStringer.createTimestampFromString(
                              dateStringer.createStringFromDateInput(
                                e.target.value
                              )
                            );
                            let dateObj = new Date(ts);
                            dateObj.setHours(23, 59, 0, 0);
                            setDataMaxTs(dateObj.getTime());
                            if (selectedDataMinTs !== "-") {
                              setSelectedDataMinTs("-");
                            }
                          }
                        }}
                        type="date"
                        className="editUserDataDateInput"
                      ></input>
                    </div>
                  </div>
                  <div className="editUserDataDateLeft">
                    <div className="editUserDataDateBlock">
                      <p className="editUserDataDateTitle">Month</p>

                      <select
                        className="editUserDataDrop"
                        value={selectedDataMinTs}
                        onChange={(e) => {
                          let val = parseInt(e.target.value);
                          console.log({ val });
                          setSelectedDataMinTs(val);
                          let dateObj = new Date(val);
                          dateObj.setMonth(dateObj.getMonth() + 1);
                          dateObj.setDate(dateObj.getDate() - 1);
                          dateObj.setHours(23, 59, 0, 0);
                          console.log({ dateObj });
                          setDataMaxTs(dateObj.getTime());
                          setDataMinTs(val);
                          setMinDateInput(
                            dateStringer.createDateInputFromDateString(
                              dateStringer.createStringFromTimestamp(val)
                            )
                          );
                          setMaxDateInput(
                            dateStringer.createDateInputFromDateString(
                              dateStringer.createStringFromTimestamp(
                                dateObj.getTime()
                              )
                            )
                          );
                        }}
                      >
                        <option value="-">Custom</option>
                        {dataMonthArr.map((x, i) => {
                          return (
                            <option key={i} value={x.startTs}>
                              {x.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`editUserDataDateRowBottom ${
                  editUserState.removed ? "removedUserPaddingLeftRight" : ""
                }`}
              >
                <div className="dataTypeSelectUpper">
                  <div className="dataTypeSelectDiv">
                    <p
                      className={`dataTypeTxt ${
                        dataType !== "all" ? "unselDataTypeTxt" : ""
                      }`}
                      onClick={() => {
                        setDataType("all");
                      }}
                    >
                      All{" "}
                    </p>
                    <p
                      className={`dataTypeTxt ${
                        dataType !== "hours" ? "unselDataTypeTxt" : ""
                      }`}
                      onClick={() => {
                        setDataType("hours");
                      }}
                    >
                      Hours{" "}
                    </p>
                    <p
                      className={`dataTypeTxt ${
                        dataType !== "leave" ? "unselDataTypeTxt" : ""
                      }`}
                      onClick={() => {
                        setDataType("leave");
                      }}
                    >
                      Leave{" "}
                    </p>
                    <p
                      className={`dataTypeTxt ${
                        dataType !== "absence" ? "unselDataTypeTxt" : ""
                      }`}
                      onClick={() => {
                        setDataType("absence");
                      }}
                    >
                      Absence{" "}
                    </p>
                  </div>
                  {dataType === "hours" ? (
                    <div className="dataTypeSelectDiv">
                      <p
                        className={`dataTypeTxt ${
                          !dataHoursFilter.includes("shifts")
                            ? "unselDataTypeTxt"
                            : ""
                        }`}
                        onClick={() => {
                          if (!dataHoursFilter.includes("shifts")) {
                            setDataHoursFilter([...dataHoursFilter, "shifts"]);
                          } else {
                            setDataHoursFilter(
                              dataHoursFilter.filter((x) => {
                                return x !== "shifts";
                              })
                            );
                          }
                        }}
                      >
                        Shifts{" "}
                        <img
                          src={unclockedCheck}
                          alt="Data type"
                          className={`dataTypeCheckImg ${
                            dataHoursFilter.includes("shifts")
                              ? ""
                              : "unselectedDataType"
                          }`}
                        />
                      </p>
                      <p
                        className={`dataTypeTxt ${
                          !dataHoursFilter.includes("overtime")
                            ? "unselDataTypeTxt"
                            : ""
                        }`}
                        onClick={() => {
                          if (!dataHoursFilter.includes("overtime")) {
                            setDataHoursFilter([
                              ...dataHoursFilter,
                              "overtime",
                            ]);
                          } else {
                            setDataHoursFilter(
                              dataHoursFilter.filter((x) => {
                                return x !== "overtime";
                              })
                            );
                          }
                        }}
                      >
                        Overtime{" "}
                        <img
                          src={unclockedCheck}
                          alt="Data type"
                          className={`dataTypeCheckImg ${
                            dataHoursFilter.includes("overtime")
                              ? ""
                              : "unselectedDataType"
                          }`}
                        />
                      </p>
                      <p
                        className={`dataTypeTxt marginRight0 ${
                          !dataHoursFilter.includes("til")
                            ? "unselDataTypeTxt"
                            : ""
                        }`}
                        onClick={() => {
                          if (!dataHoursFilter.includes("til")) {
                            setDataHoursFilter([...dataHoursFilter, "til"]);
                          } else {
                            setDataHoursFilter(
                              dataHoursFilter.filter((x) => {
                                return x !== "til";
                              })
                            );
                          }
                        }}
                      >
                        Lieu{" "}
                        <img
                          src={unclockedCheck}
                          alt="Data type"
                          className={`dataTypeCheckImg ${
                            dataHoursFilter.includes("til")
                              ? ""
                              : "unselectedDataType"
                          }`}
                        />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="dataTypeSelectLower">Lower selectr</div> */}
                {/* <p>items here</p> */}
                <div className="editUserDataBox">
                  <div className="editUserDataBoxRow">
                    <p className="editUserDataBoxRowTitle">Total hours</p>
                    <p className="editUserDataBoxRowValue">
                      {tabLoading
                        ? "-"
                        : dateStringer.formatMinsDurationToHours(
                            dataObj.durMins || 0
                          )}
                    </p>
                  </div>
                  <div className="editUserDataBoxRow">
                    <p className="editUserDataBoxRowTitle">Cost</p>
                    {!tabLoading ? (
                      <p className="editUserDataBoxRowValue">
                        {memoVals.currencySymbol}
                        {dataObj.cost ? Number(dataObj.cost.toFixed(2)) : 0}
                      </p>
                    ) : (
                      <p className="editUserDataBoxRowValue">-</p>
                    )}
                  </div>
                  <div className="editUserDataBoxRow">
                    <p className="editUserDataBoxRowTitle">
                      {dataType === "hours" || dataType === "all"
                        ? "Late count"
                        : dataType === "leave"
                        ? "Paid leave"
                        : "Paid absence"}
                    </p>
                    {tabLoading ? (
                      <p className="editUserDataBoxRowValue">-</p>
                    ) : dataType === "absence" || dataType === "leave" ? (
                      <p className="editUserDataBoxRowValue">
                        {dateStringer.formatMinsDurationToHours(
                          dataObj.paidMins || 0
                        )}{" "}
                      </p>
                    ) : (
                      <p className="editUserDataBoxRowValue">
                        {dataObj.lateCount || 0}x
                      </p>
                    )}{" "}
                  </div>
                  <div className="editUserDataBoxRow">
                    <p className="editUserDataBoxRowTitle">
                      {" "}
                      {dataType === "hours" || dataType === "all"
                        ? "Late duration"
                        : dataType === "leave"
                        ? "Unapaid leave"
                        : "Unpaid absence"}
                    </p>
                    <p className="editUserDataBoxRowValue">
                      {" "}
                      {tabLoading
                        ? "-"
                        : dateStringer.formatMinsDurationToHours(
                            dataType === "leave" || dataType === "absence"
                              ? dataObj.unpaidMins || 0
                              : dataObj.lateDurMins || 0
                          )}
                    </p>
                  </div>
                </div>
              </div>

              <div
                className={`editUserDataMain ${
                  memoVals.device.ios ? "editUserDataMainIos" : ""
                }`}
              >
                {dataArr.map((item) => {
                  if (
                    dataType === "all" ||
                    (dataType === "hours" &&
                      item.type === "shift" &&
                      dataHoursFilter.includes("shifts")) ||
                    (dataType === "hours" &&
                      (item.type === "til" || item.inLieu) &&
                      dataHoursFilter.includes("til")) ||
                    (dataType === "hours" &&
                      item.type === "overtime" &&
                      dataHoursFilter.includes("overtime")) ||
                    (dataType === "leave" && item.type === "leave") ||
                    (dataType === "absence" && item.type === "absence") ||
                    (dataType === "absence" && item.absent)
                  ) {
                    let title = "Shift";
                    if (item.shiftID || item.tilID) {
                      if (item.tilID) {
                        if (item.type === "til") {
                          title = "Shift in lieu";
                        } else {
                          title = "Overtime";
                        }
                      }
                    } else {
                      title = item.leaveTypeName;
                    }
                    return (
                      <div
                        className="dataTabUserShift"
                        onClick={() => {
                          if (item.type === "shift") {
                            if (memoVals.showReqMgrModal) {
                              memoVals.setShowReqMgrModal((x) => false);
                            }

                            memoVals.setShowEditShiftModal((x) => {
                              return {
                                itemID: item.shiftID,
                                itemType: "shift",
                                itemFName: editUserState.editFName,
                                itemLName: editUserState.editLName,
                                itemIsNew: false,
                                userID: userID,
                              };
                            });
                            setItemOpen(true);
                          }
                          if (item.type === "til" || item.type === "overtime") {
                            if (memoVals.showReqMgrModal) {
                              memoVals.setShowReqMgrModal((x) => false);
                            }
                            memoVals.setShowEditShiftModal((x) => {
                              return {
                                itemID: item.tilID,
                                itemType: item.type,
                                itemFName: editUserState.editFName,
                                itemLName: editUserState.editLName,
                                itemIsNew: false,
                                userID: userID,
                              };
                            });
                            if (memoVals.showReqMgrModal) {
                              memoVals.setShowReqMgrModal((x) => false);
                            }
                            setItemOpen(true);
                          }

                          if (
                            item.type === "absence" ||
                            item.type === "leave"
                          ) {
                            memoVals.setShowReqMgrModal((x) => item.reqID);
                            if (memoVals.showEditShiftModal) {
                              memoVals.setShowEditShiftModal((x) => false);
                            }
                            setItemOpen(true);
                          }
                        }}
                      >
                        <div className="dataTabUserShiftTop">
                          <div className="dataTabUserShiftLeft">
                            <div className="dataTabBlock dataTabBlockFirst">
                              <p className="dataTabBlockTitle">Type</p>
                              <p className={`dataTabBlockValue`}>{title}</p>
                              {item.type === "shift" ||
                              item.type === "til" ||
                              item.type === "overtime" ? (
                                <p className="dataTabBlockTeamName">
                                  {item.teamName}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="dataTabBlock dataTabTimesBlock">
                              {item.reqID ? (
                                <p
                                  className={`dataTabBlockValueTimes ${
                                    item.startDs === item.endDs
                                      ? "sameDataPushDown"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={cal}
                                    className="dataTabClockIcon"
                                    alt="Times"
                                  />
                                  {!item.startDs
                                    ? "No leave"
                                    : dateStringer.printedDateFromDs(
                                        item.startDs,
                                        false
                                      )}{" "}
                                </p>
                              ) : (
                                <p className="dataTabBlockValueTimes dataTabBlockValueTimesTimes">
                                  <img
                                    src={clock}
                                    className="dataTabClockIcon"
                                    alt="Times"
                                  />
                                  {dateStringer.dsToTimeStrip(
                                    item.startDs,
                                    true
                                  )}{" "}
                                  -{" "}
                                  {dateStringer.dsToTimeStrip(item.endDs, true)}
                                </p>
                              )}
                              {item.reqID ? (
                                <p
                                  className={`dataTabBlockValueTimes ${
                                    item.startDs === item.endDs ? "none" : ""
                                  }`}
                                >
                                  <img
                                    src={cal}
                                    className="dataTabClockIcon"
                                    alt="Times"
                                  />
                                  {dateStringer.printedDateFromDs(
                                    item.endDs,
                                    false
                                  )}{" "}
                                </p>
                              ) : (
                                <p className="dataTabBlockValueTimes ">
                                  <img
                                    src={cal}
                                    className="dataTabClockIcon"
                                    alt="Date"
                                  />
                                  {dateStringer.printedDateFromDs(
                                    item.startDs,
                                    true,
                                    false,
                                    false,
                                    true
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="dataTabBlock dataTabDurMins">
                              <p className="dataTabBlockTitle">
                                {item.unpaid ? "Duration" : "Payable"}
                              </p>
                              <p
                                className={`dataTabBlockValue dataTabBlockValueDurMins `}
                              >
                                {dateStringer.formatMinsDurationToHours(
                                  item.durMins || 0,
                                  memoVals.mobModal ? true : false
                                )}
                              </p>
                            </div>
                          </div>{" "}
                          <div className="dataTabUserShiftRight">
                            <div className="dataTabBlock flexEnd2">
                              <p className="dataTabBlockTitle textAlignRight">
                                {item.cost < 0 ? "Deduction" : "Cost"}
                              </p>
                              <p className="dataTabBlockValue textAlignRight">
                                {memoVals.currencySymbol}
                                {item.cost === 0 || item.type === "til"
                                  ? ""
                                  : item.cost <= 0
                                  ? ""
                                  : "+"}
                                {Number(item.cost ? item.cost.toFixed(2) : 0)}
                              </p>
                            </div>
                          </div>
                        </div>
                        {item.ghost ||
                        item.inLieu ||
                        item.payClocked ||
                        item.absent ||
                        item.lateDurMins > 0 ||
                        item.reqID ? (
                          <div
                            className={`dataTabUserShiftBottom ${
                              item.reqID ? "dataTabUserShiftBottomReq" : ""
                            }`}
                          >
                            <div className="tabRow">
                              {item.reqID ? (
                                <p
                                  className={`tabDays ${
                                    item.type === "leave" ? "tabDaysLeave" : ""
                                  }`}
                                >
                                  {item.days} day{item.days === 1 ? "" : "s"}
                                </p>
                              ) : (
                                ""
                              )}
                              {item.ghost ? (
                                <p className={`tabDays tabGhost`}>
                                  Paid closed day
                                </p>
                              ) : (
                                ""
                              )}
                              {item.inLieu ? (
                                <p className={`tabDays tabGhost`}>
                                  Paid & added to TOIL balance
                                </p>
                              ) : (
                                ""
                              )}
                              {item.lateDurMins ? (
                                <p className="tabLate">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.lateDurMins,
                                    true
                                  )}{" "}
                                  late -{" "}
                                  {item.unpaidLate
                                    ? item.type === "til"
                                      ? "Not earned"
                                      : "Unpaid"
                                    : item.type === "til"
                                    ? "Still earned"
                                    : "Paid"}
                                </p>
                              ) : (
                                ""
                              )}
                              {item.absent && !item.partialAbsent ? (
                                <p className="tabLate">
                                  Absent -{" "}
                                  {item.unpaidAbsent
                                    ? item.type === "til"
                                      ? "Not earned"
                                      : "Unpaid"
                                    : item.type === "til"
                                    ? "Still earned"
                                    : "Paid"}
                                </p>
                              ) : (
                                ""
                              )}
                              {item.absent && item.partialAbsent ? (
                                <p className="tabLate">
                                  {dateStringer.formatMinsDurationToHours(
                                    item.partialAbsentDuration / 1000 / 60,
                                    false
                                  )}{" "}
                                  absence -{" "}
                                  {item.unpaidAbsent
                                    ? item.type === "til"
                                      ? "Not earned"
                                      : "Unpaid"
                                    : item.type === "til"
                                    ? "Still earned"
                                    : "Paid"}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

          {/* SHIFTS: Edit User Main body */}

          {editUserPage === "Clock" ? (
            <ClockDetails
              isAdmin={editUserState.isAdmin}
              isSelf={editUserState.isSelf}
              unitUserID={editUserState.editUserUserId}
              availableZones={editUserState.availableZones}
              zones={editUserState.zones}
              managesOwnTeams={editUserState.managesOwnTeams}
              managesOwnTeam={editUserState.managesOwnTeam}
              editUserState={editUserState}
              setEditUserState={setEditUserState}
              mbrMgrOfTeams={mbrMgrOfTeams}
              setMbrMgrOfTeams={setMbrMgrOfTeams}
              allowSelfClockApprove={allowSelfClockApprove}
              setAllowSelfClockApprove={setAllowSelfClockApprove}
            />
          ) : (
            ""
          )}

          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

          {/* Edit User footer */}
          <div className="editUserModalFooterContainer">
            <div className="editUserModalFooterLeftBox">
              {showMore ? (
                <div
                  className="editUserShowMoreUnderlay"
                  onClick={() => {
                    setShowMore(false);
                  }}
                >
                  <div
                    className={`editUserShowMoreModalBox ${
                      !memoVals.mobModal
                        ? "desktopShowMoreModalBox"
                        : memoVals.device.ios
                        ? "editUserShowMoreModalBoxIos"
                        : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className="showMoreModalHeader">
                      Actions | {editUserState.editFName}
                    </div>

                    <div className="editUserMoreFlexBox">
                      <div className="editUserActionsModalMenu">
                        <div
                          onClick={() => {
                            window.location.href = `${appURL}/team-hours?userID=${editUserState.editUserUserId}`;
                          }}
                          className="editUserActionsBtn lilacColours"
                        >
                          View shifts
                        </div>
                        {/* <div
                          onClick={() => {
                            window.location.href = `${appURL}/team-leave?userID=${editUserState.editUserUserId}&fName=${editUserState.editFName}&lName=${editUserState.editLName}`;
                          }}
                          className="editUserActionsBtn lilacColours"
                        >
                          View leave requests
                        </div> */}
                        <div
                          onClick={() => {
                            window.location.href = `${appURL}/staff-dashboard/employees?allowances=true&userID=${editUserState.editUserUserId}`;
                          }}
                          className="editUserActionsBtn lilacColours"
                        >
                          View allowances
                        </div>
                        <div
                          onClick={() => {
                            let firstDayOfMonth = new Date();
                            firstDayOfMonth.setDate(1);
                            firstDayOfMonth.setHours(0, 0, 0, 0);
                            window.location.href = `${appURL}/reports?ds=${dateStringer.createStringFromTimestamp(
                              firstDayOfMonth.getTime()
                            )}&teamID=${editUserState.primaryTeamID}&userID=${
                              editUserState.editUserUserId
                            }`;
                          }}
                          className="editUserActionsBtn lilacColours"
                        >
                          View reports
                        </div>
                        {/* <a
                          href={`/team-leave/?userID=${editUserState.editUserUserId}&fName=${editUserState.editFName}&lName=${editUserState.editLName}`}
                          className="editUserActionsBtn lilacColours"
                          onClick={() => {
                            closeModal();
                          }}
                        >
                          View requests
                        </a> */}
                        <p
                          className="editUserActionsBtn lilacColours"
                          onClick={() => {
                            closeModal();
                            // memoVals.setShowEditUser(x =>false);
                            memoVals.setShowMgrAvailModal((x) => {
                              return {
                                ds: dateStringer.getMonWeekOfDs(
                                  `${dateStringer
                                    .createStringFromTimestamp(
                                      new Date().getTime()
                                    )
                                    .substr(0, 11)}H00M00`
                                ),
                                team: editUserState.primaryTeamID,
                                userID: editUserState.editUserUserId,
                              };
                            });
                          }}
                        >
                          View availability
                        </p>
                      </div>
                      <div className="editUserMoreFlexRight"></div>
                    </div>
                    <div className="editUserMoreFooter">
                      <p
                        className={`removeUsrBtn ${
                          editUserState.editUserUserId ===
                          memoVals.checkUserState.userID
                            ? "disableRemoveYourself"
                            : ""
                        }`}
                        onClick={() => {
                          if (
                            editUserState.editUserUserId ===
                            memoVals.checkUserState.userID
                          ) {
                            setCannotRemoveYourself(true);
                          } else {
                            setShowRemoveUserModal(true);
                          }
                        }}
                      >
                        <img
                          src={whitebin}
                          alt="Swap"
                          className="myShiftMsgImg"
                        />
                        Remove user
                      </p>

                      <p
                        className="editUserMoreCloseBtn"
                        onClick={() => {
                          setShowMore(false);
                        }}
                      >
                        Close
                      </p>
                    </div>
                  </div>

                  {showRemoveUserModal ? (
                    <div
                      className="addAbsenceModalUnderlay"
                      onClick={() => {
                        setShowRemoveUserModal(false);
                      }}
                    >
                      {sureRemove ? (
                        <div
                          className="formCreatedModal zoomIn"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="overlapsRenTxt alignLeft">
                            Are you sure you want to remove{" "}
                            {editUserState.editFName} and all of their
                            associated data?
                            <br />
                            <br />
                            This cannot be undone.
                          </div>
                          <div className="areYouModalBtnsDiv">
                            <p
                              className="areYouSureModalYesBtn"
                              onClick={() => {
                                // destroy user here
                                axios
                                  .post(
                                    `${serverURL}/destroy-user-data`,
                                    {
                                      userID: editUserState.editUserUserId,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      memoVals.setShowEditUser((x) => "");
                                      setSureRemove(false);
                                      memoVals.setEmployeeCount(
                                        memoVals.employeeCount - 1
                                      );
                                      memoVals.setUpdateSingleUserInUsers(
                                        (x) => {
                                          return {
                                            userID:
                                              editUserState.editUserUserId,
                                            removeFromUsers: true,
                                            removeFromDisabled: true,
                                          };
                                        }
                                      );
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }}
                            >
                              Remove
                            </p>
                            <p
                              className="areYouModalNoBtn"
                              onClick={() => {
                                setSureRemove(false);
                              }}
                            >
                              Cancel
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="formCreatedModal zoomIn"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="overlapsRenTxt alignLeft">
                            Do you want to remove all data associated to{" "}
                            {editUserState.editFName}?<br />
                            <p className="removeUserInfoTxt">
                              By doing so, any historic hours and leave will be
                              deleted forever and they will be excluded from any
                              historic reports.
                            </p>
                            {!editUserState.removed ? <br /> : ""}
                            {!editUserState.removed ? <br /> : ""}
                            {!editUserState.removed
                              ? "Or do you want to archive them instead?"
                              : ""}
                            {!editUserState.removed ? <br /> : ""}
                            {!editUserState.removed ? (
                              <p className="removeUserInfoTxt">
                                By archiving {editUserState.editFName}, FlowRota
                                will keep their historic hours and leave, but
                                they won't be able to login, and you won't be
                                charged for them in your monthly billing cycle.
                                You can re-enable them any time.
                              </p>
                            ) : (
                              ""
                            )}
                            {/* ocean */}
                          </div>
                          <div className="areYouModalBtnsDiv">
                            <p
                              className="areYouSureModalYesBtn removeBtnsSize"
                              onClick={() => {
                                setSureRemove(true);
                              }}
                            >
                              Remove
                            </p>
                            {!editUserState.removed ? (
                              <p
                                className="areYouModalNoBtn removeBtnsSize2"
                                onClick={() => {
                                  // disable user here
                                  axios
                                    .post(
                                      `${serverURL}/remove-user`,
                                      {
                                        userID: editUserState.editUserUserId,
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message.includes(
                                          "yourself"
                                        )
                                      ) {
                                        memoVals.setIndicate((x) => {
                                          return {
                                            show: true,
                                            message:
                                              "You cannot archive yourself",
                                            colour: "red",
                                            duration: 4000,
                                          };
                                        });
                                      }
                                      if (response.data.message === "success") {
                                        setShowRemoveUserModal(false);
                                        setShowMore(false);
                                        // memoVals.setShowEditUser(x =>false);
                                        memoVals.setUpdateSingleUserInUsers(
                                          (x) => {
                                            return {
                                              userID:
                                                editUserState.editUserUserId,
                                              removeFromUsers: true,
                                              removeFromDisabled: false,
                                            };
                                          }
                                        );
                                        setEditUserState({ removed: true });
                                        memoVals.setEmployeeCount(
                                          memoVals.employeeCount - 1
                                        );
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                  setShowRemoveUserModal(false);
                                }}
                              >
                                Archive
                              </p>
                            ) : (
                              ""
                            )}
                            <p
                              className="canxRemoveUsrBtn"
                              onClick={() => {
                                setShowRemoveUserModal(false);
                              }}
                            >
                              Cancel
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {!loading ? (
                <div
                  className="editUserMoreBtn"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  <img
                    src={threeDots}
                    alt="More"
                    className="editUserThreeDotsImg"
                  />
                  Actions
                </div>
              ) : (
                ""
              )}
              {editUserPage === "Data" ? (
                <div
                  className="editUserMessageBtn unarchBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/get-user-tab-data`,
                        {
                          downloadCsv: true,
                          userID: userID,
                          shifts: true,
                          leave: true,
                          absence: true,
                          csvShifts:
                            dataType === "all" ||
                            (dataType === "hours" &&
                              dataHoursFilter.includes("shifts")),
                          csvTils:
                            dataType === "all" ||
                            (dataType === "hours" &&
                              dataHoursFilter.includes("til")),
                          csvOvertimes:
                            dataType === "all" ||
                            (dataType === "hours" &&
                              dataHoursFilter.includes("overtime")),
                          csvLeave: dataType === "all" || dataType === "leave",
                          csvAbsence:
                            dataType === "all" || dataType === "absence",
                          combineWithAbsentShifts:
                            dataType === "all" || dataType === "absence",
                          startDs:
                            dateStringer.createStringFromDateInput(
                              minDateInput
                            ),
                          endDs:
                            dateStringer.createStringFromDateInput(
                              maxDateInput
                            ),
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          responseType: "blob",
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        // Create a Blob from the response data
                        const blob = new Blob([response.data], {
                          type: "text/csv",
                        });

                        // Create a URL for the Blob
                        const url = window.URL.createObjectURL(blob);

                        // Create a link element to trigger the download
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = `${editUserState.editFName.toLowerCase()}_${editUserState.editLName.toLowerCase()}_tab_data.csv`; // Specify the filename
                        document.body.appendChild(link);

                        // Trigger the download
                        link.click();

                        // Cleanup
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(link);
                      })
                      .catch((error) => {
                        console.error("Error downloading CSV:", error);
                      });
                  }}
                >
                  Export to CSV{" "}
                  <img
                    src={download}
                    alt="Message"
                    className="downloadCsvImg"
                  />
                </div>
              ) : !loading ? (
                editUserState.removed ? (
                  <div
                    className="editUserMessageBtn unarchBtn"
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/unarchive-user`,
                          {
                            userID: editUserState.editUserUserId,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            console.log("UNARCHIVING SUCCESS");

                            memoVals.setEmployeeCount(
                              memoVals.employeeCount + 1
                            );
                            setEditUserState({ removed: false, removedDs: "" });
                            memoVals.setIndicate((x) => {
                              return {
                                show: true,
                                message: "Unarchived user successfully",
                                colour: "green",
                                duration: 4000,
                              };
                            });
                            memoVals.setUpdateSingleUserInUsers((x) => {
                              return {
                                userID: editUserState.editUserUserId,
                                removeFromUsers: false,
                                removeFromDisabled: true,
                              };
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <img
                      src={unarchive}
                      alt="Message"
                      className="unarchiveImg"
                    />
                    Unarchive
                  </div>
                ) : (
                  // <div
                  //   className={`editUserMessageBtn ${
                  //     editUserState.editUserUserId ===
                  //     memoVals.checkUserState.userID
                  //       ? "none"
                  //       : ""
                  //   }`}
                  //   onClick={() => {
                  //     memoVals.setShowMessages(
                  //       (x) => editUserState.editUserUserId
                  //     );
                  //     // memoVals.setShowEditUser(x =>false);
                  //   }}
                  // >
                  //   <img
                  //     src={chat}
                  //     alt="Message"
                  //     className="editUserMsgChatImg"
                  //   />
                  //   Message
                  // </div>
                  ""
                )
              ) : (
                ""
              )}
              {!editUserState.removed && (
                <div
                  className="userHRateHistoryBtn editUserWeeklyHrsBtn"
                  onClick={() => {
                    memoVals.setShowUserHoursModal((x) => {
                      return {
                        show: true,
                        fromEditUser: true,
                        userID: editUserState.editUserUserId,
                        monWeek: dateStringer.getMonWeekOfDs(
                          dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          )
                        ),
                      };
                    });
                    closeModal();
                  }}
                >
                  Weekly hours{" "}
                  <img
                    src={popup}
                    className="editUserPopUpIcon userHrsEditUsrPopup"
                    alt="Pop up"
                  />
                </div>
              )}
            </div>

            <div className={`${sureExit ? "sureCloseUnderlay" : "none"}`}>
              <div className="sureCloseEditUserBox">
                <p className="sureCloseEditUserBoxText">
                  You haven't saved{" "}
                  {`${editUserState.editFName || "this user"}${
                    editUserState.editFName.charAt(
                      editUserState.editFName.length - 1
                    ) === "s"
                      ? "'"
                      : "'s"
                  }`}{" "}
                  {`${memoVals.editSched ? "schedule" : "hours"}`}.<br />
                  <br />
                  Are you sure you want to close?
                </p>
                <div className="sureBtnContainer">
                  <div
                    className="sureBtn1"
                    onClick={() => {
                      memoVals.setEditSched((x) => false);
                      memoVals.setEditFixedVar((x) => false);
                      closeEditUserAndUpdateAllUsers(
                        editUserState.editUserUserId
                      );

                      setSureExit(false);
                      if (allowsUpdatedLocal) {
                        memoVals.setAllowsUpdated(
                          (x) => !memoVals.allowsUpdated
                        );
                      }
                    }}
                  >
                    Yes
                  </div>
                  <div
                    className="sureBtn2"
                    onClick={() => {
                      setSureExit(false);
                    }}
                  >
                    No
                  </div>
                </div>
              </div>
            </div>

            <p
              className="editUserModalFooterRightBox"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
        {showEditProPicModal ? (
          <div
            className="editProPicUnderlay"
            onClick={() => {
              setShowEditProPicModal(false);
            }}
          >
            <div
              className={`editProPicModalBox ${
                memoVals.device.ios ? "editProPicModalBoxIos" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="editProPicModalHeader">
                <img
                  src={close}
                  alt="Close edit profile photo modal"
                  className="closeEditProPicModalIcon"
                  onClick={() => {
                    setShowEditProPicModal(false);
                  }}
                />
                Change profile photo
              </div>
              <div className="editProPicModalContentBox">
                {proPicLoading ? (
                  <p className="waitForProPic">Uploading...</p>
                ) : (
                  <input
                    type="file"
                    title=" "
                    id="avatar"
                    name="avatar"
                    className="proPicChangeBtnMyAcc"
                    accept="image/png, image/jpeg"
                    onChange={async (e) => {
                      setProPicLoading(true);
                      let imageFile = e.target.files[0];

                      const options = {
                        maxSizeMB: 0.05,
                        maxWidthOrHeight: 400,
                        useWebWorker: true,
                      };
                      try {
                        const compressedFile = await imageCompression(
                          imageFile,
                          options
                        );

                        let output = compressedFile;

                        let getBase64 = (file) => {
                          return new Promise((resolve) => {
                            let baseURL = "";
                            // Make new FileReader
                            let reader = new FileReader();

                            // Convert the file to base64 text
                            reader.readAsDataURL(file);

                            // on reader load somthing...
                            reader.onload = () => {
                              // Make a fileInfo Object
                              baseURL = reader.result;
                              resolve(baseURL);
                            };
                          });
                        };
                        axios
                          .post(
                            `${serverURL}/upload-profile-picture`,
                            {
                              userID: editUserState.editUserUserId,
                              base64: await getBase64(output),
                              remove: false,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              memoVals.setUpdatedDashData(
                                (x) => !memoVals.updatedDashData
                              );

                              setEditUserState({
                                proPicURL: response.data.url,
                              });
                              setShowEditProPicModal(false);
                              setProPicLoading(false);
                              memoVals.setUpdateUserAdmin((x) => {
                                return {
                                  userID: editUserState.editUserUserId,
                                  proPicURL: response.data.url || "",
                                };
                              });
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      } catch (error) {
                        setProPicLoading(false);
                        console.log(error);
                      }
                    }}
                  />
                )}
              </div>
              <div className="editProPicModalFooter">
                <p
                  className="removeProPicBtn"
                  onClick={() => {
                    setShowEditProPicModal(false);

                    axios
                      .post(
                        `${serverURL}/upload-profile-picture`,
                        {
                          userID: editUserState.editUserUserId,
                          remove: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setEditUserState({ proPicURL: response.data.url });
                          memoVals.setUpdatedDashData(
                            (x) => !memoVals.updatedDashData
                          );
                          memoVals.setUpdateUserAdmin((x) => {
                            return {
                              userID: editUserState.editUserUserId,
                              proPicURL: "",
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Remove
                </p>
                <p
                  className="closeProPicModalBtn"
                  onClick={() => {
                    setShowEditProPicModal(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* checkAllowances modal */}
        {checkAllowModal ? (
          <div
            className="checkAllowancesUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setCheckAllowModal(false);
            }}
          >
            <div
              className="formCreatedModal zoomIn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Amending {editUserState.editFName}
                {editUserState.editFName[editUserState.editFName.length - 1] ===
                "s"
                  ? "'"
                  : "'s"}{" "}
                weekly hours might have affected their allowance.
                <br />
                <br />
                We strongly recommend that you check {editUserState.editFName}
                {editUserState.editFName[editUserState.editFName.length - 1] ===
                "s"
                  ? "'"
                  : "'s"}{" "}
                allowances now.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setEditUserPage("Allowances");
                    setCheckAllowModal(false);
                  }}
                >
                  Allowances
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    memoVals.setEditSched((x) => false);
                    memoVals.setEditFixedVar((x) => false);
                    closeEditUserAndUpdateAllUsers(
                      editUserState.editUserUserId
                    );

                    setEditUserPage("Details");
                  }}
                >
                  Exit
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showFsChangedModal ? (
          <div
            className="checkAllowancesUnderlay"
            onClick={(e) => {
              e.stopPropagation();
              setShowFsChangedModal(false);
            }}
          >
            <div
              className="formCreatedModal zoomIn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                You have made changes to{" "}
                {dateStringer.possession(editUserState.editFName)} scheduled
                weekly hours without saving them.
                {/* <br />
                <br />
               */}
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setShowFsChangedModal(false);
                    closeModal("ignore");
                  }}
                >
                  Disregard
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setShowFsChangedModal(false);
                  }}
                >
                  Go back
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showHistory && (
          <EditUserHistoryModal
            userID={userID}
            setHistoricHoursData={setHistoricHoursData}
            historicHoursData={historicHoursData}
            setHistoryAnnLeaveMins={setHistoryAnnLeaveMins}
            setHistoryPaidAbsenceMins={setHistoryPaidAbsenceMins}
            setHistoryUnpaidAbsenceMins={setHistoryUnpaidAbsenceMins}
            fName={editUserState.editFName}
            setShowHistory={setShowHistory}
            startDate={editUserState.editStartDate}
            historyEndDs={historyEndDs}
            historicMpw={historicMpw}
            setHistoricMpw={setHistoricMpw}
            historyStartDs={historyStartDs}
            setHistoryEndDs={setHistoryEndDs}
            setHistoryStartDs={setHistoryStartDs}
          />
        )}
        {imgPreload}{" "}
        {notYetVerif ? (
          <div className="clockOnModalUnderlay">
            <div
              className={`formCreatedModal `}
              onClick={(e) => {
                e.stopPropagation();
                setNotYetVerif(false);
              }}
            >
              <p className="overlapsRenTxt">
                FlowRota will send you an email with a link to verify your email
                address. <br />
                <br />
                If you have already requested a verification email and haven't
                received it, check your spam folder.
              </p>

              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setNotYetVerif(false);
                    axios
                      .post(
                        `${serverURL}/resend-verify-email`,
                        {
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Verification email sent to you",
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Verify email
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setNotYetVerif(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {emailAlreadyExists ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                The email you have entered is already associated with another
                account.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setEmailAlreadyExists(false);
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showVerifModal ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                FlowRota has sent {editUserState.editFName} an email with a link
                to verify their email address. <br />
                <br />
                If {editUserState.editFName} hasn't received it, please ask them
                to check their spam folder or re-send it using the button below.
              </p>

              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setShowVerifModal(false);
                    axios
                      .post(
                        `${serverURL}/resend-verify-email`,
                        {
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          userID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setUpdatedDashData(
                            (x) => !memoVals.updatedDashData
                          );
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Verification email re-sent",
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Re-send email
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setShowVerifModal(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {sureRemoveFromTeam && sureRemoveFromTeam.show ? (
          <div className="clockOnModalUnderlay">
            <div
              className={`formCreatedModal `}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="overlapsRenTxt">
                Are you sure you want to remove {editUserState.editFName} from{" "}
                {sureRemoveFromTeam.teamName}?
                <br />
                <br />
                Any shifts scheduled for {sureRemoveFromTeam.fName} within{" "}
                {editUserState.teamName} from today will be deleted.
              </p>

              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    setSureRemoveFromTeam({ show: false });
                    axios
                      .post(
                        `${serverURL}/remove-user-from-team`,
                        {
                          userID: editUserState.editUserUserId,
                          teamID: sureRemoveFromTeam.teamID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setUpdateBgUsers(true);
                          memoVals.setUpdatedDashData(
                            (x) => !memoVals.updatedDashData
                          );

                          setUpdateTeams(!updateTeams);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setSureRemoveFromTeam({ show: false });
                  }}
                >
                  No
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {invalidUser ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">Employee no longer exists</p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showHourlyRateTimeline && (
          <div
            className="hourlyRateTimelineRow"
            onClick={(e) => {
              e.stopPropagation();
              setShowHourlyRateTimeline(false);
              setEditTimelineObjArr([]);
            }}
          >
            <div
              className={`hourlyRateTimelineContainer ${
                memoVals.mob
                  ? `mobileHourlyRateTimeline ${
                      memoVals.device.ios ? "mobileHourlyRateTimelineIos" : ""
                    } slideUp`
                  : "desktopHourlyRateTimeline"
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="hourlyRateTimelineHeader">
                <div className="hourlyRateTimelineHeaderSideUnit">
                  <img
                    src={close}
                    alt="Close"
                    className="hourlyRateTimelineCloseImg"
                    onClick={() => {
                      setShowHourlyRateTimeline(false);
                      setEditTimelineObjArr([]);
                    }}
                  />
                </div>
                <p className="hourlyRateModalTitle">Hourly rate timeline</p>
                <div className="hourlyRateTimelineHeaderSideUnit"></div>
              </div>
              <div className="addOrRemovePeriodContainer">
                <div className="hourlyRateTimeRemoveAllAddBtn">
                  <p
                    className={`removeTimeline ${
                      !hourlyRateTimeline[0] ? "invis" : ""
                    }`}
                    onClick={() => {
                      if (!sureRemoveAllPeriods && !sureRemoveAllPeriods2) {
                        setSureRemoveAllPeriods(true);
                      }
                      if (sureRemoveAllPeriods && !sureRemoveAllPeriods2) {
                        setSureRemoveAllPeriods2(true);
                      }
                      if (sureRemoveAllPeriods2 && sureRemoveAllPeriods) {
                        // make request
                        axios
                          .post(
                            `${serverURL}/delete-user-hourly-rate-timeline-object`,
                            {
                              userID: editUserState.editUserUserId,
                              deleteAll: true,
                              objID: "deleting all",
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              let conflict = false;
                              let overlaps = [];
                              response.data.hourlyRateTimeline.forEach(
                                (rate) => {
                                  let start =
                                    dateStringer.createTimestampFromString(
                                      rate.startDate
                                    );
                                  let end =
                                    dateStringer.createTimestampFromString(
                                      rate.endDate
                                    );

                                  if (start > end) {
                                    conflict = true;
                                  }

                                  response.data.hourlyRateTimeline.forEach(
                                    (rate2) => {
                                      if (rate2.objID !== rate.objID) {
                                        let start2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.startDate
                                          );
                                        let end2 =
                                          dateStringer.createTimestampFromString(
                                            rate2.endDate
                                          );

                                        if (
                                          (start >= start2 && start <= end2) ||
                                          (end <= end2 && end >= start2)
                                        ) {
                                          if (!overlaps.includes(rate.objID)) {
                                            overlaps.push(rate.objID);
                                          }
                                        }
                                      }
                                    }
                                  );
                                }
                              );

                              setOverlappingHourlyRates(overlaps);
                              setHourlyRateTimelineConflict(conflict);
                              setHourlyRateTimeline(
                                response.data.hourlyRateTimeline
                              );
                              setSureRemoveAllPeriods(false);
                              setSureRemoveAllPeriods2(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    {sureRemoveAllPeriods ? (
                      <div
                        className="editUserShowMoreUnderlay"
                        onClick={() => {
                          setSureRemoveAllPeriods(false);
                        }}
                      >
                        <div
                          className="formCreatedModal zoomIn"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div className="overlapsRenTxt alignLeft">
                            Are you sure you want to remove all of{" "}
                            {dateStringer.possession(editUserState.editFName)}{" "}
                            historic pay data?
                            <br />
                            <br />
                            This cannot be undone but you can re-enter the data
                            manually. The costs of{" "}
                            {dateStringer.possession(
                              editUserState.editFName
                            )}{" "}
                            historic leave and absence periods will be based on
                            their current pay rate.
                          </div>
                          <div className="areYouModalBtnsDiv">
                            <p
                              className="areYouSureModalYesBtn"
                              onClick={() => {
                                // make request
                                axios
                                  .post(
                                    `${serverURL}/delete-user-hourly-rate-timeline-object`,
                                    {
                                      userID: editUserState.editUserUserId,
                                      deleteAll: true,
                                      objID: "deleting all",
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      let conflict = false;
                                      let overlaps = [];
                                      response.data.hourlyRateTimeline.forEach(
                                        (rate) => {
                                          let start =
                                            dateStringer.createTimestampFromString(
                                              rate.startDate
                                            );
                                          let end =
                                            dateStringer.createTimestampFromString(
                                              rate.endDate
                                            );

                                          if (start > end) {
                                            conflict = true;
                                          }

                                          response.data.hourlyRateTimeline.forEach(
                                            (rate2) => {
                                              if (rate2.objID !== rate.objID) {
                                                let start2 =
                                                  dateStringer.createTimestampFromString(
                                                    rate2.startDate
                                                  );
                                                let end2 =
                                                  dateStringer.createTimestampFromString(
                                                    rate2.endDate
                                                  );

                                                if (
                                                  (start >= start2 &&
                                                    start <= end2) ||
                                                  (end <= end2 && end >= start2)
                                                ) {
                                                  if (
                                                    !overlaps.includes(
                                                      rate.objID
                                                    )
                                                  ) {
                                                    overlaps.push(rate.objID);
                                                  }
                                                }
                                              }
                                            }
                                          );
                                        }
                                      );

                                      setOverlappingHourlyRates(overlaps);
                                      setHourlyRateTimelineConflict(conflict);
                                      setHourlyRateTimeline(
                                        response.data.hourlyRateTimeline
                                      );
                                      setSureRemoveAllPeriods(false);
                                      setSureRemoveAllPeriods2(false);
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }}
                            >
                              Remove
                            </p>
                            <p
                              className="areYouModalNoBtn"
                              onClick={() => {
                                setSureRemoveAllPeriods(false);
                              }}
                            >
                              Cancel
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    Remove all
                  </p>
                  <div
                    className={`hourRateTimelineHeaderDivider ${
                      !hourlyRateTimeline[0] ? "invis" : ""
                    }`}
                  ></div>
                  <div
                    className="addHourlyRateTimelineObject"
                    onClick={() => {
                      // let newArr = [];
                      let today = new Date();
                      today.setHours(0, 0, 0, 0);
                      today.setDate(today.getDate() - 1);
                      let todayDateString =
                        dateStringer.createStringFromTimestamp(today.getTime());
                      let todayEnd = new Date();
                      todayEnd.setHours(23, 59, 0, 0);
                      todayEnd.setDate(todayEnd.getDate() - 1);

                      let todayEndDateString =
                        dateStringer.createStringFromTimestamp(
                          todayEnd.getTime()
                        );

                      if (hourlyRateTimeline[0]) {
                        hourlyRateTimeline.sort((a, b) => {
                          return b.startTs - a.startTs;
                        });

                        todayDateString = hourlyRateTimeline[0].endDate;
                        let dsObj = new Date(
                          dateStringer.createTimestampFromString(
                            todayDateString
                          )
                        );
                        dsObj.setDate(dsObj.getDate() + 1);
                        todayDateString =
                          dateStringer.createStringFromTimestamp(
                            dsObj.getTime()
                          );
                      }

                      let startDateObj = new Date(
                        dateStringer.createTimestampFromString(todayDateString)
                      );
                      let endDateObj = new Date(
                        dateStringer.createTimestampFromString(
                          todayEndDateString
                        )
                      );
                      endDateObj.setDate(endDateObj.getDate() - 1);
                      if (endDateObj.getTime() <= startDateObj.getTime()) {
                        endDateObj = startDateObj;
                      }

                      todayEndDateString =
                        dateStringer.createStringFromTimestamp(
                          endDateObj.getTime()
                        );

                      let newObj = {
                        startDate: todayDateString,
                        endDate: todayEndDateString,
                        hourlyRate: editUserState.tempHourlyRate,
                        objID: Math.random() * Math.random(),
                        startTs: new Date().getTime() + new Date().getTime(),
                        new: true,
                      };
                      setHourlyRateTimeline([newObj, ...hourlyRateTimeline]);
                      setEditTimelineObjArr([newObj.objID]);
                      setAddTimelineObj(newObj);
                    }}
                  >
                    <p className="addHourlyRateObjTxtAdd">Add</p>
                    <img
                      src={whitePlus}
                      className="whitePlusForAddingTimelineObj"
                      alt="Add period"
                    />
                  </div>
                </div>
              </div>
              <p
                className={`${
                  hourlyRateTimeline.length > 0 ? "timelineInstruction" : "none"
                }`}
              >
                The timeline below will automatically update whenever you change{" "}
                {`${editUserState.editFName || "this user"}${
                  editUserState.editFName.charAt(
                    editUserState.editFName.length - 1
                  ) === "s"
                    ? "'"
                    : "'s"
                }`}{" "}
                salary or hourly rate.
                <br />
                <br />
                If there are any gaps in dates, FlowRota will use their current
                data.
              </p>
              <p
                className={`${
                  hourlyRateTimeline.length > 0 ? "none" : "timelineInstruction"
                }`}
              >
                Whenever you change{" "}
                {`${editUserState.editFName || "this user"}${
                  editUserState.editFName.charAt(
                    editUserState.editFName.length - 1
                  ) === "s"
                    ? "'"
                    : "'s"
                }`}{" "}
                hourly rate or salary, FlowRota will keep track of changes in a
                timeline which will be shown below.
                <br />
                <br />
              </p>

              <p
                className={`${
                  hourlyRateTimeline.length > 0
                    ? "timelineInstruction x0019kw"
                    : "none"
                }`}
              >
                {determineLastDate()}
                <br />
                <br />
              </p>

              <div className="hourlyRateTimelineScrollWrapper">
                {generateHourlyRateTimeline}
                <p
                  className={`${
                    hourlyRateTimeline.length === 0
                      ? "noTimelineHistoryTxt"
                      : "none"
                  }`}
                >
                  {editUserState.editFName} has no hourly rate history. You can
                  add a period above.
                </p>
              </div>
            </div>
          </div>
        )}
        {!loading &&
        showCheckList &&
        !showHistory &&
        !showClosedDaysHistory &&
        !showMpwTimeline ? (
          <div
            className={`userCheckListModalUnderlay ${
              window.innerWidth < 820 ? "userCheckListModalUnderlayMob" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setShowCheckList(false);
              if (showMePay) {
                setShowMePay(false);
              }

              if (showMeHours) {
                setShowMeHours(false);
              }

              if (showMeAllowances) {
                setShowMeAllowances(false);
              }
            }}
          >
            <div
              className={`userCheckListModal ${
                window.innerWidth < 820 ? "userCheckListModalMob" : ""
              } ${window.innerWidth > 950 ? "userCheckListModalLarge" : ""} ${
                showMore ? "none" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="userCheckListHeader">
                <img
                  src={close}
                  alt="Close checklist"
                  className="closeCheckListImg"
                  onClick={() => {
                    setShowCheckList(false);
                    if (showMePay) {
                      setShowMePay(false);
                    }

                    if (showMeHours) {
                      setShowMeHours(false);
                    }

                    if (showMeAllowances) {
                      setShowMeAllowances(false);
                    }
                  }}
                />
                <p className="userCheckListTitle">
                  {dateStringer.possession(editUserState.editFName)} checklist
                </p>
              </div>
              <p className="checkInfoTxt">
                Complete all three tasks below to get {editUserState.editFName}{" "}
                up and running with FlowRota
              </p>
              <div
                className={`checkListItem ${
                  checkListDone.includes("pay") ||
                  editUserState.tempHourlyRate ||
                  editUserState.salaried ||
                  editUserState.salary
                    ? "opac05"
                    : ""
                }`}
                onClick={() => {
                  updateCheckList("pay");
                }}
              >
                <p className="checkListItemNum">1</p>
                <div className="checkListTxtAndShowMe">
                  <p
                    className={`checkListItemTitle ${
                      checkListDone.includes("pay") ||
                      editUserState.tempHourlyRate ||
                      editUserState.salaried ||
                      editUserState.salary
                        ? "strikeThrough"
                        : ""
                    }`}
                  >
                    Add pay details
                  </p>
                  <p
                    className="checkListShowMe"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (editUserPage !== "Details") {
                        setEditUserPage("Details");
                      }
                      setShowMePay(true);
                      if (showMeHours) {
                        setShowMeHours(false);
                      }
                      if (showMeAllowances) {
                        setShowMeAllowances(false);
                      }
                      if (memoVals.mob) {
                        setShowCheckList(false);
                      }
                    }}
                  >
                    Show me
                  </p>
                </div>
                <div
                  className={`checkListDoItem ${
                    checkListDone.includes("pay") ||
                    checkListDone.includes("pay") ||
                    editUserState.tempHourlyRate ||
                    editUserState.salaried ||
                    editUserState.salary
                      ? "cursorDefault"
                      : ""
                  }`}
                >
                  <img
                    src={greenTick}
                    alt="Completed"
                    className={`completedCheckListTickImg ${
                      checkListDone.includes("pay") ||
                      checkListDone.includes("pay") ||
                      editUserState.tempHourlyRate ||
                      editUserState.salaried ||
                      editUserState.salary
                        ? ""
                        : "invis"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`checkListItem ${
                  checkListDone.includes("hours") ||
                  editUserState.workingPattern === "zero"
                    ? "opac05"
                    : ""
                }`}
                onClick={() => {
                  updateCheckList("hours");
                }}
              >
                <p className="checkListItemNum">2</p>
                <div className="checkListTxtAndShowMe">
                  <p
                    className={`checkListItemTitle ${
                      checkListDone.includes("hours") ||
                      editUserState.workingPattern === "zero"
                        ? "strikeThrough"
                        : ""
                    }`}
                  >
                    Add contracted hours info
                  </p>
                  <p
                    className="checkListShowMe"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (editUserPage !== "Details") {
                        setEditUserPage("Details");
                      }
                      setShowMeHours(true);
                      if (showMePay) {
                        setShowMePay(false);
                      }
                      if (showMeAllowances) {
                        setShowMeAllowances(false);
                      }
                      if (memoVals.mob) {
                        setShowCheckList(false);
                      }
                    }}
                  >
                    Show me
                  </p>
                </div>

                <div
                  className={`checkListDoItem ${
                    checkListDone.includes("hours") ||
                    editUserState.workingPattern === "zero"
                      ? "cursorDefault"
                      : ""
                  }`}
                >
                  <img
                    src={greenTick}
                    alt="Completed"
                    className={`completedCheckListTickImg ${
                      checkListDone.includes("hours") ||
                      editUserState.workingPattern === "zero"
                        ? ""
                        : "invis"
                    }`}
                  />
                </div>
              </div>

              <div
                className={`checkListItem ${
                  checkListDone.includes("allowances") ? "opac05" : ""
                }`}
                onClick={() => {
                  updateCheckList("allowances");
                }}
              >
                <p className="checkListItemNum">
                  3
                  {/* {editUserState.workingPatternTarget === "zero" ? "4" : "3"} */}
                </p>
                <div className="checkListTxtAndShowMe">
                  <p
                    className={`checkListItemTitle ${
                      checkListDone.includes("allowances")
                        ? "strikeThrough"
                        : ""
                    }`}
                  >
                    Set leave allowances
                  </p>
                  <p
                    className="checkListShowMe"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (editUserPage !== "Allowances") {
                        setEditUserPage("Allowances");
                      }

                      setShowMeAllowances(true);
                      if (showMePay) {
                        setShowMePay(false);
                      }
                      if (showMeHours) {
                        setShowMeHours(false);
                      }
                      if (memoVals.mob) {
                        setShowCheckList(false);
                      }
                    }}
                  >
                    Show me
                  </p>
                </div>

                <div
                  className={`checkListDoItem ${
                    checkListDone.includes("allowances") ? "cursorDefault" : ""
                  }`}
                >
                  <img
                    src={greenTick}
                    alt="Completed"
                    className={`completedCheckListTickImg ${
                      checkListDone.includes("allowances") ? "" : "invis"
                    }`}
                  />
                </div>
              </div>

              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn completeCheckBtnAll"
                  onClick={() => {
                    setShowCheckList(false);
                    setSuppressCheckList(true);
                    updateCheckList("all");

                    if (showMeAllowances) {
                      setShowMeAllowances(false);
                    }

                    if (showMeHours) {
                      setShowMeHours(false);
                    }
                    if (showMePay) {
                      setShowMePay(false);
                    }
                  }}
                >
                  Complete all{" "}
                  <img
                    src={whiteTick}
                    alt="Complete all"
                    className="completeAllTick"
                  />
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {cannotRemoveYourself ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                You cannot remove yourself from FlowRota. Please ask a FlowRota
                administrator to do this.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setCannotRemoveYourself(false);
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {isAdminModal.show ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                {isAdminModal.name} is a FlowRota admin with the ability to
                approve any requests, shift changes, and clock cards, including
                their own.
                <br />
                <br />
                You cannot disable {isAdminModal.name} from approving their own
                items unless their admin permissions are removed in the Admin
                panel.
              </p>

              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setIsAdminModal({ show: false });
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } else {
    return imgPreload;
  }
};

export default EditUser;
