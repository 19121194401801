import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";

import { DataContext } from "../../../../contexts/DataContext";
import { StyleContext } from "../../../../contexts/StyleContext";
import { CalendarContext } from "../../../../contexts/CalendarContext";

import serverURL from "../../../../serverURL";

import close from "../../../../img/modals/close.svg";

import dateStringer from "../../../../tools/dateStringer.js";

const AddOpenShiftModal = ({ type }) => {
  const { setAddOpenShift, modalOpen, setModalOpen, device } =
    useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);
  const { setShowOpenShiftModal } = useContext(CalendarContext);

  const memoVals = useMemo(
    () => ({
      setAddOpenShift,
      mob,
      mobModal,
      modalOpen,
      setModalOpen,
      setShowOpenShiftModal,
      device,
    }),
    [
      setAddOpenShift,
      mob, //
      modalOpen,
      setModalOpen,
      setShowOpenShiftModal,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [teams, setTeams] = useState([]);
  let [teamID, setTeamID] = useState("");
  let [dateInput, setDateInput] = useState("");
  let [dateValid, setDateValid] = useState(false);

  try {
    const inputDate = document.getElementById("addShiftDateInput");

    if (inputDate && !memoVals.mob) {
      inputDate.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "date") {
            this.showPicker();
          }
        } catch (error) {
          console.error("Error in inputDate focus event listener:", error);
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up inputDate:", error);
  }

  let getDayString = (di) => {
    let dateObj = new Date(
      dateStringer.createTimestampFromString(
        dateStringer.createStringFromDateInput(di)
      )
    );

    if (dateObj.getDay() === 0) {
      return "Sun";
    }

    if (dateObj.getDay() === 1) {
      return "Mon";
    }

    if (dateObj.getDay() === 2) {
      return "Tue";
    }

    if (dateObj.getDay() === 3) {
      return "Wed";
    }

    if (dateObj.getDay() === 4) {
      return "Thu";
    }

    if (dateObj.getDay() === 5) {
      return "Fri";
    }

    if (dateObj.getDay() === 6) {
      return "Sat";
    }
  };

  useEffect(() => {
    // clean not needed
    let ds = dateStringer.createStringFromDateInput(dateInput);
    if (
      ds &&
      ds[0] === "Y" &&
      ds[1] === "2" &&
      ds[2] === "0" &&
      !ds.includes("null")
    ) {
      setDateValid(true);
    } else {
      setDateValid(false);
    }
  }, [dateInput]);

  let [maxDateInput, setMaxDateInput] = useState("");

  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();
  useEffect(() => {
    // get team users

    axios
      .post(
        `${serverURL}/get-teams-user-manages`,
        {
          someData: 1,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((resp) => {
        if (resp.data.message === "success") {
          setTeams(resp.data.array);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .post(
        `${serverURL}/get-max-date`,
        {
          userID: "testUser",
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setMaxDateInput(
            dateStringer.createDateInputFromDateString(response.data.ds)
          );
        }
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    memoVals.setAddOpenShift((x) => false);
  };
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className="editShiftModalUnderlay"
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      <div
        className={`addShiftModalBox ${
          type === "leave" ? `addShiftLeaveOnlyBox` : ""
        } ${memoVals.mobModal ? "mobModalShoulder slideUp" : ""} ${
          memoVals.device.ios ? "paddingBottom20" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={modalPosition}
        ref={modalRef}
      >
        {memoVals.mobModal && (
          <div className="modalSwiper modalSwiperAddShiftModal2"></div>
        )}
        <div
          className="addShiftModalHeader"
          onTouchStart={handleDown}
          onTouchMove={memoVals.mobModal ? handleMove : null}
          onTouchEnd={memoVals.mobModal ? handleUp : null}
          onMouseDown={memoVals.mobModal ? handleDown : null}
          onMouseMove={memoVals.mobModal ? handleMove : null}
          onMouseUp={memoVals.mobModal ? handleUp : null}
        >
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg"
            onClick={() => {
              closeModal();
            }}
          />
          <p
            className="addShiftHeaderTitle addShiftTitle"
            onClick={() => {
              if (memoVals.mobModal) {
                closeModal();
              }
            }}
          >
            Add open shift
          </p>
          <img
            src={close}
            alt="close"
            className="closeAddShiftModalImg invis"
          />
        </div>

        {/* page 0 */}
        <div
          className={`addShiftMidContainer alignMiddlePage0 addOpenShiftModalMidContainer `}
        >
          <p className="addShiftInputLabel">Team</p>
          <select
            className="addShiftOnWhichUserDropdown"
            value={teamID}
            onChange={(e) => {
              setTeamID(e.target.value);
            }}
          >
            <option value="">Select a team</option>
            {teams
              .sort(function (a, b) {
                return a.teamName.localeCompare(b.teamName);
              })
              .map((team) => {
                return (
                  <option key={team.teamID} value={team.teamID}>
                    {team.teamName}
                  </option>
                );
              })}
          </select>

          <div className="addShiftDivider"></div>
          <p className={`addShiftInputLabel ${teamID ? "" : "opacity4"} `}>
            Date
          </p>
          <div className={`addDateAndDayStringContainer`}>
            <input
              type="date"
              className={`addShiftDateInput  ${teamID ? "" : "opacity4"} ${
                memoVals.device.ios ? "iosDateInput" : ""
              }`}
              id="addShiftDateInput"
              value={dateInput}
              min={dateStringer.createDateInputFromTimestamp(
                new Date().getTime()
              )}
              max={maxDateInput}
              onChange={(e) => {
                setDateInput(e.target.value);
              }}
            ></input>{" "}
            <p className="addShiftDayString">
              {dateValid ? getDayString(dateInput) : ""}
            </p>
          </div>
        </div>
        {/* END OF PAGE 0 */}

        <div className={`addShiftFooter`}>
          {/* <p
            className="cancelAddShiftTxt"
            onClick={() => {
              setShowAddShift(false);
            }}
          >
            Cancel
          </p>{" "} */}

          <p
            className={`submitAddShiftOnUser ${
              teamID && dateValid && dateInput ? "" : "invalidAddShiftBtn"
            } `}
            onClick={() => {
              axios
                .post(
                  `${serverURL}/schedule-new-shift`,
                  {
                    teamID: teamID,
                    ds: dateStringer.createStringFromDateInput(dateInput),
                    userID: "",
                    type: "open",
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    memoVals.setShowOpenShiftModal((x) => {
                      return {
                        ds: dateStringer.createStringFromDateInput(dateInput),
                        new: true,
                        openID: response.data.shiftID,
                      };
                    });
                    closeModal();
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Add
          </p>
          <p
            className={`closeEditShiftBtn`}
            onClick={() => {
              closeModal();
            }}
          >
            Close
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddOpenShiftModal;
