import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed

import { RequestContext } from "../contexts/RequestContext";
import { UserContext } from "../contexts/UserContext";
import { StyleContext } from "../contexts/StyleContext";

import axios from "axios";
import serverURL from "../serverURL";
import dateStringer from "../tools/dateStringer";

import arrGrey from "../img/general/arrGrey.svg";
import tick from "../img/general/onTimeTick.svg";
import cross from "../img/general/unitCross.svg";
const ReqDateInputter2 = ({
  setRenewalOverlap,
  setTooFarAhead,
  localSunSchedTimes,
  localMonSchedTimes,
  localTueSchedTimes,
  localWedSchedTimes,
  localThuSchedTimes,
  localFriSchedTimes,
  localSatSchedTimes,
  //   endDate,
  setEndDate,
  alReqStartTs,
  setAlReqStartTs,
  alReqEndTs,
  setAlReqEndTs,
  halfStart,
  setHalfStart,
  halfEnd,
  setHalfEnd,
  reqDur,
  setReqDur,
  setReqDur2,
  setStartDate,
  //   startDate,
  //   setOverlappingDs,
  maxDs,
  loadedFromCal,
  // myFromReqID,
}) => {
  //   const { halfStartOffs, halfEndOffs } = useContext(RequestContext);
  const { mob } = useContext(StyleContext);

  const { setCheckUserState, checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setCheckUserState,
      checkUserState,
      mob,
    }),
    [setCheckUserState, checkUserState, mob]
  );

  let [startDateValid, setStartDateValid] = useState(true);
  let [endDateValid, setEndDateValid] = useState(true);
  let [workingPattern, setWorkingPattern] = useState("");
  let [halfStartAvailable, setHalfStartAvailable] = useState(false);
  let [halfEndAvailable, setHalfEndAvailable] = useState(false);

  let todaysDate = new Date();
  let lastYear = todaysDate.getFullYear() - 1;

  let [overlapsRenewal, setOverlapsRenewal] = useState(false);
  let [tooFuture, setTooFuture] = useState(false);
  let [showTooFutureModal, setShowTooFutureModal] = useState(false);
  let [showOverlapsRenewalModal, setShowOverlapsRenewalModal] = useState(false);

  let [selectedMy, setSelectedMy] = useState(
    dateStringer.getMyFromTs(
      loadedFromCal
        ? dateStringer.createTimestampFromString(loadedFromCal)
        : new Date().getTime()
    )
  );

  let [monthCal, setMonthCal] = useState([]);
  let [loadingCal, setLoadingCal] = useState(false);
  let [today00Ds, setToday00Ds] = useState("");
  let [hideLeftGreyArr, setHideLeftGreyArr] = useState(false);

  let [lastHoveredTs, setLastHoveredTs] = useState(0);

  let [myArr, setMyArr] = useState([]);
  useEffect(() => {
    if (!myArr[0] && maxDs) {
      let thismonth = new Date();
      thismonth.setDate(1);
      thismonth.setHours(0, 0, 0, 0);
      let maxTs = dateStringer.createTimestampFromString(maxDs);

      let months = parseInt(
        (maxTs - thismonth.getTime()) / 1000 / 60 / 60 / 24 / 30
      );

      let first = dateStringer.getMyFromTs(new Date().getTime());
      let last = dateStringer.getMyFromTs(maxTs);

      let arr = [];

      let initialDateObj = new Date(thismonth.getTime());
      for (var i = 0; i < months; i++) {
        arr.push(dateStringer.getMyFromTs(initialDateObj.getTime()));
        initialDateObj.setMonth(initialDateObj.getMonth() + 1);
      }

      console.log({ maxDs });
      console.log({ arr });

      function getFirstDaysOfMonth(timestamp1, timestamp2) {
        let start = new Date(Math.min(timestamp1, timestamp2));
        let end = new Date(Math.max(timestamp1, timestamp2));

        // Start at the beginning of the month of the start date
        start.setDate(1);
        start.setHours(0, 0, 0, 0);

        let firstDays = [];

        while (start <= end) {
          firstDays.push(start.getTime());

          // Move to the first day of the next month
          start.setMonth(start.getMonth() + 1);
        }

        return firstDays;
      }

      let today00 = new Date();
      today00.setHours(0, 0, 0, 0);
      let calcOutput = getFirstDaysOfMonth(
        today00.getTime(),
        dateStringer.createTimestampFromString(maxDs)
      );

      let newArr = [];
      calcOutput.forEach((x) => {
        newArr.push(dateStringer.getMyFromTs(x));
        console.log("--- ", dateStringer.createStringFromTimestamp(x));
      });
      setMyArr(newArr);
      //   console.log({ first, last });
    }
  }, [maxDs]);

  //   let [finalMy, setFinalMy] = useState("");
  //   useEffect(() => {
  //     if (!finalMy) {
  //       console.log({ maxDs });
  //       setFinalMy(
  //         dateStringer.getMyFromTs(dateStringer.createTimestampFromString(maxDs))
  //       );
  //     }
  //   }, []);
  useEffect(() => {
    let dateObj = new Date();
    dateObj.setHours(0, 0, 0, 0);
    setToday00Ds(dateStringer.createStringFromTimestamp(dateObj.getTime()));
  }, []);
  useEffect(() => {
    let selected = dateStringer.getTsFromMy(selectedMy);
    let selObj = new Date(selected);
    selObj.setDate(0);
    selObj.setHours(0, 0, 0, 0);
    let curr = new Date();
    curr.setDate(1);
    curr.setHours(0, 0, 0, 0);
    if (selObj.getTime() <= curr.getTime()) {
      setHideLeftGreyArr(true);
    } else {
      if (hideLeftGreyArr) {
        setHideLeftGreyArr(false);
      }
    }
  }, [selectedMy]);

  useEffect(() => {
    if (halfStart && halfEnd) {
      if (alReqEndTs === alReqStartTs) {
        setHalfEnd(false);
        setHalfStart(false);
      }
    }
  }, [halfStart, halfEnd]);

  useEffect(() => {
    if (halfEnd) {
      setHalfEnd(false);
    }
    if (halfStart) {
      setHalfStart(false);
    }
  }, [alReqEndTs, alReqStartTs]);

  //   useEffect(() => {
  //     // clean not needed
  //     if (alReqStartTs && alReqEndTs) {
  //       let start = new Date(alReqStartTs);
  //       let numOfDays = Math.round((alReqEndTs - alReqStartTs) / 86400000) + 1;
  //       if (numOfDays > 730) {
  //         numOfDays = 730;
  //       }

  //       console.log("number of days is: ", numOfDays);
  //       let overlaps = [];
  //       for (let i = 0; i < numOfDays; i++) {
  //         start.setHours(0, 0, 0, 0);
  //         let ds = dateStringer.createStringFromTimestamp(start.getTime());

  //         console.log("day", i, ds);
  //         if (halfEndOffs.includes(ds) || halfStartOffs.includes(ds)) {
  //           if (!overlaps.includes(ds)) {
  //             overlaps.push(ds);
  //           }
  //         }
  //         start.setDate(start.getDate() + 1);
  //       }
  //       setOverlappingDs(overlaps);
  //     } else {
  //       setOverlappingDs([]);
  //     }
  //   }, [alReqEndTs, alReqStartTs]);

  let getHalfStartTime = (number, ts) => {
    let dateObj = new Date(ts);
    let dayNumber = dateObj.getDay();
    if (number === 1) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.start.substr(
            0,
            2
          )}:${localSunSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.start.substr(
            0,
            2
          )}:${localMonSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.start.substr(
            0,
            2
          )}:${localTueSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.start.substr(
            0,
            2
          )}:${localWedSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.start.substr(
            0,
            2
          )}:${localThuSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.start.substr(
            0,
            2
          )}:${localFriSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.start.substr(
            0,
            2
          )}:${localSatSchedTimes.start.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 2) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localSunSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localMonSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localTueSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localWedSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localThuSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localFriSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.firstHalfEnd.substr(
            0,
            2
          )}:${localSatSchedTimes.firstHalfEnd.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 3) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.end.substr(
            0,
            2
          )}:${localSunSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.end.substr(
            0,
            2
          )}:${localMonSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.end.substr(
            0,
            2
          )}:${localTueSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.end.substr(
            0,
            2
          )}:${localWedSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.end.substr(
            0,
            2
          )}:${localThuSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.end.substr(
            0,
            2
          )}:${localFriSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.end.substr(
            0,
            2
          )}:${localSatSchedTimes.end.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    } else if (number === 4) {
      if (dayNumber === 0) {
        if (localSunSchedTimes) {
          return `${localSunSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localSunSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 1) {
        if (localMonSchedTimes) {
          return `${localMonSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localMonSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 2) {
        if (localTueSchedTimes) {
          return `${localTueSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localTueSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 3) {
        if (localWedSchedTimes) {
          return `${localWedSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localWedSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 4) {
        if (localThuSchedTimes) {
          return `${localThuSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localThuSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 5) {
        if (localFriSchedTimes) {
          return `${localFriSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localFriSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      } else if (dayNumber === 6) {
        if (localSatSchedTimes) {
          return `${localSatSchedTimes.secondHalfStart.substr(
            0,
            2
          )}:${localSatSchedTimes.secondHalfStart.substr(2, 2)}`;
        } else {
          return "½";
        }
      }
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    setLoadingCal(true);
    axios
      .post(
        `${serverURL}/get-month-cal-snake-for-req-maker`,
        {
          my: selectedMy,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          console.log(response.data);
          setMonthCal(response.data.calendar);
          setLoadingCal(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedMy]);

  useEffect(() => {
    if (alReqStartTs && alReqEndTs) {
      axios
        .post(
          `${serverURL}/count-req-days`,
          {
            startDs: dateStringer.createStringFromTimestamp(alReqStartTs),
            endDs: dateStringer.createStringFromTimestamp(alReqEndTs),
            // reqID: myFromReqID,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.reqDur) {
            setReqDur(response.data.reqDur);
          } else {
            setReqDur(response.data.counter);
          }
          setReqDur2(response.data.counter);
          setHalfStartAvailable(response.data.halfStartAvailable);
          setHalfEndAvailable(response.data.halfEndAvailable);
          setWorkingPattern(response.data.workingPattern);
          // memoVals.setCheckUserState((x) => {
          //   return {
          //     workingPattern: response.data.workingPattern,
          //   };
          // });
          setOverlapsRenewal(response.data.overlapsRenewal);
          setRenewalOverlap(response.data.overlapsRenewal);
          setShowOverlapsRenewalModal(response.data.overlapsRenewal);

          setTooFuture(response.data.tooFarAhead);
          setTooFarAhead(response.data.tooFarAhead);
          // if (myFromReqID) {
          //   setSelectedMy(myFromReqID);
          // }
          setShowTooFutureModal(
            !response.data.overlapsRenewal && response.data.tooFarAhead
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
    }
  }, [alReqEndTs, alReqEndTs]);

  return (
    <div className="requestDateInputWrapper">
      {/* OVERLAPS RENEWAL MODAL */}
      {showOverlapsRenewalModal ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowOverlapsRenewalModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Your request cannot overlap{" "}
              {dateStringer.printedDateWithYrFromDs(
                dateStringer.createStringFromTimestamp(overlapsRenewal)
              )}{" "}
              as it would fall across two seperate company years. <br />
              <br />
              Split the request into two - one in the current company year, and
              one in the next.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowOverlapsRenewalModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* END OF OVERLAPS RENEWAL MODAL */}

      {/* TOO FAR AHEAD MODAL */}
      {showTooFutureModal ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setShowTooFutureModal(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Leave can only be requested for dates up to{" "}
              {dateStringer.printedDateWithYrFromDs(tooFuture)}.<br />
              <br />
              The system administrator has yet to make leave available for next
              year.
            </p>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setAlReqStartTs();
                setAlReqEndTs();
                setStartDate("");
                setEndDate("");
                setHalfStart(false);
                setHalfEnd(false);
                setShowTooFutureModal(false);
              }}
            >
              Understood
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* END OF TOO FAR AHEAD MODAL */}
      {/* S T A R T   S H I F T */}
      <div className="availMonthSelBar">
        <img
          src={arrGrey}
          alt="Back a month"
          className={`availMonthSelArrLeft ${
            !memoVals.mob ? "availMonthSelArrLeftDesktop" : ""
          } ${hideLeftGreyArr ? "invis" : ""}`}
          onClick={() => {
            setLoadingCal(true);

            let dateObj = new Date(dateStringer.getTsFromMy(selectedMy));
            dateObj.setDate(1);
            dateObj.setMonth(dateObj.getMonth() - 1);
            setSelectedMy(dateStringer.getMyFromTs(dateObj.getTime()));
          }}
        />
        <select
          className="reqMonthDropMakerDropdown"
          value={selectedMy}
          onChange={(e) => {
            // console.log({ myArr });
            setSelectedMy(e.target.value);
            // console.log(
            //   "--",
            //   dateStringer.getMyFromTs(
            //     dateStringer.createTimestampFromString(maxDs)
            //   )
            // );
            // console.log({ finalMy, selectedMy, maxDs });
          }}
        >
          {myArr.map((x) => {
            return (
              <option key={x} value={x}>
                {dateStringer.printMonthAndYearFromMy(x, true, true)}
              </option>
            );
          })}
        </select>
        <img
          src={arrGrey}
          alt="Forward a month"
          className={`availMonthSelArrRight ${
            !memoVals.mob ? "availMonthSelArrRightDesktop" : ""
          } ${
            dateStringer.getMyFromTs(
              dateStringer.createTimestampFromString(maxDs)
            ) === selectedMy
              ? "invis"
              : ""
          }`}
          onClick={() => {
            setLoadingCal(true);
            let dateObj = new Date(dateStringer.getTsFromMy(selectedMy));
            dateObj.setDate(1);
            dateObj.setMonth(dateObj.getMonth() + 1);
            setSelectedMy(dateStringer.getMyFromTs(dateObj.getTime()));
          }}
        />
      </div>
      <div className="monthAvailDayBar">
        <p className="monthAvailDayTitle">Mon</p>
        <p className="monthAvailDayTitle">Tue</p>
        <p className="monthAvailDayTitle">Wed</p>
        <p className="monthAvailDayTitle">Thu</p>
        <p className="monthAvailDayTitle">Fri</p>
        <p className="monthAvailDayTitle">Sat</p>
        <p className="monthAvailDayTitle">Sun</p>
      </div>
      <div className="monthAvailSnake">
        {/* hello */}
        {monthCal &&
          monthCal[0] &&
          monthCal.map((row) => {
            // return "week";
            return (
              <div className="reqWkRow">
                {row &&
                  row[0] &&
                  row.map((x) => {
                    return (
                      <div
                        onMouseEnter={() => {
                          if (alReqStartTs && !alReqEndTs) {
                            if (x.ts > alReqStartTs) {
                              setLastHoveredTs(x.ts);
                            } else {
                              if (lastHoveredTs) {
                                setLastHoveredTs(0);
                              }
                            }
                          }
                        }}
                        className={`myAvailCalDayItem  ${
                          x.ts > alReqStartTs && x.ts <= lastHoveredTs
                            ? "x9900ss"
                            : ""
                        } ${x.ts === lastHoveredTs ? "lastHovReqDs" : ""} ${
                          loadingCal ? "loadingAvail" : ""
                        } ${
                          !x.beforeToday ? "reqCalItemHover" : "cursorDefault"
                        } ${
                          (x.ts >= alReqStartTs && x.ts <= alReqEndTs) ||
                          (!alReqEndTs && alReqStartTs === x.ts)
                            ? x.closed
                              ? `selReqSnakeItemClosed ${
                                  !alReqEndTs
                                    ? lastHoveredTs > x.ts
                                      ? ""
                                      : "openSnakItem"
                                    : ""
                                }`
                              : `selReqSnakeItem ${
                                  !alReqEndTs
                                    ? lastHoveredTs > x.ts
                                      ? ""
                                      : "openSnakItem"
                                    : ""
                                } ${
                                  halfStart && x.ts === alReqStartTs
                                    ? "halfSelStartReqItem"
                                    : ""
                                } ${
                                  halfEnd && x.ts === alReqEndTs
                                    ? "halfSelEndReqItem"
                                    : ""
                                }`
                            : ""
                        }
                      ${
                        x.ts > dateStringer.createTimestampFromString(maxDs)
                          ? "invis cursorDefault"
                          : ""
                      } ${
                          x.closed && !x.beforeToday
                            ? "closedReqDaySelector"
                            : ""
                        }`}
                        onClick={() => {
                          setLastHoveredTs(0);
                          if (
                            alReqStartTs &&
                            !alReqEndTs &&
                            x.ts === alReqStartTs
                          ) {
                            setAlReqEndTs(x.ts);
                          }
                          if (!x.beforeToday) {
                            if (alReqStartTs && alReqEndTs) {
                              setAlReqStartTs(x.ts);
                              setAlReqEndTs("");
                            } else {
                              if (!alReqStartTs) {
                                setAlReqStartTs(x.ts);
                              } else {
                                if (alReqStartTs && !alReqEndTs) {
                                  if (x.ts <= alReqStartTs) {
                                    setAlReqStartTs(x.ts);
                                  } else {
                                    setAlReqEndTs(x.ts);
                                  }
                                }
                              }
                            }
                          }
                        }}
                      >
                        <p
                          className={`${
                            x.focused
                              ? x.ts === lastHoveredTs ||
                                (x.ts > alReqStartTs && x.ts < lastHoveredTs)
                                ? "colorWhite"
                                : "x88s"
                              : ""
                          } ${
                            x.focused && halfStart && x.ts === alReqStartTs
                              ? "x881jj"
                              : `availDayN ${
                                  x.closed || x.alreadyOff
                                    ? "alreadyOffOrClosed"
                                    : ""
                                }`
                          } ${x.beforeToday ? "beforeTodayReqCal" : ""} ${
                            (x.ts >= alReqStartTs && x.ts <= alReqEndTs) ||
                            x.ts === alReqStartTs
                              ? x.focused
                                ? "colorWhite"
                                : ""
                              : ""
                          }`}
                        >
                          {x.dayNum}
                        </p>

                        {x.alreadyOff || x.closed ? (
                          <p
                            className={`reqMakerAlreadyOff ${
                              x.beforeToday ? "invis" : ""
                            }`}
                          >
                            {x.closed
                              ? "CLOSED"
                              : x.halfOff
                              ? "HALF OFF"
                              : "OFF"}
                          </p>
                        ) : (
                          <img
                            src={x.ok ? tick : cross}
                            alt="Availability submission"
                            className={`availSubmissionIcon ${
                              x.beforeToday ? "invis" : ""
                            }`}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>

      {/* half options */}
      {(workingPattern === "fixedScheduled" &&
        startDateValid &&
        halfStartAvailable &&
        alReqStartTs &&
        getHalfStartTime(1, alReqStartTs) &&
        alReqStartTs &&
        alReqEndTs) ||
      (workingPattern === "fixedVariable" &&
        alReqStartTs &&
        halfStartAvailable &&
        alReqStartTs &&
        alReqEndTs) ||
      (workingPattern === "fixedScheduled" &&
        halfEndAvailable &&
        endDateValid &&
        alReqEndTs &&
        getHalfStartTime(4, alReqEndTs) &&
        alReqStartTs &&
        alReqEndTs) ||
      (workingPattern === "fixedVariable" &&
        alReqEndTs &&
        halfEndAvailable &&
        alReqStartTs &&
        alReqEndTs) ? (
        <div className="reqHalfDiv">
          {" "}
          {workingPattern === "fixedScheduled" ||
          workingPattern === "fixedVariable" ? (
            <div
              className={`halfReqSelRow ${
                workingPattern === "fixedScheduled" ? "x991010101111" : ""
              }`}
            >
              {halfStartAvailable ? (
                <div
                  className={`halfReqSelRowBlock ${
                    workingPattern === "fixedScheduled" ? "fsReqblock" : ""
                  } ${alReqEndTs === alReqStartTs && halfEnd ? "invis" : ""}`}
                >
                  <p className="halfReqTitleTxt marginLeft0">Starting:</p>{" "}
                  <div
                    className="halfReqSelRowBlockInline cursorPointer"
                    onClick={() => {
                      let durationPre = reqDur;

                      if (!halfStart) {
                        // will turn true
                        durationPre -= 0.5;
                      } else {
                        durationPre += 0.5;
                      }
                      setReqDur(durationPre);
                      setReqDur2(durationPre);

                      setHalfStart(!halfStart);
                    }}
                  >
                    <p className="halfWayThruTxt">
                      Half way
                      <br />
                      through
                    </p>

                    {/*  KNOB BEGIN */}
                    <div className="knobContainer halfStartEndKnob">
                      <div
                        className={`switchKnob ${
                          halfStart ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                  {/* )} */}
                </div>
              ) : (
                ""
              )}
              {halfEndAvailable ? (
                <div
                  className={`halfReqSelRowBlock ${
                    workingPattern === "fixedScheduled" ? "fsReqblock" : ""
                  } ${halfStartAvailable ? "x1981911311" : ""} ${
                    alReqEndTs === alReqStartTs && halfStart ? "invis" : ""
                  }`}
                >
                  <p className="halfReqTitleTxt">Ending:</p>

                  <div
                    className="halfReqSelRowBlockInline cursorPointer"
                    onClick={() => {
                      let durationPre = reqDur;
                      if (!halfEnd) {
                        // will turn true
                        durationPre -= 0.5;
                      } else {
                        durationPre += 0.5;
                      }
                      setReqDur(durationPre);
                      setReqDur2(durationPre);
                      setHalfEnd(!halfEnd);
                    }}
                  >
                    <p className="halfWayThruTxt">
                      Half way
                      <br />
                      through
                    </p>
                    {/*  KNOB BEGIN */}
                    <div className="knobContainer halfStartEndKnob">
                      <div
                        className={`switchKnob ${
                          halfEnd ? "knobOn" : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                    {/*  KNOB END */}{" "}
                  </div>
                  {/* )} */}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className="reqHalfDiv"
          onClick={() => {
            console.log(memoVals.checkUserState);
            console.log({ halfStartAvailable }, workingPattern);
          }}
        ></div>
      )}
    </div>
  );
};

export default ReqDateInputter2;
