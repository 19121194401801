import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// ios done 7 sep 24

import axios from "axios";
import DatePicker from "react-datepicker";

import { DataContext } from "../contexts/DataContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";

import { NavLink } from "react-router-dom";

import serverURL from "../serverURL";
import { StyleContext } from "../contexts/StyleContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import shiftChangeArrow from "../img/general/shiftChangeArrow.svg";
import blueLoader from "../img/general/blueLoader.gif";
import arr from "../img/general/toilConvertArr.svg";

import dateStringer, { tsToHHMM } from "../tools/dateStringer";
import appURL from "../appURL";

// type = til, overtime, shift
const DeleteAccountModal = () => {
  const { mob, mobModal } = useContext(StyleContext);

  const { setShowTilTrimModal, setUpdateTilData, updateTilData } =
    useContext(CalendarContext);
  const {
    setTilReqMade,
    tilReqMade,
    showConvertToilModal,
    setShowConvertToilModal,
    setIndicate,
    setUpdateSingleUserAllowances,
    modalOpen,
    setModalOpen,
    setShowDeleteAccountModal,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mob,
      mobModal,
      showConvertToilModal,
      setShowConvertToilModal,

      setTilReqMade,
      tilReqMade,
      showConvertToilModal,
      setShowConvertToilModal,
      modalOpen,
      setModalOpen,
      setShowDeleteAccountModal,
      setIndicate,
      device,
    }),
    [
      mob, //
      mobModal, //
      showConvertToilModal,
      setShowConvertToilModal,

      setTilReqMade, //
      tilReqMade, //
      showConvertToilModal,
      setShowConvertToilModal,
      modalOpen,
      setModalOpen,
      setShowDeleteAccountModal,
      setIndicate,
      device,
    ]
  );

  let [dead, setDead] = useState(false);

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  let [showTrialExension, setShowTrialExtension] = useState(false);
  let [areYouSure, setAreYouSure] = useState(false);
  let [companyAccount, setCompanyAccount] = useState(true);
  let [toilBalance, setToilBalance] = useState(0);
  let [remainingAnnLeaveMins, setRemainingAnnLeaveMins] = useState(0);
  let [dailyMins, setDailyMins] = useState(0);
  let [fName, setFName] = useState("");

  let [hadSecondTrial, setHadSecondTrial] = useState(false);
  let [typeDelete, setTypeDelete] = useState("");
  let [minsToConvert, setMinsToConvert] = useState(0);
  let [showWarnModal, setShowWarnModal] = useState({
    show: false,
    notEnoughToil: false,
  });
  let [reasonForLeaving, setReasonForLeaving] = useState(0);
  let [otherReason, setOtherReason] = useState("");
  useEffect(() => {
    if (dataLoaded && imagesLoaded === 3) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [companyName, setCompanyName] = useState("");
  let [expiresDs, setExpiresDs] = useState("");
  let [expiresInDays, setExpiresInDays] = useState(0);
  let [expired, setExpired] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    axios
      .post(
        `${serverURL}/get-delete-data`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setCompanyName(response.data.companyName);
          setExpiresDs(response.data.expiresDs);
          setExpiresInDays(response.data.expiresInDays);
          setExpired(response.data.expired);
          setDataLoaded(true);
          setHadSecondTrial(response.data.hadSecondTrial);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowDeleteAccountModal((x) => false);
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`editShiftRequestModalUnderlay ${dead ? "deadUnderlay" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`editShiftRequestModalBox deleteAccModalBox ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          } ${dead ? "invis" : ""} ${
            memoVals.device.ios ? "paddingBottom20" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTilTrim"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`toilHeader `}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            {/* <div
            className="editMyShiftCloseHolder"
            onClick={() => {
              closeModal();
            }}
          > */}
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            {/* </div> */}
            <p
              className={`toilHeaderTxt`}
              onClick={() => {
                if (memoVals.mobModal) {
                  closeModal();
                }
              }}
            >
              Delete FlowRota account
            </p>
            {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
          </div>

          {/* <p className="toilInstructInfo">
            Convert TOIL to annual leave or vice-versa
          </p> */}

          <div className={`convertSection ${loading ? "invis" : ""}`}>
            <p className="convertOptionTitle">Delete</p>
            <div className={`formKey convertKey`}>
              <div
                className={`formKeyTab ${
                  companyAccount ? "formKeyTabUnselected" : ""
                }`}
                onClick={() => {
                  if (companyAccount) {
                    setCompanyAccount(!companyAccount);
                  }
                }}
              >
                {" "}
                <p className={`formTabTitle convertKeyTitle`}>
                  My account only
                </p>
                <div
                  className={`formTabSelDel  ${companyAccount ? "invis" : ""}`}
                ></div>
              </div>{" "}
              <div
                className={`formKeyTab ${
                  companyAccount ? "" : "formKeyTabUnselected"
                }
              `}
                onClick={() => {
                  if (!companyAccount) {
                    setCompanyAccount(!companyAccount);
                  }
                }}
              >
                <p className={`formTabTitle convertKeyTitle`}>
                  Company account
                </p>
                <div
                  className={`formTabSelDel ${!companyAccount ? "invis" : ""}`}
                ></div>
              </div>{" "}
            </div>
          </div>

          <div
            className={`editMyShiftRow noBottomBorder deleteMyAccRow ${
              companyAccount ? "companyAccDivInfo" : ""
            }`}
          >
            {!companyAccount && (
              <div className={`toilRowTitle fontSize14 lineHeight18 `}>
                <br />
                To delete your personal account without affecting the entire
                account for {companyName || "the company"} and its users, follow
                these steps:
                <br />
                <br />
                <p className="deleteTaskSpan">
                  Assign another user to the 'admin' role in the
                  'Administrators' page of the Admin Panel.
                </p>
                {/* <br /> */}
                <br />
                <p className="deleteTaskSpan">
                  {" "}
                  Ask the newly assigned admin to remove your account.
                </p>{" "}
                <br />
              </div>
            )}
            {/* {companyAccount && expired ? (
              <p className="compAccDeleteRow">
                Your trial expired {Math.abs(expiresInDays)} day
                {Math.abs(expiresInDays) === 1 ? "" : "s"} ago.
              </p>
            ) : (
              ""
            )} */}
            {companyAccount && !expired && expiresInDays > 0 ? (
              <p className="compAccDeleteRow trialEndTxt">
                Your {hadSecondTrial ? "second " : ""}trial ends in{" "}
                {Math.abs(expiresInDays)} day
                {Math.abs(expiresInDays) === 1 ? "" : "s"}.
              </p>
            ) : (
              ""
            )}

            {companyAccount ? (
              <p className="compAccDeleteRow makeBold reasonForLeavingTitle">
                Reason for leaving
              </p>
            ) : (
              ""
            )}

            {companyAccount ? (
              <div className="reasonForLeavingDiv">
                <div
                  className="leaveReasonRow"
                  onClick={() => {
                    setReasonForLeaving(1);
                  }}
                >
                  <p
                    className={`leaveReasonRowTxt ${
                      reasonForLeaving === 1 ? "colour143051 makeBold" : ""
                    }`}
                  >
                    No business need
                  </p>

                  <div
                    className={
                      reasonForLeaving === 1 ? "reasonActive" : "reasonInactive"
                    }
                  ></div>
                </div>

                <div
                  className="leaveReasonRow"
                  onClick={() => {
                    setReasonForLeaving(2);
                  }}
                >
                  <p
                    className={`leaveReasonRowTxt ${
                      reasonForLeaving === 2 ? "colour143051 makeBold" : ""
                    }`}
                  >
                    Too expensive
                  </p>

                  <div
                    className={
                      reasonForLeaving === 2 ? "reasonActive" : "reasonInactive"
                    }
                  ></div>
                </div>

                <div
                  className="leaveReasonRow"
                  onClick={() => {
                    setReasonForLeaving(3);
                  }}
                >
                  <p
                    className={`leaveReasonRowTxt ${
                      reasonForLeaving === 3 ? "colour143051 makeBold" : ""
                    }`}
                  >
                    Too complicated
                  </p>

                  <div
                    className={
                      reasonForLeaving === 3 ? "reasonActive" : "reasonInactive"
                    }
                  ></div>
                </div>

                <div
                  className="leaveReasonRow"
                  onClick={() => {
                    setReasonForLeaving(4);
                  }}
                >
                  <p
                    className={`leaveReasonRowTxt ${
                      reasonForLeaving === 4 ? "colour143051 makeBold" : ""
                    }`}
                  >
                    Using another system
                  </p>

                  <div
                    className={
                      reasonForLeaving === 4 ? "reasonActive" : "reasonInactive"
                    }
                  ></div>
                </div>

                <div
                  className={`leaveReasonRow noBorder`}
                  onClick={() => {
                    setReasonForLeaving(5);
                  }}
                >
                  <p className="leaveReasonRowTxt">Other</p>

                  <div
                    className={
                      reasonForLeaving === 5 ? "reasonActive" : "reasonInactive"
                    }
                  ></div>
                </div>
                {reasonForLeaving === 5 && (
                  <input
                    type="text"
                    value={otherReason}
                    className="reasonForLeavingStringinput"
                    placeholder="Please specify"
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val.length < 10000) {
                        setOtherReason(e.target.value);
                      }
                    }}
                  ></input>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <div className={`editMyShiftRow`}>
            <p className="toilRowTitle">Annual leave remaining</p>

            <p
              className={`takeTilDsTxt ${
                !companyAccount && minsToConvert > remainingAnnLeaveMins
                  ? "colourRed"
                  : ""
              }`}
            >
              {dateStringer.formatMinsDurationToHours(
                remainingAnnLeaveMins,
                false,
                true
              )}
              {dailyMins > 0 && <br />}
              {dailyMins > 0 && (
                <span
                  className={`convertToilAnnLeaveDaysSub ${
                    !companyAccount && minsToConvert > remainingAnnLeaveMins
                      ? "colourRed"
                      : ""
                  }`}
                >
                  {dateStringer.convertMinsToDays(
                    remainingAnnLeaveMins,
                    dailyMins,
                    true
                  )}
                </span>
              )}
            </p>
          </div>
          <div className={`editMyShiftRow noBorderBottom`}>
            <p className="toilRowTitle">
              {dateStringer.possession(fName)} daily workable hours
              <br />
              <span className={`convertToilAnnLeaveDaysSub`}>(average)</span>
            </p>

            <p className={`takeTilDsTxt `}>
              {dateStringer.formatMinsDurationToHours(dailyMins)}
            </p>
          </div> */}

          <div className="toilFooter">
            <div className="tilFooterLeft">
              <div className="submitContainerEditMyShift">
                <p
                  className={`${
                    loading || !companyAccount
                      ? "none"
                      : `requestEditMyShiftBtn`
                  } ${
                    companyAccount && reasonForLeaving === 0
                      ? "disableDeleteBtn"
                      : ""
                  }`}
                  onClick={() => {
                    if (!hadSecondTrial && expired) {
                      setShowTrialExtension(true);
                    } else {
                      setAreYouSure(true);
                    }
                  }}
                >
                  Delete account
                </p>
                <img
                  src={blueLoader}
                  className={`${loading ? "blueLoaderEditMyShiftImg" : "none"}`}
                  alt="Loading"
                  onClick={() => {
                    setLoading(false);
                  }}
                />
              </div>
            </div>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {showTrialExension && (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              We appreciate that the original 30 day trial period might not have
              been long enough for you to realise the true benefits of FlowRota.
              <br />
              <br />
              Would you like another 30-day free trial starting today?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/extend-trial`,
                      {
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "New 30 day trial started",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        setShowTrialExtension(false);
                        setTimeout(() => {
                          window.location.href = `${appURL}/admin/billing`;
                        }, 2000);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes please
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowTrialExtension(false);
                  setAreYouSure(true);
                }}
              >
                No thanks
              </p>
            </div>
          </div>
        </div>
      )}
      {areYouSure && !dead ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setAreYouSure(false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              Type DELETE below to confirm deletion of your FlowRota account.
              <br />
              <br />
              <input
                type="text"
                value={typeDelete}
                className="deleteInputConfinput"
                placeholder="Type DELETE to confirm"
                onChange={(e) => {
                  setTypeDelete(e.target.value);
                }}
              ></input>
              <br />
              <br />
              All users associated with your FlowRota account (including you)
              will be deleted and everyone will be logged out automatically.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className={`areYouSureModalYesBtn confDel ${
                  typeDelete.toLowerCase() !== "delete" ? "disable" : ""
                }`}
                onClick={() => {
                  if (typeDelete.toLowerCase() === "delete") {
                    let reason;
                    if (reasonForLeaving === 0) {
                      reason = "Unknown";
                    }
                    if (reasonForLeaving === 1) {
                      reason = "No business need";
                    }
                    if (reasonForLeaving === 2) {
                      reason = "Too expensive";
                    }
                    if (reasonForLeaving === 3) {
                      reason = "Too complicated";
                    }
                    if (reasonForLeaving === 4) {
                      reason = "Using another system";
                    }
                    if (reasonForLeaving === 5) {
                      reason = otherReason || "Unknown";
                    }
                    axios
                      .post(
                        `${serverURL}/delete-entire-account`,
                        {
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          reason,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setDead(true);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Deleted, thank you for trying us.",
                              colour: "green",
                              duration: 6000,
                            };
                          });

                          setTimeout(() => {
                            window.location.href = `https://flowrota.com`;
                          }, 6000);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                Confirm deletion
              </p>
              <p
                className="areYouModalNoBtn canxDel"
                onClick={() => {
                  setAreYouSure(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default DeleteAccountModal;
