import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";
import { AdminContext } from "../../../contexts/AdminContext";

import serverURL from "../../../serverURL";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";
import dateStringer from "../../../tools/dateStringer";
import { NavLink } from "react-router-dom";
import appURL from "../../../appURL";

const Permissions = () => {
  const { setAccNav, mob, setAdminPage } = useContext(StyleContext);

  let [excessMinsPaidInRequests, setExcessMinsPaidInRequests] = useState(false);
  const {
    setSelectedNav,
    setIndicate,
    setShowUpdatedReqsModal,
    device,
    setShowEditUser,
  } = useContext(DataContext);

  const { isAdminModal, setIsAdminModal } = useContext(AdminContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      setSelectedNav,
      setIndicate,
      setShowUpdatedReqsModal,
      device,
      setAdminPage,
      isAdminModal,
      setIsAdminModal,
      setShowEditUser,
    }),
    [
      setAccNav,
      mob,
      setSelectedNav,
      setIndicate,
      setShowUpdatedReqsModal,
      device,
      setAdminPage,
      isAdminModal,
      setIsAdminModal,
      setShowEditUser,
    ]
  );

  let [loading, setLoading] = useState(true);

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
    memoVals.setSelectedNav((x) => 5.1);

    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/permissions";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let [users, setUsers] = useState([]);
  let [teams, setTeams] = useState([]);
  useEffect(() => {
    const cancelSource3 = axios.CancelToken.source();

    if (!users[0]) {
      axios
        .post(
          `${serverURL}/get-admin-permissions`,
          { someData: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource3.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setLoading(false);
            setTeams(response.data.teams);
            setUsers(response.data.output);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource3.cancel("Component unmounted");
    };
  }, []);

  return (
    <div
      className={`AdminSettingsContainer ${
        memoVals.mob
          ? `AdminSettingsContainerMob ${
              memoVals.device.ios ? "AdminSettingsContainerMobIos" : ""
            }`
          : "borderRightGrey"
      }`}
    >
      {/* <div className='whiteGradientAdmin'></div> */}

      {loading ? (
        <img
          src={horizontalBalls}
          alt="Loading"
          className="teamSchedLoadingBallsImg"
        />
      ) : (
        <div className="AdminInner">
          {/* ROW ------------------------------------------------------------------------ */}
          {/* ROW ------------------------------------------------------------------------ */}
          <div
            className={`AdminRowContainer absenceAdminRow x13i121 ${
              memoVals.mob ? "clickShiftRowAdminMob mobileAdminRow" : ""
            }`}
          >
            <div className="settingsRow x132432222">
              <div className="rowWithSubText">
                <p className="absenceRowMainText">Permissions</p>
                <p className="absenceRowSubText overtimeRowSubText">
                  The employees listed below are managers of teams they are also
                  a member of.
                  <br />
                  <br />
                  Use the settings below to configure whether each manager can
                  approve their own requests, logs and changes.
                  <br />
                  <br />
                  <span className="makeBold">Note:</span> Staff with admin
                  permissions can approve any request from any user - and this
                  cannot be overridden unless their admin permissions are
                  removed.
                </p>

                {/* <div className="adminOtRatesDiv">Hello</div> */}
                <div className="dailyRatesDataContainer x389181 x239848943245">
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  {users.map((user, i) => {
                    return (
                      <div key={i} className="permUserRow">
                        <div className="permUserRowTop">
                          <div className="permUserRowLeft">
                            <div className="permUserRowLeftUpper">
                              <img
                                src={
                                  user.profPicUrl || profilePicturePlaceholder
                                }
                                alt={user.fName}
                                className="permUserRowImg"
                                onClick={() => {
                                  memoVals.setShowEditUser((x) => user.userID);
                                }}
                              />{" "}
                              <div className="permNameRow">
                                <p
                                  className="permNameRowLow"
                                  onClick={() => {
                                    memoVals.setShowEditUser(
                                      (x) => user.userID
                                    );
                                  }}
                                >
                                  {user.fName} {user.lName}
                                </p>
                                {user.permissions === 1 ? (
                                  <p className="permAdminBub">Admin</p>
                                ) : (
                                  <div className="mbrMgrOfDivParent">
                                    <p className="mbrMgrOfDivTitle">
                                      is both a member and manager of:
                                    </p>
                                    <div className="mbrMgrOfDiv">
                                      {user.mbrMgrOf &&
                                      Array.isArray(user.mbrMgrOf) &&
                                      user.mbrMgrOf[0]
                                        ? user.mbrMgrOf.map((team) => {
                                            return (
                                              <p
                                                className="mbrMgrOfItem"
                                                key={team.teamID}
                                              >
                                                {dateStringer.shorten(
                                                  team.teamName,
                                                  33
                                                )}
                                              </p>
                                            );
                                          })
                                        : //   <p className="mbrMgrOfItem" key={i}>
                                          //     {dateStringer.shorten(
                                          //       user.mbrMgrOf[0],
                                          //       20
                                          //     )}
                                          //   </p>
                                          ""}
                                      {/* {user.mbrMgrOf &&
                                    Array.isArray(user.mbrMgrOf) &&
                                    user.mbrMgrOf[1] ? (
                                      <p className="mbrMgrOfItem" key={i}>
                                        + {user.mbrMgrOf.length - 1}
                                      </p>
                                    ) : (
                                      ""
                                    )} */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>{" "}
                            {/* <div class        Name="permUserRowLeftLower permUserRowLeftLowerJt"> */}
                            {/* {user.jobTitle || "No job title"} */}
                            {/* </div> */}
                          </div>

                          {/* prim here */}
                          {/* <div className="permUserPrimTeamBlock">
                            <p className="permUserRowTitle">Primary team</p>
                            <select
                              className="permUserRowPrimaryTeamSelectDropdown"
                              value={user.primaryTeamID}
                            >
                              {teams.map((team) => {
                                return (
                                  <option key={team.teamID} value={team.teamID}>
                                    {team.teamName}
                                  </option>
                                );
                              })}
                            </select>
                          </div> */}
                        </div>
                        {user.permissions === 1 && (
                          <p className="appOwn">Can approve their own:</p>
                        )}

                        {user.permissions === 1 ? (
                          <div className="permUserRowBottom">
                            <div
                              className={`allowSelfKnobContainer ${
                                user.permissions === 1 ? "perm1knob" : ""
                              }`}
                              onClick={() => {
                                memoVals.setIsAdminModal((x) => user.fName);
                              }}
                            >
                              <p className="permUserRowTitle">Leave requests</p>
                              {/*  KNOB BEGIN */}
                              <div
                                className={`knobContainer ${
                                  user.permissions === 1 ? "cursorDefault" : ""
                                } knobContainerSettings allowSelfKnob`}
                              >
                                <div
                                  className={`switchKnob ${
                                    user.allowSelfApprove ||
                                    user.permissions === 1
                                      ? `knobOn ${
                                          user.permissions === 1
                                            ? "knobOnPerm1"
                                            : ""
                                        }`
                                      : "knobOff"
                                  }`}
                                ></div>

                                <div className="knobBg"></div>
                              </div>
                              {/*  KNOB END */}
                            </div>

                            <div
                              className={`allowSelfKnobContainer ${
                                user.permissions === 1 ? "perm1knob" : ""
                              }`}
                              onClick={() => {
                                memoVals.setIsAdminModal((x) => user.fName);
                              }}
                            >
                              <p className="permUserRowTitle">Clock cards</p>
                              {/*  KNOB BEGIN */}
                              <div
                                className={`knobContainer ${
                                  user.permissions === 1 ? "cursorDefault" : ""
                                }  knobContainerSettings allowSelfKnob`}
                              >
                                <div
                                  className={`switchKnob ${
                                    user.allowSelfClockApprove ||
                                    user.permissions === 1
                                      ? `knobOn ${
                                          user.permissions === 1
                                            ? "knobOnPerm1"
                                            : ""
                                        }`
                                      : "knobOff"
                                  }`}
                                ></div>

                                <div className="knobBg"></div>
                              </div>
                              {/*  KNOB END */}
                            </div>

                            <div
                              className={`allowSelfKnobContainer ${
                                user.permissions === 1 ? "perm1knob" : ""
                              }`}
                              onClick={() => {
                                memoVals.setIsAdminModal((x) => user.fName);
                              }}
                            >
                              <p className="permUserRowTitle">Shift requests</p>
                              {/*  KNOB BEGIN */}
                              <div
                                className={`knobContainer ${
                                  user.permissions === 1 ? "cursorDefault" : ""
                                }  knobContainerSettings allowSelfKnob`}
                              >
                                <div
                                  className={`switchKnob ${
                                    user.allowSelfHoursApprove ||
                                    user.permissions === 1
                                      ? `knobOn ${
                                          user.permissions === 1
                                            ? "knobOnPerm1"
                                            : ""
                                        }`
                                      : "knobOff"
                                  }`}
                                ></div>

                                <div className="knobBg"></div>
                              </div>
                              {/*  KNOB END */}
                            </div>
                          </div>
                        ) : (
                          user.mbrMgrOf.map((team, ii) => {
                            return (
                              <div
                                className={`permUserRowPerm2Row ${
                                  ii === 0 ? "noBorder" : ""
                                }`}
                                key={ii}
                              >
                                <div className="perm2row">
                                  <p className="perm2rowTitle">
                                    {team.teamName}
                                  </p>
                                  <div className="permUserRowBottom">
                                    {user.showLeave === team.teamID && (
                                      <div
                                        className={`allowSelfKnobContainer`}
                                        onClick={() => {
                                          // edit here
                                          let newVal = !user.allowSelfApprove;
                                          axios
                                            .post(
                                              `${serverURL}/toggle-allow-self-approve`,
                                              {
                                                userID: user.userID,
                                                data: newVal,
                                              },

                                              {
                                                withCredentials: true,
                                                credentials: "include",
                                              }
                                            )
                                            .then((response) => {
                                              if (
                                                response.data.message ===
                                                "success"
                                              ) {
                                                let newArr = [];

                                                users.forEach((x) => {
                                                  if (
                                                    x.userID === user.userID
                                                  ) {
                                                    let newObj = {
                                                      fName: user.fName,
                                                      lName: user.lName,
                                                      profPicUrl:
                                                        user.profPicUrl,
                                                      jobTitle: user.jobTitle,
                                                      permissions:
                                                        user.permissions,
                                                      showLeave: user.showLeave,
                                                      allowSelfApprove: newVal,
                                                      allowSelfHoursApprove:
                                                        user.allowSelfHoursApprove,
                                                      allowSelfClockApprove:
                                                        user.allowSelfClockApprove,
                                                      mbrMgrOf: user.mbrMgrOf,
                                                      userID: user.userID,
                                                      primaryTeamID:
                                                        user.primaryTeamID,
                                                      allTeams: user.allTeams,
                                                      mgrOf: user.mgrOf,
                                                    };
                                                    newArr.push(newObj);
                                                  } else {
                                                    newArr.push(x);
                                                  }
                                                });

                                                setUsers(newArr);
                                              }
                                            })
                                            .catch((err) => {
                                              console.error(err);
                                            });
                                        }}
                                      >
                                        <p className="permUserRowTitle colour0077ff">
                                          Leave requests
                                          <span className="asterixLarge">
                                            *
                                          </span>
                                        </p>
                                        {/*  KNOB BEGIN */}
                                        <div
                                          className={`knobContainer  knobContainerSettings allowSelfKnob`}
                                        >
                                          <div
                                            className={`switchKnob ${
                                              user.allowSelfApprove
                                                ? `knobOn ${
                                                    user.permissions === 1
                                                      ? "knobOnPerm1"
                                                      : ""
                                                  }`
                                                : "knobOff"
                                            }`}
                                          ></div>

                                          <div className="knobBg"></div>
                                        </div>
                                        {/*  KNOB END */}
                                      </div>
                                    )}
                                    <div
                                      className={`allowSelfKnobContainer`}
                                      onClick={() => {
                                        // edit here
                                        let newVal =
                                          user.allowSelfClockApprove?.includes(
                                            team.teamID
                                          )
                                            ? false
                                            : true;
                                        axios
                                          .post(
                                            `${serverURL}/toggle-allow-self-clock-approve`,
                                            {
                                              userID: user.userID,
                                              data: newVal,
                                              teamID: team.teamID,
                                            },

                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                            }
                                          )
                                          .then((response) => {
                                            if (
                                              response.data.message ===
                                              "success"
                                            ) {
                                              let newArr = [];

                                              users.forEach((x) => {
                                                if (x.userID === user.userID) {
                                                  let newObj = {
                                                    fName: user.fName,
                                                    lName: user.lName,
                                                    profPicUrl: user.profPicUrl,
                                                    jobTitle: user.jobTitle,
                                                    permissions:
                                                      user.permissions,
                                                    showLeave: user.showLeave,
                                                    allowSelfApprove:
                                                      user.allowSelfApprove,
                                                    allowSelfHoursApprove:
                                                      user.allowSelfHoursApprove,
                                                    allowSelfClockApprove:
                                                      response.data.data,
                                                    mbrMgrOf: user.mbrMgrOf,
                                                    userID: user.userID,
                                                    primaryTeamID:
                                                      user.primaryTeamID,
                                                    allTeams: user.allTeams,
                                                    mgrOf: user.mgrOf,
                                                  };
                                                  newArr.push(newObj);
                                                } else {
                                                  newArr.push(x);
                                                }
                                              });

                                              setUsers(newArr);
                                            }
                                          })
                                          .catch((err) => {
                                            console.error(err);
                                          });
                                      }}
                                    >
                                      <p className="permUserRowTitle">
                                        Clock cards
                                      </p>
                                      {/*  KNOB BEGIN */}
                                      <div
                                        className={`knobContainer  knobContainerSettings allowSelfKnob`}
                                      >
                                        <div
                                          className={`switchKnob ${
                                            user.allowSelfClockApprove?.includes(
                                              team.teamID
                                            )
                                              ? `knobOn ${
                                                  user.permissions === 1
                                                    ? "knobOnPerm1"
                                                    : ""
                                                }`
                                              : "knobOff"
                                          }`}
                                        ></div>

                                        <div className="knobBg"></div>
                                      </div>
                                      {/*  KNOB END */}
                                    </div>

                                    <div
                                      className={`allowSelfKnobContainer`}
                                      onClick={() => {
                                        // edit here
                                        let currTrue =
                                          user.allowSelfHoursApprove?.includes(
                                            team.teamID
                                          );

                                        axios
                                          .post(
                                            `${serverURL}/toggle-mgr-can-manage-own-team-hours`,
                                            {
                                              userID: user.userID,
                                              teamID: team.teamID,
                                              add: currTrue ? false : true,
                                              pull: currTrue ? true : false,
                                            },

                                            {
                                              withCredentials: true,
                                              credentials: "include",
                                            }
                                          )
                                          .then((response) => {
                                            if (
                                              response.data.message ===
                                              "success"
                                            ) {
                                              let newArr = [];

                                              users.forEach((x) => {
                                                if (x.userID === user.userID) {
                                                  let newObj = {
                                                    fName: user.fName,
                                                    lName: user.lName,
                                                    profPicUrl: user.profPicUrl,
                                                    jobTitle: user.jobTitle,
                                                    permissions:
                                                      user.permissions,
                                                    showLeave: user.showLeave,
                                                    allowSelfApprove:
                                                      user.allowSelfApprove,
                                                    allowSelfHoursApprove:
                                                      response.data
                                                        .allowSelfHoursApprove,
                                                    allowSelfClockApprove:
                                                      user.allowSelfClockApprove,
                                                    mbrMgrOf: user.mbrMgrOf,
                                                    userID: user.userID,
                                                    primaryTeamID:
                                                      user.primaryTeamID,
                                                    allTeams: user.allTeams,
                                                    mgrOf: user.mgrOf,
                                                  };
                                                  newArr.push(newObj);
                                                } else {
                                                  newArr.push(x);
                                                }
                                              });

                                              setUsers(newArr);
                                            }
                                          });
                                      }}
                                    >
                                      <p className="permUserRowTitle">
                                        Shift requests
                                      </p>
                                      {/*  KNOB BEGIN */}
                                      <div
                                        className={`knobContainer knobContainerSettings allowSelfKnob`}
                                      >
                                        <div
                                          className={`switchKnob ${
                                            user.allowSelfHoursApprove?.includes(
                                              team.teamID
                                            )
                                              ? `knobOn`
                                              : "knobOff"
                                          }`}
                                        ></div>

                                        <div className="knobBg"></div>
                                      </div>
                                      {/*  KNOB END */}
                                    </div>
                                  </div>
                                  {user.showLeave === team.teamID && (
                                    <p className="permLeaveAsterix">
                                      <span className="asterixLarge">*</span>
                                      {user.fName} has manager permissions for
                                      their primary team, {team.teamName}. An
                                      employee's leave requests may only be
                                      approved by managers of their primary
                                      team. Use this setting to configure
                                      whether {user.fName} can approve their own
                                      leave requests - otherwise, only other
                                      managers of {team.teamName} and FlowRota
                                      admins can approve them. Read about this{" "}
                                      <a
                                        className="permLinkX93294"
                                        href="https://flowrota.com/support/self-approve-permissions"
                                        target="_blank"
                                      >
                                        here
                                      </a>
                                      .
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    );
                  })}

                  {/* <div className="dailyRateRowSplitter"></div> */}
                  {/* OT ROW ------------------------------------------------------------------------ */}

                  {/* <div className="dailyRateRowSplitter"></div> */}
                  {/* OT ROW ------------------------------------------------------------------------ */}
                </div>
              </div>
              <div>
                {/*  KNOB BEGIN */}
                {/* <div className="knobContainer knobContainerSettings   ">
                <div
                  className={`switchKnob ${enableTil ? "knobOn" : "knobOff"}`}
                ></div>

                <div className="knobBg"></div>
              </div> */}
                {/*  KNOB END */}
              </div>
            </div>
          </div>
          {/* END OF ROW ------------------------------------------------------------------------ */}

          <br />
        </div>
      )}
    </div>
  );
};

export default Permissions;
