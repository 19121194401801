import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import whiteLogo from "../../img/general/whiteLogo.svg";
import whiteTick from "../../img/general/whiteTick.svg";
import cancel from "../../img/general/whiteCanx.svg";
import popup from "../../img/general/popup.svg";

import fullScreen from "../../img/general/fullScreen.svg";
import logo from "../../img/general/logo.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import eye from "../../img/signIn/eye.svg";
import userIcon from "../../img/general/userIcon.svg";
import teamIcon from "../../img/general/teamIcon.svg";
import teamIcon2 from "../../img/general/teamIcon2.svg";
import editGrey from "../../img/general/editGrey.svg";
import signout from "../../img/general/signout.svg";
import install from "../../img/general/install.svg";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";

// content components

import TopBar from "../../components/TopBar";
import NotifSettings from "./NotifSettings";

import dateStringer from "../../tools/dateStringer";

import MyReqsDataBox from "../requests/MyReqsDataBox";

import HoursDataBox from "../calendar/shifts/HoursDataBox";
import appURL from "../../appURL";

const ClockTerminal = () => {
  // check the user is valid - this fct will update the UserContext

  let [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    // log in
    // CheckUser("919");
  }, []);

  const { setShowClockOnModal, showClockOnModal } = useContext(DataContext);

  let [tappedNum, setTappedNum] = useState("");
  let [loading, setLoading] = useState(true);
  let [companyName, setCompanyName] = useState(``);
  let [pin, setPin] = useState("");
  let [pinLoading, setPinLoading] = useState(false);
  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [jobTitle, setJobTitle] = useState();
  let [profPicUrl, setProfPicUrl] = useState("");
  let [invalidPin, setInvalidPin] = useState(false);
  let [clockOnNotAllowed, setClockOnNotAllowed] = useState(false);

  useEffect(() => {
    document.title = "FlowRota - Clock terminal";
  }, []);

  let [terminalNameLogin, setTerminalNameLogin] = useState(false);
  let [terminalCamera, setTerminalCamera] = useState(false);
  let [users, setUsers] = useState([]);
  let [selectedUserID, setSelectedUserID] = useState("");

  useEffect(() => {
    if (!showClockOnModal) {
      setSelectedUserID("");
      setFName("");
      setLName("");
      setProfPicUrl("");
      setPin("");
    }
  }, [showClockOnModal]);

  useEffect(() => {
    const styleElement = document.querySelector("#progressier-widget");
    if (styleElement) {
      // Append display:none !important; to the .progressier-widget class
      styleElement.textContent += `
        .progressier-widget,
        .progressier-widget *,
        .progressier-widget-icon,
        .progressier-widget-logo {
          display: none !important;
        }
      `;
    }

    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/init-terminal`,
        {
          someData: 1,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "not admin") {
          window.location.href = "/account";
        } else {
          setTerminalNameLogin(response.data.terminalNameLogin);
          setTerminalCamera(response.data.terminalCamera);
          setCompanyName(response.data.companyName);
          setUsers(response.data.users);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  const [time, setTime] = useState("");
  const [showColon, setShowColon] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      setShowColon((prevShowColon) => !prevShowColon); // Toggle colon visibility
      setTime(`${hours}${showColon ? ":" : " "}${minutes}`);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [showColon]);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [photo, setPhoto] = useState(null);

  let [disabledCam, setDisabledCam] = useState(false);
  let [camNotWorking, setCamNotWorking] = useState(false);

  useEffect(() => {
    let stream;

    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" }, // Use the front-facing camera
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
        if (!showClockOnModal) {
          setDisabledCam(true);
        }
        setCamNotWorking(true);
      }
    };

    if (terminalCamera) {
      startCamera();
    }

    // Cleanup function to stop the camera stream when the component unmounts
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [terminalCamera, showClockOnModal]);

  const launch = () => {
    if (terminalCamera && !camNotWorking) {
      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const context = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        setPhoto(canvas.toDataURL("image/png"));

        let b64string = canvas.toDataURL("image/png");

        // Function to convert base64 string to Blob
        function base64ToBlob(base64, contentType = "", sliceSize = 512) {
          const byteCharacters = atob(base64.split(",")[1]);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          return new Blob(byteArrays, { type: contentType });
        }

        // Function to compress the image Blob
        async function compressImage(base64String) {
          const blob = base64ToBlob(base64String, "image/png");

          const options = {
            maxSizeMB: 0.04,
            maxWidthOrHeight: 500,
            useWebWorker: true,
          };

          try {
            const compressedBlob = await imageCompression(blob, options);
            return compressedBlob;
          } catch (error) {
            console.error("Error during compression:", error);
          }
        }

        // Function to convert Blob back to base64
        function blobToBase64(blob) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        }

        // Full process to compress a base64 string
        async function compressBase64Image(base64String) {
          const compressedBlob = await compressImage(base64String);
          const compressedBase64 = await blobToBase64(compressedBlob);
          return compressedBase64;
        }

        // Example usage
        compressBase64Image(b64string).then((compressedBase64String) => {
          let compressedBase64 = compressedBase64String;
          setShowClockOnModal({
            userID: selectedUserID,
            photo: compressedBase64,
          });
        });

        //   =======
      } else {
        setShowClockOnModal({ userID: selectedUserID });
      }
    } else {
      setShowClockOnModal({ userID: selectedUserID });
    }
  };

  const handleKeyPress = (value) => {
    if (pin.length < 6) {
      setPin((prevPin) => prevPin + value);
      setTappedNum(value);
      setTimeout(() => {
        setTappedNum();
      }, 100);
    }
  };

  function isValidPin(p) {
    // Check if the string is exactly 6 characters long

    // Regular expression to match only digits 0-9
    const digitRegex = /^[0-9]{6}$/;

    // Test the string against the regex
    return digitRegex.test(p);
  }

  let checkPin = () => {
    setPinLoading(true);
    axios
      .post(
        `${serverURL}/check-pin`,
        {
          pin,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (response.data.valid) {
            setSelectedUserID(response.data.userID);
            setFName(response.data.fName);
            setLName(response.data.lName);
            setJobTitle(response.data.jobTitle);
            setProfPicUrl(response.data.profPicUrl);
            setTimeout(() => {
              if (!showClockOnModal) {
                setPin("");

                setSelectedUserID("");
                setFName("");
                setLName("");
                setJobTitle("");
                setProfPicUrl("");
              }
            }, 8000);
            if (!response.data.clockOnAllowed) {
              setClockOnNotAllowed(true);
            }
          } else {
            setInvalidPin(true);
            setPin("");
          }
          setTimeout(() => {
            setPinLoading(false);
          }, 1500);
        }
      });
  };
  const renderButton = (value) =>
    value === "go" ? (
      <button
        className={`keypadBtn keypadGo ${
          pin.length === 6 && isValidPin(pin) && !pinLoading ? "" : "invis"
        }`}
        onClick={() => {
          checkPin();
        }}
      >
        <img src={whiteTick} alt="Clock on go" className="terminalWhiteGo" />
      </button>
    ) : value === "cancel" ? (
      <button
        className={`keypadBtn keypadCanx ${pin ? "" : "invis"}`}
        onClick={() => {
          setPin("");
        }}
      >
        <img src={cancel} alt="Cancel" className="terminalWhiteCancel" />
      </button>
    ) : (
      <button
        className={`keypadBtn ${tappedNum} ${
          tappedNum === value ? "keypadBtnActive" : ""
        }`}
        onClick={() => handleKeyPress(value)}
      >
        {value}
      </button>
    );

  let [hideLink, setHideLink] = useState(false);
  let [emailInput, setEmailInput] = useState("");
  let [passInput, setPassInput] = useState("");
  let [wrongEmail, setWrongEmail] = useState(false);
  let [showPassword, setShowPassword] = useState(false);
  let [wrongPass, setWrongPass] = useState(false);
  function getDeviceName() {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      return "Android Device";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return "iOS Device";
    } else if (/Windows Phone/i.test(userAgent)) {
      return "Windows Phone";
    } else if (/Windows/i.test(userAgent)) {
      return "Windows PC";
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
      return "Macintosh";
    } else if (/Linux|X11/i.test(userAgent)) {
      return "Linux";
    } else {
      return "Unknown Device";
    }
  }

  const [ipAddress, setIpAddress] = useState("");
  let [location, setLocation] = useState("Unknown location");

  useEffect(() => {
    // var elements = document.getElementsByClassName("flowWidget");

    // if (elements) {
    //   for (var i = 0; i < elements.length; i++) {
    //     elements[i].style.visibility = "hidden";
    //   }
    // }
    // Function to fetch IP address

    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);

        const locationApi = await fetch(`https://ip-api.com/json/${data.ip}`);
        const data2 = await locationApi.json();

        setLocation(data2.city);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []);
  let [signInLoading, setSignInLoading] = useState(false);
  let enterFct = (e, btn) => {
    if (e.key === "Enter" || btn) {
      setSignInLoading(true);
      axios
        .post(
          `${serverURL}/signin`,
          {
            email: emailInput,
            password: passInput,
            client: "React",
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            deviceName: getDeviceName(),
            ip: ipAddress,
            location: location,
            terminal: true,
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          // console.log(response.data.email);
          axios
            .post(
              `${serverURL}/init-terminal`,
              {
                someData: 1,
              },
              {
                withCredentials: true,
                credentials: "include",
                // cancelToken: cancelSource1.token,
              }
            )
            .then((response) => {
              if (response.data.message === "not admin") {
                window.location.href = "/account";
              } else {
                setTerminalNameLogin(response.data.terminalNameLogin);
                setTerminalCamera(response.data.terminalCamera);
                setCompanyName(response.data.companyName);
                setUsers(response.data.users);
                setLoading(false);
              }
            })
            .catch((err) => {
              console.error(err);
            });
          setTimeout(() => {
            setSignInLoading(false);
          }, 2000);
          if (response.data.error === "incorrect email") {
            console.log("email not found");
            setWrongEmail(true);
            setWrongPass(false);
          } else {
          }
          if (response.data.error === "incorrect password") {
            console.log("wrong password");
            setWrongEmail(false);
            setWrongPass(true);
          } else if (response.data.loggedIn) {
            setSignedIn(true);
          }
        });
    }
  };

  // master
  return (
    <div className="clockTermBg">
      <img
        src={whiteLogo}
        alt="FlowRota white logo"
        className="frWhiteClockTermLogoImg"
      />
      <div
        className={`clockTermDiv ${loading ? "invis" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <div className="clockTermLeft">lefty</div> */}
        {/* <div className="clockTermSplit"></div> */}
        <div className="clockTermRight">
          {terminalNameLogin ? (
            <p className="clockTermRightInstruct">
              Select yourself from the list below to clock on or off.
            </p>
          ) : (
            <p className="clockTermRightInstruct">
              Enter your pin below. You can find your pin in your 'Profile' in
              the 'Dashboard' in the mobile app.
            </p>
          )}

          {terminalNameLogin ? (
            <select
              className="terminalNameSelect"
              value={selectedUserID}
              onChange={(e) => {
                let val = e.target.value;
                setSelectedUserID(val);
                let userFilt = users.filter((x) => {
                  return x.userID === val;
                });
                if (userFilt[0]) {
                  setFName(userFilt[0].fName);
                  setLName(userFilt[0].lName);
                  setProfPicUrl(userFilt[0].profPicUrl);
                  setJobTitle(userFilt[0].jobTitle);
                  if (!userFilt[0].clockOnAllowed) {
                    setClockOnNotAllowed(true);
                  }
                  setTimeout(() => {
                    if (!showClockOnModal) {
                      setPin("");

                      setSelectedUserID("");
                      setFName("");
                      setLName("");
                      setJobTitle("");
                      setProfPicUrl("");
                    }
                  }, 8000);
                }
              }}
            >
              <option value="">-- Employee</option>
              {users
                .sort(function (a, b) {
                  return a.fName.localeCompare(b.fName);
                })
                .map((user) => {
                  return (
                    <option key={user.userID} value={user.userID}>
                      {user.fName} {user.lName}
                    </option>
                  );
                })}
            </select>
          ) : (
            <input
              type="number"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  checkPin();
                }
              }}
              // type="number"
              className={`clockPinPreview ${
                pin.length === 6 && !isValidPin(pin) ? "invalidPin" : ""
              }`}
              value={pin}
              onChange={(e) => {
                if (e.target.value.length > 6) {
                } else {
                  setPin(e.target.value);
                }
                setSelectedUserID("");
              }}
            ></input>
          )}

          {selectedUserID && (
            <div className="terminalUserInfo">
              <img
                src={profPicUrl || profilePicturePlaceholder}
                alt={`${fName} ${lName}`}
                className="terminalProPic"
              />
              <div className="terminalUserNameJt">
                <p className="terminalUserName">
                  {fName} {lName}
                </p>
                <p className="terminalJt">{jobTitle}</p>
              </div>
            </div>
          )}
          {selectedUserID && (
            <div className="terminalNotYou">
              <p
                className="termNotYouBtn"
                onClick={() => {
                  setPin("");
                  setSelectedUserID("");
                }}
              >
                Not you?
              </p>
            </div>
          )}

          {terminalNameLogin && !selectedUserID && (
            <div className="termClockFlasher">{time}</div>
          )}
          {/* {selectedUserID && terminalCamera && camJsx} */}
          {pin.length === 6 && !isValidPin(pin) && (
            <p className="pinCharErr">Pin should only contain numbers</p>
          )}

          <video
            className={`terminalVid ${loading ? "invis" : ""} ${
              terminalNameLogin && terminalCamera && selectedUserID
                ? "terminalVidNameSelUser"
                : ""
            } ${!terminalCamera ? "none" : ""} ${
              !terminalNameLogin && !selectedUserID ? "none" : ""
            } ${
              !terminalNameLogin && selectedUserID
                ? "terminalVidPinSelUser"
                : ""
            }`}
            ref={videoRef}
            autoPlay
            playsInline
            style={{ width: "100%" }}
          />
          <canvas ref={canvasRef} style={{ display: "none" }} />
          {selectedUserID && (
            <div className="terminalClockOnRow">
              <p
                className={`termClockOnBtn ${
                  terminalNameLogin && selectedUserID
                    ? "termClockOnBtnName"
                    : ""
                }`}
                onClick={() => {
                  launch();
                }}
              >
                Launch clock{" "}
                <img src={popup} alt="Clock on popup" className="termPopup" />
              </p>
            </div>
          )}
          {!terminalNameLogin && !selectedUserID && (
            <div className="termKeypad">
              <div className="termKeypadRow">
                {renderButton(1)}
                {renderButton(2)}
                {renderButton(3)}
              </div>
              <div className="termKeypadRow">
                {renderButton(4)}
                {renderButton(5)}
                {renderButton(6)}
              </div>
              <div className="termKeypadRow">
                {renderButton(7)}
                {renderButton(8)}
                {renderButton(9)}
              </div>
              <div className="termKeypadRow">
                {renderButton("cancel")}
                {renderButton(0)}
                {renderButton("go")}
              </div>
            </div>
          )}
        </div>
      </div>
      <p className={`termCompName ${!companyName ? "invis" : ""}`}>
        {companyName}
      </p>

      {document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.msFullscreenEnabled ? (
        <p
          className={`termFullScreen ${
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
              ? "none"
              : ""
          }`}
          onClick={() => {
            const element = document.documentElement; // Get the root element

            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
              // For Firefox
              element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
              // For Chrome, Safari, and Opera
              element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
              // For IE/Edge
              element.msRequestFullscreen();
            }
          }}
        >
          Full screen{" "}
          <img src={fullScreen} alt="Full screen" className="fullScreenImg" />
        </p>
      ) : (
        ""
      )}
      {disabledCam ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setDisabledCam(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              Your device either has no camera, or you need to allow FlowRota
              permissions to use it.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setDisabledCam(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {clockOnNotAllowed ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setClockOnNotAllowed(false);
            setPin("");
            setSelectedUserID("");
            setFName("");
            setLName("");
            setProfPicUrl("");
            setJobTitle("");
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You are currently unable to clock on. <br />
              <br />
              Please ask a manager to enable your permissions to clock on.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setClockOnNotAllowed(false);
                  setPin("");
                  setSelectedUserID("");
                  setFName("");
                  setLName("");
                  setProfPicUrl("");
                  setJobTitle("");
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {invalidPin ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setInvalidPin(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              The PIN you entered doesn't match any account associated with
              FlowRota at {companyName}.<br />
              <br />
              Please verify your PIN on the dashboard in your mobile app.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setInvalidPin(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {!signedIn && (
        <div className="areYouSureModalUnderlay defaultCursor termSignInUnderlay">
          <div className="signUpContainer">
            <div className="signinUpper">
              <p className="signInTitle signInTitleTerminal">
                Terminal admin sign in
                <img
                  src={logo}
                  alt="FlowRota"
                  className="signInLogoImg signInLogoImgTerminal"
                />
              </p>
              {/* <img className="signUpLogo" alt="FlowRota logo" src={logoicon} /> */}
              <form className="signUpForm">
                <p className="signInInputTitle">Email</p>
                <input
                  type="email"
                  placeholder="email"
                  id="email"
                  name="email"
                  className="signinInput signUpEmailInput"
                  onKeyPress={(e) => {
                    enterFct(e, false);
                  }}
                  onChange={(e) => {
                    setEmailInput(e.target.value);
                    if (hideLink) {
                      setHideLink(false);
                    }
                  }}
                />
                <br />
                <p className={`${wrongEmail ? "wrongSignInPass" : "none"}`}>
                  Hmm, that email isn't registered...
                </p>
                <p className="signInInputTitle x20483085">Password</p>
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  id="password"
                  placeholder="password"
                  name="password"
                  onKeyPress={(e) => {
                    enterFct(e, false);
                  }}
                  onChange={(e) => {
                    setPassInput(e.target.value);
                  }}
                ></input>
                <img
                  className="showPassword"
                  src={eye}
                  alt="show password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />{" "}
                <br />
                <p className={`${wrongPass ? "wrongSignInPass" : "none"}`}>
                  Wrong password
                </p>
                <div className="forgotContainer">
                  <NavLink to="/reset-password">
                    <p className="forgotPass">Forgotten password&nbsp;&nbsp;</p>
                  </NavLink>
                </div>
                <br /> <br />
                {/* <NavLink to='/account-admin-general'> */}{" "}
                <p
                  className={`signinBtn2 ${signInLoading ? "disable" : ""}`}
                  onClick={(event) => {
                    enterFct("", true);
                  }}
                >
                  Sign in
                </p>
                {/* </NavLink> */}
                <br />
                <p
                  className={`emailLogInLinkBtn ${
                    emailInput &&
                    emailInput.includes("@") &&
                    emailInput.includes(".") &&
                    emailInput.length < 999
                      ? ""
                      : "invis"
                  } ${hideLink ? "invis" : ""}`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/request-email-login-link`,
                        {
                          email: emailInput,

                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          deviceName: getDeviceName(),
                          ip: ipAddress,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (
                          response.data.message.includes("found") ||
                          response.data.message.includes("invalid")
                        ) {
                          setWrongEmail(true);
                        }
                        if (response.data.message === "success") {
                          setShowCheckEmail(true);
                          setHideLink(true);
                        }
                      });
                  }}
                >
                  Email me a one-click login link{" "}
                </p>
              </form>
            </div>
            <div className="signinLower">
              <div className="signinDivider"></div>
              <div className="signinFooter">
                <p></p>
                <a
                  href="https://flowrota.com/privacy"
                  className="signInPrivacy"
                  target="_blank"
                >
                  Privacy & Terms
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
      )}

      <Navbar terminal={true} />
    </div>
  );
};

export default ClockTerminal;
