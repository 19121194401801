// CSS for this modal is in calendar.css

// use props
// show modal with showEditMyShiftModal stored in CalendarContext

import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// clean not needed
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import DatePicker from "react-datepicker";

import { DataContext } from "../../../contexts/DataContext";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";

import { NavLink } from "react-router-dom";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";
import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import close from "../../../img/modals/close.svg";
import shiftChangeArrow from "../../../img/general/shiftChangeArrow.svg";
import blueLoader from "../../../img/general/blueLoader.gif";
import arr from "../../../img/general/toilConvertArr.svg";

import dateStringer, { tsToHHMM } from "../../../tools/dateStringer";

// type = til, overtime, shift
const ToilConversionModal = ({ userID }) => {
  const { mob, mobModal } = useContext(StyleContext);
  const { setShowTilTrimModal, setUpdateTilData, updateTilData } =
    useContext(CalendarContext);
  const {
    setTilReqMade,
    tilReqMade,
    showConvertToilModal,
    setShowConvertToilModal,
    setIndicate,
    setUpdateSingleUserAllowances,
    modalOpen,
    setModalOpen,
    updatedDashData,
    setUpdatedDashData,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      mob,
      mobModal,
      showConvertToilModal,
      setShowConvertToilModal,

      setTilReqMade,
      tilReqMade,
      showConvertToilModal,
      setShowConvertToilModal,
      modalOpen,
      setModalOpen,
      updatedDashData,
      setUpdatedDashData,
      device,
    }),
    [
      mob, //
      mobModal, //
      showConvertToilModal,
      setShowConvertToilModal,

      setTilReqMade, //
      tilReqMade, //
      showConvertToilModal,
      setShowConvertToilModal,
      modalOpen,
      setModalOpen,
      updatedDashData,
      setUpdatedDashData,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  let [toilToLeave, setToilToLeave] = useState(true);
  let [toilBalance, setToilBalance] = useState(0);
  let [remainingAnnLeaveMins, setRemainingAnnLeaveMins] = useState(0);
  let [dailyMins, setDailyMins] = useState(0);
  let [fName, setFName] = useState("");
  let [minsToConvert, setMinsToConvert] = useState(0);
  let [showWarnModal, setShowWarnModal] = useState({
    show: false,
    notEnoughToil: false,
  });

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 3) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={shiftChangeArrow}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={blueLoader}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    setDataLoaded(true);
    axios
      .post(
        `${serverURL}/get-user-toil-conversion-data`,
        {
          userID,
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          // setYearStartDs(response.data.forceStartDs);
          // setYearEndDs(response.data.forceEndDs);

          // setAccruedTil(response.data.tilMinsWorked);
          // setTakenTil(response.data.tilTaken);
          setFName(response.data.fName);
          setDataLoaded(true);
          setToilBalance(response.data.toilBalance);
          setRemainingAnnLeaveMins(response.data.remainingAnnLeaveMins);
          setDailyMins(response.data.dailyMins);
          if (response.data.dailyMins) {
            if (response.data.toilBalance < 0) {
              setMinsToConvert(0);
            } else {
              if (response.data.dailyMins <= response.data.toilBalance) {
                setMinsToConvert(Math.round(response.data.dailyMins));
              } else {
                setMinsToConvert(Math.round(response.data.toilBalance || 0));
              }
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowConvertToilModal((x) => "");
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className={`editShiftRequestModalUnderlay`}
      onClick={(e) => {
        e.stopPropagation();
        closeModal();
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`editShiftRequestModalBox ${
            memoVals.mobModal ? "mobModalShoulder" : ""
          } ${memoVals.device.ios ? "paddingBottom20" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperTilTrim"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`toilHeader `}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            {/* <div
            className="editMyShiftCloseHolder"
            onClick={() => {
              closeModal();
            }}
          > */}
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            {/* </div> */}
            <p
              className={`toilHeaderTxt ${!fName ? "invis" : ""} ${
                fName.length > 10 ? "toilHeaderTxtSmaller" : ""
              }`}
              onClick={() => {
                if (memoVals.mobModal) {
                  closeModal();
                }
              }}
            >
              Convert {dateStringer.possession(fName)} TOIL
            </p>
            {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
          </div>

          {/* <p className="toilInstructInfo">
            Convert TOIL to annual leave or vice-versa
          </p> */}

          <div className={`convertSection ${loading ? "invis" : ""}`}>
            <p className="convertOptionTitle">Convert</p>
            <div className={`formKey convertKey`}>
              <div
                className={`formKeyTab ${
                  toilToLeave ? "" : "formKeyTabUnselected"
                }
              `}
                onClick={() => {
                  if (!toilToLeave) {
                    setToilToLeave(true);
                  }
                }}
              >
                <p className={`formTabTitle convertKeyTitle2`}>
                  TOIL to annual leave
                </p>
                <div
                  className={`formTabSel ${!toilToLeave ? "invis" : ""}`}
                ></div>
              </div>
              <p
                className={`formKeyTab ${
                  toilToLeave ? "formKeyTabUnselected" : ""
                }`}
                onClick={() => {
                  if (toilToLeave) {
                    setToilToLeave(false);
                  }
                }}
              >
                {" "}
                <p className={`formTabTitle convertKeyTitle2`}>
                  Annual leave to TOIL
                </p>
                <div
                  className={`formTabSel  ${toilToLeave ? "invis" : ""}`}
                ></div>
              </p>
            </div>
          </div>
          {/* {toilToLeave ? (
            <p
              className={`unclocksExplainer x238929842122 ${
                !fName ? "invis" : ""
              }`}
            >
              TOIL can only be used on scheduled shifts whereas annual leave can
              be taken without scheduled shifts. Use this tool to extend{" "}
              {dateStringer.possession(fName)} annual leave allowance by
              deducting from their TOIL balance.{" "}
            </p>
          ) : (
            <p
              className={`unclocksExplainer x238929842122 ${
                !fName ? "invis" : ""
              }`}
            >
              TOIL can only be used on scheduled shifts whereas annual leave can
              be taken without scheduled shifts. Use this tool to increase{" "}
              {dateStringer.possession(fName)} TOIL balance by deducting from
              their annual leave allowance.{" "}
            </p>
          )} */}
          <div className={`editMyShiftRow `}>
            <p className="toilRowTitle">TOIL balance</p>

            <p
              className={`takeTilDsTxt ${
                (toilToLeave &&
                  Math.round(minsToConvert) > Math.round(toilBalance)) ||
                toilBalance === 0
                  ? toilToLeave
                    ? "colourRed"
                    : ""
                  : ""
              } ${!fName ? "invis" : ""}`}
            >
              {dateStringer.formatMinsDurationToHours(toilBalance, false, true)}
              {toilBalance > 60 ? <br /> : ""}
              {toilBalance > 60 ? (
                <span className={`convertToilAnnLeaveDaysSub`}>
                  {Math.round(toilBalance)} mins
                </span>
              ) : (
                ""
              )}
            </p>
          </div>

          <div className={`editMyShiftRow`}>
            <p className="toilRowTitle">Annual leave remaining</p>

            <p
              className={`takeTilDsTxt ${
                !toilToLeave &&
                Math.round(minsToConvert) > Math.round(remainingAnnLeaveMins)
                  ? "colourRed"
                  : ""
              }`}
            >
              {dateStringer.formatMinsDurationToHours(
                remainingAnnLeaveMins,
                false,
                true
              )}
              {dailyMins > 0 && <br />}
              {dailyMins > 0 && (
                <span
                  className={`convertToilAnnLeaveDaysSub ${
                    !toilToLeave &&
                    Math.round(minsToConvert) >
                      Math.round(remainingAnnLeaveMins)
                      ? "colourRed"
                      : ""
                  }`}
                >
                  {dateStringer.convertMinsToDays(
                    remainingAnnLeaveMins,
                    dailyMins,
                    true
                  )}
                </span>
              )}
            </p>
          </div>
          <div className={`editMyShiftRow noBorderBottom`}>
            <p className="toilRowTitle">
              {dateStringer.possession(fName)} daily workable hours
              <br />
              <span className={`convertToilAnnLeaveDaysSub`}>(average)</span>
            </p>

            <p className={`takeTilDsTxt `}>
              {dateStringer.formatMinsDurationToHours(dailyMins)}
            </p>
          </div>

          {toilToLeave ? (
            <div className="editMyShiftRow highlightedTilRow1">
              <p className="toilRowTitle weight600 fromToToilTitle fromToToilTitleTOIL">
                From TOIL balance
              </p>
              <div className="toilConvertInputDiv">
                <div className="toilConvertInputDivBtns">
                  <input
                    className={`shiftTimeInput tilTrimTimeInput tilConvertInput `}
                    type="number"
                    id="toilStart"
                    //   defaultValue={ }
                    value={minsToConvert}
                    onChange={(e) => {
                      let val = parseInt(e.target.value);

                      // if (val <= toilBalance) {
                      if (val > 99999) {
                        setMinsToConvert(99999);
                      } else {
                        if (val < 0) {
                          setMinsToConvert(0);
                        } else {
                          setMinsToConvert(val);
                        }
                      }
                    }}
                  />
                  <p className="tilConvMins">mins</p>
                </div>

                {/* {dailyMins > 0 && ( */}
                <p
                  className={`toilconvertPreviewDaySpan ${
                    dailyMins > 0 ? "" : "invis"
                  }`}
                >
                  (
                  {dateStringer
                    .convertMinsToDays(minsToConvert, dailyMins, true)
                    .includes("minute")
                    ? dateStringer.formatMinsDurationToHours(minsToConvert)
                    : dateStringer.convertMinsToDays(
                        minsToConvert,
                        dailyMins,
                        true
                      )}
                  )
                </p>
                {/* )} */}
              </div>
            </div>
          ) : (
            <div className="editMyShiftRow highlightedTilRow1">
              <p className="toilRowTitle weight600 fromToToilTitle fromToToilTitleTOIL">
                From annual leave allowance
              </p>
              <div className="toilConvertInputDiv">
                <div className="toilConvertInputDivBtns">
                  <input
                    className={`shiftTimeInput tilTrimTimeInput tilConvertInput `}
                    type="number"
                    id="toilStart"
                    //   defaultValue={ }
                    value={minsToConvert}
                    onChange={(e) => {
                      let val = parseInt(e.target.value);

                      if (val > 99999) {
                        setMinsToConvert(99999);
                      } else {
                        if (val < 0) {
                          setMinsToConvert(0);
                        } else {
                          setMinsToConvert(val);
                        }
                      }
                    }}
                  />
                  <p className="tilConvMins">mins</p>
                </div>

                {/* <p className={`toilconvertPreviewDaySpan invis`}>.</p> */}

                <p
                  className={`toilconvertPreviewDaySpan ${
                    dailyMins > 0 ? "" : "invis"
                  }`}
                >
                  {" "}
                  (
                  {dateStringer
                    .convertMinsToDays(minsToConvert, dailyMins, true)
                    .includes("minute")
                    ? dateStringer.formatMinsDurationToHours(minsToConvert)
                    : dateStringer.convertMinsToDays(
                        minsToConvert,
                        dailyMins,
                        true
                      )}
                  )
                </p>
              </div>
            </div>
          )}
          {/* ARROW HERE */}
          <div className="toilConvRowMid">
            <img
              src={arr}
              alt="Conversion direction"
              className="convertArrImg"
            />
          </div>
          <div className="editMyShiftRow highlightedTilRow2">
            <p className="toilRowTitle weight600 fromToToilTitle">
              {toilToLeave
                ? "New remaining annual leave allowance:"
                : "New TOIL balance:"}
            </p>

            <p className="toiConvertNewBalance">
              {toilToLeave
                ? dateStringer.formatMinsDurationToHours(
                    remainingAnnLeaveMins + (minsToConvert || 0)
                  )
                : dateStringer.formatMinsDurationToHours(
                    toilBalance + (minsToConvert || 0)
                  )}
            </p>
          </div>

          <div className="toilFooter">
            <div className="tilFooterLeft">
              <div className="submitContainerEditMyShift">
                <p
                  className={`${loading ? "none" : `requestEditMyShiftBtn`} ${
                    (toilToLeave && minsToConvert > toilBalance) ||
                    (toilToLeave && toilBalance === 0)
                      ? "disabled_"
                      : ""
                  } ${minsToConvert === 0 ? "disabled" : ""} ${
                    (!toilToLeave && minsToConvert > remainingAnnLeaveMins) ||
                    (!toilToLeave && remainingAnnLeaveMins === 0)
                      ? "disabled"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      (toilToLeave && minsToConvert > toilBalance) ||
                      (toilToLeave && toilBalance === 0)
                    ) {
                      setShowWarnModal({ show: true, notEnoughToil: true });
                    } else if (
                      (!toilToLeave && minsToConvert > remainingAnnLeaveMins) ||
                      (!toilToLeave && remainingAnnLeaveMins === 0)
                    ) {
                      setShowWarnModal({ show: true, notEnoughToil: false });
                    } else {
                      // convert here

                      axios
                        .post(
                          `${serverURL}/convert-toil-to-ann-leave`,
                          {
                            userID,
                            nowDs: dateStringer.createStringFromTimestamp(
                              new Date().getTime()
                            ),
                            toilToLeave,
                            minsToConvert,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setUpdateSingleUserAllowances(userID);
                            memoVals.setUpdatedDashData(
                              (x) => !memoVals.updatedDashData
                            );

                            setIndicate((x) => {
                              return {
                                show: true,
                                message: "Conversion successful",
                                colour: "green",
                                duration: 4000,
                              };
                            });
                            closeModal();
                          }
                        });
                    }
                  }}
                >
                  Convert
                </p>
                <img
                  src={blueLoader}
                  className={`${loading ? "blueLoaderEditMyShiftImg" : "none"}`}
                  alt="Loading"
                  onClick={() => {
                    setLoading(false);
                  }}
                />
              </div>
            </div>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {showWarnModal.show && (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {fName} does not have enough{" "}
              {showWarnModal.notEnoughToil ? "TOIL" : "annual leave"} for this
              conversion.
              <br />
              <br />
              Converting this TOIL to annual leave will give {fName} a negative
              balance.
              <br />
              <br />
              Are you sure you want to convert this TOIL?
            </p>

            {!showWarnModal.notEnoughToil ? (
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/convert-toil-to-ann-leave`,
                        {
                          userID,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          toilToLeave,
                          minsToConvert,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setUpdateSingleUserAllowances(userID);
                          memoVals.setUpdatedDashData(
                            (x) => !memoVals.updatedDashData
                          );

                          setIndicate((x) => {
                            return {
                              show: true,
                              message: "Conversion successful",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          closeModal();
                        }
                      });
                  }}
                >
                  Yes
                </p>
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setShowWarnModal({ show: false });
                  }}
                >
                  Cancel
                </p>
              </div>
            ) : (
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setShowWarnModal({ show: false });
                  }}
                >
                  Understood
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default ToilConversionModal;
