import React, { useState, useEffect, useContext, useMemo } from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

// css is in editUser.css

import axios from "axios";
import serverURL from "../../../../serverURL";
import dateStringer, {
  formatMinsDurationToHours,
} from "../../../../tools/dateStringer";

import horizontalBalls from "../../../../img/loaders/horizontalBalls.svg";
import editGrey from "../../../../img/general/editGrey.svg";
import bin1 from "../../../../img/general/bin1.svg";
import whitePlus from "../../../../img/general/whitePlus.svg";
import close from "../../../../img/modals/close.svg";

import { DataContext } from "../../../../contexts/DataContext";
import { AdminContext } from "../../../../contexts/AdminContext";
import { StyleContext } from "../../../../contexts/StyleContext";

const MpwTimelineInlet = ({ userID, year, fName, editUserState }) => {
  const {
    setMpwTimelineConflicts,
    showMpwTimeline,
    setShowMpwTimeline,
    device,
  } = useContext(DataContext);
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setMpwTimelineConflicts,
      showMpwTimeline,
      setShowMpwTimeline,
      mob,
      device,
    }),
    [setMpwTimelineConflicts, showMpwTimeline, setShowMpwTimeline, mob, device]
  );

  let [mpwTimelineLoading, setMpwTimelineLoading] = useState(true);
  let [mpwTimeline, setMpwTimeline] = useState([]);
  let [currMpw, setCurrMpw] = useState(0);
  let [lastDs, setLastDs] = useState("");

  let [tempHourlyRate, setTempHourlyRate] = useState(0);

  let [overlapIDs, setOverlapsIDs] = useState([]);

  useEffect(() => {
    let endTsArr = [];
    mpwTimeline.forEach((mpw) => {
      endTsArr.push(dateStringer.createTimestampFromString(mpw.end));
    });
    endTsArr.sort(function (a, b) {
      return b - a;
    });
    setLastDs(
      dateStringer.createStringFromTimestamp(
        endTsArr[0] + 1000 * 60 * 60 * 20.5
      )
    );
  }, [mpwTimeline]);

  useEffect(() => {
    setMpwTimelineLoading(true);
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-mpw-timeline-by-year`,
        { userID: userID, year: "all" },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setMpwTimeline(response.data.timeline);
          let conflict = false;
          let overlaps = [];
          response.data.timeline.forEach((mpw) => {
            let start = dateStringer.createTimestampFromString(mpw.start);
            let end = dateStringer.createTimestampFromString(mpw.end);

            if (start > end) {
              conflict = true;
            }

            response.data.timeline.forEach((mpw2) => {
              if (mpw2.mpwID !== mpw.mpwID) {
                let start2 = dateStringer.createTimestampFromString(mpw2.start);
                let end2 = dateStringer.createTimestampFromString(mpw2.end);

                if (
                  (start >= start2 && start <= end2) ||
                  (end <= end2 && end >= start2)
                ) {
                  console.log(mpw2);
                  if (!overlaps.includes(mpw2.mpwID)) {
                    overlaps.push(mpw2.mpwID);
                  }
                }
              }
            });
          });
          setOverlapsIDs(overlaps);
          console.log("overlaps: ", overlaps);
          if (conflict || overlaps[0]) {
            memoVals.setMpwTimelineConflicts(true);
          } else {
            memoVals.setMpwTimelineConflicts(false);
          }
          setMpwTimelineLoading(false);
          setCreated(response.data.created);
          setCurrMpw(response.data.currMpw);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [selectedMpw, setSelectedMpw] = useState("");
  let [editTimelineObjArr, setEditTimelineObjArr] = useState([]);
  let [editTimelineStart, setEditTimelineStart] = useState("");
  let [editTimelineEnd, setEditTimelineEnd] = useState("");
  let [startValid, setStartValid] = useState(true);
  let [endValid, setEndValid] = useState(true);
  let [startDateIsAfterWarning, setStartDateIsAfterWarning] = useState(false);
  let [editTimelineHourlyRate, setEditTimelineHourlyRate] = useState();

  let [tempStart, setTempStart] = useState("");
  let [tempEnd, setTempEnd] = useState("");
  let [tempRate, setTempRate] = useState(0);
  let [sureDeletePeriodObjID, setSureDeletePeriodObjID] = useState("");

  let [created, setCreated] = useState(0);

  let [sureRemove, setSureRemove] = useState("");

  let [loadingSave, setLoadingSave] = useState(false);

  let [sureRemoveAllPeriods, setSureRemoveAllPeriods] = useState(false);
  let [sureRemoveAllPeriods2, setSureRemoveAllPeriods2] = useState(false);

  mpwTimeline.sort(function (a, b) {
    return (
      dateStringer.createTimestampFromString(b.start) -
      dateStringer.createTimestampFromString(a.start)
    );
  });

  let generateTimeline = mpwTimeline.map((obj) => {
    return (
      <div key={obj.mpwID} className="mpwUnit">
        {obj.initial && (
          <div className="initMpw">Before {fName} was added to FlowRota:</div>
        )}

        <p
          className={`${
            overlapIDs.includes(obj.mpwID) ? "overlapsMpwUnitTxt" : "none"
          }`}
        >
          Overlaps another period
        </p>
        <div
          className={`hourlyRateTimelineUnit ${
            selectedMpw === obj.mpwID ? "selectedHourlyRateTimelineUnit" : ""
          } ${
            loadingSave && selectedMpw === obj.mpwID ? "opac4LoadingSave" : ""
          } ${
            overlapIDs.includes(obj.mpwID) ||
            dateStringer.createTimestampFromString(obj.start) >
              dateStringer.createTimestampFromString(obj.end)
              ? "redBorderMpwUnit"
              : ""
          }`}
          onClick={() => {}}
        >
          <div
            className={`${
              selectedMpw === obj.mpwID
                ? "none"
                : "hourlyRateTimeinlineUnitContent"
            }`}
            onClick={() => {
              console.log(obj);
            }}
          >
            <div className="hourlyRateTimelineLeftSide">
              <p className="hourlyRateLeftSideTxt">
                <span
                  className={`${
                    dateStringer.createTimestampFromString(obj.start) >
                    dateStringer.createTimestampFromString(obj.end)
                      ? "startAfterEndMpwUnitSpanStart"
                      : ""
                  }`}
                >
                  {dateStringer.createShortStripFromDateString(obj.start)}
                </span>{" "}
                <span className="spanHourlyRateTimelineToSpan">to</span>{" "}
                {dateStringer.createShortStripFromDateString(obj.end)}
              </p>
              <div className="hourlyRateLeftDivider"></div>
              <p className="hourlyRateLeftSideTxt">
                {dateStringer.formatMinsDurationToHours(obj.mpw)}
                <span className="spanHourlyRateTimelineToSpan">
                  &nbsp;per week
                </span>
              </p>
            </div>

            <div className="hourlyRateTimelineRightSide">
              <img
                src={editGrey}
                alt="edit"
                className="editHourlyRateTimelineImg"
                onClick={() => {
                  setSureRemove("");
                  setSelectedMpw(obj.mpwID);
                  setEditTimelineStart("");
                  setSureDeletePeriodObjID("");
                  // setEditTimelineEnd("");
                  // setSelectedMpw(obj.mpwID);
                  let dateInputStringStart =
                    dateStringer.createDateInputFromDateString(obj.start);
                  setTempStart(dateInputStringStart);
                  setEditTimelineStart(obj.start);

                  let dateInputStringEnd =
                    dateStringer.createDateInputFromDateString(obj.end);
                  setTempEnd(dateInputStringEnd);
                  setTempRate(obj.mpw);
                  setEditTimelineHourlyRate(obj.mpw);
                  setEditTimelineEnd(obj.end);
                }}
              />
            </div>
          </div>

          {/* selected to edit */}
          <div
            className={`${
              selectedMpw === obj.mpwID
                ? "hourlyRateTimeinlineUnitContent"
                : "none"
            }`}
            onClick={() => {
              console.log(obj);
            }}
          >
            <div className="hourlyRateTimelineLeftSideSelected">
              <div className="hourlyRateSelectedRow">
                <p className="hourlyRateFromToTxt">From</p>
                <input
                  className={`${
                    selectedMpw === obj.mpwID
                      ? `${
                          startValid
                            ? "editPeriodDateInput editPeriodValid"
                            : "editPeriodDateInput"
                        }`
                      : "none"
                  }`}
                  type="date"
                  value={tempStart}
                  onChange={(e) => {
                    if (!obj.initial) {
                      setTempStart(e.target.value);

                      if (
                        e.target.value.length === 10 &&
                        e.target.value[0] === "2"
                      ) {
                        setStartValid(true);

                        setEditTimelineStart(
                          dateStringer.createStringFromDateInput(e.target.value)
                        );
                      } else {
                        setStartValid(false);
                      }
                    }
                  }}
                ></input>
              </div>
              <div className="hourlyRateLeftDivider"></div>
              <div className="hourlyRateSelectedRow">
                <p className="hourlyRateFromToTxt">until</p>

                <input
                  className={`${
                    selectedMpw === obj.mpwID
                      ? `${
                          endValid
                            ? "editPeriodDateInput editPeriodValid editPeriodSpaceUnder"
                            : "editPeriodDateInput editPeriodSpaceUnder"
                        }`
                      : "none"
                  }`}
                  type="date"
                  value={tempEnd}
                  onChange={(e) => {
                    if (!obj.initial) {
                      setTempEnd(e.target.value);
                      console.log(e.target.value);

                      if (
                        e.target.value.length === 10 &&
                        e.target.value[0] === "2"
                      ) {
                        setEndValid(true);

                        setEditTimelineEnd(
                          `${dateStringer
                            .createStringFromDateInput(e.target.value)
                            .substr(0, 11)}H23M59`
                        );

                        let startTs =
                          dateStringer.createTimestampFromString(
                            editTimelineStart
                          );
                        let endTs = dateStringer.createTimestampFromString(
                          dateStringer.createStringFromDateInput(e.target.value)
                        );

                        if (startTs > endTs) {
                          setStartDateIsAfterWarning(true);
                        } else {
                          setStartDateIsAfterWarning(false);
                        }

                        // setStartDateIsAfterWarning(false);
                      } else {
                        setEndValid(false);
                      }
                    }
                  }}
                ></input>
              </div>
              <div className="hourlyRateLeftDivider"></div>
              <div className="hourlyRateSelectedRowRateContainer">
                <input
                  type="number"
                  id="hourlyRateInput"
                  value={tempRate}
                  className="hourlyRateInput
                  editHourlyRate mpwInputTimelineObj"
                  // value={tempHourlyRate}
                  onChange={(e) => {
                    setTempRate(e.target.value);
                    setEditTimelineHourlyRate(e.target.value);
                  }}
                ></input>
                <p className="spanHourlyRateTimelineToSpanEdit mpwMinsPerWeekTxt">
                  mins per week <br />
                  <span className="minsPerWeekMpwSpan">
                    (
                    {dateStringer.formatMinsDurationToHours(
                      editTimelineHourlyRate
                    )}
                    )
                  </span>
                </p>
              </div>
            </div>

            <div className="hourlyRateTimelineRightSide">
              <div className="selectedHourlyRateContainer">
                <img
                  src={bin1}
                  alt="Remove"
                  className={`binCircleTimelineImg ${
                    obj.initial ? "none" : ""
                  }`}
                  onClick={() => {
                    console.log(
                      "startValid: ",
                      startValid,
                      "endValid: ",
                      endValid
                    );
                    if (!obj.initial) {
                      // make request
                      setLoadingSave(true);
                      axios
                        .post(
                          `${serverURL}/delete-user-mpw-timeline-object`,
                          {
                            userID: userID,
                            mpwID: obj.mpwID,
                            deleteAll: false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )

                        .then((response) => {
                          if (response.status === 200) {
                            setMpwTimeline(response.data.timeline);
                            setLoadingSave(false);
                            let conflict = false;
                            let overlaps = [];
                            response.data.timeline.forEach((mpw) => {
                              let start =
                                dateStringer.createTimestampFromString(
                                  mpw.start
                                );
                              let end = dateStringer.createTimestampFromString(
                                mpw.end
                              );

                              if (start > end) {
                                conflict = true;
                              }

                              response.data.timeline.forEach((mpw2) => {
                                if (mpw2.mpwID !== mpw.mpwID) {
                                  let start2 =
                                    dateStringer.createTimestampFromString(
                                      mpw2.start
                                    );
                                  let end2 =
                                    dateStringer.createTimestampFromString(
                                      mpw2.end
                                    );

                                  if (
                                    (start >= start2 && start <= end2) ||
                                    (end <= end2 && end >= start2)
                                  ) {
                                    console.log(mpw2);
                                    if (!overlaps.includes(mpw2.mpwID)) {
                                      overlaps.push(mpw2.mpwID);
                                    }
                                  }
                                }
                              });
                            });

                            setOverlapsIDs(overlaps);

                            if (conflict || overlaps[0]) {
                              memoVals.setMpwTimelineConflicts(true);
                            } else {
                              memoVals.setMpwTimelineConflicts(false);
                            }
                            setSelectedMpw("");
                            setCurrMpw(response.data.currMpw);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                  }}
                />

                <p
                  // src={saveDayHours}
                  // alt="Save"
                  className={`${
                    startValid && endValid && !startDateIsAfterWarning
                      ? "saveEditUserValBtn"
                      : "saveEditUserValBtn invalidDs"
                  }`}
                  onClick={() => {
                    console.log("firing req");
                    setLoadingSave(true);

                    if (startValid && endValid) {
                      let timelineObj = {
                        start:
                          editTimelineStart ||
                          dateStringer.createStringFromDateInput(tempStart),
                        end:
                          editTimelineEnd ||
                          dateStringer.createStringFromDateInput(tempEnd),
                        mpw:
                          parseInt(editTimelineHourlyRate) ||
                          parseInt(tempRate),
                        mpwID: obj.mpwID,
                        added: obj.added,
                        initial: obj.initial,
                      };

                      // make request
                      axios
                        .post(
                          `${serverURL}/update-user-mpw-timeline-object`,
                          {
                            userID: userID,
                            data: timelineObj,
                            mpwID: obj.mpwID,
                            newObj: obj.new,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.status === 200) {
                            setMpwTimeline(response.data.timeline);
                            setLoadingSave(false);
                            let conflict = false;
                            let overlaps = [];
                            response.data.timeline.forEach((mpw) => {
                              let start =
                                dateStringer.createTimestampFromString(
                                  mpw.start
                                );
                              let end = dateStringer.createTimestampFromString(
                                mpw.end
                              );

                              if (start > end) {
                                conflict = true;
                              }

                              response.data.timeline.forEach((mpw2) => {
                                if (mpw2.mpwID !== mpw.mpwID) {
                                  let start2 =
                                    dateStringer.createTimestampFromString(
                                      mpw2.start
                                    );
                                  let end2 =
                                    dateStringer.createTimestampFromString(
                                      mpw2.end
                                    );

                                  if (
                                    (start >= start2 && start <= end2) ||
                                    (end <= end2 && end >= start2)
                                  ) {
                                    console.log(mpw2);
                                    if (!overlaps.includes(mpw2.mpwID)) {
                                      overlaps.push(mpw2.mpwID);
                                    }
                                  }
                                }
                              });
                            });

                            setOverlapsIDs(overlaps);

                            if (conflict || overlaps[0]) {
                              memoVals.setMpwTimelineConflicts(true);
                            } else {
                              memoVals.setMpwTimelineConflicts(false);
                            }
                            setSelectedMpw("");
                            setCurrMpw(response.data.currMpw);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }
                    // });
                  }}
                >
                  Save
                </p>

                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          {/* end of selected to edit */}
        </div>
        <p
          className={`${
            dateStringer.createTimestampFromString(obj.start) >
            dateStringer.createTimestampFromString(obj.end)
              ? "endDateAfterStartMpwUnit"
              : "none"
          }`}
        >
          Start date is after end date
        </p>
        <p className={`${obj.initial ? "showCreatedDs" : "none"}`}>
          {fName} was added to FlowRota on{" "}
          {dateStringer.printedDateWithYrFromDs(
            dateStringer.createStringFromTimestamp(created)
          )}
        </p>
      </div>
    );
  });
  return (
    <div
      className={`${memoVals.showMpwTimeline ? "mpwTimelineUnderlay" : "none"}`}
      onClick={(e) => {
        memoVals.setShowMpwTimeline((x) => false);
      }}
    >
      {/* class="hourlyRateTimelineContainer desktopHourlyRateTimeline" */}
      <div
        className={`${
          memoVals.mob
            ? `mpwTimelineModalBodyMob ${
                memoVals.device.ios ? "mobileHourlyRateTimelineIos" : ""
              } slideUp`
            : "mpwTimelineModalBody"
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img
          src={horizontalBalls}
          alt="Loading"
          className={`${
            mpwTimelineLoading ? "loadingMpwTimelineHorizBallsImg" : "none"
          }`}
        />
        <div className={`${mpwTimelineLoading ? "none" : "mpwTimelineHeader"}`}>
          <div className="hourlyRateTimelineHeaderSideUnit">
            <img
              src={close}
              alt="Close"
              className="hourlyRateTimelineCloseImg"
              onClick={() => {
                memoVals.setShowMpwTimeline((x) => false);
              }}
            />
          </div>
          <p className="hourlyRateModalTitle">Contracted hours timeline</p>
          {/* <div className="hourlyRateTimelineHeaderSideUnit"></div> */}
        </div>
        <div
          className={`${mpwTimelineLoading ? "none" : "timelineTopContainer"}`}
        >
          {/* - - -  */}

          <div className="addOrRemoveMpwPeriodContainer">
            <div className="hourlyRateTimeRemoveAllAddBtn">
              <p
                className={`removeTimeline ${
                  dateStringer.printedDateWithYrFromDs(lastDs)
                    ? ""
                    : "hideRemoveTimelineBtn invis"
                }`}
                onClick={() => {
                  setSureRemoveAllPeriods(true);
                }}
              >
                {sureRemoveAllPeriods ? (
                  <div
                    className="editUserShowMoreUnderlay"
                    onClick={() => {
                      setSureRemoveAllPeriods(false);
                    }}
                  >
                    <div
                      className="formCreatedModal zoomIn"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="overlapsRenTxt alignLeft">
                        Are you sure you want to remove all of{" "}
                        {dateStringer.possession(editUserState.editFName)}{" "}
                        historic contracted hours data?
                        <br />
                        <br />
                        This cannot be undone but you can re-enter add the data
                        manually. <br />
                        <br />
                        Without their historic data, FlowRota will use{" "}
                        {dateStringer.possession(editUserState.editFName)}{" "}
                        current contracted hours per week when calculating
                        historic hours.
                      </div>
                      <div className="areYouModalBtnsDiv">
                        <p
                          className="areYouSureModalYesBtn"
                          onClick={() => {
                            if (
                              !sureRemoveAllPeriods &&
                              !sureRemoveAllPeriods2
                            ) {
                              setSureRemoveAllPeriods(true);
                            }
                            if (
                              sureRemoveAllPeriods &&
                              !sureRemoveAllPeriods2
                            ) {
                              setSureRemoveAllPeriods2(true);
                            }
                            if (sureRemoveAllPeriods2 && sureRemoveAllPeriods) {
                              // make request
                              axios
                                .post(
                                  `${serverURL}/delete-user-mpw-timeline-object`,
                                  {
                                    userID: userID,
                                    mpwID: "",
                                    deleteAll: true,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )

                                .then((response) => {
                                  if (response.status === 200) {
                                    setMpwTimeline(response.data.timeline);
                                    setLoadingSave(false);
                                    let conflict = false;
                                    let overlaps = [];
                                    response.data.timeline.forEach((mpw) => {
                                      let start =
                                        dateStringer.createTimestampFromString(
                                          mpw.start
                                        );
                                      let end =
                                        dateStringer.createTimestampFromString(
                                          mpw.end
                                        );

                                      if (start > end) {
                                        conflict = true;
                                      }

                                      response.data.timeline.forEach((mpw2) => {
                                        if (mpw2.mpwID !== mpw.mpwID) {
                                          let start2 =
                                            dateStringer.createTimestampFromString(
                                              mpw2.start
                                            );
                                          let end2 =
                                            dateStringer.createTimestampFromString(
                                              mpw2.end
                                            );

                                          if (
                                            (start >= start2 &&
                                              start <= end2) ||
                                            (end <= end2 && end >= start2)
                                          ) {
                                            console.log(mpw2);
                                            if (
                                              !overlaps.includes(mpw2.mpwID)
                                            ) {
                                              overlaps.push(mpw2.mpwID);
                                            }
                                          }
                                        }
                                      });
                                    });

                                    setOverlapsIDs(overlaps);

                                    if (conflict || overlaps[0]) {
                                      memoVals.setMpwTimelineConflicts(true);
                                    } else {
                                      memoVals.setMpwTimelineConflicts(false);
                                    }
                                    setSelectedMpw("");
                                    setCurrMpw(response.data.currMpw);
                                    setSureRemoveAllPeriods(false);
                                    setSureRemoveAllPeriods2(false);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                          }}
                        >
                          Remove
                        </p>
                        <p
                          className="areYouModalNoBtn"
                          onClick={() => {
                            setSureRemoveAllPeriods(false);
                          }}
                        >
                          Cancel
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                Remove all
              </p>
              <div
                className={`hourRateTimelineHeaderDivider ${
                  dateStringer.printedDateWithYrFromDs(lastDs) ? "" : "invis"
                }`}
              ></div>
              <div
                className="addHourlyRateTimelineObject"
                onClick={() => {
                  // let newArr = [];
                  let today = new Date();
                  let addedDate = new Date();
                  today.setHours(0, 0, 0, 0);
                  let todayDateString = dateStringer.createStringFromTimestamp(
                    today.getTime()
                  );
                  let todayEnd = new Date();
                  todayEnd.setHours(23, 59, 0, 0);
                  let todayEndDateString =
                    dateStringer.createStringFromTimestamp(todayEnd.getTime());
                  let newObj = {
                    start: todayDateString,
                    end: todayEndDateString,
                    mpw: tempHourlyRate,
                    mpwID: Math.random() * Math.random(),
                    added: dateStringer.createStringFromTimestamp(
                      addedDate.getTime()
                    ),
                    new: true,
                  };
                  console.log(newObj);
                  setMpwTimeline([newObj, ...mpwTimeline]);
                  setSelectedMpw(newObj.mpwID);
                  setEditTimelineHourlyRate(0);
                }}
              >
                <p className="addHourlyRateObjTxtAdd">Add</p>
                <img
                  src={whitePlus}
                  className="whitePlusForAddingTimelineObj"
                  alt="Add period"
                />
              </div>
            </div>
          </div>
          {/* <p className='timelineTitle'> */}
          {/* Schedule history */}
          {/* Timeline of {fName}'s
            <br />
            contracted schedule */}
          {/* </p> */}
          <p className={`${mpwTimeline[0] ? "currMpwInfoTxt" : "none"}`}>
            {" "}
            <span className={`${mpwTimeline[0] ? "" : "none"}`}>
              From {dateStringer.printedDateWithYrFromDs(lastDs)},{" "}
            </span>
            {fName} is contracted for{" "}
            {dateStringer.formatMinsDurationToHours(currMpw)} per week.
            <br />
            <br />
            <span className={`${mpwTimeline[0] ? "" : "none"}`}>
              {fName}'s current contracted hours per week of{" "}
              {dateStringer.formatMinsDurationToHours(currMpw)} per week will be
              used for any gaps in dates below, so please ensure there are no
              gaps.
            </span>
            <br />
            <br />
          </p>
        </div>
        <div
          className={`${
            mpwTimelineLoading ? "none" : "hourlyRateTimelineScrollWrapper"
          }`}
        >
          <p className={`${!mpwTimeline[0] ? "fromOnwardsMpw" : "none"}`}>
            There is no schedule history for {fName}. They are currently
            contracted for {dateStringer.formatMinsDurationToHours(currMpw)} per
            week.
          </p>
          {generateTimeline}
        </div>
      </div>
    </div>
  );
};

export default MpwTimelineInlet;
