import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
  useMemo,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done

import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import zzz from "../../../img/general/zzz.svg";
import purpleZzz from "../../../img/general/purpleZzz.svg";

import whiteAdd from "../../../img/general/whiteAdd.svg";
import magniWhite from "../../../img/general/magniWhite.svg";
import sortByInline from "../../../img/general/sortByInline.svg";
import purplePlus from "../../../img/general/purplePlus.svg";
import purpleCross from "../../../img/general/purpleCross2.svg";

import whitecross from "../../../img/general/whitecross.svg";
import backToAllUsers from "../../../img/general/backToAllUsers.svg";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import profilePicturePlaceholder from "../../../img/general/profilePicturePlaceholder.svg";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import serverURL from "../../../serverURL";

// import the info modal

import AddNewUserModals from "./subcomponents/AddNewUserModals";
import dateStringer from "../../../tools/dateStringer";

const Users = () => {
  const { setAccNav, mob } = useContext(StyleContext);
  const {
    device,
    setShowEditUser,
    setShowAddUserModal,
    addNewUserDynamic,
    updateSingleUserInUsers,
    setUpdateSingleUserInUsers, //
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      setShowEditUser,
      setShowAddUserModal,
      addNewUserDynamic,
      updateSingleUserInUsers,
      setUpdateSingleUserInUsers,
      device,
    }),
    [
      setAccNav,
      mob,
      setShowEditUser,
      setShowAddUserModal,
      addNewUserDynamic,
      updateSingleUserInUsers,
      setUpdateSingleUserInUsers,
      device,
    ]
  );

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
  }, []);
  let [showTopBorder, setShowTopBorder] = useState(false);

  let [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(true);
  let [toggleFind, setToggleFind] = useState(false);
  let [sortBy, setSortBy] = useState("fName");
  let [findValue, setFindValue] = useState("");
  let [showDisabled, setShowDisabled] = useState(false);

  let findValueRef = useRef();
  let scrollRef = useRef();
  let [disabledUsers, setDisabledUsers] = useState([]);

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/employees";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [findValue, sortBy, showDisabled]);

  useEffect(() => {
    if (toggleFind && findValueRef) {
      // focus
      findValueRef.current.focus();
    }
  }, [toggleFind]);

  let [newUsers, setNewUsers] = useState([]);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    console.log("multi:::", params.multi);
    console.log("userIDs:::", params.userIDs);
    if (params.multi && params.userIDs) {
      let arr = params.userIDs.split("_");
      setNewUsers(arr);
      console.log({ arr });
      console.log("new users", { arr });
    }
  }, []);
  useEffect(() => {
    if ((!showDisabled && !users[0]) || (showDisabled && !disabledUsers[0])) {
      const cancelSource1 = axios.CancelToken.source();

      setLoading(true);
      axios
        .post(
          `${serverURL}/get-all-users`,
          {
            data: 1,
            archived: showDisabled,
            fromAdmin: true,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (showDisabled) {
              setDisabledUsers(
                response.data.usersArray.sort((a, b) =>
                  a.fName.localeCompare(b.fName)
                )
              );
            } else {
              setUsers(
                response.data.usersArray.sort((a, b) =>
                  a.fName.localeCompare(b.fName)
                )
              );
            }
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [showDisabled]);

  useEffect(() => {
    if (memoVals.updateSingleUserInUsers.userID) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-all-users`,
          {
            userID: memoVals.updateSingleUserInUsers.userID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setUpdateSingleUserInUsers((x) => {
              return { userID: "" };
            });

            let newArr = response.data.usersArray;

            users.forEach((user) => {
              if (user.userID !== memoVals.updateSingleUserInUsers.userID) {
                newArr.push(user);
              }
            });

            setSortBy("fName");
            setUsers(
              newArr.sort((a, b) => {
                return a.fName.localeCompare(b.fName);
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [memoVals.updateSingleUserInUsers]);

  useEffect(() => {
    if (memoVals.addNewUserDynamic.userID) {
      let newArr = [memoVals.addNewUserDynamic, ...users];

      newArr.sort((a, b) => {
        return a.fName.localeCompare(b.fName);
      });
      setSortBy("fName");
      setUsers(newArr);
    }
  }, [memoVals.addNewUserDynamic]);

  const { updateUserAdmin } = useContext(DataContext);

  if (sortBy === "fName") {
    users.sort((a, b) => a.fName.localeCompare(b.fName));
  }

  if (sortBy === "lName") {
    users.sort((a, b) => a.lName.localeCompare(b.lName));
  }

  if (sortBy === "startDate") {
    users.sort((a, b) => a.startTs - b.startTs);
  }

  if (sortBy === "jt") {
    users.sort((a, b) => a.jobTitle.localeCompare(b.jobTitle));
  }

  if (sortBy === "team") {
    users.sort((a, b) => a.teamName.localeCompare(b.teamName));
  }

  useEffect(() => {
    if (updateUserAdmin.userID) {
      let newArr = [];
      users.forEach((user) => {
        if (user.userID === updateUserAdmin.userID) {
          if (updateUserAdmin.fName) {
            let newObj = user;
            newObj["fName"] = updateUserAdmin.fName;
            newObj["lName"] = updateUserAdmin.lName;
            newObj["jobTitle"] = updateUserAdmin.jt;
            newObj["startDate"] = updateUserAdmin.startDate;
            newArr.push(newObj);
          }

          if (updateUserAdmin.proPicURL) {
            let newObj = user;
            newObj["profPicUrl"] = updateUserAdmin.proPicURL;

            newArr.push(newObj);
          }

          if (updateUserAdmin.primaryTeamID) {
            let teamsWithOutPrim =
              updateUserAdmin.teams && updateUserAdmin.teams[0]
                ? updateUserAdmin.teams.filter((item) => {
                    return item.teamID !== updateUserAdmin.primaryTeamID;
                  })
                : [];
            let newObj = user;

            newObj["otherTeams"] = teamsWithOutPrim;
            newObj["teamName"] = updateUserAdmin.primaryTeamName;
            newArr.push(newObj);
          }
        } else {
          newArr.push(user);
        }
      });

      setUsers(newArr);
    }
  }, [updateUserAdmin]);
  let generateUsers = users
    .sort((a, b) => {
      // Check if a and b are in newUsers
      const isNewUserA = newUsers.includes(a.userID);
      const isNewUserB = newUsers.includes(b.userID);

      // Sort new users first
      if (isNewUserA && !isNewUserB) {
        return -1;
      } else if (!isNewUserA && isNewUserB) {
        return 1;
      } else {
        // If both are new users or both are not new users, sort by fName alphabetically
        return a.fName.localeCompare(b.fName);
      }
    })
    .map((user, i) => {
      if ((showDisabled && user.removed) || (!showDisabled && !user.removed)) {
        let fullNameString = `${user.fName.toLowerCase()} ${user.lName.toLowerCase()}`;
        if (
          user.fName.toLowerCase().includes(findValue.toLowerCase()) ||
          user.lName.toLowerCase().includes(findValue.toLowerCase()) ||
          fullNameString.toLowerCase().includes(findValue.toLowerCase()) ||
          (user.employeeNumber || "")
            .toLowerCase()
            .includes(findValue.toLowerCase())
        ) {
          return (
            <Fragment key={i}>
              <div
                className={`adminUserItem ${
                  newUsers.includes(user.userID) ? "adminUserItemNew" : ""
                } ${user.removed ? "archivedAdminUserItem" : ""} ${
                  memoVals.mob && !showDisabled ? "adminUserItemMob" : ""
                }`}
                onClick={() => {
                  memoVals.setShowEditUser((x) => user.userID);
                }}
              >
                <div className="adminUserItemTop">
                  {newUsers.includes(user.userID) && (
                    <div className="newUserBlob">New</div>
                  )}
                  {user.profPicUrl ? (
                    <img
                      src={user.profPicUrl}
                      alt={user.fName}
                      className={`adminUserProfPic ${
                        memoVals.mob ? "adminUserProfPicMob" : ""
                      } ${user.removed ? "grayscale" : ""}`}
                    />
                  ) : (
                    <div
                      className={`adminUserInits ${
                        user.removed ? "adminInitsRemovedHeight" : ""
                      }`}
                    >
                      <img
                        src={profilePicturePlaceholder}
                        alt={user.fName}
                        className={`adminInitsPlaceholderImg ${
                          user.removed
                            ? "adminInitsRemovedHeightImg grayscale"
                            : ""
                        }`}
                      />
                    </div>
                  )}
                  <div
                    className={`adminUserTopNameAndJt ${
                      user.removed ? "x8281" : ""
                    }`}
                  >
                    <p
                      className={`adminUserName paddingLeft0 ${
                        user.removed ? "colourWhite_ pullArchNameUp" : ""
                      }`}
                    >
                      {user.fName} {user.lName}{" "}
                      {user.you ? (
                        <span className="adminUsrYouSpan">(You)</span>
                      ) : (
                        ""
                      )}
                    </p>
                    {user.removed ? (
                      <p className="adminUserJt">
                        Archived{" "}
                        {dateStringer.printedDateWithYrFromDs(user.removedDs)}
                      </p>
                    ) : (
                      <p className="adminUserJt">
                        {user.jobTitle || "No job title"}
                      </p>
                    )}
                    {memoVals.mob ? (
                      ""
                    ) : showDisabled ? (
                      <p className={`adminUserJt adminUserJoin none`}>
                        Archived{" "}
                        {dateStringer.printedDateWithYrFromDs(user.removedDs)}
                      </p>
                    ) : (
                      <p className={`adminUserJt adminUserJoin`}>
                        Joined{" "}
                        {user.startDate === "Unknown"
                          ? user.startDate
                          : dateStringer.printedDateWithYrFromDs(
                              user.startDate
                            )}
                      </p>
                    )}
                  </div>
                </div>
                <div className="adminUserMeta">
                  <p className="adminUserStaffNumTitle">
                    Staff number:{" "}
                    <span className="adminUserStaffNumVal">
                      {user.employeeNumber || "None set"}
                    </span>
                  </p>
                  <p className="adminUserStaffNumTitle">
                    Clock pin:{" "}
                    <span className="adminUserStaffNumVal">
                      {user.pin || "None set"}
                    </span>
                  </p>
                </div>
              </div>
            </Fragment>
          );
        }
      }
    });
  return (
    <Fragment>
      <img src={whitecross} alt="_" className="preloadCloseSort" />
      <img src={sortByInline} alt="_" className="preloadCloseSort" />
      <img src={backToAllUsers} alt="_" className="preloadCloseSort" />
      <div className="adminUsersParent">
        <div
          className={`adminUserKeyBar ${
            memoVals.mob ? "adminUserKeyBarMob" : ""
          }`}
        >
          <div className="adminUserKeyLeft">
            <div
              className={`adminAddUsrBtn ${memoVals.mob ? "blueColours" : ""} ${
                toggleFind ? "none" : ""
              } lilacColours colourWhite`}
              onClick={() => {
                setToggleFind(false);
                memoVals.setShowAddUserModal((x) => true);
              }}
            >
              Add employee
              <img
                src={memoVals.mob ? whiteAdd : whiteAdd}
                alt="Add new employee"
                className="addNewEmpIcon"
              />
            </div>
            <div
              className={`adminAddUsrBtn adminUsrSearchBtn ${
                memoVals.mob ? "adminAddUsrBtnMob" : "none"
              } ${memoVals.mob && toggleFind ? "x23089472" : ""}`}
              onClick={() => {
                if (toggleFind) {
                  setFindValue("");
                }
                setToggleFind(!toggleFind);
              }}
            >
              {memoVals.mob && toggleFind ? "" : "Search"}
              <img
                src={memoVals.mob && toggleFind ? whitecross : magniWhite}
                alt="Add new employee"
                className="addNewEmpIcon"
              />
            </div>
            <input
              type="text"
              className={`findValueInput ${
                memoVals.mob && !toggleFind ? "none" : ""
              }`}
              value={findValue}
              ref={findValueRef}
              onKeyDown={(e) => {
                if (e.key === "Escape" || e.key === "Enter") {
                  setToggleFind(false);
                  setFindValue("");
                }
              }}
              onChange={(e) => {
                console.log(e.target.value);
                setFindValue(e.target.value);
              }}
              placeholder="Find an employee"
            ></input>
          </div>
          <div className="adminUserKeyRight">
            <div
              className={`adminAddUsrBtn archiveAdminUsersBtn ${
                memoVals.mob ? "usersArchiveRadiusMob" : "usersArchiveRadius"
              } ${showDisabled ? "x293847872_" : ""}`}
              onClick={() => {
                setToggleFind(false);
                setShowDisabled(!showDisabled);
              }}
            >
              Archived
              <img
                src={
                  showDisabled
                    ? memoVals.mob
                      ? purpleCross
                      : whitecross
                    : memoVals.mob
                    ? purpleZzz
                    : zzz
                }
                alt="Add new employee"
                className={`addNewEmpIcon2 ${showDisabled ? "x239894" : ""}`}
              />
            </div>
          </div>
        </div>
        <div
          className={`AdminSettingsContainer ${
            memoVals.mob
              ? `AdminSettingsContainerMob ${
                  memoVals.device.ios ? "AdminSettingsContainerMobUsersIos" : ""
                } minWidth100vw adminUsersBoxHeightMob ${
                  showTopBorder ? "showUsersTopBorder" : ""
                }`
              : "maxWidth600px borderRightGrey adminUsersBoxHeight"
          }`}
          ref={scrollRef}
          onScroll={(e) => {
            if (memoVals.mob) {
              if (e.target.scrollTop > 0) {
                setShowTopBorder(true);
              } else {
                setShowTopBorder(false);
              }
            }
          }}
        >
          <div
            className={`adminBoxWidther ${
              memoVals.mob ? "adminBoxWidtherMob" : ""
            }`}
          >
            {!loading && showDisabled ? (
              <p
                className={`showDisabledTxt ${
                  memoVals.mob ? "showDisabledTxtMob" : ""
                }`}
              >
                You won't be charged for archived employees unless they are
                active for at least one whole day of the billing month.
              </p>
            ) : (
              ""
            )}
            {loading ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="loadingUsersAdmin"
              />
            ) : (
              generateUsers
            )}
            <br />
          </div>

          {/* <AddNewUserModals /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Users;
