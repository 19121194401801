import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import appURL from "../appURL";

import offline from "../img/general/offline.svg";

const Offline = () => {
  // MASTER RETURN
  return (
    <div className="fourContainer">
      <img src={offline} alt="Offline" className="fourImg" />
      <p className="fourTxt">You're offline</p>
      <p className="fourTxtSub">Come back when you're back online.</p>
      <NavLink to="/" className="fourCalBtn">
        Go to your calendar
      </NavLink>
    </div>
  );
};

export default Offline;
