import React, { useContext, useEffect, useState, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24
import { StyleContext } from "../contexts/StyleContext";
import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";
import { AdminContext } from "../contexts/AdminContext";
import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import navyClose from "../img/general/navycross.svg";
import map from "../img/general/map.svg";

import editUserEdit1 from "../img/general/editUserEdit1.svg";

const ViewZoneModal = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    setViewZoneModal,
    viewZoneModal,
    gpsArray,
    setGpsArray,
    setIndicate,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setViewZoneModal, //
      viewZoneModal, //
      gpsArray, //
      setGpsArray, //
      setIndicate, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setViewZoneModal, //
      viewZoneModal, //
      gpsArray, //
      setGpsArray, //
      setIndicate, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [adding, setAdding] = useState(memoVals.viewZoneModal === "add");

  let [name, setName] = useState("Zone location");
  let [lon, setLon] = useState(adding ? 0 : "");
  let [lat, setLat] = useState(adding ? 0 : "");
  let [toggleEditName, setToggleEditName] = useState(false);
  let [toggleEditLon, setToggleEditLon] = useState(false);
  let [toggleEditLat, setToggleEditLat] = useState(false);
  let [newLon, setNewLon] = useState();
  let [newLat, setNewLat] = useState();
  let [newName, setNewName] = useState("Zone location");

  let [lonValid, setLonValid] = useState(adding ? false : true);
  let [latValid, setLatValid] = useState(adding ? false : true);
  let [nameValid, setNameValid] = useState(adding ? false : true);

  useEffect(() => {
    // clean not needed
    // gps
    if (adding) {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      function success(pos) {
        const crd = pos.coords;

        console.log("Your current position is:");
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);

        setNewLon(crd.longitude);
        setLon(crd.longitude);

        setNewLat(crd.latitude);
        setLat(crd.latitude);
      }

      function error(err) {
        if (err) {
          // Snowdon peak
          setNewName("Snowdon summit");
          setName("Snowdon summit");
          setNewLon(-4.0810905);
          setLon(-4.0810905);

          setNewLat(53.0684949);
          setLat(53.0684949);
        }

        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    }
  }, []);

  useEffect(() => {
    // clean not needed

    if (newLon && newLon <= 180 && newLon >= -180) {
      setLonValid(true);
    } else {
      setLonValid(false);
    }
    if (newLat && newLat <= 90 && newLat >= -90) {
      setLatValid(true);
    } else {
      setLatValid(false);
    }

    if (newName && typeof newName === "string" && newName.length < 80) {
      setNameValid(true);
    } else setNameValid(false);
  }, [newLon, newLat, newName]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setViewZoneModal((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setViewZoneModal((x) => false);
  };

  let updateGPSarr = (longit, latit, namePassed, addGpsID, addTsAdded) => {
    let newArr = [];

    memoVals.gpsArray.forEach((item) => {
      if (item.gpsID === memoVals.viewZoneModal) {
        let newObj = {
          gpsID: memoVals.viewZoneModal,
          lat: latit,
          lon: longit,
          name: namePassed,
          tsAdded: item.tsAdded,
        };
        newArr.push(newObj);
      } else {
        newArr.push(item);
      }
    });

    if (addGpsID) {
      newArr.push({
        gpsID: addGpsID,
        lat: latit,
        lon: longit,
        name: namePassed,
        tsAdded: addTsAdded,
      });
    }
    setGpsArray((x) => newArr);
  };
  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    if (!adding) {
      axios
        .post(
          `${serverURL}/get-zone-info`,
          {
            gpsID: memoVals.viewZoneModal,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setName(response.data.name);
            setLon(response.data.lon);
            setLat(response.data.lat);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  return (
    <div
      className="clockOnModalUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {name || typeof lon === "number" || typeof lat === "number" || adding ? (
        <div
          className={`dayCommentsModal ${
            memoVals.mobModal
              ? `mobModalShoulder ${
                  memoVals.device.ios ? "paddingBottom35" : ""
                }`
              : "maxViewZoneModalWidth"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="mySwapsHeader" onClick={() => {}}>
            <img
              src={memoVals.mobModal ? navyClose : navyClose}
              alt="Close"
              className="closeMySwapsModalImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p>{adding ? "Add a " : ""}GPS zone</p>

            <p></p>
          </div>
          <div className="dayCommentsModalBody x24984983344">
            <div className="gpsZoneRow noBorder">
              <p className="lonLatTitles">Zone name</p>
              {toggleEditName ? (
                <div className={`lonLatInputDiv`}>
                  {" "}
                  <input
                    type="text"
                    defaultValue="Current location"
                    className={`latLonInput`}
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  ></input>
                  <p
                    className={`saveSettBtn ${
                      nameValid ? "" : "disableLonLat"
                    }`}
                    onClick={() => {
                      if (nameValid) {
                        setToggleEditName(false);
                        if (newName !== name) {
                          setName(newName);

                          axios
                            .post(
                              `${serverURL}/edit-gps-zone`,
                              {
                                editLat: lat,
                                editLong: lon,
                                editTitle: newName,
                                gpsID: memoVals.viewZoneModal,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setName(response.data.name);
                                setLon(response.data.lon);
                                setLat(response.data.lat);
                                updateGPSarr(
                                  response.data.lon,
                                  response.data.lat,
                                  response.data.name
                                );
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                <p
                  className={`lonLatVal ${
                    name.length > 24 ? "smallZoneName2" : ""
                  }`}
                >
                  {name || "Zone name"}{" "}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setNewName(name);
                      setToggleEditName(true);
                    }}
                  />
                </p>
              )}
            </div>{" "}
            <div className="gpsZoneRow">
              <p className="lonLatTitles">Latitude</p>
              {toggleEditLat ? (
                <div className={`lonLatInputDiv`}>
                  {" "}
                  <input
                    type="text"
                    className={`latLonInput`}
                    value={newLat}
                    onChange={(e) => {
                      setNewLat(e.target.value);
                    }}
                  ></input>
                  <p
                    className={`saveSettBtn ${latValid ? "" : "disableLonLat"}`}
                    onClick={() => {
                      if (latValid) {
                        setToggleEditLat(false);
                        let original = lat;
                        if (newLat !== lat) {
                          setLat(newLat);
                          axios
                            .post(
                              `${serverURL}/edit-gps-zone`,
                              {
                                editLat: parseFloat(newLat),
                                editLong: lon,
                                editTitle: name,
                                gpsID: memoVals.viewZoneModal,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setName(response.data.name);
                                setLon(response.data.lon);
                                setLat(response.data.lat);
                                updateGPSarr(
                                  response.data.lon,
                                  response.data.lat,
                                  response.data.name
                                );
                              } else {
                                setLat(original);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                <p
                  className={`lonLatVal ${
                    lat && lat.length > 21 ? "smallZoneName2" : ""
                  }`}
                >
                  {lat || "-"}{" "}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setNewLat(lat);
                      setToggleEditLat(true);
                    }}
                  />
                </p>
              )}
            </div>
            <div className="gpsZoneRow">
              <p className="lonLatTitles">Longitude</p>
              {toggleEditLon ? (
                <div className={`lonLatInputDiv`}>
                  {" "}
                  <input
                    type="text"
                    className={`latLonInput`}
                    value={newLon}
                    onChange={(e) => {
                      setNewLon(e.target.value);
                    }}
                  ></input>
                  <p
                    className={`saveSettBtn ${lonValid ? "" : "disableLonLat"}`}
                    onClick={() => {
                      if (lonValid) {
                        setToggleEditLon(false);
                        let original = lon;
                        if (newLon !== lon) {
                          setLon(newLon);
                          axios
                            .post(
                              `${serverURL}/edit-gps-zone`,
                              {
                                editLat: lat,
                                editLong: parseFloat(newLon),
                                editTitle: name,
                                gpsID: memoVals.viewZoneModal,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setName(response.data.name);
                                setLon(response.data.lon);
                                setLat(response.data.lat);
                                updateGPSarr(
                                  response.data.lon,
                                  response.data.lat,
                                  response.data.name
                                );
                              } else {
                                setLon(original);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }}
                  >
                    Save
                  </p>
                </div>
              ) : (
                <p
                  className={`lonLatVal ${
                    lon && lon.length > 21 ? "smallZoneName2" : ""
                  }`}
                >
                  {lon || "-"}{" "}
                  <img
                    src={editUserEdit1}
                    alt="Edit"
                    className="adminEditBtnImg"
                    onClick={() => {
                      setNewLon(lon);
                      setToggleEditLon(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
          <div className="dayCommentsModalFooter">
            <div className="viewMapsAndSaveDiv">
              {adding ? (
                <p
                  className="saveGpsZoneBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/edit-gps-zone`,
                        {
                          editLat: newLat,
                          editLong: newLon,
                          editTitle: newName,
                          gpsID: memoVals.viewZoneModal,
                          adding: true,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setName(response.data.name);
                          setLon(response.data.lon);
                          setLat(response.data.lat);
                          updateGPSarr(
                            response.data.lon,
                            response.data.lat,
                            response.data.name,
                            response.data.gpsID,
                            response.data.tsAdded
                          );
                          memoVals.setViewZoneModal((x) => response.data.gpsID);
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "GPS zone added",
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                          setAdding(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Save
                </p>
              ) : (
                ""
              )}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
                className="viewGpsZoneMapBtn"
                target="_blank"
              >
                View in Maps <img src={map} alt="View map" className="mapImg" />
              </a>
            </div>

            <p
              className="closeSeeUserIDBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ViewZoneModal;
