import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
import axios from "axios";
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done

import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";
import { UserContext } from "../../contexts/UserContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import whitecross from "../../img/general/whitecross.svg";
import navyClose from "../../img/general/navyClose.svg";
import whiteGo from "../../img/general/whiteGo.svg";

import whiteTriangle from "../../img/general/whiteTriangle.svg";

import popup from "../../img/general/popup.svg";

import "../../styles/calendar.css";

const MyReqsDataBox = ({
  modal,
  setShowAllows,
  profileSideBox,
  selectedYear,
  setSelectedYearParent,
  myCalPage,
}) => {
  const {
    hoursDataLoading,
    setHoursDataLoading,
    device,
    updatedReq,
    modalOpen,
    setModalOpen,
    setShowMyShiftModal,
  } = useContext(DataContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      hoursDataLoading,
      setHoursDataLoading,
      device,
      updatedReq,
      checkUserState,
      currencySymbol,
      modalOpen,
      setModalOpen,
      setShowMyShiftModal,
    }),
    [
      hoursDataLoading, //
      setHoursDataLoading, //
      device, //
      updatedReq, //
      checkUserState, //
      currencySymbol, //
      modalOpen,
      setModalOpen,
      setShowMyShiftModal,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [showTopWhiteBorder, setShowTopWhiteBorder] = useState(false);
  let [showConversions, setShowConversions] = useState(false);
  // console.log("rendered myReqsDataBox", new Date().getTime());
  let [availableYears, setAvailableYears] = useState([]);
  // let [selectedYear, setSelectedYear] = useState();

  useEffect(() => {
    // clean not needed
    memoVals.setHoursDataLoading((x) => true);
  }, []);

  let [customQty, setCustomQty] = useState(0);

  let [pHols, setPHols] = useState([]);
  let [specDays, setSpecDays] = useState([]);
  let [pHolsString, setPHolsString] = useState("-");
  let [specDaysString, setSpecDaysString] = useState("-");
  let [availableTilMins, setAvailableTilMins] = useState(0);
  // let [usedTilMins, setUsedTilMins] = useState(0);

  let [showPHolsOrClosedDays, setShowPHolsOrClosedDays] = useState({
    show: false,
    arr: [],
    specDays: false,
    pHols: false,
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      if (modal) {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener("popstate", function (event) {
          window.history.pushState(null, document.title, window.location.href);
          setShowAllows(false);
        });
      }
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let [prevYear, setPrevYear] = useState(
    memoVals.checkUserState.currClientYear || new Date().getTime()
  );

  useEffect(() => {
    // clean not needed
    if (parseInt(selectedYear) < memoVals.checkUserState.currClientYear) {
      setPrevYear(true);
    } else {
      setPrevYear(false);
    }
  }, [selectedYear]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-available-years`,
        {
          userOnly: true,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setAvailableYears(response.data.availableYears || []);
          if (!myCalPage && setSelectedYearParent) {
            setSelectedYearParent(response.data.currentYear);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [data, setData] = useState({
    userDailyMins: 0,
    customData: [],
    totalAllowMins: 0,
    usedMins: 0,
    remaining: 0,
  });

  let [annLeaveData, setAnnLeaveData] = useState({
    show: false,
  });

  let [toilConversionArr, setToilConversionArr] = useState([]);
  let [totToToil, setTotToToil] = useState(0);
  let [totToLeave, setTotToLeave] = useState(0);
  let [shiftLeaveArr, setShiftLeaveArr] = useState([]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (selectedYear) {
      memoVals.setHoursDataLoading((x) => true);
      axios
        .post(
          `${serverURL}/get-requests-data-box`,
          {
            year: parseInt(selectedYear),
            annLeaveOnly: false,
            calPage: myCalPage ? true : false,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            getTilInfo: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (parseInt(selectedYear) >= checkUserState.currClientYear) {
              setAnnLeaveData({
                show: true,
                totDays: response.data.allowanceData.totalAllowDays,
                dailyMins: response.data.allowanceData.userDailyMins,
                usedMins: response.data.allowanceData.usedMins,
                remainingMins: response.data.allowanceData.remaining,
              });
            } else {
              setAnnLeaveData({
                show: false,
              });
            }
            setTotToToil(response.data.totToToil);
            setTotToLeave(response.data.totToLeave);
            setToilConversionArr(response.data.toilConvArr);
            setData(response.data.allowanceData);
            if (
              response.data.allowanceData &&
              response.data.allowanceData.customData
            ) {
              setCustomQty(response.data.allowanceData.customData.length);
              // setCustomQty(0);
            }
            setAvailableTilMins(response.data.availableToil);
            // setUsedTilMins(response.data.tilUsed);
            setPHols(response.data.pHols);
            setSpecDays(response.data.specDays);
            let totPholsQty = 0;
            let surpassedPHolsQty = 0;
            let totSdayQty = 0;
            let surpassedSDayQty = 0;
            response.data.pHols.forEach((ph) => {
              totPholsQty++;
              if (ph.surpassed) {
                surpassedPHolsQty++;
              }
            });

            response.data.specDays.forEach((sd) => {
              totSdayQty++;
              if (sd.surpassed) {
                surpassedSDayQty++;
              }
            });

            setPHolsString(
              `${totPholsQty - surpassedPHolsQty} / ${totPholsQty} remaining`
            );

            setSpecDaysString(
              `${totSdayQty - surpassedSDayQty} / ${totSdayQty} remaining`
            );
            setShiftLeaveArr(response.data.shiftLeaveArr);
            memoVals.setHoursDataLoading((x) => false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedYear, memoVals.updatedReq]);

  let breakName = (val) => {
    let output = "";
    if (val) {
      let spaceFound = false;
      for (let i = 0; i < val.length; i++) {
        if (!spaceFound) {
          if (val[i] !== " ") {
            output += val[i];
          } else {
            spaceFound = true;
          }
        }
      }

      if (output.length > 11) {
        return true;
      } else {
        return false;
      }
    }
  };

  let [showCustom, setShowCustom] = useState(
    modal || profileSideBox ? true : true
  );

  let generateCustoms = useMemo(() => {
    return data.customData
      ? data.customData.map((item, i) => {
          // return "" ;
          return (
            <div className="customMappedItem" key={i}>
              <div
                className={`desktopDataRow desktopRowThickBorder customDataBoxTitle`}
              >
                <p
                  className={`desktopDataTitle colorWhite boldDataVal customLeaveDataTitle 
                ${breakName(item.name) && !modal ? "font12px" : ""}
                ${
                  mob
                    ? `txtFontSize16px ${
                        item.name.length > 19 ? "customTitleLongSmall" : ""
                      }`
                    : ""
                } `}
                  onClick={() => {}}
                >
                  {item.name}
                </p>
                <p
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal ${
                    mob ? "txtFontSize16px" : ""
                  }`}
                >
                  {item.unlimited ||
                  (item.daysOrHours === "days" && item.totAllow === 366)
                    ? "Unlimited"
                    : item.daysOrHours !== "days"
                    ? dateStringer.formatMinsDurationToHours(
                        item.totAllow,
                        false,
                        true
                      )
                    : `${item.totAllow} ${
                        item.totAllow === 0 || item.totAllow > 1
                          ? "days"
                          : "day"
                      }`}
                </p>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div className="desktopDataRow">
                <p
                  className={`desktopDataTitle colorWhite font12px ${
                    mob ? "txtFontSize16px" : ""
                  }`}
                >
                  Used
                </p>
                <div
                  className={`desktopDataValue boldDataVal ${
                    mob ? "txtFontSize16px" : ""
                  } ${memoVals.hoursDataLoading ? "none" : ""} `}
                >
                  {/* {dateStringer.convertMinsToDays(data.usedMins, data.userDailyMins)} */}
                  {item.daysOrHours === "days" ? (
                    item.used === 0 ? (
                      "-"
                    ) : (
                      <p>
                        {item.used}{" "}
                        {item.used > 1 || item.used <= 0 ? "days" : "day"}
                      </p>
                    )
                  ) : (
                    <p>
                      {item.used === 0
                        ? "-"
                        : dateStringer.formatMinsDurationToHours(
                            item.used,
                            false,
                            true
                          )}
                    </p>
                  )}
                </div>

                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
              <div
                className={`desktopDataRow customDataRowBottomBorder ${
                  i === data.customData.length - 1 ? "noBorder" : ""
                } noBorder`}
              >
                <p
                  className={`desktopDataTitle colorWhite font12px ${
                    mob ? "txtFontSize16px" : ""
                  } customRemainingTxt`}
                >
                  Remaining
                </p>
                <div
                  className={`${
                    memoVals.hoursDataLoading ? "none" : ""
                  } desktopDataValue boldDataVal customRemainingRow ${
                    mob ? "txtFontSize16px" : ""
                  } colourBlue ${item.remaining < 0 ? "colourLightRed" : ""}`}
                >
                  {item.unlimited ||
                  (item.daysOrHours === "days" && item.totAllow === 366) ? (
                    "Unlimited"
                  ) : item.daysOrHours === "days" ? (
                    <p>
                      {item.remaining}{" "}
                      {item.remaining > 1 || item.remaining <= 0
                        ? "days"
                        : "day"}
                    </p>
                  ) : (
                    <p>
                      {dateStringer.formatMinsDurationToHours(
                        item.remaining,
                        false,
                        true
                      )}
                    </p>
                  )}
                  {item.unlimited ||
                  (item.daysOrHours === "days" && item.totAllow === 366) ? (
                    ""
                  ) : !prevYear &&
                    item.daysOrHours === "days" &&
                    item.remaining !== 366 &&
                    item.remaining * data.userDailyMins > 0 ? (
                    <span className="myLeaveDataMinsSpan">
                      {dateStringer.formatMinsDurationToHours(
                        item.remaining * data.userDailyMins,
                        false,
                        true
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </div>{" "}
                <p
                  className={`${
                    memoVals.hoursDataLoading
                      ? "memoVals.hoursDataLoading"
                      : "none"
                  }`}
                >
                  -
                </p>
              </div>
            </div>
          );
        })
      : "";
  }, [
    showCustom,
    selectedYear,
    data.customData,
    memoVals.hoursDataLoading,
    customQty,
  ]);
  let customScrollBox = useRef();
  useEffect(() => {
    // no clean needed
    if (customScrollBox.current) {
      customScrollBox.current.scrollTop = 0;
    }
  }, [showCustom, selectedYear]);

  let generateAvailableYears = useMemo(() => {
    return availableYears.map((item) => (
      <option value={item} key={item}>
        {item}
      </option>
    ));
  }, [availableYears]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setShowAllows(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [isAtTop, setIsAtTop] = useState(true);
  let [showShiftLeaveTypeIDs, setShowShiftLeaveTypeIDs] = useState([]);
  // master return

  return (
    <div
      className={`desktopDataBox ${
        modal ? "modalReqDataBox" : ""
      } reqsDesktopBoxScroll 
      ${
        1 + 1 === 2
          ? ""
          : customQty === 0
          ? `noCustomsDataBoxSize ${
              memoVals.device.ios ? "noCustomsDataBoxSizeIos" : ""
            }`
          : customQty === 1
          ? "oneCustomsDataBoxSize"
          : customQty === 2
          ? "twoCustomsDataBoxSize"
          : ""
      }
       ${!showCustom ? "dataBoxMin" : ""} ${
        memoVals.device.vhIssue && modal ? "vhIssueModalMyAllows" : ""
      } ${
        customQty === 0 && !modal
          ? !myCalPage
            ? "x292929dd"
            : "x1839181s"
          : ""
      } ${memoVals.device.ios ? "mobAllowIosBox" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {/* <div className={`${modal ? "showMyToggler" : "none"}`}>
        <img
          src={whitecross}
          alt="Close"
          className="closeStatsModalCrossImg"
          onClick={() => {
            setShowReqStats(false);
          }}
        />
      </div> */}
      {window.innerWidth <= 550 && (
        <div className="modalSwiper modalSwiperMyAllows"></div>
      )}
      <div
        className="desktopDataBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={window.innerWidth <= 550 ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 ? handleUp : null}
      >
        <div
          className="myAllowsHeaderLeft marginLeftMinus5"
          onClick={() => {
            if (mob) {
              setShowAllows(false);
            }
          }}
        >
          {mob || modal ? (
            <img
              src={whitecross}
              alt="Close"
              className="closeReqDataBoxImg x23141"
              onClick={() => {
                // setShowReqStats(false);
                setShowAllows(false);
              }}
            />
          ) : (
            ""
          )}
          <span
            className={`dataBoxHeading colourNavy_ ${
              modal ? "font18px modalMyReqsDataBoxTitle" : ""
            } ${
              myCalPage
                ? `myCalPageDataBoxTitle ${
                    mob || modal ? "myCalPageDataBoxTitleMob" : ""
                  }`
                : ""
            }`}
            onClick={() => {
              console.log({ prevYear });
            }}
          >
            {" "}
            {myCalPage ? `${selectedYear} allowances` : "My allowances"}
          </span>
        </div>

        {!myCalPage && setSelectedYearParent ? (
          <select
            className={`reqsDataBoxYearSelect ${mob ? "txtFontSize16px" : ""}`}
            value={selectedYear}
            onChange={(e) => {
              e.stopPropagation();
              setSelectedYearParent(e.target.value);
            }}
          >
            {generateAvailableYears}
          </select>
        ) : (
          ""
        )}
      </div>
      <div
        className="myReqSideboxTop"
        onTouchStart={window.innerWidth <= 550 && isAtTop ? handleDown : null}
        onTouchMove={window.innerWidth <= 550 && isAtTop ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 && isAtTop ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 && isAtTop ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 && isAtTop ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 && isAtTop ? handleUp : null}
      >
        <div className="desktopDataRow desktopRowThickBorder">
          <div
            className={`desktopDataTitle colorWhite boldDataVal ${
              modal ? "font15px" : ""
            } colourNavy_ x13843782111`}
            onClick={() => {}}
          >
            <p>
              {dateStringer.convertMinsToDays(
                data.totalAllowMins,
                Math.floor(data.userDailyMins),
                true
              ).length >= 12 && !modal
                ? "Ann. leave"
                : "Annual leave"}
            </p>
            <p className="annLeaveSubYrly">Annual allowance</p>
          </div>
          <p
            className={`${
              memoVals.hoursDataLoading ? "none" : ""
            } desktopDataValue boldDataVal ${modal ? "font15px" : ""}`}
          >
            {annLeaveData.show ? (
              <span className="reqsDataDaySpan">
                {annLeaveData.totDays || 0} day
                {annLeaveData.totDays === 1 ? "" : "s"}
              </span>
            ) : (
              ""
            )}{" "}
            {dateStringer.formatMinsDurationToHours(
              data.totalAllowMins,
              false,
              true
            )}
          </p>{" "}
          <p
            className={`${
              memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
            }`}
          >
            -
          </p>
        </div>
        <div className="desktopDataRow paddingRight8">
          <p
            className={`desktopDataTitle colorWhite ${
              modal ? "font15px" : ""
            } usedRemainingIndent`}
          >
            Used
          </p>
          <p
            className={`${
              memoVals.hoursDataLoading ? "none" : ""
            } desktopDataValue boldDataVal ${modal ? "font15px" : ""}`}
          >
            {annLeaveData.show ? (
              <span className="reqsDataDaySpan">
                {dateStringer.convertMinsToDays(
                  Math.round(annLeaveData.usedMins),
                  Math.floor(annLeaveData.dailyMins),
                  true
                )}
              </span>
            ) : (
              ""
            )}
            {dateStringer.formatMinsDurationToHours(data.usedMins)}
          </p>{" "}
          <p
            className={`${
              memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
            }`}
          >
            -
          </p>
        </div>
        <div className="desktopDataRow noBorder paddingRight8">
          <p
            className={`desktopDataTitle colorWhite ${
              modal ? "font15px" : ""
            } usedRemainingIndent`}
          >
            Remaining
          </p>
          <p
            className={`${
              memoVals.hoursDataLoading ? "none" : ""
            } desktopDataValue boldDataVal ${
              modal ? "font15px" : ""
            } colourBlue ${data.remaining < 0 ? "colourLightRed" : ""}`}
          >
            {dateStringer.convertMinsToDays(
              Math.round(data.remaining),
              Math.floor(data.userDailyMins),
              true
            )}
            {/* {prevYear
              ? "-"
              : dateStringer.convertMinsToDays(
                  Math.round(data.remaining),
                  Math.floor(data.userDailyMins),
                  true
                )} */}
            {dateStringer.convertMinsToDays(
              Math.round(data.remaining),
              Math.floor(data.userDailyMins),
              true
            ) !==
            dateStringer.formatMinsDurationToHours(
              data.remaining,
              false,
              true
            ) ? (
              <span
                className={`myLeaveDataMinsSpan  ${
                  prevYear ? "makeTxtPrimary" : ""
                } ${data.remaining < 0 ? "none" : ""}`}
              >
                {dateStringer.formatMinsDurationToHours(
                  data.remaining,
                  false,
                  true
                )}
              </span>
            ) : (
              ""
            )}
          </p>{" "}
          <p
            className={`${
              memoVals.hoursDataLoading ? "memoVals.hoursDataLoading" : "none"
            }`}
          >
            -
          </p>
        </div>
      </div>
      {/* - - - - */}
      <div className="emptyDesktopDataRow"></div>

      {/* {showCustom ? (
        <div
          className={`myReqDataSplitter ${
            modal ? "modalShowCustomDataSplitter" : ""
          }`}
        ></div>
      ) : (
        ""
      )} */}
      {myCalPage && (pHols[0] || specDays[0]) ? (
        <div
          className={`pHolSpecDayRow ${
            !specDays[0] || !pHols[0] ? "maxHeight50" : ""
          }`}
        >
          {pHols[0] ? (
            <div className={`pHolDataRow ${!specDays[0] ? "noBorder" : ""}`}>
              <p className="pHolDataRowTitle">
                {memoVals.currencySymbol === "£" ? "Bank" : "Public"} holidays
              </p>
              <div className="desktopDataValue boldDataVal">
                {pHolsString}
                <p
                  className="bankPopUpViewBtn"
                  onClick={() => {
                    setShowPHolsOrClosedDays({
                      show: true,
                      arr: pHols,
                      specDays: false,
                      pHols: true,
                    });
                  }}
                >
                  View
                  <img src={popup} className="bankPopup" alt="Bank holidays" />
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {specDays[0] ? (
            <div className="pHolDataRow noBorder">
              <p className="pHolDataRowTitle">Closed days</p>
              <div className="desktopDataValue boldDataVal">
                {specDaysString}

                <p
                  className="bankPopUpViewBtn"
                  onClick={() => {
                    setShowPHolsOrClosedDays({
                      show: true,
                      arr: specDays,
                      specDays: true,
                      pHols: false,
                    });
                  }}
                >
                  View
                  <img src={popup} className="bankPopup" alt="Bank holidays" />
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {showCustom ? (
        <div
          onScroll={(e) => {
            if (e.target.scrollTop > 0) {
              setShowTopWhiteBorder(true);
              setIsAtTop(false);
            } else {
              setShowTopWhiteBorder(false);
              setIsAtTop(true);
            }
          }}
          ref={customScrollBox}
          className={`myAllowsSideBoxCustomsScroll ${
            (pHols[0] && !specDays[0] && !modal && !mob) ||
            (!pHols[0] && specDays[0] && !modal && !mob)
              ? `customScrollBoxWhenOnlyOnePHolRowShown ${
                  memoVals.device.vhIssue ? "cusVh2" : ""
                }`
              : ""
          } ${
            !modal && pHols[0] && specDays[0]
              ? `customScrollBoxWhenBothPHolRowShown ${
                  memoVals.device.vhIssue ? "cusVh1" : ""
                }`
              : ""
          } ${
            modal
              ? `modalCustomScrollBox2 ${
                  (pHols[0] && !specDays[0]) || (!pHols[0] && specDays[0])
                    ? `customScrollBoxWhenOnlyOnePHolRowShownModal  ${
                        memoVals.device.vhIssue ? "cusVh3" : ""
                      }`
                    : ""
                } ${
                  pHols[0] && specDays[0]
                    ? `customScrollBoxWhenBothPHolRowShownModal ${
                        memoVals.device.vhIssue ? "cusVh4" : ""
                      }`
                    : ""
                }`
              : ""
          } ${mob || modal ? "modalAllowsHeightForFooter" : ""} ${
            showTopWhiteBorder ? "topWhiteBorder" : ""
          } ${mobModal && customQty === 1 ? "x7ssd7" : ""} ${
            customQty === 0 ? "x182348129d" : ""
          }`}
        >
          <div className="customMappedItem x348772344">
            <div
              className={`desktopDataRow desktopRowThickBorder customDataBoxTitle`}
            >
              <p
                className={`desktopDataTitle colorWhite boldDataVal customLeaveDataTitle 
               
                ${mob ? `txtFontSize16px` : ""} `}
                onClick={() => {}}
              >
                Time off in lieu{" "}
                <span className="toilAvailToTake">
                  {memoVals.checkUserState.tilEnabled
                    ? "available to take"
                    : "- disabled"}
                </span>
              </p>
              <p
                className={`${
                  memoVals.hoursDataLoading ? "none" : ""
                } desktopDataValue boldDataVal ${
                  mob ? "txtFontSize16px" : ""
                } ${availableTilMins < 0 ? "regBgToil" : ""}`}
              >
                {availableTilMins < 0 ? "-" : ""}
                {dateStringer.formatMinsDurationToHours(
                  availableTilMins,
                  false,
                  false
                )}
              </p>{" "}
              <p
                className={`${
                  memoVals.hoursDataLoading
                    ? "memoVals.hoursDataLoading"
                    : "none"
                }`}
              >
                -
              </p>
            </div>
            {totToLeave || totToToil ? (
              <div className="tilExtra">
                <div className="tilExtraRow">
                  <p className="tilExTitle">TOIL converted to annual leave</p>
                  <p className="tilExValue">
                    {dateStringer.formatMinsDurationToHours(totToLeave)}
                  </p>
                </div>

                <div className="tilExtraRow tilExtraRowLast">
                  <p className="tilExTitle">Annual leave converted to TOIL</p>
                  <p className="tilExValue">
                    {dateStringer.formatMinsDurationToHours(totToToil)}
                  </p>
                </div>
                <div
                  className="showConvArr"
                  onClick={() => {
                    setShowConversions(!showConversions);
                  }}
                >
                  Conversions{" "}
                  <img
                    src={whiteTriangle}
                    alt="Conversions"
                    className={`convWhiteTri ${
                      showConversions ? "convWhiteTriActive" : ""
                    }`}
                  />
                </div>
                {showConversions && (
                  <div className="convArr">
                    {toilConversionArr &&
                      toilConversionArr.map((item, i) => {
                        return (
                          <div
                            className={`dashTilListItem defaultCursor x32453234332`}
                            key={i}
                          >
                            <div className="dashTilListItemLeft">
                              <p className={`dashTilType colourWhite`}>
                                {item.toLeave
                                  ? "TOIL to annual leave"
                                  : "Annual leave to TOIL"}
                              </p>
                              <p className="dashTilListItemDs x238942223">
                                {dateStringer.printedDateFromDs(item.ds, true)}
                              </p>
                              <p
                                className={`dashTilListItemTimeStrip colourNavy x318924982423`}
                              >
                                By {item.fName} {item.lName}
                              </p>
                              {item.convert &&
                              item.addedByFName &&
                              item.addedByLName ? (
                                <p className="itemconvAddedBy">
                                  By {item.addedByFName} {item.addedByLName[0]}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="dashTilListItemRight">
                              <p
                                className={`dashTilListItemDurMins colourNavy_`}
                              >
                                {dateStringer.formatMinsDurationToHours(
                                  item.mins
                                )}
                              </p>
                              {/* <p className="dashTilListItemType">
                                {item.toLeave
                                  ? "deducted from TOIL balance, added to annual leave"
                                  : "deducted from annual leave, added to TOIL balance"}
                              </p> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          {shiftLeaveArr.map((item, i) => {
            return (
              <div
                className="customMappedItem shiftLeaveCustomMappedItem"
                key={i}
              >
                <div
                  className={`desktopDataRow desktopRowThickBorder customDataBoxTitle x239849848533 ${
                    showShiftLeaveTypeIDs.includes(item.shiftLeaveTypeID)
                      ? "x239849848533Active"
                      : ""
                  }`}
                  onClick={() => {
                    if (showShiftLeaveTypeIDs.includes(item.shiftLeaveTypeID)) {
                      setShowShiftLeaveTypeIDs(
                        showShiftLeaveTypeIDs.filter((x) => {
                          return x !== item.shiftLeaveTypeID;
                        })
                      );
                    } else {
                      setShowShiftLeaveTypeIDs([
                        ...showShiftLeaveTypeIDs,
                        item.shiftLeaveTypeID,
                      ]);
                    }
                  }}
                >
                  <p
                    className={`desktopDataTitle colorWhite boldDataVal customLeaveDataTitle 
                ${breakName(item.name) && !modal ? "font12px" : ""}
                ${
                  mob
                    ? `txtFontSize16px ${
                        item.name.length > 19 ? "customTitleLongSmall" : ""
                      }`
                    : ""
                } `}
                    onClick={() => {}}
                  >
                    {item.name}
                  </p>
                  <div className="shiftLeaveArrWrap">
                    <p
                      className={`${
                        memoVals.hoursDataLoading ? "none" : ""
                      } desktopDataValue boldDataVal ${
                        mob ? "txtFontSize16px" : ""
                      }`}
                    >
                      {dateStringer.formatMinsDurationToHours(item.durMins)}
                    </p>{" "}
                    <p
                      className={`${
                        memoVals.hoursDataLoading
                          ? "memoVals.hoursDataLoading"
                          : "none"
                      }`}
                    >
                      -
                    </p>
                    {!memoVals.hoursDataLoading && (
                      <img
                        src={whiteGo}
                        className={`whiteGoShiftLeaveArrImg ${
                          showShiftLeaveTypeIDs.includes(item.shiftLeaveTypeID)
                            ? "whiteGoShiftLeaveArrImgActive"
                            : ""
                        }`}
                        alt="Show shifts"
                      />
                    )}
                  </div>
                </div>
                {showShiftLeaveTypeIDs.includes(item.shiftLeaveTypeID) && (
                  <div className="shiftLeaveArrDownList">
                    {item.items.map((x, ii) => {
                      return (
                        <div
                          className="shiftLeaveArrItem"
                          key={ii}
                          onClick={() => {
                            memoVals.setShowMyShiftModal((xxx) => {
                              return {
                                unitType: x.type,
                                unitID: x.shiftID || x.tilID,
                              };
                            });
                          }}
                        >
                          <p className="shiftLeaveArrItemTitle">
                            {x.type === "shift" ? "Shift" : "Overtime"}
                          </p>
                          <div className="shiftLeaveArrItemLower">
                            <p className="shiftLeaveArrItemDs">
                              {dateStringer.printedDateFromDs(x.startDs)}
                            </p>

                            <p className="shiftLeaveArrItemTime">
                              {dateStringer.formatMinsDurationToHours(
                                x.durMins
                              )}
                              {/* {dateStringer.dsToTimeStrip(x.startDs)} -{" "}
                              {dateStringer.dsToTimeStrip(x.endDs)} */}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* map through items here */}
              </div>
            );
          })}
          {generateCustoms}
        </div>
      ) : (
        ""
      )}
      {showPHolsOrClosedDays.show ? (
        <div
          className="showPHolsUnderlay"
          onClick={() => {
            setShowPHolsOrClosedDays({
              show: false,
              arr: [],
              pHols: false,
              specDays: false,
            });
          }}
        >
          {" "}
          <div
            className="showDayModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="showDayModalHeader">
              <img
                src={navyClose}
                alt="Close"
                className="closeShowDayModalImg"
                onClick={() => {
                  setShowPHolsOrClosedDays({
                    show: false,
                    arr: [],
                    pHols: false,
                    specDays: false,
                  });
                }}
              />
              <p className="showDayHeaderTitle">
                {showPHolsOrClosedDays.pHols
                  ? `${
                      memoVals.currencySymbol === "£" ? "Bank" : "Public"
                    } holidays`
                  : "Closed days"}{" "}
                in {selectedYear}
              </p>
            </div>
            <div className="showDayModalBody pholsShowModalBody">
              {showPHolsOrClosedDays.arr
                .sort((a, b) => {
                  const currentTime = Date.now();

                  if (a.ts > currentTime && b.ts > currentTime) {
                    // Both timestamps are in the future
                    return a.ts - b.ts; // Sort by ascending timestamp
                  } else if (a.ts > currentTime && b.ts <= currentTime) {
                    // Timestamp of 'a' is in the future, 'b' is in the past
                    return -1; // 'a' comes before 'b'
                  } else if (a.ts <= currentTime && b.ts > currentTime) {
                    // Timestamp of 'b' is in the future, 'a' is in the past
                    return 1; // 'b' comes before 'a'
                  } else {
                    // Both timestamps are in the past
                    return b.ts - a.ts; // Sort by descending timestamp
                  }
                })
                .map((item) => {
                  return (
                    <div
                      className={`showDayListPHolSDay ${
                        !item.surpassed ? "unsurpassedItem" : ""
                      }`}
                    >
                      <p
                        className={`pHolSDayTitle lineHeight18 ${
                          item.title.length > 32 ? "font12" : ""
                        } ${
                          !item.surpassed ? "unsurpassedItemTxt" : "colourWhite"
                        }`}
                      >
                        {item.title}
                      </p>

                      <p className="showDayPholClosed">
                        {dateStringer.printedDateWithYrFromDs(item.ds)}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="showDayModalFooter">
              <p></p>

              <p
                className="closeShowDayBtnTxt"
                onClick={() => {
                  setShowPHolsOrClosedDays({
                    show: false,
                    arr: [],
                    pHols: false,
                    specDays: false,
                  });
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {modal ? (
        <div className="myAllowsFooter">
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn x98763274`}
            onClick={() => {
              setShowAllows(false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyReqsDataBox;
