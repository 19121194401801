import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  Fragment,
} from "react";

// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";

import dateStringer from "../../../tools/dateStringer";

import serverURL from "../../../serverURL";

import "../../../styles/calendar.css";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import navyAdd from "../../../img/general/navyAdd.svg";
import desktopBurger from "../../../img/general/desktopBurger.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import rotaTeam from "../../../img/general/rotaTeam.svg";

import timesheetImg from "../../../img/general/ts.svg";
import teamIcon from "../../../img/general/teamIcon2.svg";

import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";

const Timesheets = ({ sideBoxData, my, setMy }) => {
  const { setCalPage } = useContext(CalendarContext);

  const { checkUserState } = useContext(UserContext);
  const {
    setShowTimesheet,
    setShowViewTimesheet,
    updateUserTimesheets,
    createNewTimesheet,
    setCreateNewTimesheet,
    device,
  } = useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setCalPage, //
      checkUserState, //
      setShowTimesheet, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      mob, //
      full, //
      fullButNarrow, //
      createNewTimesheet,
      setCreateNewTimesheet,
      device,
    }),
    [
      setCalPage, //
      checkUserState, //
      setShowTimesheet, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      mob, //
      full, //
      fullButNarrow, //
      createNewTimesheet,
      setCreateNewTimesheet,
      device,
    ]
  );

  document.title = "FlowRota - My timesheets";

  useEffect(() => {
    memoVals.setCalPage((x) => "shifts");
  }, []);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && params.timesheetID) {
      memoVals.setShowViewTimesheet((x) => {
        return {
          show: true,
          timesheetID: dateStringer.cleanParam(params.timesheetID),
          isMgr: false,
        };
      });

      let currentUrl = new URL(window.location.href);

      // Remove query parameters by setting search to an empty string
      currentUrl.search = "";

      // Update the URL without the query parameters
      window.history.replaceState({}, document.title, currentUrl.href);
    }
  }, []);

  // shifts, swaps, stats

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={navyAdd}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDate}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={desktopBurger}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={timesheetImg}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={teamIcon}
        alt="Timesheet"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [yearsArr, setYearsArr] = useState([]);
  let [selectedYear, setSelectedYear] = useState(
    memoVals.checkUserState.currClientYear
  );
  const cancelSource1 = axios.CancelToken.source();

  useEffect(() => {
    if (!yearsArr[1]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.availableYears || []);
          }
        })
        .catch((e) => {
          console.error("ERR", e);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [tsArr, setTsArr] = useState([]);

  let [finalNotYetSubmittedIndex, setFinalNotYetSubmittedIndex] = useState(-1);
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-timesheets`,
        {
          year: selectedYear,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource2.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          let pending = [];
          let nonPending = [];
          response.data.output
            .sort((a, b) => {
              return b.startTs - a.startTs;
            })
            .forEach((item) => {
              if (!item.submitted) {
                pending.push(item);
              } else {
                nonPending.push(item);
              }
            });
          if (pending[0] && !memoVals.mob) {
            pending.push({ split: true });
          } else {
          }
          if (pending[0] && nonPending[0]) {
            setFinalNotYetSubmittedIndex(pending.length - 1);
          } else {
            if (finalNotYetSubmittedIndex !== -1) {
              setFinalNotYetSubmittedIndex(-1);
            }
          }
          setTsArr([...pending, ...nonPending]);
          setDataLoaded(true);
          if (loading) {
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource2.cancel("Component unmounted");
    };
  }, [selectedYear, memoVals.updateUserTimesheets, memoVals.mob]);

  let generateTimesheets = useMemo(() => {
    return tsArr.map((ts, i) => {
      let dateString = !ts.split ? (
        <>
          {" "}
          {dateStringer.printedDateFromDs(
            ts.startDs,
            false,
            false,
            "tsSuff"
          )}{" "}
          {ts.endDs &&
          ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00` &&
          ts.submitted
            ? "-"
            : ""}{" "}
          {ts.endDs &&
          ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00` &&
          ts.submitted
            ? dateStringer.printedDateFromDs(ts.endDs, false, false, "tsSuff")
            : ""}{" "}
          {!ts.submitted ? (
            <Fragment>
              <span className="tsUpToTodaySpan"> up to</span> today
            </Fragment>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      );

      let makeSmaller = false;
      if (
        !ts.split &&
        (memoVals.mob || memoVals.fullButNarrow) &&
        ts.startDs !== `${ts.endDs.substr(0, 11)}H00M00`
      ) {
        makeSmaller = true;
      }

      if (ts.split && !memoVals.mob) {
        return <div className="pendingTsSplitter ccSplitter" key={i}></div>;
      } else {
        return (
          <div
            className={`dvStateHighlight dvStateHighlightShift ${
              !memoVals.mob
                ? "absShiftItemDesktop tsItemDesktop"
                : "absShiftItemMob minHeight80"
            } newMyAbsShFlex dataRows0 ${
              i === finalNotYetSubmittedIndex ? "finalPreSh" : ""
            }`}
            key={i}
            onClick={() => {
              if (ts.submitted) {
                memoVals.setShowViewTimesheet((x) => {
                  return {
                    show: true,
                    timesheetID: ts.timesheetID,
                    isMgr: false,
                  };
                });
              } else {
                memoVals.setShowTimesheet((x) => {
                  return {
                    timesheetID: ts.timesheetID,
                  };
                });
              }
            }}
          >
            <div
              className={`newMyAbsShLeft ${
                memoVals.mob ? "newMyAbsShLeftMob" : ""
              }`}
            >
              {!ts.submitted && (
                <div className="myHoursNeedAcc myHoursNeedAccTs"></div>
              )}

              <div className="dvStateHighlightedNote">
                <p className="dvStateHighlightedNoteName userShItemTxtTitle">
                  <img
                    src={desktopBurger}
                    alt="Rota clock inline"
                    className="rotaClockOpenDvInlineImg rotaClockOpenDvInlineImgTsBurger"
                  />
                  Timesheet
                </p>
                <p className="dvStateHighlightedNoteName">
                  <img
                    src={rotaDate}
                    alt="Rota clock inline"
                    className="rotaClockOpenDvInlineImg marginRight10px"
                  />
                  {dateStringer.printedDateFromDs(ts.startDs)}{" "}
                  {ts.endDs &&
                  ts.endDs.substr(0, 11) !== ts.startDs.substr(0, 11)
                    ? "-"
                    : ""}{" "}
                  {ts.endDs &&
                  ts.endDs.substr(0, 11) !== ts.startDs.substr(0, 11)
                    ? dateStringer.printedDateFromDs(ts.endDs)
                    : ""}
                </p>

                <div className={`dvShiftNameRow `}>
                  <img src={rotaTeam} className="dvShPP" alt="Team" />{" "}
                  <p className="dvShName bbcada">{ts.teamName}</p>
                </div>
              </div>
            </div>
            <div className="myShRightDiv">
              <div
                className={`newMyAbsShRightSec tilShiftDurationTxtTeam  absentButPaid ${
                  memoVals.mob ? "newMyAbsShRightSecMob" : ""
                } shiftItemDurMins`}
              >
                {ts.itemsQty} record{ts.itemsQty === 1 ? "" : "s"}
              </div>
              {/* <br /> */}
              {!ts.submitted ? (
                <p className="tsStatusTxt colour00aaff">Not yet submitted</p>
              ) : ts.submitted && !ts.approved && !ts.declined ? (
                <p className="tsStatusTxt">Awaiting approval</p>
              ) : ts.approved ? (
                <p className="tsStatusTxt ccApp">Approved</p>
              ) : (
                <p className="tsStatusTxt ccDec">Declined</p>
              )}
            </div>
          </div>
        );
        return (
          <div
            key={i}
            className={`mgrReqItem tsItemUnit ${mob ? "borderRadius10" : ""} ${
              !ts.submitted ? "mgrReqItemPendingBorder" : ""
            } ${!ts.approved && !ts.declined ? "pendClock" : ""}`}
            onClick={() => {
              console.log({ ts });
              if (ts.submitted) {
                memoVals.setShowViewTimesheet((x) => {
                  return {
                    show: true,
                    timesheetID: ts.timesheetID,
                    isMgr: false,
                  };
                });
              } else {
                memoVals.setShowTimesheet((x) => {
                  return {
                    timesheetID: ts.timesheetID,
                  };
                });
              }
            }}
          >
            <div
              className={`tsUnitLeft ${
                !ts.submitted ? "tsUnitLeftNonSubmitted" : ""
              }`}
            >
              {!ts.submitted ? <div className="unsubmittedTsBlob"></div> : ""}
              <img
                src={timesheetImg}
                alt="Timesheet"
                className={`tsUnitImg ${!ts.submitted ? "x308491" : ""} ${
                  memoVals.mob ? "tsUnitImgMob" : ""
                }`}
              />
            </div>
            <div
              className={`reqColBarDiv reqColBarAdjust5 ${
                ts.approved ? "" : "divPend"
              } ${ts.declined ? "divDec" : ""}`}
            ></div>
            <div
              className={`tsUnitMiddle ${
                memoVals.mob ? "tsUnitMiddleMob" : ""
              } ${!ts.submitted ? "pendingReqBord" : ""}`}
            >
              <div className="tsUnitMidLeft">
                <div
                  className={`tsFromTilSection ${
                    !ts.approved && !ts.declined ? "x32897478243" : ""
                  }`}
                >
                  <div className="tsUnitFromToSection">
                    {/* <p className="tsUnitDsTitle">From</p> */}
                    <div
                      className={`tsUnitDsValue ${
                        memoVals.mob ? "tsUnitDsValueMob" : ""
                      } ${makeSmaller ? "x208492" : ""}`}
                    >
                      {dateString}
                    </div>
                  </div>
                  {/* <div className="tsUnitFromToSection">
                  <p className="tsUnitDsTitle">Until</p>
                  <p
                    className={`tsUnitDsValue ${memoVals.mob ? "tsUnitDsValueMob" : ""}`}
                  >
                    {dateStringer.printedDateFromDs(ts.endDs)}
                  </p>
                </div> */}
                </div>
                <div className={`tsUnitTeamName `}>
                  <img src={teamIcon} alt="Team" className="teamIconTs" />{" "}
                  {dateStringer.shorten(
                    ts.teamName,
                    memoVals.mob || memoVals.fullButNarrow ? 20 : 20
                  )}
                  {!ts.approved && !ts.declined && ts.submitted ? (
                    <p
                      className={`submittedTsBadge notYetSub ${
                        memoVals.mob ? "tsBadMob" : ""
                      }`}
                    >
                      Submitted
                      {/* <span className="smallSubmitBadgeSpan">Submitted</span>
                    {memoVals.mob ? <br /> : " "}
                    {dateStringer.printedDateFromDs(ts.submitted)} */}
                    </p>
                  ) : (
                    ""
                  )}
                  {ts.declined ? (
                    <p
                      className={`submittedTsBadge notYetSub declinedTsCol ${
                        memoVals.mob ? "tsBadMob" : ""
                      }`}
                    >
                      Declined
                      {/* <span className="smallSubmitBadgeSpan">Submitted</span>
                    {memoVals.mob ? <br /> : " "}
                    {dateStringer.printedDateFromDs(ts.submitted)} */}
                    </p>
                  ) : (
                    ""
                  )}
                  {ts.approved ? (
                    <p
                      className={`submittedTsBadge notYetSub approvedTsCol ${
                        memoVals.mob ? "tsBadMob" : ""
                      }`}
                    >
                      Approved
                      {/* <span className="smallSubmitBadgeSpan">Submitted</span>
                    {memoVals.mob ? <br /> : " "}
                    {dateStringer.printedDateFromDs(ts.submitted)} */}
                    </p>
                  ) : (
                    ""
                  )}
                  {/* {!ts.approved && !ts.declined && !ts.submitted ? (
                      <p
                        className={`submittedTsBadge notYetSub  ${
                          memoVals.mob ? "tsBadMob" : ""
                        }`}
                      >
                        Yet to submit
                      </p>
                    ) : (
                      ""
                    )} */}
                </div>
              </div>
              <div className="tsUnitMidRight">
                <div className="tsUnitFromToSection x1341">
                  <p className="tsUnitDsTitle">Records</p>
                  <p className="tsUnitDsValue">{ts.itemsQty}</p>
                </div>
              </div>
            </div>
            <div className="itemTongue tsTongue">
              <p className="tongueTxt clockTongueTxt">Timesheet</p>
            </div>
            {/* <div className="tsUnitRight"></div> */}
          </div>
        );
      }
    });
  }, [tsArr, memoVals.mob, memoVals.fullButNarrow]);

  let generateYearsArr = useMemo(() => {
    let currYr = new Date().getFullYear();
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year, i) => {
        if (year <= currYr) {
          return (
            <option key={i} value={year}>
              {year}
            </option>
          );
        }
      })
    );
  }, [yearsArr]);

  // ------------------------------------------------------------------------

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      {" "}
      {/* insert header here */}
      <ShiftsHeader my={my} setMy={setMy} />
      <div className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}>
        <div
          className={`desktopMasterMain ${
            !memoVals.full && !memoVals.mob ? "midContentWidth" : ""
          } ${memoVals.mob ? "mob100Witdth" : ""} `}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar2 " : ""
            } ${!memoVals.mob && "borderLeftGrey"}`}
          >
            <div className="shiftsPageFilterBarLeft x239349090293">
              <select
                className={`myReqsYearDropdown ${
                  memoVals.mob ? "myReqsYearDropdownMobCal" : ""
                } myCalMonthsDropSize_ ${
                  selectedYear === new Date().getFullYear()
                    ? "colour00aaff"
                    : ""
                }`}
                value={selectedYear}
                onChange={(e) => {
                  if (e.target.value) {
                    setLoading(true);
                    setSelectedYear(parseInt(e.target.value));
                  }
                }}
              >
                {!yearsArr.includes(selectedYear) ? (
                  <option value="">--</option>
                ) : (
                  ""
                )}
                {generateYearsArr}
              </select>
            </div>
            {/* <div className="shiftsPageFilterBarRight"></div> */}
            {memoVals.full && (
              <p
                className={`addStaffLeaveBtn ${
                  !memoVals.full ? "nonFullAddLeaveBtn" : ""
                } x239349090293 newTsBtnUserTsPage ${loading ? "invis" : ""}`}
                onClick={() => {
                  memoVals.setCreateNewTimesheet((x) => true);
                }}
              >
                New timesheet
                <img src={navyAdd} alt="Add form" className="whiteAddFormImg" />
              </p>
            )}
          </div>

          {/* <div
            className={`filterSplitterContainer ${
              !memoVals.mob && "borderLeftGrey"
            } ${!memoVals.full ? "noBorderRight" : ""}`}
          >
            <div className="filterSplitter"></div>
          </div> */}

          <div
            className={`tsContainer ${!memoVals.full ? "noBorderRight" : ""} ${
              memoVals.mob
                ? `mobTsContainer ${
                    memoVals.device.ios ? "mobTsContainerIos" : ""
                  }`
                : ""
            }`}
          >
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />

            {/* {!loading && notYetSubmittedExists ? (
              <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                Not yet submitted
              </p>
            ) : (
              ""
            )} */}
            {!loading ? generateTimesheets : ""}

            {!loading && tsArr.length === 0 ? (
              <div
                className={`noShiftsTxtContainer noUpcomingReqs ${
                  memoVals.mob ? "x1984971" : ""
                }`}
              >
                <div className="noShiftsSideBar"></div>

                <p className={`noShiftsTxt x19873 noShiftsTxtMobClocks`}>
                  No timesheets created in {selectedYear}
                </p>

                <div className="noShiftsSideBar"></div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
        </div>
      </div>
      {!full && (
        <p
          className="addShiftBtnOnTeamShifts blueColours"
          onClick={() => {
            memoVals.setCreateNewTimesheet((x) => true);
          }}
        >
          +
        </p>
      )}
      {imgPreload}
    </div>
  );
};

export default Timesheets;
