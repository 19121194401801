import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 july 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import { useHistory, useLocation } from "react-router-dom";

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";
import whitecross from "../../img/general/whitecross.svg";
import popup from "../../img/general/popup.svg";

import "../../styles/calendar.css";

const StaffAbsenceDataBox = ({ modal, teamID, setShowSideModal }) => {
  const { setShowEditUser, device, setViewAbsenceUserTimeline } =
    useContext(DataContext);
  const { checkUserState } = useContext(UserContext);
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      checkUserState, //
      setShowEditUser, //
      mob,
      device,
      setViewAbsenceUserTimeline,
    }),
    [checkUserState, setShowEditUser, mob, device, setViewAbsenceUserTimeline]
  );

  let [selectedYear, setSelectedYear] = useState(
    memoVals.checkUserState.currClientYear
  );
  let [yearsArr, setYearsArr] = useState([new Date().getFullYear()]);

  //   - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let [months, setMonths] = useState([]);
  let [staff, setStaff] = useState([]);
  let [selectedUserID, setSelectedUserID] = useState("all");
  let [teamName, setTeamName] = useState("All of your staff");

  // let [my, setMy] = useState(
  //   `${
  //     new Date().getMonth() < 10 ? "0" : ""
  //   }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
  //     2,
  //     2
  //   )}`
  // );

  let shortenAbsName = (val) => {
    if (val) {
      if (val.length > 18) {
        return `${val.substr(0, 16)}...`;
      } else {
        return val;
      }
    }
  };

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-team-absence-data`,
        {
          year: selectedYear,
          teamID: teamID || "allStaff",
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (months && months[0]) {
          } else {
            setMonths(response.data.monthOptions);
          }
          setSelectedYear(response.data.yearUsed);
          setTeamName(
            response.data.permissions === 1
              ? "All staff (you are an admin)"
              : "All of your staff"
          );
          setStaff(response.data.arr);
          setYearsArr(response.data.yearsArr || []);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [teamID, selectedYear]);

  //   - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      setShowSideModal(false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  let [showTopWhiteBorder, setShowTopWhiteBorder] = useState(false);

  // stella
  let generateAllStaffData = useMemo(() => {
    return staff && staff[0]
      ? staff.map((item, i) => {
          let dontShowBottomBorder = false;
          staff.forEach((s, ind) => {
            if (s.userID === selectedUserID) {
              if (i === ind - 1) {
                dontShowBottomBorder = true;
              }
            }
          });
          return (
            <div
              key={item.userID}
              className={`payableHoursUserItemContainer ${
                i === staff.length - 1 || selectedUserID === item.userID
                  ? "noBo rder"
                  : ""
              } ${dontShowBottomBorder ? "noBorderBottom" : ""} `}
              onClick={(e) => {
                e.stopPropagation();

                if (selectedUserID === item.userID) {
                  setSelectedUserID("");
                } else {
                  setSelectedUserID(item.userID);
                }
              }}
            >
              <div
                className={`mgrTeamHoursDataItem ${
                  item.userID === selectedUserID
                    ? "noHoverExpandable dataItemBorderRight"
                    : ""
                }
           
                `}
              >
                <p
                  className={`mgrReqFullName ${
                    memoVals.mob ? "txtFontSize16px" : ""
                  }`}
                >
                  {item.fName} {item.lName}
                </p>

                {selectedUserID === item.userID ? (
                  <div
                    className="payableUserBtns"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {/* {modal ? (
                      ""
                    ) : (
                      <p
                        className="payableUserHours"
                        onClick={() => {
                          if (!modal) {
                            setShowRequests(false);
                            setLoadUser({ my, userID: item.userID });
                          }
                        }}
                      >
                        Hours
                      </p>
                    )} */}
                    <p
                      className="payableUserHours"
                      onClick={() => {
                        memoVals.setShowEditUser(item.userID);
                        // setShowTeamHoursStatsModal(false);
                      }}
                    >
                      Profile
                    </p>
                  </div>
                ) : (
                  <p
                    className={`allStaffReqsDataValue ${
                      memoVals.mob ? "txtFontSize16px" : ""
                    }`}
                  >
                    {dateStringer.formatMinsDurationToHours(item.totAbsentMins)}
                  </p>
                )}
              </div>
              {selectedUserID === item.userID ? (
                <div
                  className="expandedNamePayableHoursDiv"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <p
                    className="absDataViewAbsTimelineBtn lilacColours"
                    onClick={() => {
                      memoVals.setViewAbsenceUserTimeline((x) => {
                        return {
                          show: true,
                          userID: item.userID,
                          year: selectedYear,
                          mgr: true,
                        };
                      });
                    }}
                  >
                    Timeline{" "}
                    <img
                      src={popup}
                      alt="Timeline popup"
                      className="absTimelinepopupImg"
                    />
                  </p>
                  <div className="payableExpandedRow payablePurpleBg staffAbsUnpPaFix">
                    <p className="payableExpandedTitle">
                      {selectedYear} absence
                    </p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totAbsentMins
                      )}
                    </p>
                  </div>
                  <div className="payableExpandedRow payablePurpleBg staffAbsUnpPaFix">
                    <p className="payableExpandedTitle">Absence percentage</p>
                    <p className="payableExpandedValue">{item.absPer}%</p>
                  </div>
                  <div className="payableExpandedRow payablePurpleBg staffAbsUnpPaFix">
                    <p className="payableExpandedTitle">Total paid absence</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totPaidAbsentMins
                      )}
                    </p>
                  </div>
                  <div className="payableExpandedRow payablePurpleBg staffAbsUnpPaFix">
                    <p className="payableExpandedTitle">Total unpaid absence</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totUnpaidAbsenceMins
                      )}
                    </p>
                  </div>

                  <p className="sideBoxAbsTypesMapTitle">
                    Absence period types:
                  </p>
                  <div className="sideBoxAbsTypesMap">
                    {item.absenceTypesFinal.map((abs) => {
                      return (
                        <div className="payableExpandedRow">
                          <p className="payableExpandedTitle2">
                            {shortenAbsName(abs.name)}
                          </p>
                          <p className="payableExpandedValue">
                            {dateStringer.formatMinsDurationToHours(abs.mins)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="staffAbsDataBoxSplitter"></div> */}
                  <div className="payableExpandedRow staffAbsFixedRow payablePurpleBg">
                    <p className="payableExpandedTitle3">Late count</p>
                    <p className="payableExpandedValue">{item.lateCount}</p>
                  </div>
                  {/* <div className="staffAbsDataBoxSplitter"></div> */}
                  <div className="payableExpandedRow staffAbsFixedRow payablePurpleBg">
                    <p className="payableExpandedTitle3">Late duration</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(item.totLateMins)}
                    </p>
                  </div>
                  {/* <div className="staffAbsDataBoxSplitter"></div> */}
                  <div className="payableExpandedRow staffAbsFixedRow payablePurpleBg">
                    <p className="payableExpandedTitle3">Total absence</p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totAbsentShiftMins +
                          item.totAbsentOvertimeMins +
                          item.totAbsentTilMins
                      )}
                    </p>
                  </div>
                  {/* <div className="staffAbsDataBoxSplitter"></div>
                  <div className="payableExpandedRow staffAbsFixedRow payablePurpleBg">
                    <p className="payableExpandedTitle3">
                      Tot. overtime absence
                    </p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totAbsentOvertimeMins
                      )}
                    </p>
                  </div>
                  {/* <div className="staffAbsDataBoxSplitter"></div> */}
                  {/* <div className="payableExpandedRow staffAbsFixedRow payablePurpleBg">
                    <p className="payableExpandedTitle3">
                      Tot. shift in lieu absence
                    </p>
                    <p className="payableExpandedValue">
                      {dateStringer.formatMinsDurationToHours(
                        item.totAbsentTilMins
                      )}
                    </p>
                  </div>{" "}
                  */}
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })
      : "";
  }, [staff, selectedUserID]);

  let generateAvailableYears = useMemo(() => {
    return (
      yearsArr &&
      yearsArr.map((item) => (
        <option value={item} key={item}>
          {item}
        </option>
      ))
    );
  }, [yearsArr]);

  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 10) {
      setShowSideModal(false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };
  // master return
  return (
    <div
      className={`desktopDataBox noBottomPadding ${
        modal
          ? "modalTeamHoursDataBox modalStaffAbsDataBox"
          : "teamHoursDataBoxDesktopScroll"
      } mgrReqsDataBoxColours ${memoVals.device.ios ? "paddingBottom20" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={modalPosition}
      ref={modalRef}
    >
      {/* <div className={`${modal ? "showMyToggler" : "none"}`}>
        <img
          src={whitecross}
          alt="Close"
          className="closeStatsModalCrossImg"
          onClick={() => {
            setShowReqStats(false);
          }}
        />
      </div> */}
      {window.innerWidth <= 550 && (
        <div className="modalSwiper modalSwiperMyAllows"></div>
      )}
      <div
        className="desktopTeamHoursDataBoxHeader"
        onTouchStart={handleDown}
        onTouchMove={window.innerWidth <= 550 ? handleMove : null}
        onTouchEnd={window.innerWidth <= 550 ? handleUp : null}
        onMouseDown={window.innerWidth <= 550 ? handleDown : null}
        onMouseMove={window.innerWidth <= 550 ? handleMove : null}
        onMouseUp={window.innerWidth <= 550 ? handleUp : null}
      >
        <div className="staffAbsDataBoxHeaderLeft">
          {modal ? (
            <img
              src={whitecross}
              alt="Close"
              className="closeStaffAbsDataImg"
              onClick={() => {
                setShowSideModal(false);
              }}
            />
          ) : (
            ""
          )}
          <div className="payableTitleAndTeamNameDivAbs lockerbie">
            <p
              className={`dataBoxHeading colourNavy_ ${
                modal ? "font18px" : ""
              } mgrHoursDataBoxHeading`}
              onClick={() => {
                console.log({ teamName });
              }}
            >
              Absence data{" "}
            </p>
            <p className="payableDataBoxTeamName">{teamName}</p>
          </div>
        </div>

        <select
          className={`teamHoursMyDropdown reqDataBoxPurpleBg x8198111333 ${
            memoVals.mob ? "txtFontSize15px" : ""
          }`}
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(e.target.value);
          }}
        >
          {generateAvailableYears}
        </select>
      </div>

      <div
        onScroll={(e) => {
          if (e.target.scrollTop > 0) {
            setShowTopWhiteBorder(true);
          } else {
            setShowTopWhiteBorder(false);
          }
        }}
        className={`allStaffAnnLeaveDiv ${
          modal ? "allStaffAnnLeaveDivModal" : ""
        } ${
          !memoVals.mob && !modal ? "allStaffTeamHoursDivModalDesktop" : ""
        } ${showTopWhiteBorder ? "topWhiteBorder" : ""} ${
          modal ? "paddingTop20Px staffAbsDataModalBody" : ""
        }`}
      >
        {generateAllStaffData}
      </div>
      {modal || memoVals.mob ? (
        <div
          className={`mgrAllowsFooter ${
            selectedUserID !== "all" ? "noBorder" : ""
          }`}
        >
          <p></p>{" "}
          <p
            className={`closeEditShiftBtn closeMgrPurpleBtn`}
            onClick={() => {
              setShowSideModal(false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default StaffAbsenceDataBox;
