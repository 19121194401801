import React, { useContext, useEffect, useMemo } from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import { NavLink } from "react-router-dom";

import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";
import dateStringer from "../../tools/dateStringer";

import subNavbarTriangle from "../../img/general/subNavbarTriangle.svg";

import "../../styles/accountNavbar.css";

const MgrAccountNavbar = () => {
  const { accNav, setAccNav, hideMgrAccNav, mob, setAdminPage, adminPage } =
    useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);
  const { device } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      accNav,
      setAccNav,
      hideMgrAccNav,
      mob,
      setAdminPage,
      adminPage,
      checkUserState,
      device,
    }),
    [
      accNav, //
      setAccNav, //
      hideMgrAccNav, //
      mob, //
      setAdminPage, //
      adminPage, //
      checkUserState, //
      device,
    ]
  );

  // if the page is refreshed, set the AccNav to settings
  useEffect(() => {
    // clean not needed
    if (window.location.href.includes("account")) {
      memoVals.setAccNav((x) => "settings");
    }
    if (window.location.href.includes("dashboard")) {
      memoVals.setAccNav((x) => "dashboard");
    }
  }, []);

  return (
    <div
      className={`${
        memoVals.checkUserState.permissions === 3 ||
        memoVals.hideMgrAccNav ||
        !memoVals.mob
          ? "none"
          : `purpleBgCalSubbar profAdminNavBar ${
              memoVals.device.ios ? "profAdminNavBarIos" : ""
            }`
      }`}
    >
      <NavLink to="/reports">
        <div
          className={`innerd ${
            memoVals.checkUserState.permissions > 1 ? "nonAdminInnerd" : ""
          }`}
          onClick={() => {
            memoVals.setAccNav((x) => "reports");
          }}
        >
          <p
            className={`accountNavBtn ${
              memoVals.accNav === "reports" ? "accBtnSel" : "inactiveAccBtn"
            }`}
          >
            Reports
          </p>
          <img
            className={`${
              memoVals.accNav == "reports" ? "selectPoint" : "selectPoint invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
      <NavLink to="/admin">
        {" "}
        <div
          className={`${
            memoVals.checkUserState.permissions === 1 ? "innerd" : "none"
          }`}
          onClick={() => {
            memoVals.setAccNav((x) => "admin");
          }}
        >
          <p
            className={`accountNavBtn ${
              memoVals.accNav === "admin" ? "accBtnSel" : "inactiveAccBtn"
            }`}
            onClick={() => {
              if (memoVals.adminPage === "General") {
                memoVals.setAdminPage("Leave Types");
              }
              if (memoVals.adminPage === "Leave Types") {
                memoVals.setAdminPage("Users");
              }
              if (memoVals.adminPage === "Users") {
                memoVals.setAdminPage("Teams");
              }
              if (memoVals.adminPage === "Teams") {
                memoVals.setAdminPage("Closed Days");
              }
              if (memoVals.adminPage === "Closed Days") {
                memoVals.setAdminPage("Absence");
              }
              if (memoVals.adminPage === "Absence") {
                memoVals.setAdminPage("Overtime");
              }
              if (memoVals.adminPage === "Overtime") {
                memoVals.setAdminPage("Billing");
              }
              if (memoVals.adminPage === "Billing") {
                memoVals.setAdminPage("Admins");
              }
              if (memoVals.adminPage === "Admins") {
                memoVals.setAdminPage("General");
              }
              if (memoVals.accNav !== "admin") {
                memoVals.setAccNav((x) => "admin");
              }
            }}
          >
            Admin
          </p>
          <img
            className={`${
              memoVals.accNav == "admin" ? "selectPoint" : "selectPoint invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
      <NavLink to="/staff-dashboard">
        <div
          className={`innerd ${
            memoVals.checkUserState.permissions > 1 ? "nonAdminInnerd" : ""
          }`}
          onClick={() => {
            memoVals.setAccNav((x) => "dashboard");
          }}
        >
          <p
            className={`accountNavBtn ${
              memoVals.accNav === "dashboard" ? "accBtnSel" : "inactiveAccBtn"
            }`}
          >
            Dashboard
          </p>
          <img
            className={`${
              memoVals.accNav == "dashboard"
                ? "selectPoint"
                : "selectPoint invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>

      <NavLink to="/account">
        {" "}
        <div
          className={`innerd ${
            memoVals.checkUserState.permissions > 1 ? "nonAdminInnerd" : ""
          }`}
          onClick={() => {
            memoVals.setAccNav((x) => "settings");
          }}
        >
          <p
            className={`accountNavBtn ${
              memoVals.accNav === "settings" ? "accBtnSel" : "inactiveAccBtn"
            }`}
          >
            {dateStringer.shorten(
              memoVals.checkUserState.fName,
              window.innerWidth < 450 ? 13 : 999
            ) || "Me"}
          </p>
          <img
            className={`${
              memoVals.accNav == "settings" || memoVals.accNav === "allowances"
                ? "selectPoint"
                : "selectPoint invis"
            }`}
            src={subNavbarTriangle}
            alt="selected"
          />
        </div>
      </NavLink>
    </div>
  );
};

export default MgrAccountNavbar;
