import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import "../../styles/leaveRequests.css";
import { NavLink } from "react-router-dom";

import dateStringer from "../../tools/dateStringer";

import CheckUser from "../../tools/CheckUser";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import Navbar from "../Navbar";
import serverURL from "../../serverURL";

import whitecross from "../../img/general/whitecross.svg";
import rotaClock2 from "../../img/general/rotaClock2.svg";
import lateArr from "../../img/general/lateArr.svg";
import tickWhite from "../../img/general/tickWhite.svg";
import greenTick from "../../img/general/greenTick.svg";
import downWhite2 from "../../img/general/downWhite2.svg";
import time from "../../img/general/shiftBlockAbs.svg";
import late from "../../img/general/late.svg";
import whiteTick from "../../img/general/whiteTick.svg";

import filterWhite from "../../img/general/filterWhite.svg";
import whiteAddThin from "../../img/general/whiteAddThin.svg";
import formImg from "../../img/general/form.svg";
import lateImg from "../../img/general/late.svg";
import periodIcon from "../../img/general/periodIcon.svg";
import ongoingPeriod from "../../img/general/ongoingPeriod.svg";
import rotaClock from "../../img/general/rotaClock.svg";
import perfImg from "../../img/general/performance.svg";
import whiteGo from "../../img/general/whiteGo.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import teamIcon from "../../img/general/teamIcon.svg";
import popupNavy from "../../img/general/popupNavy.svg";
import whiteBurger from "../../img/general/whiteBurger.svg";
import desktopBurger from "../../img/general/desktopBurger.svg";
import lightBlueQuestionMark from "../../img/general/lightBlueQuestionMark.svg";
import doubleTick from "../../img/general/doubleTick.svg";
import doubleTickRed from "../../img/general/doubleTickRed.svg";
import rotaPerson from "../../img/general/rotaPerson.svg";
import rotaTag from "../../img/general/rotaTag.svg";
import popup from "../../img/general/popup.svg";
import sun from "../../img/general/sun.svg";
import ongoing from "../../img/general/ongoing.svg";

import rotaDur from "../../img/general/rotaDur.svg";
import rotaTeam from "../../img/general/rotaTeam.svg";

import CalendarSubBar from "../calendar/CalendarSubBar";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import MyReqsDataBox from "../requests/MyReqsDataBox";
import MgrReqsDataBox from "../requests/MgrReqsDataBox";
import ManagerShiftModal from "../calendar/shifts/ManagerShiftModal";
import StaffAbsenceDataBox from "../calendar/StaffAbsenceDataBox";

import TopBar from "../../components/TopBar";
import appURL from "../../appURL";

const StaffAbsence = ({ isTeam }) => {
  CheckUser(false, false, "StaffAbsence.js");
  const {
    showReqStats,
    setShowReqStats,
    setShowUserReqFromNav,
    setShowReqMgrModal,
    showEditShiftModal,
    absencePage,
    setAbsencePage,
    setShowCreateFormModal,
    setShowForm,
    pushForm,
    setShowAddLeaveOnTeam,
    setOpenManagerShiftModal,
    showEditUser,
    showNotifications,
    showMessages,
    setShowAddAbsenceModal,
    device,
    countData,
    updateTabData,
    setUpdateTabData,
    setShowEditUser,
  } = useContext(DataContext);
  const { calPage, setCalPage } = useContext(CalendarContext);
  const { mob, full, width, fullButNarrow, wide } = useContext(StyleContext);
  const { checkUserState, currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showReqStats, //
      setShowReqStats, //
      setShowUserReqFromNav, //
      setShowReqMgrModal, //
      showEditShiftModal, //
      absencePage, //
      setAbsencePage, //
      setShowCreateFormModal, //
      setShowForm, //
      pushForm, //
      setShowAddLeaveOnTeam, //
      setOpenManagerShiftModal, //
      calPage, //
      setCalPage, //
      mob, //
      full, //
      width, //
      fullButNarrow, //
      wide, //
      showNotifications,
      showMessages,
      setShowAddAbsenceModal,
      checkUserState,
      currencySymbol,
      device,
      updateTabData,
      setUpdateTabData,
      setShowEditUser,
    }),
    [
      showReqStats, //
      setShowReqStats, //
      setShowUserReqFromNav, //
      setShowReqMgrModal, //
      showEditShiftModal, //
      absencePage, //
      setAbsencePage, //
      setShowCreateFormModal, //
      setShowForm, //
      pushForm, //
      setShowAddLeaveOnTeam, //
      setOpenManagerShiftModal, //
      calPage, //
      setCalPage, //
      mob, //
      full, //
      width, //
      fullButNarrow, //
      wide, //
      showNotifications,
      showMessages,
      setShowAddAbsenceModal,
      checkUserState,
      currencySymbol,
      device,
      updateTabData,
      setUpdateTabData,
      setShowEditUser,
    ]
  );

  let [enableForm, setEnableForm] = useState(false);
  let [questionsQty, setQuestionsQty] = useState(0);
  useEffect(() => {
    // memoVals.setAbsencePage(x =>"shifts");
  }, []);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={whiteTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={ongoing}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={sun}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaPerson}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      {/* Additional images */}
      <img
        src={popup}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={doubleTick}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={doubleTickRed}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={lightBlueQuestionMark}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={desktopBurger}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteBurger}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={late}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popupNavy}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock2}
        alt="Rota Clock 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lateArr}
        alt="Late Arrival"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={tickWhite}
        alt="Tick White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greenTick}
        alt="Green Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={downWhite2}
        alt="Down White 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={time}
        alt="Time"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={filterWhite}
        alt="Filter White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteAddThin}
        alt="White Add Thin"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={formImg}
        alt="Form"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={lateImg}
        alt="Late"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={periodIcon}
        alt="Period Icon"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={ongoingPeriod}
        alt="Ongoing Period"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Rota Clock"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={perfImg}
        alt="Performance"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTag}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteGo}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 33) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let showReqs = useRef();
  let [enableRank, setEnableRank] = useState(false);
  useEffect(() => {
    if (memoVals.calPage !== "team-absence") {
      memoVals.setCalPage((x) => "team-absence");
    }
  }, [memoVals.calPage]);
  let [showInstruction, setShowInstuction] = useState(false);
  useEffect(() => {
    // clean not needed
    if (window.location.href.includes("forms")) {
      memoVals.setAbsencePage((x) => "forms");
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && dateStringer.cleanParam(params.formID)) {
      memoVals.setAbsencePage((x) => "forms");
      memoVals.setShowForm((x) => {
        return { isMgr: true, formID: dateStringer.cleanParam(params.formID) };
      });
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  // let [memoVals.absencePage, setAbsencePage] = useState("periods"); // periods or shifts
  document.title = "FlowRota - Staff absence";

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  let [selectedTeam, setSelectedTeam] = useState("allStaff");

  let [absences, setAbsences] = useState([]);
  let [shifts, setShifts] = useState([]);
  let [lates, setLates] = useState([]);

  useEffect(() => {
    // clean not needed

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && dateStringer.cleanParam(params.reqID)) {
      memoVals.setShowUserReqFromNav(
        (x) => `*${dateStringer.cleanParam(params.reqID)}`
      );
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  useEffect(() => {
    // clean not needed

    if (
      memoVals.pushForm &&
      memoVals.pushForm.creating &&
      memoVals.pushForm.isMgr
    ) {
      let toReview = [];
      let remaining = [memoVals.pushForm];
      forms.forEach((f) => {
        if (f.showPendingTitle || f.split) {
        } else {
          if (!f.signedByMgr && f.filledIn) {
            toReview.push(f);
          } else {
            remaining.push(f);
          }
        }
      });
      setForms([
        {
          init: true,
          showPendingTitle: toReview.length > 0 ? true : false,
        },
        ...toReview,
        { split: true, show: toReview.length > 0 ? true : false },
        ...remaining,
      ]);

      setFormsToReviewQty(toReview.length);
    }

    if (
      memoVals.pushForm &&
      memoVals.pushForm.updating &&
      memoVals.pushForm.isMgr
    ) {
      let toReview = [];
      let remaining = [];
      forms.forEach((f) => {
        if (f.showPendingTitle || f.split) {
        } else {
          if (!f.signedByMgr && f.filledIn) {
            if (f.formID !== memoVals.pushForm.formID) {
              toReview.push(f);
            }
          } else {
            if (f.formID === memoVals.pushForm.formID) {
              remaining.push(memoVals.pushForm);
            } else {
              remaining.push(f);
            }
          }
        }
      });

      remaining.sort((a, b) => {
        return (
          dateStringer.createTimestampFromString(a.createdDs) -
          dateStringer.createTimestampFromString(b.createdDs)
        );
      });
      setForms([
        {
          init: true,
          showPendingTitle: toReview.length > 0 ? true : false,
        },
        ...toReview,
        { split: true, show: toReview.length > 0 ? true : false },
        ...remaining,
      ]);

      setFormsToReviewQty(toReview.length);
    }

    if (
      memoVals.pushForm &&
      memoVals.pushForm.deleting &&
      memoVals.pushForm.isMgr
    ) {
      let toReview = [];
      let remaining = [];
      forms.forEach((f) => {
        if (f.showPendingTitle || f.split) {
        } else {
          if (!f.signedByMgr && f.filledIn) {
            if (f.formID !== memoVals.pushForm.formID) {
              toReview.push(f);
            }
          } else {
            if (f.formID !== memoVals.pushForm.formID) {
              remaining.push(f);
            }
          }
        }
      });

      remaining.sort((a, b) => {
        return (
          dateStringer.createTimestampFromString(a.createdDs) -
          dateStringer.createTimestampFromString(b.createdDs)
        );
      });
      setForms([
        {
          init: true,
          showPendingTitle: toReview.length > 0 ? true : false,
        },
        ...toReview,
        { split: true, show: toReview.length > 0 ? true : false },
        ...remaining,
      ]);

      setFormsToReviewQty(toReview.length);
    }
  }, [memoVals.pushForm]);

  let [selectedYear, setSelectedYear] = useState(checkUserState.currClientYear);
  let [yearsArr, setYearsArr] = useState([selectedYear]);
  let [showFilter, setShowFilter] = useState(false);
  let [selectedType, setSelectedType] = useState("allTypes");
  let [types, setTypes] = useState([]);
  let [users, setUsers] = useState([]);
  let [selectedUser, setSelectedUser] = useState("all");

  let [showSideModal, setShowSideModal] = useState(false);

  let [itemID, setItemID] = useState("");
  let [itemType, setItemType] = useState("");
  let [itemFName, setItemFName] = useState("");
  let [itemLName, setItemLName] = useState("");
  let [itemUserID, setItemUserID] = useState("");
  let [formsToReviewQty, setFormsToReviewQty] = useState(0);

  let renderEditShiftModal = () => {
    if (memoVals.showEditShiftModal && !memoVals.showEditShiftModal.itemID) {
      return (
        <ManagerShiftModal
          getFromServerID={itemID}
          unitType={itemType}
          unitFName={itemFName}
          unitLName={itemLName}
          unitUserID={itemUserID}
        />
      );
    }
  };

  useEffect(() => {
    // clean not needed

    if (memoVals.mob) {
      setSelectedType("allTypes");
    }
  }, [memoVals.mob]);

  // filter states
  let [allNames, setAllNames] = useState([]); // array of objects w/ fName, lName and userID

  let [teams, setTeams] = useState([]);
  useEffect(() => {
    if (!users[0]) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-users-user-can-manage`,
          {
            someData: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setUsers(response.data.usersArr || []);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  useEffect(() => {
    // clean not needed
    if (showFilter) {
      setShowFilter(false);
    }
    if (showInstruction) {
      setShowInstuction(false);
    }
  }, [memoVals.absencePage]);

  let getListInto = () => {
    if (memoVals.absencePage === "periods") {
      return (
        <>
          Absence periods replace the need to add absent shifts to your rotas in
          order to record staff absence.
          <br />
          <br />
          Similar to leave periods requested by staff, the duration from each
          absence period is automatically calculated based on the employee's
          typical (or contracted) work schedule - either as paid or unpaid time
          off.
        </>
      );
    }
    if (memoVals.absencePage === "shifts") {
      return (
        <>
          Shifts can be marked as partially or wholly absent without the need
          for an absence period to be logged. The absence from these shifts
          count towards each staff member's total absence.
        </>
      );
    }

    if (memoVals.absencePage === "late") {
      return (
        <>
          Shifts can be marked as started late, either due to the employee
          clocking on late, or the employee's manager manually logging a late
          start. The absence caused by the late start can be recorded as either
          paid or unpaid.
        </>
      );
    }

    if (memoVals.absencePage === "forms") {
      return (
        <>
          Return-to-work forms are forms sent to employees from their managers
          to complete upon their return back to work.
        </>
      );
    }
  };
  let generateUsers = React.useMemo(() => {
    // if (users && users[0]) {
    return (
      users &&
      users
        .sort((a, b) => {
          return a.fName.localeCompare(b.fName);
        })
        .map((item) => {
          return (
            <option key={item.userID} value={item.userID}>
              {item.fName} {item.lName[0]}
            </option>
          );
        })
    );
    // }
  }, [showFilter, selectedUser]);

  let generateTeamsSelectBar = React.useMemo(() => {
    // let shortenTeamName = (val) => {
    //   if (val.length > 15) {
    //     return `${val.substr(0, 12)}...`;
    //   } else {
    //     return val;
    //   }
    // };
    return users
      .sort((a, b) => {
        return a.fName.localeCompare(b.fName);
      })
      .map((item) => {
        return (
          <option key={item.userID} value={item.userID}>
            {item.fName} {item.lName[0]}
          </option>
        );
      });
  }, [users]);

  let shortenLeaveName = (string) => {
    if (string) {
      if (memoVals.mob) {
        if (string.length > 17) {
          return `${string.substr(0, 15)}...`;
        } else {
          return string;
        }
      } else {
        if (memoVals.width < 1160) {
          if (string.length > 22) {
            return `${string.substr(0, 19)}...`;
          } else {
            return string;
          }
        } else {
          return string;
        }
      }
    }
  };
  let [forms, setForms] = useState([]);

  let [noForms, setNoForms] = useState(false);
  useEffect(() => {
    // clean not needed
    let validCount = 0;

    forms.forEach((f) => {
      if (
        selectedUser === "all" ||
        (selectedUser !== "all" && f.users && f.users.includes(selectedUser))
      ) {
        validCount++;
      }
    });

    if (!validCount) {
      let name = "";
      users.forEach((t) => {
        if (t.userID === selectedUser) {
          name = t.fName;
        }
      });
      if (name) {
        setNoForms(`No forms in ${selectedYear} for ${name}`);
      } else {
        setNoForms(`No forms in ${selectedYear}`);
      }
    } else {
      setNoForms(false);
    }
  }, [selectedUser, forms]);

  let shortenTitle = (val) => {
    if (val) {
      if (val.length > 42) {
        return `${val.substr(0, 38)}...`;
      } else {
        return val;
      }
    }
  };

  let shortenName = (val) => {
    if (val && val.length > 11) {
      return `${val.substr(0, 9)}...`;
    } else {
      return val;
    }
  };
  let generateForms = React.useMemo(() => {
    return forms.map((form, i) => {
      if (selectedUser === form.userID || selectedUser === "all") {
        if (selectedUser === "all" && form.showPendingTitle) {
          return (
            <p
              key={i}
              className={`pendingReviewFormsTitle ${
                showInstruction ? "x10983180" : ""
              }`}
            >
              Pending manager review
            </p>
          );
        } else if (selectedUser === "all" && form.split && form.show) {
          return <div className="toReviewSplitter" key={i}></div>;
        } else if (!form.split && !form.init) {
          if (selectedUser === "all" || selectedUser === form.userID) {
            return (
              <div className={`formUnitParent`} index={i}>
                <div className="clockNameAndTeamDiv">
                  <div
                    className="mgrClockNameDiv  pendClockName_"
                    onClick={(e) => {
                      e.stopPropagation();

                      memoVals.setShowEditUser((x) => form.userID);
                    }}
                  >
                    <img
                      src={form.picUrl || profilePicturePlaceholder}
                      alt={form.fName}
                      className="itemNameTabImg"
                    />{" "}
                    {form.fName} {form.lName}{" "}
                    {form.userID === memoVals.checkUserState.userID ? (
                      <span className="youSpan">(You)</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div
                  className={`mgrClockUnit noBorderRadiusBottomRight ${
                    memoVals.mob ? "borderRadius10" : ""
                  }`}
                  onClick={() => {
                    memoVals.setShowForm((x) => {
                      return { isMgr: true, formID: form.formID };
                    });
                  }}
                >
                  {form.filledIn && !form.signedByMgr ? (
                    <div className={`unsubmittedTsBlob clockAwaitBlob`}></div>
                  ) : (
                    ""
                  )}
                  {!form.filledIn ? (
                    <div
                      className={`unsubmittedTsBlob clockAwaitBlob formNotFillInBlobMgr`}
                    ></div>
                  ) : (
                    ""
                  )}
                  <div className="teamClockLeftParent">
                    <div className="tilShiftSection0"></div>
                    <div
                      className={`tilShiftSection1Clock pendingClockSection1 ${
                        window.innerWidth < 1080 && memoVals.full
                          ? "pendingClockSection1CorrectionMidFull"
                          : ""
                      } ${
                        window.innerWidth < 458
                          ? "pendingClockSection1Under458With"
                          : ""
                      }`}
                    >
                      <p className={`tilShiftDs tilShiftDsFirstRow flexStart`}>
                        {/* x222 */}
                        <img
                          src={desktopBurger}
                          alt="Clocked times"
                          className="teamClockRotaClockImg"
                        />

                        {form.title}
                      </p>

                      <p
                        className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2`}
                      >
                        <img
                          src={rotaDur}
                          alt="Clocked times"
                          className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                        />
                        {dateStringer.formatMinsDurationToHours(form.mins)}{" "}
                        absent
                      </p>
                      <p
                        className={`tilShiftDs tilShiftDs flexStart teamClocksLowerRow teamClocksLowerRow2`}
                      >
                        <img
                          src={
                            form.filledIn && form.signedByMgr
                              ? greenTick
                              : rotaPerson
                          }
                          alt="Clocked times"
                          className="teamClockRotaClockImg teamClockRotaClockImgCoffee"
                        />
                        {!form.filledIn
                          ? "Not yet completed"
                          : form.signedByMgr
                          ? `Approved`
                          : "Awaiting approval"}
                      </p>
                    </div>
                  </div>
                  <div className={`teamClockRightParent`}>
                    <div className="tilShiftSection2 pendingClockSection2">
                      <div className="tsIllus absFormIllus">
                        <p className="tsIllusTitle">
                          Absence form{" "}
                          {/* <img
                            src={editGrey}
                            alt="Timesheet"
                            className="tsIllusPencil"
                          /> */}
                        </p>
                        <p className="tsIllusValue absFormIllusVal">
                          {form.questionsLength} question
                          {form.questionsLength === 1 ? "" : "s"}
                          {/* {form.itemsLength === 1 ? "" : "s"} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {clock.oneOfTwo === "two" ? (
                  <div className="x485487597252"></div>
                ) : (
                  ""
                )}{" "} */}
              </div>
              // <div
              //   className={`formUnitParent ${
              //     i === 0 ? "firstFormUnitParent" : ""
              //   }`}
              // >
              //   <div
              //     className={`absentShiftUnit noAnimation formUnitX ${
              //       !form.signedByMgr ? "formLeftIncompleteBorderPurple" : ""
              //     } ${form.signedByMgr ? "formLeftGreen" : ""} ${
              //       form.filledIn && !form.signedByMgr
              //         ? "formLeftIncompleteBorderBlue pend x00191342323"
              //         : ""
              //     } ${memoVals.mob ? "borderRadius10" : ""}`}
              //     onClick={() => {
              //       // open form here
              //       console.log(form);
              //       memoVals.setShowForm((x) => {
              //         return { isMgr: true, formID: form.formID };
              //       });
              //     }}
              //   >
              //     <div className="absentShiftLeftParent">
              //       <div className="formLeftWrapper">
              //         <div className="formIconLeft">
              //           <img src={formImg} alt="Form" className="formIcon" />
              //         </div>
              //         <div className="reqColBarDiv reqColBarAdjust2 divPend"></div>

              //         <div
              //           className={`absentShiftUnitLeft ${
              //             window.innerWidth < 380 ? "x183981982" : ""
              //           }`}
              //         >
              //           <div
              //             className={`absenceShiftUnitLeftUpper font14 ${
              //               form.title && form.title.length > 26
              //                 ? `formTitleSmaller`
              //                 : ""
              //             } ${
              //               window.innerWidth < 380
              //                 ? `x283219831 ${
              //                     form.title && form.title > 20 ? "font10" : ""
              //                   }`
              //                 : ""
              //             } `}
              //           >
              //             {shortenTitle(form.title)}
              //           </div>
              //           <div className="absenceShiftUnitLeftLower noBorder">
              //             {form.filledIn ? (
              //               <div className="formMgrApproved usrCompleteForm">
              //                 <img
              //                   src={greenTick}
              //                   alt="Approved"
              //                   className="formTick"
              //                 />
              //                 Filled in
              //               </div>
              //             ) : (
              //               <p className="notYetFilledIn">Not yet completed</p>
              //             )}
              //           </div>
              //         </div>
              //       </div>
              //       <div className="absenceShiftUnitRight x19749711">
              //         <div
              //           className={`absenceShiftUnitLeftUpper colourAbsenceBlue ${
              //             !form.filledIn ? "margiTop10" : ""
              //           } x19749171 flexEnd_`}
              //         >
              //           {form.mins ? (
              //             <p className="absentShiftScheduledSpan x140719741 invis">
              //               Absent:
              //             </p>
              //           ) : (
              //             ""
              //           )}{" "}
              //           <p
              //             className={`absenceSpanColour ${
              //               !form.mins ? "unkDurStr" : "formMinsStr x1974101"
              //             }`}
              //           >
              //             {form.mins
              //               ? dateStringer.formatMinsDurationToHours(form.mins)
              //               : "Unknown duration"}
              //           </p>
              //         </div>
              //         <div className="absenceShiftUnitLeftLower displayFlexEnd x131314 noBorder">
              //           {form.signedByMgr ? (
              //             <div className="formMgrApproved">
              //               <img
              //                 src={tickWhite}
              //                 alt="Approved"
              //                 className="formTick"
              //               />
              //               Signed off
              //             </div>
              //           ) : (
              //             ""
              //           )}{" "}
              //           {!form.signedByMgr && form.filledIn ? (
              //             <div className="rtwFormNotYetApp">
              //               Not yet signed off
              //             </div>
              //           ) : (
              //             ""
              //           )}
              //         </div>
              //       </div>
              //     </div>
              //     <div className="itemTongue formTongue">
              //       <p className="tongueTxt">Form</p>
              //     </div>
              //   </div>
              //   <div
              //     className={`formUnitName ${
              //       !form.signedByMgr && form.filledIn ? "pendClockName" : ""
              //     }`}
              //   >
              //     <img
              //       src={form.picUrl || profilePicturePlaceholder}
              //       alt={form.fName}
              //       className="itemNameTabImg"
              //     />{" "}
              //     {form.fName} {form.lName}{" "}
              //     {form.userID === memoVals.checkUserState.userID ? (
              //       <span className="youSpan">(You)</span>
              //     ) : (
              //       ""
              //     )}
              //   </div>
              //   {/* <div
              //     className={`formCreatedTab ${
              //       form.signedByMgr ? "invis" : ""
              //     } ${form.filledIn && !form.signedByMgr ? "formSubName" : ""}`}
              //   >
              //     Issued{" "}
              //     {dateStringer.printedDateWithYrFromDWithoutDay(
              //       form.createdDs
              //     )}
              //   </div> */}
              // </div>
            );
          }
        }
      }
    });
  }, [forms, selectedUser, showInstruction]);

  let renderSideModal = () => {
    if (showSideModal && !showEditUser) {
      return (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setShowSideModal(false);
          }}
        >
          <StaffAbsenceDataBox
            modal={true}
            teamID={selectedTeam}
            year={selectedYear}
            years={yearsArr}
            setYear={setSelectedYear}
            setShowSideModal={setShowSideModal}
          />
        </div>
      );
    }
  };

  let [allowSelfHoursApprove, setAllowSelfHoursApprove] = useState([]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();
    const cancelSource3 = axios.CancelToken.source();
    const cancelSource4 = axios.CancelToken.source();
    const cancelSource5 = axios.CancelToken.source();
    if (!yearsArr[1]) {
      axios
        .post(
          `${serverURL}/get-available-years`,
          {
            userOnly: false,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setYearsArr(response.data.data || []);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (memoVals.absencePage === "periods") {
      axios
        .post(
          `${serverURL}/get-absence-periods-for-mgr`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
            teamID: selectedTeam,
            getCosts: memoVals.mob ? false : true,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource2.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setYearsArr(response.data.yearsArr || []);
            setTypes(response.data.absenceTypes);
            setAbsences(response.data.arr);
            setUsers(response.data.users);
            setEnableRank(response.data.enableRank);
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (memoVals.absencePage === "shifts") {
      axios
        .post(
          `${serverURL}/get-absent-shifts-for-mgr`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
            teamID: selectedTeam,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource3.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (
              response.data.allowSelfHoursApprove &&
              Array.isArray(response.data.allowSelfHoursApprove)
            ) {
              setAllowSelfHoursApprove(response.data.allowSelfHoursApprove);
            }
            setShifts(response.data.arr);
            setUsers(response.data.users);
            setDataLoaded(true);
            setEnableRank(response.data.enableRank);

            // setYearsArr(response.data.yearsArr || []);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (memoVals.absencePage === "late") {
      axios
        .post(
          `${serverURL}/get-late-shifts-for-mgr`,
          {
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: parseInt(selectedYear),
            teamID: selectedTeam,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource4.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setLates(response.data.arr);
            setUsers(response.data.users);
            setDataLoaded(true);
            setEnableRank(response.data.enableRank);

            // setYearsArr(response.data.yearsArr || []);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (memoVals.absencePage === "forms") {
      axios
        .post(
          `${serverURL}/get-forms-for-mgr`,
          {
            showArchived: false,
            nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
            year: selectedYear,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource5.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            let toReview = [];
            let remaining = [];
            response.data.forms.forEach((f) => {
              if (!f.signedByMgr && f.filledIn) {
                toReview.push(f);
              } else {
                remaining.push(f);
              }
            });
            // setForms(response.data.forms);
            if (!response.data.forms[0]) {
              setForms([]);
            } else {
              setForms([
                {
                  init: true,
                  showPendingTitle: toReview.length > 0 ? true : false,
                },
                ...toReview,
                { split: true, show: toReview.length > 0 ? true : false },
                ...remaining,
              ]);
            }
            setFormsToReviewQty(toReview.length);
            setDataLoaded(true);
            setEnableRank(response.data.enableRank);
            setEnableForm(response.data.enableForm);
            setQuestionsQty(response.data.questionsQty);
            // setYearsArr(response.data.yearsArr || []);

            console.log(response.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
      cancelSource3.cancel("Component unmounted");
      cancelSource4.cancel("Component unmounted");
      cancelSource5.cancel("Component unmounted");
    };
  }, [
    selectedYear,
    memoVals.absencePage,
    memoVals.mob,
    memoVals.updateTabData,
  ]);

  useEffect(() => {
    // clean not needed
    if (showReqs.current) {
      showReqs.current.scrollTop = 0;
    }
  }, []);

  let createDate = (start, end, ongoing) => {
    if (start && end) {
      let startDate = parseInt(start.substr(9, 2));
      let startMonth = parseInt(start.substr(6, 2));
      let endDate = parseInt(end.substr(9, 2));
      let endMonth = parseInt(end.substr(6, 2));
      let yr = `'${JSON.stringify(parseInt(end.substr(1, 4))).substr(2, 2)}`;
      let startMonthOutput = "";
      if (startMonth === 0) {
        startMonthOutput = "Jan";
      } else if (startMonth === 1) {
        startMonthOutput = "Feb";
      } else if (startMonth === 2) {
        startMonthOutput = "Mar";
      } else if (startMonth === 3) {
        startMonthOutput = "Apr";
      } else if (startMonth === 4) {
        startMonthOutput = "May";
      } else if (startMonth === 5) {
        startMonthOutput = "Jun";
      } else if (startMonth === 6) {
        startMonthOutput = "Jul";
      } else if (startMonth === 7) {
        startMonthOutput = "Aug";
      } else if (startMonth === 8) {
        startMonthOutput = "Sep";
      } else if (startMonth === 9) {
        startMonthOutput = "Oct";
      } else if (startMonth === 10) {
        startMonthOutput = "Nov";
      } else if (startMonth === 11) {
        startMonthOutput = "Dec";
      }

      let endMonthOutput = "";
      if (endMonth === 0) {
        endMonthOutput = "Jan";
      } else if (endMonth === 1) {
        endMonthOutput = "Feb";
      } else if (endMonth === 2) {
        endMonthOutput = "Mar";
      } else if (endMonth === 3) {
        endMonthOutput = "Apr";
      } else if (endMonth === 4) {
        endMonthOutput = "May";
      } else if (endMonth === 5) {
        endMonthOutput = "Jun";
      } else if (endMonth === 6) {
        endMonthOutput = "Jul";
      } else if (endMonth === 7) {
        endMonthOutput = "Aug";
      } else if (endMonth === 8) {
        endMonthOutput = "Sep";
      } else if (endMonth === 9) {
        endMonthOutput = "Oct";
      } else if (endMonth === 10) {
        endMonthOutput = "Nov";
      } else if (endMonth === 11) {
        endMonthOutput = "Dec";
      }

      if (ongoing) {
        return `Started ${startDate} ${startMonthOutput}`;
      } else {
        if (startMonthOutput !== endMonthOutput) {
          return `${startDate} ${startMonthOutput} - ${endDate} ${endMonthOutput} ${yr}`;
        } else if (
          startMonthOutput === endMonthOutput &&
          startDate === endDate
        ) {
          return `${startDate} ${endMonthOutput} ${yr}`;
        } else {
          return `${startDate} - ${endDate} ${endMonthOutput} ${yr}`;
        }
      }
    }
  };

  let generateTypes = React.useMemo(() => {
    return types.map((t) => {
      return <option value={t.absenceTypeID}>{t.name}</option>;
    });
  }, [types, showFilter, selectedType]);

  let generateYearsArr = useMemo(() => {
    yearsArr.sort();
    return (
      yearsArr &&
      yearsArr.map((year) => {
        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })
    );
  }, [yearsArr]);

  let generateAbsences = React.useMemo(() => {
    return absences.map((item, i) => {
      if (selectedType === "allTypes" || selectedType === item.leaveTypeID) {
        if (selectedUser === "all" || selectedUser === item.userID) {
          let countdown = () => {
            let startDateObj = new Date(
              dateStringer.createTimestampFromString(item.reqStart)
            );

            let endDateObj = new Date(
              dateStringer.createTimestampFromString(item.reqEnd)
            );
            let todayObj = new Date();
            todayObj.setHours(0, 0, 0, 0);

            let dayDiff = Math.round(
              (startDateObj.getTime() - todayObj.getTime()) / 86400000
            );

            if (!item.ongoing && todayObj.getTime() >= endDateObj.getTime()) {
              // has ended already, or ended today
              let endedDiff = Math.round(
                (todayObj.getTime() - endDateObj.getTime()) / 86400000
              );

              if (endedDiff === 0) {
                return `Ended today`;
              } else {
                if (endedDiff === 1) {
                  return `Ended yesterday`;
                } else {
                  return `Ended ${endedDiff} days ago`;
                }
              }
            } else {
              if (dayDiff === 1) {
                return "Starts tomorrow";
              } else {
                if (dayDiff === 0) {
                  return "Starts today";
                } else {
                  if (dayDiff < 0) {
                    let val = dayDiff * -1;

                    if (val === 1) {
                      return `Started yesterday`;
                    } else {
                      return `Started ${val} days ago`;
                    }
                  } else {
                    return `Starts in ${dayDiff} days`;
                  }
                }
              }
            }
          };

          let durationString = () => {
            let exc = 0;
            if (item.excessMins) {
              exc = item.excessMins;
            }
            return `${dateStringer.formatMinsDurationToHours(
              item.totMins + exc,
              true
            )}`;
          };
          //  0 0 0 0 0 0 0 0 0
          return (
            <div className="reqUnitParent" key={item.reqID}>
              <div
                onClick={() => {
                  memoVals.setShowReqMgrModal((x) => item.reqID);
                }}
                className={`mgrReqItem pend ${
                  memoVals.mob ? "borderRadius10x" : ""
                } leaveReqItem leaveReqItemStaffAbs`}
              >
                {" "}
                {item.ongoing ? (
                  <div className="unsubmittedTsBlob annLeaveBlob"></div>
                ) : (
                  ""
                )}
                <div className="reqPlaneDiv">
                  <img
                    src={item.ongoing ? ongoingPeriod : periodIcon}
                    alt="Requested leave"
                    className={`reqPlaneIcon opac05 maxWidth14 ${
                      memoVals.mob ? "reqPlaneIconMob" : ""
                    }`}
                  />
                </div>
                <div className="reqColBarDiv divPend"></div>
                <div
                  className={`mgrReqItemCol2 ${
                    memoVals.mob ? "mgrReqItemCol2Mob" : ""
                  }`}
                >
                  <div className="mgrReqTopRow">
                    <div className="mgrReqName mgrReqName2">
                      {/* ------ */}
                      <div
                        className={`mgrReqDateStrip pendDateDiv x29898988444 staffAbsPeriodLeftContent`}
                      >
                        <div
                          className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons `}
                        >
                          <img
                            src={desktopBurger}
                            alt="Request duration"
                            className="rotaSunReqs leaveNameBurger"
                          />
                          {dateStringer.shorten(
                            item.absenceTypeName,
                            memoVals.mob ? 20 : 30
                          )}
                        </div>

                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan `}
                        >
                          <img
                            src={sun}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          {item.totDays} day
                          {item.totDays !== 1 ? "s" : ""}{" "}
                          <span className="ba0000 x484889598533">absent</span>
                        </div>
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur `}
                        >
                          <img
                            src={item.ongoing ? ongoing : rotaDur}
                            alt="Request duration"
                            className={`rotaSunReqs ${
                              item.ongoing ? "x24894389348934" : ""
                            }`}
                          />
                          {item.ongoing ? "Ongoing" : durationString()}
                        </div>
                      </div>
                      {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                    </div>
                    <div className="absTimelineVal absTimelineValReqs rowReverse_ marginRight0">
                      <div
                        className={`absCalDiv ${
                          item.reqStart === item.reqEnd ? "invis" : ""
                        }`}
                      >
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div
                          className={`absCalDivLower ${
                            1 + 1 === 2
                              ? "absCalDivLowerAllTime"
                              : "absCalDivLowerAllTime"
                          }`}
                        >
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs ba0000">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.reqStart
                            )}
                          </p>
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.reqStart.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerYear">
                            {item.reqStart.substr(1, 4)}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`absCanDivSplitter ${
                          item.reqStart === item.reqEnd ? "invis" : ""
                        }`}
                      ></div>
                      <div className={`absCalDiv`}>
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div
                          className={`absCalDivLower ${
                            1 + 1 === 2
                              ? "absCalDivLowerAllTime"
                              : "absCalDivLowerAllTime"
                          }`}
                        >
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs ba0000">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.reqEnd
                            )}
                          </p>{" "}
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.reqEnd.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerYear">
                            {item.reqStart.substr(1, 4)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`mgrReqNameTab pendClockName tabStaffAbsPeriod`}
                onClick={() => {
                  memoVals.setShowEditUser((x) => item.userID);
                }}
              >
                <img
                  src={item.picUrl || profilePicturePlaceholder}
                  alt={item.fName}
                  className="itemNameTabImg"
                />{" "}
                {item.fName} {item.lName}
                {item.userID === memoVals.checkUserState.userID ? (
                  <span className="youSpan">(You)</span>
                ) : (
                  ""
                )}
              </div>
            </div>
            // <div
            //   className={`mgrAbsencePeriodParent ${
            //     i === 0 ? "mgrAbsencePeriodParentFirst" : ""
            //   }`}
            //   onClick={() => {
            //     memoVals.setShowReqMgrModal((x) => item.reqID);
            //     //   memoVals.setShowUserReqFromNav(x =>`*${item.reqID}`);
            //   }}
            // >
            //   <div
            //     className={`mgrReqItem ${item.ongoing ? "pend" : ""} ${
            //       memoVals.mob ? "borderRadius10" : ""
            //     } x134814`}
            //     key={item.reqID}
            //   >
            //     <img
            //       src={item.ongoing ? ongoingPeriod : periodIcon}
            //       alt="Period"
            //       className={`absencePeriodIcon ${
            //         memoVals.mob ? "absencePeriodIconMob" : ""
            //       }`}
            //     />
            //     <div className="reqColBarDiv divPend"></div>

            //     <div
            //       className={`mgrReqItemCol2 ${
            //         memoVals.mob ? "mobAbsenceCol2" : ""
            //       }`}
            //     >
            //       <div className="mgrReqTopRow">
            //         {/* <p className="mgrReqName">{item.leaveTypeName}</p> */}

            //         <div
            //           onClick={() => {
            //             console.log(
            //               createDate(item.reqStart, item.reqEnd, item.ongoing)
            //                 .length > 14
            //                 ? "MAKE SMALLER"
            //                 : "13831981"
            //             );
            //           }}
            //           className={`mgrReqName staffAbsentShiftsName ${
            //             createDate(item.reqStart, item.reqEnd, item.ongoing)
            //               .length > 14 &&
            //             (memoVals.mob || memoVals.fullButNarrow)
            //               ? "x1848918"
            //               : ""
            //           }`}
            //         >
            //           {createDate(item.reqStart, item.reqEnd, item.ongoing)}
            //           {/* {item.fName || ""} {item.lName[0] || ""} */}
            //         </div>

            //         <p
            //           className={`mgrReqTypeName startsInTxtAbsence mgrAbsName ${
            //             dateStringer.shorten(
            //               item.absenceTypeName || "",
            //               memoVals.mob || memoVals.fullButNarrow ? 18 : 18
            //             ).length > 18 &&
            //             // (memoVals.mob || memoVals.fullButNarrow) &&
            //             createDate(item.reqStart, item.reqEnd, item.ongoing)
            //               .length > 16
            //               ? "x18918491"
            //               : ""
            //           }`}
            //         >
            //           {dateStringer.shorten(
            //             item.absenceTypeName || "",
            //             memoVals.full && !memoVals.fullButNarrow ? 22 : 15
            //             // memoVals.mob || memoVals.fullButNarrow ? 15 : 99
            //           )}{" "}
            //         </p>
            //       </div>
            //       <div className="mgrReqBottomRow">
            //         <p
            //           className={`mgrReqDateStrip ${
            //             createDate(item.reqStart, item.reqEnd, item.ongoing)
            //               .length > 14
            //               ? "mgrReqDateStripLong"
            //               : ""
            //           } ${item.ongoing ? "pendDateDiv" : ""}`}
            //         >
            //           {item.totDays} day
            //           {item.totDays !== 1 ? "s" : ""}
            //           {/* <br />
            //           <span
            //             className={`mgrReqDayLengthSpan mgrReqDayLengthSpanNewLine

            //           `}
            //           >
            //             {item.totDays} day
            //             {item.totDays !== 1 ? "s" : ""}
            //           </span> */}
            //         </p>
            //         <div className="mgrReqDurAndCostDiv">
            //           {item.salaried && memoVals.mob && 2 + 2 === 5 ? (
            //             ""
            //           ) : (
            //             <p
            //               className={`mgrReqDurTxt ${
            //                 item.ongoing ? "ongoingTab" : ""
            //               } ${!item.unpaid && mob ? "marginRight0" : ""}`}
            //               onClick={() => {
            //                 console.log(item);
            //               }}
            //             >
            //               {item.ongoing ? "Ongoing" : durationString()}
            //             </p>
            //           )}
            //           {item.unpaid ? (
            //             <p className="mgrReqCostTxt unpaidCostColours">
            //               Unpaid
            //             </p>
            //           ) : item.salaried && !item.ongoing ? (
            //             <p
            //               className={`mgrReqCostTxt ${
            //                 memoVals.mob ? "none" : ""
            //               }`}
            //             >
            //               Salary{" "}
            //               {item.cost > 0 ? (
            //                 <span className="reqSalPlusSpan">
            //                   + {currencySymbol}
            //                   {dateStringer.formatCost(item.cost)}
            //                 </span>
            //               ) : (
            //                 ""
            //               )}
            //             </p>
            //           ) : (
            //             <p
            //               className={`mgrReqCostTxt ${
            //                 item.ongoing || memoVals.mob ? "none" : ""
            //               }`}
            //             >
            //               {currencySymbol}
            //               {dateStringer.formatCost(item.cost)}
            //             </p>
            //           )}
            //         </div>
            //       </div>
            //     </div>
            //     <div className="itemTongue absentShiftTongue">
            //       <p className="tongueTxt">Absence</p>
            //     </div>
            //   </div>
            //   <div
            //     className={`absPeriodName ${
            //       item.ongoing ? "absPeriodNameOngoing pendClockName" : ""
            //     }`}
            //   >
            //     <img
            //       src={item.picUrl || profilePicturePlaceholder}
            //       alt={item.fName}
            //       className="itemNameTabImg"
            //     />{" "}
            //     {item.fName} {item.lName}{" "}
            //     {item.userID === memoVals.checkUserState.userID ? (
            //       <span className="youSpan">(You)</span>
            //     ) : (
            //       ""
            //     )}
            //   </div>
            // </div>
          );
        }
      } else {
        return;
      }
    });
  }, [
    absences,
    selectedType,
    selectedUser,
    showFilter,
    memoVals.mob,
    memoVals.fullButNarrow,
  ]);

  let generateShifts = React.useMemo(() => {
    return shifts.map((item, i) => {
      if (selectedUser === item.userID || selectedUser === "all") {
        let printShiftDurationSubTxt = () => {
          // house
          if (item.durMins === 0 || !item.durMins) {
            return "0 mins";
          }
          if (
            // unit.type === "shift" &&
            item.absent &&
            !item.partialAbsent &&
            item.unpaidAbsent
          ) {
            return "0 hours";
          } else {
            return `${dateStringer.formatMinsDurationToHours(
              item.durMins || 0,
              false,
              true
            )}`;
          }
        };

        let rows = 4;
        if (item.absent) {
          rows++;
        }
        if (
          item.tilRequested ||
          item.outstandingChanges ||
          item.selfLoggedAbsence
          // ||
          // unit.data.logged
        ) {
          rows++;
        }

        if (item.tags && Array.isArray(item.tags) && item.tags[0]) {
          rows++;
        }

        // rows: 3, 4, 5, 6
        return (
          <div className="reqUnitParent" key={i}>
            <div
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: item.type === "shift" ? item.shiftID : "",
                    tilID: item.type !== "shift" ? item.tilID : "",
                    fName: item.fName,
                    lName: item.lName,
                    openedFromShiftsPage: true,
                    type: item.type,
                  };
                });
              }}
              className={`mgrReqItem pend ${
                memoVals.mob ? "borderRadius10x" : ""
              } leaveReqItem ${
                rows === 3
                  ? "shiftItemRows3"
                  : rows === 4
                  ? "shiftItemRows4"
                  : rows === 5
                  ? "shiftItemRows5"
                  : rows === 6
                  ? "shiftItemRows6"
                  : ""
              }`}
            >
              {" "}
              <div
                className={`unsubmittedTsBlob annLeaveBlob ${
                  item.tilRequested ||
                  item.outstandingChanges ||
                  item.selfLoggedAbsence
                    ? ""
                    : "none"
                } ${
                  rows === 3
                    ? "annLeaveBlobRows3"
                    : rows === 4
                    ? "annLeaveBlobRows4"
                    : rows === 5
                    ? "annLeaveBlobRows5"
                    : rows === 6
                    ? "annLeaveBlobRows6"
                    : ""
                }`}
              ></div>
              <div className="reqPlaneDiv opac06">
                <img
                  src={time}
                  alt="Requested leave"
                  className={`reqPlaneIcon shIconImgTeamSh ${
                    memoVals.mob ? "reqPlaneIconMob" : ""
                  }`}
                />
              </div>
              <div className="reqColBarDiv divPend"></div>
              <div
                className={`mgrReqItemCol2 ${
                  memoVals.mob ? "mgrReqItemCol2Mob" : ""
                }`}
              >
                <div className="mgrReqTopRow">
                  <div className="mgrReqName mgrReqName2">
                    {/* ------ */}
                    <div
                      className={`mgrReqDateStrip pendDateDiv x29898988444 ${
                        rows === 3
                          ? "shContentLeftRows3"
                          : rows === 4
                          ? "shContentLeftRows4"
                          : rows === 5
                          ? "shContentLeftRows5"
                          : rows === 6
                          ? "shContentLeftRows6"
                          : ""
                      }`}
                    >
                      <div
                        className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                      >
                        <img
                          src={desktopBurger}
                          alt="Request duration"
                          className="rotaSunReqs leaveNameBurger"
                        />

                        <p
                          className={`shTypeNameStrip ${
                            (item.type === "til" || item.type === "overtime") &&
                            window.innerWidth < 390
                              ? "shTypeNameStripSmall"
                              : ""
                          }`}
                        >
                          {item.ghost
                            ? "Paid day off"
                            : item.type === "shift"
                            ? "Shift absence"
                            : item.type === "overtime" || item.type === "til"
                            ? "Overtime absence"
                            : "Other---"}
                        </p>
                        {item.requireAccept ? (
                          !item.accepted ? (
                            <img
                              src={lightBlueQuestionMark}
                              alt="Not yet accepted"
                              className="shItemAccQImg shItemAccQImgQ"
                            />
                          ) : (
                            <img
                              src={
                                item.absent || item.selfLoggedAbsence
                                  ? doubleTickRed
                                  : doubleTick
                              }
                              alt="Accepted"
                              className="shItemAccQImg"
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                      >
                        <img
                          src={rotaDur}
                          alt="Request duration"
                          className="rotaSunReqs"
                        />
                        {printShiftDurationSubTxt()}
                      </div>
                      <div
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                      >
                        <img
                          src={rotaTeam}
                          alt="Request duration"
                          className="rotaSunReqs"
                        />
                        {dateStringer.shorten(
                          item.teamName,
                          window.innerWidth > 1056 ? 28 : 18
                        )}
                      </div>

                      {item.absent ? (
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur ba0000 makeBold marginTop6`}
                        >
                          <img
                            src={rotaPerson}
                            alt="Request duration"
                            className="rotaSunReqs marginBottom0"
                          />

                          <p className="teamShiftStringTxt fontWeight400">
                            {item.absent
                              ? item.unpaidAbsent
                                ? "Unpaid"
                                : "Paid"
                              : ""}
                            {item.absent
                              ? item.partialAbsent
                                ? `: ${dateStringer.dsToTimeStrip(
                                    item.partialStart,
                                    true
                                  )} - ${dateStringer.dsToTimeStrip(
                                    item.partialEnd,
                                    true
                                  )}`
                                : " absence"
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.tags[0] ? (
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur flexStart marginTop6`}
                        >
                          <img
                            src={rotaTag}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          <div className="myHoursTagsInline x28987654345654">
                            <div
                              className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 ${
                                item.tags.length === 1 ? "noBorder" : ""
                              }`}
                            >
                              {dateStringer.shorten(
                                item.tags[0],
                                window.innerWidth < 400 ? 10 : 12
                              )}
                            </div>
                            {item.tags &&
                            Array.isArray(item.tags) &&
                            item.tags.length > 1 ? (
                              <div
                                className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 noBorder`}
                              >
                                +{item.tags.length - 1}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.selfLoggedAbsence ||
                      item.outstandingChanges ||
                      item.tilRequested ? (
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur  marginTop6 ${
                            item.selfLoggedAbsence
                              ? "ba0000 makeBold"
                              : item.outstandingChanges || item.tilRequested
                              ? "colour00aaff makeBold"
                              : ""
                          }`}
                        >
                          <img
                            src={rotaPerson}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          <p className="teamShiftStringTxt">
                            {item.selfLoggedAbsence
                              ? "Logged absence"
                              : item.outstandingChanges
                              ? "Change requested"
                              : item.tilRequested
                              ? "TOIL requested"
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                  </div>
                  <div className="teamShRightBlock">
                    <div className="absTimelineVal absTimelineValReqs rowReverse marginRight0">
                      <div
                        className={`shItemTimes ${item.ghost ? "invis" : ""}`}
                      >
                        <p className="shItemTimeRow">
                          {item.start.length > 10
                            ? dateStringer.dsToTimeStrip(item.start, true)
                            : item.start}
                        </p>
                        <div className="shItemTimeRowSplitter"></div>
                        <p className="shItemTimeRow">
                          {item.end.length > 10
                            ? dateStringer.dsToTimeStrip(item.end, true)
                            : item.end}
                        </p>
                      </div>
                      <div className={`absCalDiv`}>
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div className={`absCalDivLower absCalDivLowerAllTime`}>
                          {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs">
                            {dateStringer.getThreeDayLettersFromDs(item.end)}
                          </p>{" "}
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.start.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerYear">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.start
                            )}
                            {/* {unit.startDs.substr(1, 4)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <p className={`teamClockBreakDurStrip bbcada marginTop20`}>
                      {dateStringer.shorten(
                        item.teamName,
                        memoVals.mob || memoVals.fullButNarrow ? 17 : 35
                      )}{" "}
                      <img
                        src={rotaTeam}
                        alt="Team"
                        className="teamIconHours opac05"
                      />
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`mgrReqNameTab pendClockName ${
                rows === 3
                  ? "shNameTabRows3"
                  : rows === 4
                  ? "shNameTabRows4"
                  : rows === 5
                  ? "shNameTabRows5"
                  : rows === 6
                  ? "shNameTabRows6"
                  : ""
              }`}
              onClick={() => {
                memoVals.setShowEditUser((x) => item.userID);
              }}
            >
              <img
                src={item.picUrl || profilePicturePlaceholder}
                alt={item.fName}
                className="itemNameTabImg"
              />{" "}
              {item.fName} {item.lName}
              {item.userID === memoVals.checkUserState.userID ? (
                <span className="youSpan">(You)</span>
              ) : (
                ""
              )}{" "}
            </div>
            {/* sub bar begins */}
            {(item.logged || item.selfLoggedAbsence) && (
              <div
                className={`mgrClockSubBar ${
                  memoVals.mob ? "mgrClockSubBarMob" : ""
                }
              pendClockSub teamShiftsSubAppBar ${
                rows === 6 ? "teamShiftsSubAppBarRows5" : ""
              }`}
              >
                <div
                  className={`clocksSchedSubLeft ${
                    memoVals.mob ? "font12px" : ""
                  } `}
                >
                  {/* <div className={`clockSchedTitle`}>Title here</div> */}
                  {item.selfLoggedAbsence ? (
                    <p className="clockSchedValue fontSize14 lineHeight15">
                      <span className="subBarLoggedTitle subBarLoggedTitle2">
                        Requires action
                      </span>{" "}
                    </p>
                  ) : (
                    // <p className="clockSchedValue fontSize14 lineHeight15">
                    //   <span className="subBarLoggedTitle">Logged:</span>{" "}
                    //   {unit.data.logged}
                    // </p>
                    <p className="clockSchedValue fontSize14 lineHeight15">
                      <span className="subBarLoggedTitle subBarLoggedTitle2">
                        Logged:{" "}
                        <span className="colour00aaff">{item.logged}</span>
                      </span>{" "}
                    </p>
                  )}
                </div>
                <div
                  className={`clocksSchedSubRight ${
                    memoVals.mob ? "flexEnd" : ""
                  }`}
                >
                  {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                  {item.selfLoggedAbsence ? (
                    <p
                      className={`approveClock ${
                        item.userID === memoVals.checkUserState.userID &&
                        !allowSelfHoursApprove.includes(item.teamID) &&
                        !memoVals.checkUserState.permissions !== 1
                          ? "invis"
                          : ""
                      } reqActBtn`}
                      onClick={() => {
                        memoVals.setOpenManagerShiftModal((x) => {
                          return {
                            shiftID: item.type === "shift" ? item.shiftID : "",
                            tilID: item.type !== "shift" ? item.tilID : "",
                            fName: item.fName,
                            lName: item.lName,
                            openedFromShiftsPage: true,
                            type: item.type,
                          };
                        });
                      }}
                    >
                      Action{" "}
                      <img
                        src={popup}
                        alt="Approve clock card"
                        className="approveCCtickimg reqActpopup"
                      />
                    </p>
                  ) : (
                    <p
                      className={`approveClock ${
                        item.userID === memoVals.checkUserState.userID &&
                        !allowSelfHoursApprove.includes(item.teamID) &&
                        !memoVals.checkUserState.permissions !== 1
                          ? "invis"
                          : ""
                      }`}
                      onClick={() => {}}
                    >
                      Approve{" "}
                      <img
                        src={whiteTick}
                        alt="Approve clock card"
                        className="approveCCtickimg"
                      />
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        );
        // let dateStrip = `${dateStringer.tsToDayAndDate(
        //   dateStringer.createTimestampFromString(item.start),
        //   false
        // )}`;

        // let oldStart = "00:00";
        // let oldEnd = "00:00";
        // oldStart = `${item.start.substr(12, 2)}:${item.start.substr(15, 2)}`;
        // oldEnd = `${item.end.substr(12, 2)}:${item.end.substr(15, 2)}`;

        // let printShiftDurationSubTxt = () => {
        //   // house
        //   // if (item.type === "shift") {
        //   if (item.absenceDurMins === 0) {
        //     return "0 mins";
        //   }
        //   // } else {
        //   //   if (item.durMins === 0) {
        //   //     return "0 mins";
        //   //   }
        //   // }
        //   // if (
        //   //   // unit.type === "shift" &&
        //   //   item.absent &&
        //   //   !item.partialAbsent &&
        //   //   item.unpaidAbsent
        //   // ) {
        //   //   return "0 hours";
        //   // } else {
        //   return `${dateStringer.formatMinsDurationToHours(
        //     `${item.absenceDurMins}`
        //   )}`;

        //   // `${item.duration / 1000 / 60}${item.durMins}`;
        //   // }
        // };
        // return (
        //   <div
        //     key={item.shiftID || item.tilID}
        //     className={`teamShiftUnit ${i === 0 ? "firstTeamShiftUnit" : ""} ${
        //       item.absent && item.tags[0] ? "tagsAndAbsent" : ""
        //     } ${item.absent && !item.tags[0] ? "noTagsAbsent" : ""}

        //   ${
        //     selectedUser === "all" || selectedUser === item.userID ? "" : "none"
        //   }

        //   `}
        //   >
        //     <div
        //       onClick={() => {
        //         console.log({ item });
        //         // console.log(
        //         //   item.shiftID || "noshiftid",
        //         //   item.tilID || "notilid"
        //         // );
        //         memoVals.setOpenManagerShiftModal((x) => {
        //           return {
        //             shiftID: item.shiftID,
        //             tilID: item.tilID,
        //             fName: item.fName,
        //             lName: item.lName,
        //             openedFromShiftsPage: true,
        //             type: item.type,
        //           };
        //         });
        //       }}
        //       className={`${
        //         selectedUser === "all" || selectedUser === item.userID
        //           ? `${
        //               loading
        //                 ? "none"
        //                 : `tilShiftUnit teamTilShiftUnit ${
        //                     item.tags[0] || item.absent
        //                       ? "tagsOnShiftExtend"
        //                       : ""
        //                   } ${item.ghost ? "ghostBgShiftTilItem" : ""}`
        //             }`
        //           : "none"
        //       }

        //   ${memoVals.mob ? "borderRadius10" : ""}

        //   `}
        //     >
        //       <div className={`myHoursLeftWrapper`}>
        //         <img src={time} alt="My hours" className="timeHoursImg" />{" "}
        //         <div className="reqColBarDiv reqColBarAdjust1 divDec"></div>
        //         <div className={`tilShiftSection1team marginLeft16px`}>
        //           <p
        //             className={`tilShiftUnitName ${
        //               item.tilApproved && !item.wholeTil ? "x9749725" : ""
        //             }`}
        //           >
        //             {dateStrip}
        //             <span className="ndStRdSpan2">
        //               {dateStrip[dateStrip.length - 1] === "1"
        //                 ? dateStrip[dateStrip.length - 2] === "1"
        //                   ? "th"
        //                   : "st"
        //                 : dateStrip[dateStrip.length - 1] === "2"
        //                 ? "nd"
        //                 : dateStrip[dateStrip.length - 1] === "3"
        //                 ? "rd"
        //                 : "th"}
        //             </span>
        //             {"  "} {dateStringer.getMonthThreeLettersFromDs(item.start)}
        //           </p>
        //           <div
        //             className={`tilShiftDsTeam ${item.ghost ? "x08141" : ""}`}
        //           >
        //             {/* <p className="teamHoursDsStrip">
        //             {dateStringer.tsToDayAndDate(unit.startTs)}
        //           </p>{" "} */}
        //             {/* <p
        //               className={`${
        //                 item.tilApproved && !item.wholeTil
        //                   ? `tilShiftTimes x1972141 ${
        //                       dateStringer.generateTilShiftString(
        //                         item.tilStart || "00:00",
        //                         item.tilEnd || "00:00",
        //                         oldStart || "00:00",
        //                         oldEnd || "00:00",
        //                         false
        //                       ).length > 17
        //                         ? "smallerTxtTimeTilTeamStrip"
        //                         : ""
        //                     }`
        //                   : "none"
        //               }`}
        //             >
        //               <img
        //                 src={rotaClock}
        //                 alt="Clock"
        //                 className={`teamHoursRotaClock ${
        //                   item.tilApproved && !item.wholeTil ? "x19749111" : ""
        //                 }`}
        //               />{" "}
        //               {dateStringer.generateTilShiftString(
        //                 item.tilStart || "00:00",
        //                 item.tilEnd || "00:00",
        //                 oldStart || "00:00",
        //                 oldEnd || "00:00",
        //                 false
        //               )}
        //             </p> */}
        //             {/* if not a til, no til string needed, just use normal time string: */}
        //             {/* <p
        //               className={`${
        //                 !item.tilApproved ? "tilShiftTimes x1972141 " : "none"
        //               }`}
        //             > */}
        //             {item.partialAbsent ? (
        //               <p className="tilShiftTimes x1972141 colorRed2">
        //                 <img
        //                   src={rotaClock}
        //                   alt="Clock"
        //                   className="teamHoursRotaClock"
        //                 />
        //                 {`${item.partialStart.substr(
        //                   12,
        //                   2
        //                 )}:${item.partialStart.substr(
        //                   15,
        //                   2
        //                 )} - ${item.partialEnd.substr(
        //                   12,
        //                   2
        //                 )}:${item.partialEnd.substr(15, 2)}`}
        //               </p>
        //             ) : (
        //               <p className="tilShiftTimes x1972141 colorRed2">
        //                 <img
        //                   src={rotaClock}
        //                   alt="Clock"
        //                   className="teamHoursRotaClock"
        //                 />
        //                 {`${item.start.substr(12, 2)}:${item.start.substr(
        //                   15,
        //                   2
        //                 )} - ${item.end.substr(12, 2)}:${item.end.substr(
        //                   15,
        //                   2
        //                 )}`}
        //               </p>
        //             )}
        //           </div>
        //           {/* <div
        //           className={`${
        //             unit.tags[0] ? "teamShiftTagsContainer" : "none"
        //           }`}
        //         >
        //           <p className="teamShiftTagItem">{formatTag(unit.tags[0])}</p>
        //           <p
        //             className={`${
        //               unit.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
        //             }`}
        //           >
        //             {formatTag(unit.tags[1])}
        //           </p>
        //           <p
        //             className={`${
        //               unit.tags[memoVals.mob ? 1 : 2]
        //                 ? "teamShiftTagsSurplusIndicator"
        //                 : "none"
        //             }`}
        //           >
        //             +{memoVals.mob ? unit.tags.length - 1 : unit.tags.length - 2}
        //           </p>
        //         </div> */}
        //         </div>
        //       </div>
        //       <div className="tilShiftRightParent">
        //         <div className="tilShiftSection2team">
        //           <p
        //             className={`${item.ghost ? "none" : "tilShiftTypeTxtTeam"}`}
        //           >
        //             {item.type === "shift"
        //               ? "Shift"
        //               : `${
        //                   item.type === "overtime" ? "Overtime" : "Time in Lieu"
        //                 }`}
        //           </p>
        //           <p className={`${item.ghost ? "paidDayOffSubTxt" : "none"}`}>
        //             Paid day off
        //           </p>
        //           <div className="hoursAndCostContainer">
        //             <p
        //               className={`tilShiftDurationTxtTeam redMgrMins ${
        //                 // unit.type === "shift" &&
        //                 item.absent && !item.partialAbsent
        //                   ? // &&
        //                     // unit.data.unpaidAbsent
        //                     `${
        //                       item.unpaidAbsent
        //                         ? "absentSubTxtBarShifts"
        //                         : "absentButPaid"
        //                     } redMgrMinsHalf`
        //                   : ""
        //               } ${
        //                 // unit.type === "shift" &&
        //                 item.absent && item.partialAbsent
        //                   ? `${
        //                       item.unpaidAbsent
        //                         ? "partialAbsentSubTxtBarShifts"
        //                         : "partialAbsentSubTxtBarShiftsPaid"
        //                     }`
        //                   : ""
        //               } ${item.ghost ? "none" : ""}`}
        //             >
        //               {printShiftDurationSubTxt()}
        //             </p>

        //             {item.type !== "til" ? (
        //               <p
        //                 className={`hoursCostItem ${
        //                   item.unpaidAbsent ? "unpaidCostColours" : ""
        //                 }`}
        //               >
        //                 {item.unpaidAbsent ? "Unpaid" : "Paid"}
        //                 {/* {currencySymbol}
        //                 {Math.round(item.cost)} */}
        //               </p>
        //             ) : (
        //               ""
        //             )}
        //           </div>
        //           <p className={`teamClockBreakDurStrip x131453678`}>
        //             {dateStringer.shorten(
        //               item.teamName,
        //               memoVals.mob || memoVals.fullButNarrow ? 22 : 35
        //             )}
        //             <img src={teamIcon} alt="Team" className="teamIconAbs" />
        //           </p>{" "}
        //         </div>
        //         <div
        //           className={`itemTongue shiftTongueMgr ${
        //             item.type === "shift"
        //               ? ""
        //               : item.type === "til"
        //               ? "tilTongueMgr"
        //               : "overtimeTongueMgr"
        //           }`}
        //         >
        //           <p className="tongueTxt leaveTongueTxt">
        //             {item.type === "til"
        //               ? "Lieu"
        //               : item.type === "shift"
        //               ? "Shift"
        //               : "Overtime"}
        //           </p>
        //         </div>
        //       </div>
        //     </div>

        //     <div
        //       className={`${
        //         item.absent && !loading ? "subShiftAbsenceContainer" : "none"
        //       }`}
        //     >
        //       <div className="subShiftAbsenceHolder">
        //         <p
        //           className={`${
        //             item.absent && !item.partialAbsent ? "" : "none"
        //           }`}
        //         >
        //           Absent
        //         </p>
        //         <p
        //           className={`${
        //             item.absent && item.partialAbsent ? "" : "none"
        //           }`}
        //         >
        //           Partially absent
        //         </p>
        //       </div>
        //     </div>

        //     <div
        //       className={`mgrClockNameDiv teamShiftNameTab x08917471 ${
        //         item.tags[0] ? "x29749711" : ""
        //       }${item.partialAbsent && item.absent ? "x104811" : ""} ${
        //         item.ghost ? "x2084971" : ""
        //       }`}
        //     >
        //       <img
        //         src={item.picUrl || profilePicturePlaceholder}
        //         alt={item.fName}
        //         className="itemNameTabImg"
        //       />
        //       {item.fName} {item.lName}{" "}
        //       {item.userID === memoVals.checkUserState.userID ? (
        //         <span className="youSpan">(You)</span>
        //       ) : (
        //         ""
        //       )}{" "}
        //       {item.absName && (
        //         <span className="absNameSpan">- {item.absName}</span>
        //       )}
        //     </div>
        //     <div
        //       className={`${
        //         item.tags[0]
        //           ? `teamShiftTagsContainer ${
        //               item.tilApproved && !item.wholeTil ? "x1084971971" : ""
        //             } ${item.absent && item.partialAbsent ? "x1794911" : ""}`
        //           : "none"
        //       }`}
        //     >
        //       <p className="teamShiftTagItem">{formatTag(item.tags[0])}</p>
        //       <p
        //         className={`${
        //           item.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
        //         }`}
        //       >
        //         {formatTag(item.tags[1])}
        //       </p>
        //       <p
        //         className={`${
        //           item.tags[memoVals.mob ? 1 : 2]
        //             ? "teamShiftTagsSurplusIndicator"
        //             : "none"
        //         }`}
        //       >
        //         +{memoVals.mob ? item.tags.length - 1 : item.tags.length - 2}
        //       </p>
        //     </div>
        //   </div>
        //   // <div
        //   //   className={`mgrAbsencePeriodParent x0984014 ${
        //   //     i === 0 ? "firsMgrAbsShift" : ""
        //   //   }`}
        //   //   onClick={() => {
        //   //     setItemID(item.shiftID || item.tilID);
        //   //     setItemType(item.shiftID ? "shift" : item.type || "shift");
        //   //     setItemFName(item.fName);
        //   //     setItemLName(item.lName);
        //   //     setShowEditShiftModal(true);
        //   //   }}
        //   // >
        //   //   <div className={`absentShiftUnit ${memoVals.mob ? "borderRadius10" : ""}`}>
        //   //     <div className="absentShiftLeftParent">
        //   //       <div className="formLeftWrapper">
        //   //         <div className="formIconLeft">
        //   //           <img
        //   //             src={absentShiftIcon}
        //   //             alt="Form"
        //   //             className="absentShiftIcon"
        //   //           />
        //   //         </div>
        //   //         <div className="absentShiftUnitLeft2">
        //   //           <div className="absenceShiftUnitLeftUpper staffAbsentShiftsName">
        //   //             {dateStringer.printedDateFromDs(item.start, false, false)}
        //   //           </div>
        //   //           <div className="absenceShiftUnitLeftLower absShiftX">
        //   //             {/* {dateStringer.printedDateFromDs(item.start)} */}
        //   //             {/* <br />{" "} */}
        //   //             {/* <span className="staffAbsLateStartSpan"> */}
        //   //             {dateStringer.dsToTimeStrip(item.start)} -{" "}
        //   //             {dateStringer.dsToTimeStrip(item.end)}
        //   //             {/* </span> */}
        //   //           </div>
        //   //         </div>
        //   //       </div>
        //   //       <div className="myAbsentShiftsLowerRight">
        //   //         <span className="absentShiftScheduledSpan x0193011">
        //   //           Absent:
        //   //         </span>
        //   //         {item.partialAbsent ? (
        //   //           <span className="myAbsShiftPurp lateStartStr">
        //   //             {" "}
        //   //             {dateStringer.dsToTimeStrip(item.partialStart)} -{" "}
        //   //             {dateStringer.dsToTimeStrip(item.partialEnd)}
        //   //           </span>
        //   //         ) : (
        //   //           <span className="myAbsShiftPurp lateStartStr">
        //   //             memoVals.full{" "}
        //   //             {item.type === "shift"
        //   //               ? "shift"
        //   //               : item.type === "til"
        //   //               ? "lieu"
        //   //               : "overtime"}
        //   //           </span>
        //   //         )}
        //   //       </div>
        //   //     </div>
        //   //     <div className="itemTongue absentShiftTongue">
        //   //       <p className="tongueTxt">
        //   //         {item.type === "shift"
        //   //           ? "Shift"
        //   //           : item.type === "til"
        //   //           ? "Lieu"
        //   //           : "Overtime"}
        //   //       </p>
        //   //     </div>
        //   //   </div>{" "}
        //   //   <div className="absPeriodName x131141">
        //   //     {item.fName} {item.lName}
        //   //   </div>
        //   // </div>
        // );
      }
    });
  }, [shifts, showFilter, selectedUser, loading, window.innerWidth]);

  let generateLates = React.useMemo(() => {
    return lates.map((item, i) => {
      if (selectedUser === item.userID || selectedUser === "all") {
        let printShiftDurationSubTxt = () => {
          // house
          if (item.durMins === 0 || !item.durMins) {
            return "0 mins";
          }
          if (
            // unit.type === "shift" &&
            item.absent &&
            !item.partialAbsent &&
            item.unpaidAbsent
          ) {
            return "0 hours";
          } else {
            return `${dateStringer.formatMinsDurationToHours(
              item.durMins || 0,
              false,
              true
            )}`;
          }
        };

        let rows = 5;

        if (
          item.tilRequested ||
          item.outstandingChanges ||
          item.selfLoggedAbsence
          // ||
          // unit.data.logged
        ) {
          rows++;
        }

        if (item.tags && Array.isArray(item.tags) && item.tags[0]) {
          rows++;
        }

        // rows: 3, 4, 5, 6
        return (
          <div className="reqUnitParent" key={i}>
            <div
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: item.type === "shift" ? item.shiftID : "",
                    tilID: item.type !== "shift" ? item.tilID : "",
                    fName: item.fName,
                    lName: item.lName,
                    openedFromShiftsPage: true,
                    type: item.type,
                  };
                });
              }}
              className={`mgrReqItem pend ${
                memoVals.mob ? "borderRadius10x" : ""
              } leaveReqItem ${
                rows === 3
                  ? "shiftItemRows3"
                  : rows === 4
                  ? "shiftItemRows4"
                  : rows === 5
                  ? "shiftItemRows5"
                  : rows === 6
                  ? "shiftItemRows6"
                  : ""
              }`}
            >
              {" "}
              <div
                className={`unsubmittedTsBlob annLeaveBlob ${
                  item.tilRequested ||
                  item.outstandingChanges ||
                  item.selfLoggedAbsence
                    ? ""
                    : "none"
                } ${
                  rows === 3
                    ? "annLeaveBlobRows3"
                    : rows === 4
                    ? "annLeaveBlobRows4"
                    : rows === 5
                    ? "annLeaveBlobRows5"
                    : rows === 6
                    ? "annLeaveBlobRows6"
                    : ""
                }`}
              ></div>
              <div className="reqPlaneDiv opac06">
                <img
                  src={time}
                  alt="Requested leave"
                  className={`reqPlaneIcon shIconImgTeamSh ${
                    memoVals.mob ? "reqPlaneIconMob" : ""
                  }`}
                />
              </div>
              <div className="reqColBarDiv divPend"></div>
              <div
                className={`mgrReqItemCol2 ${
                  memoVals.mob ? "mgrReqItemCol2Mob" : ""
                }`}
              >
                <div className="mgrReqTopRow">
                  <div className="mgrReqName mgrReqName2">
                    {/* ------ */}
                    <div
                      className={`mgrReqDateStrip pendDateDiv x29898988444 ${
                        rows === 3
                          ? "shContentLeftRows3"
                          : rows === 4
                          ? "shContentLeftRows4"
                          : rows === 5
                          ? "shContentLeftRows5"
                          : rows === 6
                          ? "shContentLeftRows6"
                          : ""
                      }`}
                    >
                      <div
                        className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                      >
                        <img
                          src={desktopBurger}
                          alt="Request duration"
                          className="rotaSunReqs leaveNameBurger"
                        />

                        <p
                          className={`shTypeNameStrip ${
                            (item.type === "til" || item.type === "overtime") &&
                            window.innerWidth < 390
                              ? "shTypeNameStripSmall"
                              : ""
                          }`}
                        >
                          {item.ghost
                            ? "Paid day off"
                            : item.type === "shift"
                            ? "Late shift start"
                            : item.type === "overtime" || item.type === "til"
                            ? "Late overtime start"
                            : "Other---"}
                        </p>
                        {item.requireAccept ? (
                          !item.accepted ? (
                            <img
                              src={lightBlueQuestionMark}
                              alt="Not yet accepted"
                              className="shItemAccQImg shItemAccQImgQ"
                            />
                          ) : (
                            <img
                              src={
                                item.absent || item.selfLoggedAbsence
                                  ? doubleTickRed
                                  : doubleTick
                              }
                              alt="Accepted"
                              className="shItemAccQImg"
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                      >
                        <img
                          src={rotaDur}
                          alt="Request duration"
                          className="rotaSunReqs"
                        />
                        <p className="lateDurStripInline">
                          {dateStringer.formatMinsDurationToHours(
                            item.lateDurMins
                          )}
                        </p>
                      </div>
                      <div
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                      >
                        <img
                          src={rotaTeam}
                          alt="Request duration"
                          className="rotaSunReqs"
                        />
                        {dateStringer.shorten(
                          item.teamName,
                          window.innerWidth > 1056 ? 28 : 18
                        )}
                      </div>
                      <div
                        className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur ba0000 makeBold marginTop6`}
                      >
                        <img
                          src={rotaClock}
                          alt="Request duration"
                          className="rotaSunReqs marginBottom0"
                        />

                        <p className="teamShiftStringTxt fontWeight600_ displayFlex">
                          {dateStringer.dsToTimeStrip(item.start, true)}{" "}
                          <img
                            src={lateArr}
                            alt="Late"
                            className="lateArrInlineNew"
                          />{" "}
                          {dateStringer.dsToTimeStrip(item.lateStartDs, true)}
                        </p>
                      </div>

                      {item.tags[0] ? (
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur flexStart marginTop6`}
                        >
                          <img
                            src={rotaTag}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          <div className="myHoursTagsInline x28987654345654">
                            <div
                              className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 ${
                                item.tags.length === 1 ? "noBorder" : ""
                              }`}
                            >
                              {dateStringer.shorten(item.tags[0], 25)}
                            </div>
                            {item.tags &&
                            Array.isArray(item.tags) &&
                            item.tags.length > 1 ? (
                              <div
                                className={`shTagInline myRotaTag x23784575 fontSize14 lineHeight15 noBorder`}
                              >
                                +{item.tags.length - 1}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {item.selfLoggedAbsence ||
                      item.outstandingChanges ||
                      item.tilRequested ? (
                        <div
                          className={`mgrReqDayLengthSpan upcomingMgrReqsSpan upcomingMgrReqsSpanDur  marginTop6 ${
                            item.selfLoggedAbsence
                              ? "ba0000 makeBold"
                              : item.outstandingChanges || item.tilRequested
                              ? "colour00aaff makeBold"
                              : ""
                          }`}
                        >
                          <img
                            src={rotaPerson}
                            alt="Request duration"
                            className="rotaSunReqs"
                          />
                          <p className="teamShiftStringTxt">
                            {item.selfLoggedAbsence
                              ? "Logged absence"
                              : item.outstandingChanges
                              ? "Change requested"
                              : item.tilRequested
                              ? "TOIL requested"
                              : ""}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
                  </div>
                  <div className="teamShRightBlock">
                    <div className="absTimelineVal absTimelineValReqs rowReverse marginRight0">
                      <div
                        className={`shItemTimes ${item.ghost ? "invis" : ""}`}
                      >
                        <p className="shItemTimeRow">
                          {item.start.length > 10
                            ? dateStringer.dsToTimeStrip(item.start, true)
                            : item.start}
                        </p>
                        <div className="shItemTimeRowSplitter"></div>
                        <p className="shItemTimeRow">
                          {item.end.length > 10
                            ? dateStringer.dsToTimeStrip(item.end, true)
                            : item.end}
                        </p>
                      </div>
                      <div className={`absCalDiv`}>
                        <div className="absCalDivUpper">
                          <div className="absDivCalNip"></div>
                          <div className="absDivCalNip"></div>
                        </div>
                        <div className={`absCalDivLower absCalDivLowerAllTime`}>
                          {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                          <p className="absCalDivLowerMonth absCalDivLowerMonthReqs">
                            {dateStringer.getThreeDayLettersFromDs(item.end)}
                          </p>{" "}
                          <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                            {parseInt(item.start.substr(9, 2))}
                          </p>
                          <p className="absCalDivLowerYear">
                            {dateStringer.getMonthThreeLettersFromDs(
                              item.start
                            )}
                            {/* {unit.startDs.substr(1, 4)} */}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <p className={`teamClockBreakDurStrip bbcada marginTop20`}>
                      {dateStringer.shorten(
                        item.teamName,
                        memoVals.mob || memoVals.fullButNarrow ? 17 : 35
                      )}{" "}
                      <img
                        src={rotaTeam}
                        alt="Team"
                        className="teamIconHours opac05"
                      />
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`mgrReqNameTab pendClockName ${
                rows === 3
                  ? "shNameTabRows3"
                  : rows === 4
                  ? "shNameTabRows4"
                  : rows === 5
                  ? "shNameTabRows5"
                  : rows === 6
                  ? "shNameTabRows6"
                  : ""
              }`}
              onClick={() => {
                memoVals.setShowEditUser((x) => item.userID);
              }}
            >
              <img
                src={item.picUrl || profilePicturePlaceholder}
                alt={item.fName}
                className="itemNameTabImg"
              />{" "}
              {item.fName} {item.lName}
              {item.userID === memoVals.checkUserState.userID ? (
                <span className="youSpan">(You)</span>
              ) : (
                ""
              )}{" "}
            </div>
            {/* sub bar begins */}
            {(item.logged || item.selfLoggedAbsence) && (
              <div
                className={`mgrClockSubBar ${
                  memoVals.mob ? "mgrClockSubBarMob" : ""
                }
              pendClockSub teamShiftsSubAppBar ${
                rows === 6 ? "teamShiftsSubAppBarRows5" : ""
              }`}
              >
                <div
                  className={`clocksSchedSubLeft ${
                    memoVals.mob ? "font12px" : ""
                  } `}
                >
                  {/* <div className={`clockSchedTitle`}>Title here</div> */}
                  {item.selfLoggedAbsence ? (
                    <p className="clockSchedValue fontSize14 lineHeight15">
                      <span className="subBarLoggedTitle subBarLoggedTitle2">
                        Requires action
                      </span>{" "}
                    </p>
                  ) : (
                    // <p className="clockSchedValue fontSize14 lineHeight15">
                    //   <span className="subBarLoggedTitle">Logged:</span>{" "}
                    //   {unit.data.logged}
                    // </p>
                    <p className="clockSchedValue fontSize14 lineHeight15">
                      <span className="subBarLoggedTitle subBarLoggedTitle2">
                        Logged:{" "}
                        <span className="colour00aaff">{item.logged}</span>
                      </span>{" "}
                    </p>
                  )}
                </div>
                <div
                  className={`clocksSchedSubRight ${
                    memoVals.mob ? "flexEnd" : ""
                  }`}
                >
                  {/* {!approveLoading && !clock.approved && !clock.declined && ( */}
                  {item.selfLoggedAbsence ? (
                    <p
                      className={`approveClock ${
                        item.userID === memoVals.checkUserState.userID &&
                        !allowSelfHoursApprove.includes(item.teamID) &&
                        !memoVals.checkUserState.permissions !== 1
                          ? "invis"
                          : ""
                      } reqActBtn`}
                      onClick={() => {
                        memoVals.setOpenManagerShiftModal((x) => {
                          return {
                            shiftID: item.type === "shift" ? item.shiftID : "",
                            tilID: item.type !== "shift" ? item.tilID : "",
                            fName: item.fName,
                            lName: item.lName,
                            openedFromShiftsPage: true,
                            type: item.type,
                          };
                        });
                      }}
                    >
                      Action{" "}
                      <img
                        src={popup}
                        alt="Approve clock card"
                        className="approveCCtickimg reqActpopup"
                      />
                    </p>
                  ) : (
                    <p
                      className={`approveClock ${
                        item.userID === memoVals.checkUserState.userID &&
                        !allowSelfHoursApprove.includes(item.teamID) &&
                        !memoVals.checkUserState.permissions !== 1
                          ? "invis"
                          : ""
                      }`}
                      onClick={() => {}}
                    >
                      Approve{" "}
                      <img
                        src={whiteTick}
                        alt="Approve clock card"
                        className="approveCCtickimg"
                      />
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
      // if (
      //   !showFilter ||
      //   (showFilter && (selectedUser === item.userID || selectedUser === "all"))
      // ) {
      //   return (
      //     <div
      //       className={`mgrAbsencePeriodParent x0984014 ${
      //         i === 0 ? "firsMgrAbsShift" : ""
      //       }`}
      //       onClick={() => {
      //         setItemID(item.shiftID || item.tilID);
      //         setItemType(item.shiftID ? "shift" : item.tilType || "shift");
      //         setItemFName(item.fName);
      //         setItemLName(item.lName);
      //         setShowEditShiftModal(true);
      //       }}
      //     >
      //       <div className={`absentShiftUnit ${memoVals.mob ? "borderRadius10" : ""}`}>
      //         <div className="absentShiftLeftParent">
      //           <div className="formLeftWrapper">
      //             <div className="formIconLeft">
      //               <img src={lateImg} alt="Form" className="lateIcon" />
      //             </div>
      //             <div className="absentShiftUnitLeft">
      //               <div className="absenceShiftUnitLeftUpper staffLateName">
      //                 {dateStringer.printedDateFromDs(item.start)}
      //               </div>
      //               <div className="absenceShiftUnitLeftLower latesLower">
      //                 <span className="absentShiftScheduledSpan bbcada">
      //                   Scheduled start:
      //                   <br />
      //                 </span>{" "}
      //                 {dateStringer.dsToTimeStrip(item.start, true)}
      //               </div>
      //             </div>
      //           </div>
      //           <div className="absenceShiftUnitRight">
      //             {" "}
      //             <p className="absentShiftScheduledSpan x9739171">
      //               {item.clockedLate ? "Clocked on:" : "Started:"}
      //             </p>{" "}
      //             <div className="absenceShiftUnitLeftUpper colourAbsenceBlue flexEnd">
      //               {dateStringer.formatMinsDurationToHours(
      //                 item.lateDurMins || 0
      //               )}{" "}
      //               late
      //             </div>
      //             <div className="myAbsentShiftsLowerRight minHeight24 x20831981">
      //               {/* <br /> */}
      //               <p className="absenceSpanColour lateStartStr x917491">
      //                 {dateStringer.dsToTimeStrip(item.lateStartDs, true)}
      //               </p>
      //             </div>
      //           </div>
      //         </div>
      //         <div className="itemTongue absentShiftTongue">
      //           <p className="tongueTxt">
      //             {item.type === "shift"
      //               ? "Shift"
      //               : item.type === "til"
      //               ? "Lieu"
      //               : "Overtime"}
      //           </p>
      //         </div>
      //       </div>{" "}
      //       <div className="absPeriodName x131141">
      //         {item.fName} {item.lName}
      //       </div>
      //     </div>
      //   );
      // }
    });
  }, [lates, showFilter, selectedUser, loading, window.innerWidth]);
  let [showMobMenu, setShowMobMenu] = useState(false);
  // MASTER RETURN
  return (
    <div className="reqsPageHolder">
      <div className="reqsPage">
        <div className="imgPreloads">
          <img src={greenTick} alt="1" className="imgPre" />
          <img src={tickWhite} alt="1" className="imgPre" />
          <img src={periodIcon} alt="1" className="imgPre" />
          <img src={lateImg} alt="1" className="imgPre" />
          <img src={formImg} alt="1" className="imgPre" />
        </div>{" "}
        <div
          className={`${
            memoVals.mob
              ? "reqsPageUpperContainerTeam"
              : "reqsPageUpperContainer"
          } ${!memoVals.mob ? "pageLeft240pxDesktop" : "noBorderBottom"}`}
        >
          {!memoVals.mob ? <TopBar /> : ""}

          {/* Reqs page header here */}

          <div className="shiftsHeaderContentContainer staffAbsenceHeaderPadding">
            <div
              className={`shiftsHeaderLeft ${memoVals.wide ? "wideTitle" : ""}`}
            >
              <div
                onClick={() => {
                  console.log({ noForms });
                }}
                className={`myRequestsHeaderLeftTitle ${
                  !memoVals.mob ? "headerTitleSize myReqsTitle2" : ""
                } staffColour ${
                  memoVals.mob
                    ? "myRequestsHeaderLeftTitleMob x398294727445 colourWhite"
                    : "headerTitleColourMgr"
                } `}
              >
                {memoVals.mob ? (
                  ""
                ) : (
                  <span className="staffTitleLeftBlob">&nbsp;</span>
                )}
                Staff absence
              </div>

              {window.innerWidth < 820 ? (
                <div
                  className="mobShiftTeamMenu x24758728723333"
                  onClick={() => {
                    setShowMobMenu(!showMobMenu);
                  }}
                >
                  {countData.forms +
                  countData.ongoingAbs +
                  countData.selfLoggedAbsence ? (
                    <div className={`shMenuBlob`}></div>
                  ) : (
                    ""
                  )}
                  <img
                    src={whiteBurger}
                    alt="Shifts menu"
                    className="shiftsMenuBurger"
                  />

                  <p className="mobShiftTeamMenuTitle">
                    {memoVals.absencePage === "late"
                      ? "Late starts"
                      : memoVals.absencePage === "shifts"
                      ? "Absent shifts"
                      : memoVals.absencePage === "periods"
                      ? "Absence periods"
                      : memoVals.absencePage === "forms"
                      ? "Forms"
                      : memoVals.absencePage === "rank"
                      ? "Ranking"
                      : ""}
                  </p>
                  {memoVals.mob && showMobMenu && (
                    <div
                      className="shiftsMobMenuUnderlay"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMobMenu(false);
                      }}
                    >
                      <div
                        className="shiftsMobMenuUnderlayUpper"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMobMenu(false);
                        }}
                      ></div>
                      <div
                        className={`shiftsMobMenu ${
                          countData.selfLoggedAbsence > 0
                            ? "shiftsMobMenuSLA6"
                            : "shiftsMobMenuSLA"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {countData.selfLoggedAbsence > 0 ? (
                          <div
                            className={`shiftsMobMenuItem `}
                            onClick={() => {
                              // go to logged abs shifts in team hours here
                              setShowMobMenu(false);
                              window.location.href = `${appURL}/team-hours?logged=true`;
                            }}
                          >
                            <div className={`shiftsMobMenuItemBlob `}></div>
                            Logged absence
                            {countData.selfLoggedAbsence > 0 && (
                              <p className="staffMobHrMenuQty">
                                {countData.selfLoggedAbsence > 9
                                  ? "9+"
                                  : countData.selfLoggedAbsence}
                              </p>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div
                          className={`shiftsMobMenuItem ${
                            memoVals.absencePage === "shifts"
                              ? "shiftsMobMenuItemActive"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setAbsencePage((x) => "shifts");
                            setShowMobMenu(false);
                          }}
                        >
                          <div
                            className={`shiftsMobMenuItemBlob ${
                              memoVals.absencePage === "shifts"
                                ? "shiftsMobMenuItemBlobActive"
                                : ""
                            }`}
                          ></div>
                          Shifts
                        </div>
                        <div
                          className={`shiftsMobMenuItem ${
                            memoVals.absencePage === "periods"
                              ? "shiftsMobMenuItemActive"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setAbsencePage((x) => "periods");
                            setShowMobMenu(false);
                          }}
                        >
                          <div
                            className={`shiftsMobMenuItemBlob ${
                              memoVals.absencePage === "periods"
                                ? "shiftsMobMenuItemBlobActive"
                                : ""
                            }`}
                          ></div>
                          Periods
                          {countData.ongoingAbs > 0 && (
                            <p className="staffMobHrMenuQty">
                              {countData.ongoingAbs > 9
                                ? "9+"
                                : countData.ongoingAbs}
                            </p>
                          )}
                        </div>
                        <div
                          className={`shiftsMobMenuItem ${
                            memoVals.absencePage === "late"
                              ? "shiftsMobMenuItemActive"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setAbsencePage((x) => "late");
                            setShowMobMenu(false);
                          }}
                        >
                          <div
                            className={`shiftsMobMenuItemBlob ${
                              memoVals.absencePage === "late"
                                ? "shiftsMobMenuItemBlobActive"
                                : ""
                            }`}
                          ></div>
                          Late starts
                        </div>
                        <div
                          className={`shiftsMobMenuItem ${
                            memoVals.absencePage === "forms"
                              ? "shiftsMobMenuItemActive"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setAbsencePage((x) => "forms");
                            setShowMobMenu(false);
                          }}
                        >
                          <div
                            className={`shiftsMobMenuItemBlob ${
                              memoVals.absencePage === "late"
                                ? "shiftsMobMenuItemBlobActive"
                                : ""
                            }`}
                          ></div>
                          Forms{" "}
                          {countData.forms > 0 && (
                            <p className="staffMobHrMenuQty">
                              {countData.forms > 9 ? "9+" : countData.forms}
                            </p>
                          )}
                        </div>
                        <div
                          className={`shiftsMobMenuItem ${
                            memoVals.absencePage === "rank"
                              ? "shiftsMobMenuItemActive"
                              : ""
                          } noBorder`}
                          onClick={() => {
                            memoVals.setAbsencePage((x) => "rank");

                            setShowMobMenu(false);
                          }}
                        >
                          <div className={`shiftsMobMenuItemBlob`}></div>Ranking
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`absenceHeaderMenuRow absenceHeaderMenuRowStaff ${
                    memoVals.mob
                      ? "absenceHeaderMenuMob"
                      : !memoVals.full
                      ? "x000110101"
                      : ""
                  }`}
                >
                  <div
                    className={`shiftHeaderMenuItem teamShiftsHeaderHover ${
                      memoVals.absencePage === "shifts"
                        ? `shiftHeaderMenuItemSelect staffColour purBor  ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      console.log(
                        "memoVals.absencePage; ",
                        memoVals.absencePage
                      );

                      if (memoVals.absencePage === "shifts") {
                        // nil
                      } else {
                        memoVals.setAbsencePage((x) => "shifts");
                        setDataLoaded(false);
                        setLoading(true);
                      }
                    }}
                  >
                    <div className="headerMenuTopRow">
                      {" "}
                      {memoVals.mob ? "Shifts" : "Absent shifts"}{" "}
                      {countData.selfLoggedAbsence ? (
                        <p className="shiftsHeaderQty shiftsHeaderQtyMgr">
                          {countData.selfLoggedAbsence > 9
                            ? "9+"
                            : countData.selfLoggedAbsence}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "shifts"
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "shifts" ? "" : "none"
                      } purpeMenuBottom `}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div className="shifHeaderMenuSplitterTeam"></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem teamShiftsHeaderHover ${
                      memoVals.mob ? "" : ""
                    } ${
                      memoVals.absencePage === "periods"
                        ? `shiftHeaderMenuItemSelect staffColour purBor ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    } `}
                    onClick={() => {
                      if (memoVals.absencePage === "periods") {
                        // nil
                      } else {
                        memoVals.setAbsencePage((x) => "periods");
                        setLoading(true);
                        setDataLoaded(false);
                      }
                    }}
                  >
                    <div className="headerMenuTopRow">
                      Periods{" "}
                      {countData.ongoingAbs ? (
                        <p className="shiftsHeaderQty shiftsHeaderQtyMgr">
                          {countData.ongoingAbs > 9
                            ? "9+"
                            : countData.ongoingAbs}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "periods"
                          ? "none"
                          : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "periods" ? "" : "none"
                      } purpeMenuBottom `}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`shiftHeaderMenuItem teamShiftsHeaderHover ${
                      memoVals.absencePage === "late"
                        ? `shiftHeaderMenuItemSelect staffColour purBor  ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      console.log(
                        "memoVals.absencePage; ",
                        memoVals.absencePage
                      );

                      if (memoVals.absencePage === "late") {
                        // nil
                      } else {
                        memoVals.setAbsencePage((x) => "late");
                        setDataLoaded(false);
                        setLoading(true);
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">Lateness</p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "late" ? "none" : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "late" ? "" : "none"
                      } purpeMenuBottom `}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div
                    className={`shiftHeaderMenuItem teamShiftsHeaderHover ${
                      memoVals.absencePage === "forms"
                        ? `shiftHeaderMenuItemSelect staffColour purBor  ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      console.log(
                        "memoVals.absencePage; ",
                        memoVals.absencePage
                      );

                      if (memoVals.absencePage === "forms") {
                        // nil
                      } else {
                        memoVals.setAbsencePage((x) => "forms");
                        setDataLoaded(false);
                        setLoading(true);
                      }
                    }}
                  >
                    <div className="headerMenuTopRow">
                      Forms{" "}
                      {countData.forms ? (
                        <p className="shiftsHeaderQty shiftsHeaderQtyMgr">
                          {countData.forms > 9 ? "9+" : countData.forms}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "forms" ? "none" : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "forms" ? "" : "none"
                      } purpeMenuBottom `}
                    ></p>
                  </div>
                  <div className="subHeadSplit"></div>
                  <div
                    className={`shiftHeaderMenuItem teamShiftsHeaderHover ${
                      memoVals.absencePage === "rank"
                        ? `shiftHeaderMenuItemSelect staffColour purBor  ${
                            memoVals.mob ? "colourWhite" : ""
                          }`
                        : ""
                    }`}
                    onClick={() => {
                      if (memoVals.absencePage === "rank") {
                        // nil
                      } else {
                        memoVals.setAbsencePage((x) => "rank");
                        setDataLoaded(false);
                        setLoading(true);
                      }
                    }}
                  >
                    <p className="headerMenuTopRow">
                      {!memoVals.full ? "Rank" : "Staff ranking"}
                    </p>

                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "rank" ? "none" : "x198389191"
                      } `}
                    ></p>
                    <p
                      className={`headerMenuBottomRow ${
                        memoVals.absencePage === "rank" ? "" : "none"
                      } purpeMenuBottom `}
                    ></p>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`shiftHeaderTeamRightContainer staffAbsHeader ${
                memoVals.mob
                  ? "shiftHeaderTeamRightContainerMob"
                  : "desktopStaffAbsHeader"
              }`}
            >
              <NavLink to="/absence" onClick={() => {}}>
                <div
                  className={`myHoursBtn ${!memoVals.mob ? "myAbsBtn" : ""} ${
                    memoVals.mob ? "myHoursBtnMob" : ""
                  }`}
                >
                  <p className="teamHrsBtn myAbsBtnTxt">My absence</p>
                  <img src={whiteGo} alt="Team" className="whiteGoImg" />
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div
          className={`requestPageParent ${
            !memoVals.mob ? "pageLeft240pxDesktop borderLeftGrey" : ""
          } `}
        >
          {" "}
          <div
            className={`desktopMaster ${memoVals.mob ? "desktopPurpBg" : ""}`}
          >
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob ? "mobReqsContainerFullWidth" : ""
                }`}
              >
                {/* requests container starts */}

                <div
                  className={`reqsPageFilterHolder ${
                    !memoVals.mob ? "borderRightGrey" : "noBorderRight"
                  } ${loading ? "noBorder_" : ""}`}
                >
                  {memoVals.mob ? (
                    <div className="staffAbsMobShoulder">
                      <div className="staffAbsMobShoulderRow">
                        <div
                          className={`myAbsTopLeft ${
                            memoVals.full ? "x038141" : ""
                          }`}
                        >
                          <select
                            className={`myReqsYearDropdown`}
                            value={selectedYear}
                            onChange={(e) => {
                              if (e.target.value) {
                                setDataLoaded(false);
                                setLoading(true);

                                setSelectedYear(parseInt(e.target.value));
                                setSelectedType("allTypes");
                              }
                            }}
                          >
                            {!yearsArr.includes(selectedYear) ? (
                              <option value="">--</option>
                            ) : (
                              ""
                            )}
                            {generateYearsArr}
                          </select>
                          <select
                            className={`shiftsHeaderTeamDropdown ${
                              memoVals.mob && window.innerWidth < 440
                                ? `shiftsHeaderTeamDropdownMob2 ${
                                    window.innerWidth < 355
                                      ? "shiftsHeaderTeamDropdownMob3"
                                      : ""
                                  }`
                                : memoVals.mob
                                ? "shiftsHeaderTeamDropdown3"
                                : ""
                            } ${
                              window.innerWidth > 400 && window.innerWidth < 454
                                ? "shiftsHeaderTeamDropdown4"
                                : ""
                            } ${
                              selectedUser !== "all"
                                ? `teamSelectedDrop ${
                                    memoVals.mob
                                      ? "mobteamSelectedDrop x108314"
                                      : ""
                                  }`
                                : ""
                            }`}
                            value={selectedUser}
                            onChange={(e) => {
                              // setSelectedUser("all");
                              setShowFilter(false);
                              setSelectedUser(e.target.value);
                            }}
                          >
                            <option value="all">-- All staff</option>
                            {generateTeamsSelectBar}
                          </select>
                        </div>

                        <div className="staffAbsFilterBarRight">
                          <div
                            className={`myAllowsWhiteBtn staffAbsPerfBtn`}
                            onClick={() => {
                              setShowSideModal(true);
                            }}
                          >
                            {" "}
                            Performance
                            {/* {memoVals.mob ? "Staff abse" : "My allowances"} */}
                            <img
                              src={popupNavy}
                              alt="Staff rota"
                              className={`availPopupImg ${
                                window.innerWidth < 370 ? "invis_" : ""
                              }`}
                            />
                          </div>
                          {/* <div className="staffAbsDataBtn">
                            Performance
                    
                          </div> */}
                          {/* <img
                            src={perfImg}
                            alt="Absence performance"
                            className="absPerfIcon"
                            onClick={() => {
                              setShowSideModal(true);
                            }}
                          /> */}
                          {/* <p
                            className={`absenceFiltBtn ${
                              showFilter ? "absFiltBtnActiveColours" : ""
                            }`}
                            onClick={() => {
                              setShowFilter(!showFilter);
                              setSelectedUser("all");
                              setSelectedType("allTypes");
                              console.log({ users });
                            }}
                          >
                            <img
                              src={filterWhite}
                              alt="Close filter"
                              className={`filtImg ${
                                !showFilter ? "x19831" : ""
                              }`}
                            />
                            {showFilter ? (
                              <img
                                src={whitecross}
                                alt="Close filter"
                                className="closeAbsFiltCrossImg"
                              />
                            ) : (
                              ""
                            )}
                          </p> */}
                          {/* <select
                            className={`shiftsHeaderTeamDropdown ${
                              memoVals.mob ? "shiftsHeaderTeamDropdownMob2" : ""
                            } ${
                              selectedTeam !== "allStaff"
                                ? `teamSelectedDrop ${
                                    memoVals.mob ? "mobteamSelectedDrop" : ""
                                  }`
                                : ""
                            }`}
                            value={selectedTeam}
                            onChange={(e) => {
                              setSelectedUser("all");
                              setShowFilter(false);
                              setSelectedTeam(e.target.value);
                            }}
                          >
                            <option value="allStaff">
                              -- All of your teams
                            </option>
                            {generateTeamsSelectBar}
                          </select> */}
                        </div>
                      </div>
                      {/* <div
                        className={`staffAbsMobShoulderRow ${
                          showFilter ? "noBorder" : ""
                        } ${memoVals.absencePage === "forms" ? "none" : ""} flexEnd`}
                      >
                        {memoVals.absencePage === "forms" ? (
                          <div
                            className="newFormBtn"
                            onClick={() => {
                              memoVals.setShowCreateFormModal(x =>true);
                            }}
                          >
                            Create form{" "}
                            <img
                              src={whiteAddThin}
                              alt="Add form"
                              className="whiteAddFormImg"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                       
                      </div> */}
                    </div>
                  ) : (
                    <div className={`reqPageFilterBarMy`}>
                      <div
                        className={`myAbsencesFiltBar ${
                          memoVals.mob ? "mobMyReqsFilterbar" : ""
                        } ${
                          !memoVals.full && !memoVals.mob ? "minWidth312_" : ""
                        }`}
                      >
                        <div className="formYrAndCreateDiv">
                          <select
                            className={`myReqsYearDropdown`}
                            value={selectedYear}
                            onChange={(e) => {
                              if (e.target.value) {
                                setDataLoaded(false);
                                setLoading(true);

                                setSelectedYear(parseInt(e.target.value));
                                setSelectedType("allTypes");
                              }
                            }}
                          >
                            {!yearsArr.includes(selectedYear) ? (
                              <option value="">--</option>
                            ) : (
                              ""
                            )}
                            {generateYearsArr}
                          </select>
                          {memoVals.absencePage === "forms" &&
                          !showFilter &&
                          enableForm &&
                          questionsQty > 0 ? (
                            <div
                              className="newFormBtn createFormInline"
                              onClick={() => {
                                memoVals.setShowCreateFormModal((x) => true);
                              }}
                            >
                              New form{" "}
                              <img
                                src={whiteAddThin}
                                alt="Add form"
                                className="whiteAddFormImg"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div
                          className={`staffAbsFiltBarRight ${
                            showFilter ? "showFiltBarRightAbs" : ""
                          } ${memoVals.full ? "flexEnd" : ""}`}
                        > */}
                        {/* {memoVals.full ? (
                            ""
                          ) : (
                            <div
                              className={`myAbsencePerfWhiteBtn myAbsPerInline ${
                                memoVals.mob ? "myAllowsWhiteBtnMob" : ""
                              }`}
                              onClick={() => {
                                setShowSideModal(true);
                              }}
                            >
                              {memoVals.mob ? "Performance" : "Performance"}
                              <img
                                src={popup2}
                                alt="Staff rota"
                                className="availPopupImg"
                              />
                            </div>
                          )}{" "} */}
                        {/* <p
                            className={`absenceFiltBtn ${
                              showFilter ? "absFiltBtnActiveColours" : ""
                            }`}
                            onClick={() => {
                              setShowFilter(!showFilter);
                              setSelectedUser("all");
                              setSelectedType("allTypes");
                              console.log({ users });
                            }}
                          >
                            <img
                              src={filterWhite}
                              alt="Close filter"
                              className={`filtImg ${
                                !showFilter ? "x19831" : ""
                              }`}
                            />
                            {showFilter ? (
                              <img
                                src={whitecross}
                                alt="Close filter"
                                className="closeAbsFiltCrossImg"
                              />
                            ) : (
                              ""
                            )} */}
                        {/* </p> */}
                        {/* </div> */}
                        {/* <p className="myAbsenceExplain">
                        {memoVals.absencePage === "periods"
                          ? "Periods of absence"
                          : memoVals.absencePage === "shifts"
                          ? "Individual absent shifts"
                          : "Shifts started late"}
                      </p> */}
                        {/* <select
                        className={`myReqsTypesDropdown ${
                          memoVals.mob ? "myReqsTypesDropdownMob" : ""
                        } ${memoVals.absencePage !== "periods" ? "invis" : ""}`}
                        value={selectedType}
                        onChange={(e) => {
                          console.log(
                            "setting selected type to: ",
                            e.target.value
                          );
                          setSelectedType(e.target.value);
                        }}
                      >
                        {" "}
                        <option value="allTypes">All types</option>
                        {generateTypes}
                      </select> */}
                        <div className="staffAbsMediumTopRight">
                          {!memoVals.mob && !memoVals.full && (
                            <div
                              className={`myAllowsWhiteBtn staffAbsPerfBtnMedium`}
                              onClick={() => {
                                setShowSideModal(true);
                              }}
                            >
                              {" "}
                              Performance
                              {/* {memoVals.mob ? "Staff abse" : "My allowances"} */}
                              <img
                                src={popupNavy}
                                alt="Staff rota"
                                className={`availPopupImg ${
                                  window.innerWidth < 370 ? "invis_" : ""
                                }`}
                              />
                            </div>
                          )}{" "}
                          <select
                            className={`shiftsHeaderTeamDropdown absTeamHeaderDropdown ${
                              memoVals.mob ? "shiftsHeaderTeamDropdownMob" : ""
                            } ${memoVals.fullButNarrow ? "x198319821" : ""} ${
                              selectedUser !== "all"
                                ? `teamSelectedDrop ${
                                    memoVals.mob ? "mobteamSelectedDrop2" : ""
                                  }`
                                : ""
                            }`}
                            value={selectedUser}
                            onChange={(e) => {
                              setSelectedUser(e.target.value);
                              setShowFilter(false);
                              // setSelectedTeam(e.target.value);
                            }}
                          >
                            <option value="all">-- All of your staff</option>
                            {generateTeamsSelectBar}
                          </select>
                        </div>
                        {/* desktop team */}
                      </div>
                    </div>
                  )}
                </div>

                {memoVals.mob ? (
                  ""
                ) : (
                  <div
                    className={`Container borderRightGrey  ${
                      loading || !memoVals.full ? "noBorder" : ""
                    }`}
                  >
                    {!loading && !showFilter ? (
                      <div className="reqFilterSplitter"></div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {showFilter ? (
                  <div className="staffAbsenceFiltDiv">
                    <select
                      value={selectedUser}
                      className="shiftNamesDropdown"
                      onChange={(e) => {
                        setSelectedUser(e.target.value);
                      }}
                    >
                      <option value="all">
                        {selectedUser === "all"
                          ? "-- All your staff"
                          : "-- Individual employee"}
                      </option>
                      {generateUsers}
                    </select>

                    {memoVals.absencePage === "periods" ? (
                      <select
                        className="shiftNamesDropdown shiftNamesDropdownTypeSelStaff"
                        value={selectedType}
                        onChange={(e) => {
                          setSelectedType(e.target.value);
                        }}
                      >
                        <option value="allTypes">-- All types</option>
                        {generateTypes}
                      </select>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div
                  ref={showReqs}
                  className={`showReqs ${
                    memoVals.mob ? "noBorder minW101pc" : ""
                  } absBox ${
                    memoVals.absencePage === "periods" && !showInstruction
                      ? "periodsAbsBox"
                      : ""
                  } ${memoVals.mob ? "absBoxMobStaff" : ""} ${
                    memoVals.device.ios ? "absBoxMobStaffIos" : ""
                  } ${
                    memoVals.absencePage === "forms"
                      ? "staffFormsContainer"
                      : ""
                  } ${
                    showFilter
                      ? `absBoxFilterActiveStaff ${
                          memoVals.device.ios
                            ? "absBoxFilterActiveStaffIos"
                            : ""
                        }`
                      : ""
                  }`}
                >
                  {" "}
                  {/* {!loading && showInstruction ? (
                    <img
                      src={bbcadaClose}
                      alt="Hide info"
                      className="hideInfoTxtClose"
                      onClick={() => {
                        setShowInstuction(false);
                      }}
                    />
                  ) : (
                    ""
                  )} */}
                  {!loading && !showInstruction ? (
                    <div
                      className={`showInstructWrap ${
                        memoVals.absencePage === "periods"
                          ? "periodsInstructWrap"
                          : ""
                      }`}
                      onClick={() => {
                        setShowInstuction(true);
                      }}
                    >
                      <div className={`showInstructBtn `}>
                        <p className="qMarkInstruct">What are these?</p>
                        <img
                          src={downWhite2}
                          alt="Show info"
                          className="showInstructDownWhiteImg"
                        />
                      </div>
                    </div>
                  ) : (
                    // <img
                    //   src={bbcadaClose}
                    //   alt="Hide info"
                    //   className="hideInfoTxtClose x082"
                    //   onClick={() => {
                    //     setShowInstuction(!showInstruction);
                    //   }}
                    // />
                    ""
                  )}
                  {!loading && showInstruction ? (
                    <div
                      className={`unlinkedClockCardsExplain x4082 ${
                        memoVals.mob ? "unlinkedClockCardsExplain" : ""
                      }`}
                    >
                      <div className="showInstructHeader">
                        <div className="whatAreTheseTitle">
                          {/* <img
                            src={pinkQ}
                            alt="Explained"
                            className="whatAreTheseQimg"
                          />{" "} */}
                          <p className="showInstructTitle">
                            {memoVals.absencePage === "periods"
                              ? "Absence periods"
                              : memoVals.absencePage === "shifts"
                              ? "Absent shifts"
                              : memoVals.absencePage === "late"
                              ? "Shifts started late"
                              : memoVals.absencePage === "forms"
                              ? "Absence forms"
                              : "Absence ranknig"}
                          </p>
                        </div>
                        <img
                          src={whitecross}
                          alt="Hide info"
                          className="hideInfoTxtClose"
                          onClick={() => {
                            setShowInstuction(false);
                          }}
                        />
                      </div>
                      {getListInto()}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "periods" &&
                      absences.length === 0 &&
                      selectedType === "allTypes" &&
                      !showInstruction
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxtMgr" : ""
                          } x23894985891`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt noAbsPeriodsMgr">
                        No periods of absence in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="noShiftsSideBar"></div>
                  </div>
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "late" &&
                      lates.length === 0 &&
                      selectedType === "allTypes" &&
                      !showInstruction
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxtMgr" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt">
                        No shifts started late in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="noShiftsSideBar"></div>
                  </div>
                  <div
                    className={`${
                      !loading &&
                      memoVals.absencePage === "shifts" &&
                      shifts.length === 0 &&
                      selectedType === "allTypes" &&
                      !showInstruction
                        ? `noShiftsTxtContainer noUpcomingReqs ${
                            memoVals.mob ? "mobNoShiftsTxtMgr" : ""
                          }`
                        : "none"
                    }`}
                  >
                    <div className="noShiftsSideBar"></div>
                    {!loading ? (
                      <p className="noShiftsTxt">
                        No absent shifts in {selectedYear}
                      </p>
                    ) : (
                      ""
                    )}

                    <div className="noShiftsSideBar"></div>
                  </div>
                  {/* {noForms ? "NOforms " : "_fdorms"} */}
                  {/* {!loading && memoVals.absencePage === "forms" 
                    ? "NO FORMS"
                    : ""} */}
                  {/* LOADING */}
                  {!loading &&
                  !enableForm &&
                  memoVals.absencePage === "forms" ? (
                    <p className="formsDisabled">
                      Return-to-work absence forms are currently disabled.{" "}
                      {checkUserState.permissions === 1
                        ? "You can enable them in the Admin panel, under Absence settings."
                        : ""}
                    </p>
                  ) : (
                    ""
                  )}
                  {!loading &&
                  enableForm &&
                  questionsQty === 0 &&
                  memoVals.absencePage === "forms" ? (
                    <p className="formsDisabled x1981d">
                      Return-to-work absence forms are enabled, but no questions
                      exist in the FlowRota admin settings.{" "}
                      {checkUserState.permissions === 1
                        ? "You must add questions in the Admin panel (under Absence settings) in order for you and managers to create forms for employees to complete upon their return to work."
                        : "Ask your FlowRota administrator to add questions in order to create forms for your employees to complete upon their return to work."}
                    </p>
                  ) : (
                    ""
                  )}
                  {!loading &&
                  memoVals.absencePage === "shifts" &&
                  countData.selfLoggedAbsence > 0 ? (
                    <div className="slaAbsItem">
                      <p className="slaAbsItemTxt">
                        {countData.selfLoggedAbsence} shift
                        {countData.selfLoggedAbsence === 1 ? "" : "s"} logged as
                        absent
                      </p>
                      <p
                        className="slaAbsItemBtn"
                        onClick={() => {
                          // go to logged abs shifts in team hours here
                          window.location.href = `${appURL}/team-hours?logged=true`;
                        }}
                      >
                        View{" "}
                        <img src={whiteGo} alt="Team" className="whiteGoImg" />
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {loading ? (
                    <img
                      src={horizontalBalls}
                      alt="loading"
                      className="blueLoadingReqs"
                    />
                  ) : memoVals.absencePage === "periods" ? (
                    generateAbsences
                  ) : memoVals.absencePage === "shifts" ? (
                    generateShifts
                  ) : memoVals.absencePage === "forms" ? (
                    noForms ? (
                      <div
                        className={`noShiftsTxtContainer noUpcomingReqs ${
                          showInstruction ? "none" : ""
                        } ${memoVals.mob ? "mobNoShiftsTxtMgr" : ""}`}
                      >
                        <div className="noShiftsSideBar"></div>
                        {!loading ? (
                          <p className="noShiftsTxt">{noForms}</p>
                        ) : (
                          ""
                        )}
                        <div className="noShiftsSideBar"></div>
                      </div>
                    ) : (
                      generateForms
                    )
                  ) : (
                    generateLates
                  )}
                  {/* END OF LOADING */}
                </div>
              </div>
            </div>
            {memoVals.full ? (
              <div className="desktopMasterSecondary">
                <StaffAbsenceDataBox
                  teamID={selectedTeam}
                  year={selectedYear}
                  years={yearsArr}
                  setYear={setSelectedYear}
                  //   setLoadUser={setLoadUser}
                  //   setShowRequests={setShowRequests}
                  // setShowTeamHoursStatsModal={setShowTeamHoursStatsModal}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* end of requests */}
        {/* Stats modal */}
        {memoVals.showReqStats === "myStats" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats((x) => "");
            }}
          >
            <MyReqsDataBox
              modal={true}
              setShowAllows={setShowReqStats}
              selectedYear={selectedYear}
              setSelectedYearParent={setSelectedYear}
            />
          </div>
        ) : (
          ""
        )}
        {renderSideModal()}
        {renderEditShiftModal()}
        {/* mgr Stats modal */}
        {memoVals.showReqStats === "mgr" ? (
          <div
            className="reqStatsUnderlay"
            onClick={(e) => {
              memoVals.setShowReqStats((x) => "");
            }}
          >
            <MgrReqsDataBox modal={true} allNames={allNames} />
          </div>
        ) : (
          ""
        )}
        {/* end of stats modals */}
        {/* <p
          className={`addShiftBtnOnTeamShifts ${
            loading ? "disableAddBtn" : ""
          } ${memoVals.full ? "x091011" : ""} ${
            memoVals.absencePage === "shifts" ||
            memoVals.absencePage === "late" ||
            showSideModal ||
            !memoVals.mob
              ? "none"
              : ""
          }`}
          onClick={() => {
            console.log(memoVals.absencePage);
            if (!loading) {
              if (memoVals.absencePage === "periods") {
                memoVals.setShowAddLeaveOnTeam((x) => "abs");
              }

              if (memoVals.absencePage === "forms") {
                memoVals.setShowCreateFormModal((x) => true);
              }

              // setShowTilOrShiftPopUp(true);
            }
            // setAddShiftPage(0);
            // setAddShiftOrigin("onTeam");
            // setShowAddShift(true);
          }}
        >
          +
        </p> */}
        {/* <p
          className="addShiftBtnOnTeamShifts"
          onClick={() => {
            memoVals.setShowAddLeaveOnTeam(x =>{
              new: true,
            });
          }}
        >
          +
        </p> */}
        <Navbar />
        {memoVals.mob && <CalendarSubBar />}
      </div>

      <p
        className={`addShiftBtnOnTeamShifts absAddCornerBtn
           ${loading ? "disableAddBtn" : ""}
        ${
          (memoVals.showNotifications || memoVals.showMessages) && memoVals.mob
            ? "none"
            : ""
        }`}
        onClick={() => {
          if (!loading) {
            if (memoVals.absencePage === "periods") {
              memoVals.setShowAddLeaveOnTeam((x) => "abs");
            } else if (memoVals.absencePage === "forms") {
              memoVals.setShowCreateFormModal((x) => true);
            } else {
              memoVals.setShowAddAbsenceModal((x) => true);
            }

            // setShowTilOrShiftPopUp(true);
          }
        }}
      >
        +
      </p>
      {imgPreload}
    </div>
  );
};

export default StaffAbsence;
