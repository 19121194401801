import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";

import dateStringer from "../../tools/dateStringer";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";

import close from "../../img/modals/close.svg";
import shiftBlock from "../../img/general/shiftBlock.svg";
import rotaDur from "../../img/general/rotaDur.svg";
import rotaTeam from "../../img/general/rotaTeam.svg";
import editNavy from "../../img/general/editNavy.svg";
import rotaDate from "../../img/general/rotaDate.svg";
import rotaPlane from "../../img/general/rotaPlane.svg";
import streamCross from "../../img/general/streamCross.svg";
import rotaReq from "../../img/general/rotaReq.svg";

import desktopBurger from "../../img/general/desktopBurger.svg";

import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

const UserHoursModal = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    setOpenManagerShiftModal,
    setShowReqModalInViewer,
    showReqModalInViewer,
    showEditUser,
    setShowEditUser,
    showMyShiftModal,

    setShowMyShiftModal,
    modalOpen,
    setModalOpen,
    device,
    viewAbsenceUserTimeline,
    setViewAbsenceUserTimeline,
    showReqMgrModal,
    setShowReqMgrModal,
    setShowEditShiftModal,
    showEditShiftModal,
    showUserReqFromNav,
    setShowUserReqFromNav,
    //
    showUserHoursModal,
    setShowUserHoursModal,
    setSeeUserID,
    seeUserID,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      showEditUser,
      showEditShiftModal,
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      modalOpen,
      setModalOpen,
      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
      setSeeUserID,
      seeUserID,

      //
      showUserHoursModal,
      setShowUserHoursModal,
    }),
    [
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      showEditUser,
      showEditShiftModal,
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      modalOpen,
      setModalOpen,

      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
      setSeeUserID,
      seeUserID,

      //
      showUserHoursModal,
      setShowUserHoursModal,
    ]
  );
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shiftBlock}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editNavy}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDate}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPlane}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={streamCross}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaReq}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [periodLoading, setPeriodLoading] = useState(false);
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowUserHoursModal((x) => {
        return { show: false };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowUserHoursModal((x) => {
      return { show: false };
    });
    if (memoVals.showUserHoursModal.fromEditUser) {
      memoVals.setShowEditUser((x) => memoVals.showUserHoursModal.userID);
    }
  };

  let [fName, setFName] = useState("");
  let [lName, setLName] = useState("");
  let [profPicUrl, setProfPicUrl] = useState("");

  let [arr, setArr] = useState([]);
  let [isMgr, setIsMgr] = useState(false);
  let [weeklyAverageContractedMins, setWeeklyAverageContractedMins] =
    useState(0);
  let [weekMinsArr, setWeekMinsArr] = useState([]);
  let [startDs, setStartDs] = useState("");
  let [endDs, setEndDs] = useState("");
  let [payableMins, setPayableMins] = useState(0); // worked hours only
  let [leaveMins, setLeaveMins] = useState(0); // paid leave/absence only
  let [periodMode, setPeriodMode] = useState(false);
  let [startDateInput, setStartDateInput] = useState("");
  let [endDateInput, setEndDateInput] = useState("");

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    let run = false;
    if (!periodMode) {
      run = true;
    } else {
      if (startDateInput.length === 10 && endDateInput.length === 10) {
        let endTs = dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(endDateInput)
        );

        let startTs = dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(startDateInput)
        );
        if (endTs >= startTs) {
          let diff = Math.round((endTs - startTs) / 86400000);
          if (diff <= 366) {
            run = true;
          }
        }
      }
    }
    if (run) {
      if (periodMode) {
        setPeriodLoading(true);
      }
      axios
        .post(
          `${serverURL}/user-hours-modal`,
          {
            userID: showUserHoursModal.userID,
            monWeek: periodMode ? "" : showUserHoursModal.monWeek,
            startDs: periodMode
              ? dateStringer.createStringFromDateInput(startDateInput)
              : "",
            endDs: periodMode
              ? `${dateStringer
                  .createStringFromDateInput(endDateInput)
                  .substr(0, 11)}H23M59`
              : "",
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (periodMode) {
              setTimeout(() => {
                setPeriodLoading(false);
              }, 400);
            }
            //
            setArr(response.data.output);
            setFName(response.data.fName);
            setLName(response.data.lName);
            setProfPicUrl(response.data.profPicUrl);
            setWeeklyAverageContractedMins(
              response.data.weeklyAverageContractedMins
            );
            setLeaveMins(response.data.leaveMins);
            setWeekMinsArr(response.data.weekMinsArr);
            setIsMgr(response.data.isMgr);
            setStartDs(response.data.minDs);
            setEndDs(response.data.endDs);
            setPayableMins(response.data.payableMins);

            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [startDateInput, endDateInput]);

  useEffect(() => {
    if (!startDateInput) {
      let endObj = new Date(
        dateStringer.createTimestampFromString(showUserHoursModal.monWeek)
      );
      endObj.setDate(endObj.getDate() + 6);
      setStartDateInput(
        dateStringer.createDateInputFromDateString(showUserHoursModal.monWeek)
      );

      setEndDateInput(
        dateStringer.createDateInputFromTimestamp(endObj.getTime())
      );
    }
  }, []);

  let [dateInvalid, setDateInvalid] = useState(false);
  let [exceeded, setExceeded] = useState(false);
  useEffect(() => {
    if (periodMode) {
      if (startDateInput.length === 10 && endDateInput.length === 10) {
        let startTs = dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(startDateInput)
        );
        let endTs = dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(endDateInput)
        );

        if (Math.round((endTs - startTs) / 86400000) > 366) {
          setExceeded(true);
          setPeriodLoading(false);
        } else {
          setExceeded(false);
        }
        if (
          dateStringer.createTimestampFromString(
            dateStringer.createStringFromDateInput(endDateInput)
          ) <
          dateStringer.createTimestampFromString(
            dateStringer.createStringFromDateInput(startDateInput)
          )
        ) {
          setDateInvalid(true);
        } else {
          setDateInvalid(false);
        }
      } else {
        setDateInvalid(false);
        setExceeded(false);
      }
    }
  }, [startDateInput, endDateInput]);
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // MASTER RETURN
  return (
    <div
      className={`filtTagsUnderlay ${
        memoVals.showReqMgrModal ||
        memoVals.showUserReqFromNav ||
        memoVals.showEditUser ||
        memoVals.showMyShiftModal.unitID ||
        (memoVals.showEditShiftModal.itemID &&
          !memoVals.showUserHoursModal.fromShift)
          ? "none"
          : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      <div className="tagsFiltDropper">
        <div
          className={`filtTagsModal noMarginBottom ${
            memoVals.mobModal
              ? "filtTagsModalMob mobModalShoulder"
              : "userHoursModalBody zoomIn"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {" "}
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewUserhours"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`userHoursHeader `}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            <p
              className={`toilHeaderTxt ${!fName ? "invis" : ""}`}
              onClick={() => {
                if (memoVals.mob) {
                  closeModal();
                }
              }}
            >
              {dateStringer.possession(`${fName}`)} hours{" "}
              <img
                src={profPicUrl || profilePicturePlaceholder}
                alt={fName}
                className="userHoursPPimg"
                onClick={() => {
                  if (isMgr) {
                    memoVals.setShowEditUser(
                      memoVals.showUserHoursModal.userID
                    );
                  } else {
                    memoVals.setSeeUserID((x) => {
                      return {
                        userID: memoVals.showUserHoursModal.userID,
                        openedFromUserRota: false,
                      };
                    });
                  }
                }}
              />
            </p>
            {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
          </div>
          <div
            className={`tagsFiltScrollBox paddingBottom10 ${
              !loading && arr.length === 0 ? "noArrUserHourscontentBox" : ""
            } ${
              !loading && arr.length > 0
                ? "userHoursModalContentBoxWithArr"
                : ""
            }`}
          >
            {loading ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="viewAbsHorizBalls"
              />
            ) : (
              <div>
                <div className="userHoursModalContent">
                  <div className="userHoursModalRow">
                    {periodMode ? (
                      <input
                        type="date"
                        className={`userHoursDatePeriodInput ${
                          dateInvalid ? "invalUserHoursDateInput" : ""
                        }`}
                        value={startDateInput}
                        onChange={(e) => {
                          setPeriodLoading(true);
                          setStartDateInput(e.target.value);
                        }}
                      ></input>
                    ) : (
                      <div className="userHoursModalRowTitle">
                        Week commencing
                        <p className="userHoursModalRowTitleSub">7 days</p>
                      </div>
                    )}
                    {periodMode && window.innerWidth > 380 && (
                      <p className="userHoursUntil">-</p>
                    )}
                    {periodMode ? (
                      <input
                        type="date"
                        className={`userHoursDatePeriodInput ${
                          dateInvalid ? "invalUserHoursDateInput" : ""
                        }`}
                        value={endDateInput}
                        onChange={(e) => {
                          setPeriodLoading(true);

                          setEndDateInput(e.target.value);
                        }}
                      ></input>
                    ) : (
                      <p
                        className={`userHoursModalRowValue ${
                          periodLoading ? "invis" : ""
                        }`}
                      >
                        {window.innerWidth < 381
                          ? dateStringer.printedDateFromDs(
                              startDs,
                              true,
                              false,
                              false,
                              true
                            )
                          : dateStringer.printedDateFromDs(startDs, true)}{" "}
                        <img
                          src={editNavy}
                          alt="Edit dates"
                          className="userHoursEditBtn"
                          onClick={() => {
                            setPeriodMode(true);
                          }}
                        />
                      </p>
                    )}
                  </div>

                  <div className="userHoursModalRow">
                    <div className="userHoursModalRowTitle">
                      Contracted hours
                      <p className="userHoursModalRowTitleSub">Average</p>
                    </div>

                    <p
                      className={`userHoursModalRowValue ${
                        periodLoading ? "invis" : ""
                      }`}
                    >
                      {dateStringer.formatMinsDurationToHours(
                        weeklyAverageContractedMins
                      )}
                    </p>
                  </div>
                  <div className="userHoursModalRow">
                    <p className="userHoursModalRowTitle">Hours worked</p>
                    <p
                      className={`userHoursModalRowValue ${
                        periodLoading ? "invis" : ""
                      }`}
                    >
                      {dateStringer.formatMinsDurationToHours(payableMins)}
                    </p>
                  </div>
                  <div className="userHoursModalRow">
                    <p className="userHoursModalRowTitle">
                      Paid leave & absence
                    </p>
                    <p
                      className={`userHoursModalRowValue ${
                        periodLoading ? "invis" : ""
                      }`}
                    >
                      {dateStringer.formatMinsDurationToHours(leaveMins)}
                    </p>
                  </div>
                  <div className="userHoursModalRow noBorder">
                    <p className="userHoursModalRowTitle">
                      Difference
                      <p className="userHoursModalRowTitleSub">
                        Contracted hours vs. paid for
                      </p>
                    </p>
                    <p
                      className={`userHoursModalRowValue ${
                        periodLoading ? "invis" : ""
                      } ${
                        payableMins + leaveMins - weeklyAverageContractedMins <
                        0
                          ? "negativeTxt"
                          : "colour00aaff"
                      }`}
                    >
                      {payableMins + leaveMins - weeklyAverageContractedMins > 0
                        ? "+"
                        : ""}
                      {payableMins + leaveMins - weeklyAverageContractedMins < 0
                        ? `${Math.round(
                            payableMins +
                              leaveMins -
                              weeklyAverageContractedMins
                          )} minute${
                            payableMins +
                              leaveMins -
                              weeklyAverageContractedMins ===
                            -1
                              ? ""
                              : "s"
                          }`
                        : dateStringer.formatMinsDurationToHours(
                            payableMins +
                              leaveMins -
                              weeklyAverageContractedMins
                          )}
                    </p>
                  </div>
                </div>
                {/* <div className="userHoursModalBoxSplitter"></div> */}
                <div className="userHoursModalBoxArr">
                  {arr.length === 0 && !periodMode ? (
                    <p className="noUserHoursTxt">
                      {fName} has no payable hours within this period
                    </p>
                  ) : (
                    ""
                  )}
                  {arr[0] && (
                    <p className="userHoursArrTitle">
                      {dateStringer.possession(fName)} payable hours
                    </p>
                  )}
                  {periodLoading ? (
                    <img
                      src={horizontalBalls}
                      alt="Loading"
                      className={`periodLoadingImg ${
                        dateInvalid ? "invis" : ""
                      }`}
                    />
                  ) : exceeded && !dateInvalid ? (
                    <p className="noUserHoursTxt">
                      Maximum one year period allowed. Please adjust your dates.
                    </p>
                  ) : (
                    arr.map((item, i) => {
                      return (
                        <div
                          className={`userHoursItem ${
                            item.type === "leave"
                              ? "userHoursLeaeveBg"
                              : item.type === "absence"
                              ? "userHoursAbsenceBg"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            if (
                              item.type === "absence" ||
                              item.type === "leave"
                            ) {
                              if (isMgr) {
                                memoVals.setShowReqMgrModal((x) => item.reqID);
                              } else {
                                memoVals.setShowUserReqFromNav(
                                  (x) => item.reqID
                                );
                              }
                            } else {
                              if (isMgr) {
                                memoVals.setShowEditShiftModal((x) => {
                                  return {
                                    itemID: item.shiftID || item.tilID,
                                    itemType: item.type,

                                    itemIsNew: false,
                                    userID: memoVals.showUserHoursModal.userID,
                                  };
                                });
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType: item.type,
                                    unitID: item.shiftID || item.tilID,
                                  };
                                });
                              }
                            }
                          }}
                        >
                          <div className="userHoursItemLeft">
                            <img
                              src={
                                item.type === "absence"
                                  ? streamCross
                                  : item.type === "leave"
                                  ? item.annLeave
                                    ? rotaPlane
                                    : rotaReq
                                  : shiftBlock
                              }
                              alt="Hours"
                              className="userHoursItemLeftImg"
                            />
                          </div>
                          <div className="userHoursItemMid">
                            <div className="userHoursItemMidRow x484877857878">
                              <img
                                src={desktopBurger}
                                alt="Item date"
                                className="userHoursDataRowImg"
                              />{" "}
                              {item.type === "shift"
                                ? "Shift"
                                : item.type === "til"
                                ? "Overtime (in lieu)"
                                : item.type === "overtime"
                                ? "Overtime (paid)"
                                : item.typeName}
                            </div>
                            <div className="userHoursItemMidRow">
                              <img
                                src={rotaDate}
                                alt="Item date"
                                className="userHoursDataRowImg"
                              />{" "}
                              {dateStringer.printedDateFromDs(item.startDs)}
                            </div>
                            <div className="userHoursItemMidRow">
                              <img
                                src={rotaTeam}
                                alt="Item date"
                                className="userHoursDataRowImg"
                              />{" "}
                              {item.teamName}
                            </div>
                          </div>
                          <div className="userHoursItemRight">
                            <img
                              src={rotaDur}
                              alt="Item date"
                              className="userHoursDataRowImg"
                            />{" "}
                            {dateStringer.formatMinsDurationToHours(
                              item.reqID
                                ? item.payableMins
                                : item.payableDurMins
                            )}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="toilFooter">
            <p></p>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      </div>
      {imgPreload}
    </div>
  );
};

export default UserHoursModal;
