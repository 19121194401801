import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";

import dateStringer from "../../tools/dateStringer";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";

import checkboxInstall from "../../img/general/checkboxLilac.svg";
import close from "../../img/modals/close.svg";
import cross516b8b from "../../img/general/cross516b8b.svg";
import shiftBlock from "../../img/general/shiftBlock.svg";

import LeaveReqModal from "../requests/LeaveReqModal";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

const ViewUserAbsenceTimelineModal = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    setOpenManagerShiftModal,
    setShowReqModalInViewer,
    showReqModalInViewer,
    setShowEditUser,
    showMyShiftModal,

    setShowMyShiftModal,
    modalOpen,
    setModalOpen,
    device,
    viewAbsenceUserTimeline,
    setViewAbsenceUserTimeline,
    showReqMgrModal,
    setShowReqMgrModal,
    setShowEditShiftModal,
    showUserReqFromNav,
    setShowUserReqFromNav,
    showEditShiftModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      showEditShiftModal,
      modalOpen,
      setModalOpen,
      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
    }),
    [
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      modalOpen,
      setModalOpen,
      showEditShiftModal,

      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
    ]
  );
  let [loading, setLoading] = useState(false);
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      //     memoVals.setShowViewTeamOnRequest(x =>false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setViewAbsenceUserTimeline((x) => {
      return { show: false };
    });
  };

  let [fName, setFName] = useState("");
  let [timeline, setTimeline] = useState([]);
  let [year, setYear] = useState(1111);
  let [years, setYears] = useState([]);
  let [allTime, setAllTime] = useState(false);
  let [reload, setReload] = useState(false);
  const cancelSource1 = axios.CancelToken.source();
  const cancelSource2 = axios.CancelToken.source();

  useEffect(() => {
    if (!memoVals.showReqMgrModal && !memoVals.showEditShiftModal) {
      setLoading(true);
      if (!years[0]) {
        axios
          .post(
            `${serverURL}/get-available-years`,
            {
              userID: viewAbsenceUserTimeline.userID,
              userOnly: true,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              setYears(response.data.data);
              if (year === 1111) {
                setYear(response.data.currentYear);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      axios
        .post(
          `${serverURL}/user-absence-timeline`,
          {
            userID: viewAbsenceUserTimeline.userID,
            year: parseInt(year),
            allTime,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setFName(response.data.fName);
            setTimeline(response.data.output);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
        cancelSource2.cancel("Component unmounted");
      };
    }
  }, [reload, memoVals.showReqMgrModal, memoVals.showEditShiftModal]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // MASTER RETURN
  return (
    <div
      className={`filtTagsUnderlay ${
        memoVals.showReqMgrModal || memoVals.showUserReqFromNav ? "none" : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      <div className="tagsFiltDropper">
        <div
          className={`filtTagsModal ${
            memoVals.mobModal ? "filtTagsModalMob mobModalShoulder" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {" "}
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewTs"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`toilHeader `}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            <p
              className={`toilHeaderTxt fontSize14 ${!fName ? "invis" : ""}`}
              onClick={() => {
                if (memoVals.mob) {
                  closeModal();
                }
              }}
            >
              {dateStringer.possession(fName)} absence timeline
            </p>
            {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
          </div>
          <div className="viewAbsShoulder">
            <div
              className="viewAbsAllTimeBtn lilacColours"
              onClick={() => {
                setAllTime(!allTime);
                setReload(!reload);
              }}
            >
              All time{" "}
              <div
                className={`allTimeCheckWrap ${
                  allTime ? "allTimeCheckWrapActive" : ""
                }`}
              >
                <img
                  src={checkboxInstall}
                  alt="All time checkbox"
                  className={`allTimeCheckImg ${allTime ? "" : "invis"}`}
                />
              </div>
            </div>{" "}
            <select
              className={`myReqsYearDropdown`}
              value={allTime ? "Year" : year}
              onChange={(e) => {
                let val = e.target.value;
                setYear(val);
                setReload(!reload);
              }}
            >
              {" "}
              {allTime && <option value="Year">Year</option>}
              {years.map((x) => {
                return (
                  <option value={x} key={x}>
                    {x}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="tagsFiltScrollBox paddingBottom10">
            {loading ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="viewAbsHorizBalls"
              />
            ) : timeline.length === 0 ? (
              <p className="noAbsTimelineTxt">
                No absence history for {fName}
                {!allTime ? ` in ${year}` : ""}
              </p>
            ) : (
              timeline.map((item, i) => {
                if (item.type === "period") {
                  return (
                    <div
                      className={`absTimelineItem absTimelineItem1 ${
                        allTime ? "absTimelineItemAllTime" : ""
                      }`}
                      key={i}
                      onClick={() => {
                        if (memoVals.viewAbsenceUserTimeline.mgr) {
                          memoVals.setShowReqMgrModal((x) => item.reqID);
                        } else {
                          memoVals.setShowUserReqFromNav((x) => item.reqID);
                        }
                      }}
                    >
                      <div className="absTimelineItemLeft">
                        <div className="absTimelineIcon2">
                          <img
                            src={cross516b8b}
                            alt="Absence period"
                            className="absTimelinePeriodIconImg2"
                          />
                        </div>
                        <div className="absTimelineMid">
                          <p className="absTimelineTitle">
                            {item.absenceTypeName.length > 16
                              ? dateStringer.shorten(item.absenceTypeName, 14)
                              : item.absenceTypeName}
                          </p>
                          <p className="absTimelineType">
                            {item.days} day
                            {item.days === 1 || item.days === 0.5 ? "" : "s"}
                            <span className="absTimelineValMinsSpan">
                              {dateStringer.formatMinsDurationToHours(
                                item.mins
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="absTimelineVal">
                        <div
                          className={`absCalDiv ${
                            item.startDs === item.endDs ? "invis" : ""
                          }`}
                        >
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              allTime ? "absCalDivLowerAllTime" : ""
                            }`}
                          >
                            <p className="absCalDivLowerDate">
                              {parseInt(item.startDs.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.startDs
                              )}
                            </p>
                            {allTime && (
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className={`absCanDivSplitter ${
                            item.startDs === item.endDs ? "invis" : ""
                          }`}
                        ></div>
                        <div className="absCalDiv">
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              allTime ? "absCalDivLowerAllTime" : ""
                            }`}
                          >
                            <p className="absCalDivLowerDate">
                              {parseInt(item.endDs.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.endDs
                              )}
                            </p>{" "}
                            {allTime && (
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`absTimelineItem ${
                        allTime ? "absTimelineItemAllTime" : ""
                      }`}
                      key={i}
                      onClick={() => {
                        if (memoVals.viewAbsenceUserTimeline.mgr) {
                          memoVals.setShowEditShiftModal((x) => {
                            return {
                              itemID: item.itemID,
                              itemType: item.type,
                              itemIsNew: false,
                              userID: viewAbsenceUserTimeline.userID,
                            };
                          });
                        } else {
                          memoVals.setShowMyShiftModal((x) => {
                            return {
                              unitType: item.type,
                              unitID: item.itemID,
                            };
                          });
                        }
                      }}
                    >
                      <div className="absTimelineItemLeft">
                        <div className="absTimelineIcon2">
                          <img
                            src={shiftBlock}
                            alt="Absence period"
                            className="absTimelinePeriodIconImg2ShiftBlock"
                          />
                        </div>
                        <div className="absTimelineMid">
                          <p className="absTimelineTitle absTimelineTitle2">
                            {item.type === "til"
                              ? "Absent overtime (time in lieu)"
                              : `Absent ${item.type}`}
                          </p>
                          <p className="absTimelineType">
                            {dateStringer.formatMinsDurationToHours(
                              item.absenceMins
                            )}{" "}
                            {item.partialAbsent ? "(partial)" : "(whole)"}
                          </p>
                          <p className="absTimelineType absTimelineTitle2 absTimelineTitle3">
                            {item.absenceTypeName
                              ? dateStringer.shorten(item.absenceTypeName, 18)
                              : item.unpaidAbsent
                              ? "Unpaid absence"
                              : "Paid absence"}
                          </p>
                        </div>
                      </div>
                      <div className="absTimelineVal absTimelineVal2">
                        <div className={`absCalDiv invis`}>
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              allTime ? "absCalDivLowerAllTime" : ""
                            }`}
                          >
                            <p className="absCalDivLowerDate">
                              {parseInt(item.startDs.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.startDs
                              )}
                            </p>
                            {allTime && (
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={`absCanDivSplitter invis`}></div>
                        <div className="absCalDiv">
                          <div className="absCalDivUpper">
                            <div className="absDivCalNip"></div>
                            <div className="absDivCalNip"></div>
                          </div>
                          <div
                            className={`absCalDivLower ${
                              allTime ? "absCalDivLowerAllTime" : ""
                            }`}
                          >
                            <p className="absCalDivLowerDate">
                              {parseInt(item.startDs.substr(9, 2))}
                            </p>
                            <p className="absCalDivLowerMonth">
                              {dateStringer.getMonthThreeLettersFromDs(
                                item.startDs
                              )}
                            </p>{" "}
                            {allTime && (
                              <p className="absCalDivLowerYear">
                                {item.startDs.substr(1, 4)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            )}
          </div>
          <div className="toilFooter">
            <p></p>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserAbsenceTimelineModal;
