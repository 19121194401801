import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared up 10 sep 23
// images preloaded 19 sep 23
// axios caught 21 sep 23

import axios from "axios";
import { CalendarContext } from "../../../contexts/CalendarContext";
import { UserContext } from "../../../contexts/UserContext";
import { DataContext } from "../../../contexts/DataContext";
import { StyleContext } from "../../../contexts/StyleContext";
import serverURL from "../../../serverURL";

import dateStringer from "../../../tools/dateStringer";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";
import rotaCoffee from "../../../img/general/rotaCoffee.svg";
import rotaClock from "../../../img/general/rotaClock.svg";
import rotaDate from "../../../img/general/rotaDate.svg";
import shiftBlockNavy from "../../../img/general/shiftBlockNavy.svg";

import clockcard from "../../../img/general/shiftBlockNavy.svg";
import rotaTeam from "../../../img/general/rotaTeam.svg";

import "../../../styles/calendar.css";

import ShiftsHeader from "./ShiftsHeader";
import HoursDatabox from "./HoursDataBox";

const ClockOns = ({ sideBoxData, my, setMy }) => {
  const { setCalPage } = useContext(CalendarContext);
  const { setPage, availableMonths, setClockOnID, deletedClockOnID, device } =
    useContext(DataContext);
  const { mob, full, fullButNarrow } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setCalPage, //
      setPage, //
      availableMonths, //
      setClockOnID, //
      deletedClockOnID, //
      device, //
      mob, //
      full, //
      fullButNarrow, //
    }),
    [
      setCalPage, //
      setPage, //
      availableMonths, //
      setClockOnID, //
      deletedClockOnID, //
      device, //
      mob, //
      full, //
      fullButNarrow, //
    ]
  );

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      {/* Previously added images */}
      {/* ... */}
      {/* Additional images */}
      <img
        src={clockcard}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shiftBlockNavy}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaDate}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaTeam}
        alt="White Go"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [reverse, setReverse] = useState(false);
  let [clockOns, setClockOns] = useState([]);
  let [finalAwaitingClockIndex, setFinalAwaitingClockIndex] = useState(-1);
  let [showShifts, setShowShifts] = useState(true);
  let [showTils, setShowTils] = useState(true);
  let [showOvertimes, setShowOvertimes] = useState(true);

  useEffect(() => {
    setMy(dateStringer.getMyFromTs(new Date().getTime()));

    // setMy("0724");
    // setMy(`${monthNum}${yearNum}`);
  }, []);
  document.title = "FlowRota - My clock cards";

  let [pendingsExist, setPendingExists] = useState(false);
  let [approvedExist, setApprovedExist] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (my) {
      let loadFlagged = false;
      if (!loading) {
        setLoading(true);
        loadFlagged = true;
      }
      axios
        .post(
          `${serverURL}/get-user-clock-ons`,
          { my: my },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            // setReverse(response.data.reverse);
            let pending = [];
            let nonPending = [];

            response.data.clockOns.forEach((c) => {
              if (c.approved || c.declined) {
                nonPending.push(c);
              } else {
                pending.push(c);
              }
            });

            if (pending[0] && nonPending[0]) {
              // if (!memoVals.mob) {
              setFinalAwaitingClockIndex(pending.length - 1);
              // }
            } else {
              if (finalAwaitingClockIndex !== -1) {
                setFinalAwaitingClockIndex(-1);
              }
            }

            if (pending[0] && nonPending[0] && !memoVals.mob) {
              pending.push({ split: true });
              setPendingExists(true);
            } else {
              setPendingExists(false);
            }

            setClockOns([...pending, ...nonPending]);

            if (nonPending[0]) {
              setApprovedExist(true);
            } else {
              setApprovedExist(false);
            }

            if (loadFlagged) {
              setLoading(false);
            }
            setDataLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [my]);

  // clockOns.sort(function (a, b) {
  //   //   if (!reverse) {
  //   return b.order - a.order;
  //   //   } else {
  //   //     return a.order - b.order;
  //   //   }
  // });

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!loading) {
      let count = 0;
      clockOns.forEach((item) => {
        if (item) {
          count++;
        }
      });
      if (!count) {
        axios
          .post(
            `${serverURL}/get-user-clock-ons`,
            { my: my },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "success") {
              let approvedOrDeclined = response.data.clockOns.filter((x) => {
                return x.approved || x.declined;
              });

              let pending = response.data.clockOns.filter((x) => {
                return !x.declined && !x.approved;
              });

              if (pending[0] && approvedOrDeclined[0]) {
                // if (!memoVals.mob) {
                setFinalAwaitingClockIndex(pending.length - 1);
                // }
              } else {
                if (finalAwaitingClockIndex !== -1) {
                  setFinalAwaitingClockIndex(-1);
                }
              }

              if (pending[0] && approvedOrDeclined[0] && !memoVals.mob) {
                pending.push({ split: true });
                setPendingExists(true);
              } else {
                setPendingExists(false);
              }
              setClockOns([...pending, ...approvedOrDeclined]);

              if (approvedOrDeclined[0]) {
                setApprovedExist(true);
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [loading]);

  useEffect(() => {
    // clean not needed
    let newArr = [];
    clockOns.forEach((c) => {
      if (c.clockOnID !== memoVals.deletedClockOnID && !c.split) {
        newArr.push(c);
      }
    });

    let pending = [];
    let nonPending = [];

    newArr.forEach((c) => {
      if (c.approved || c.declined) {
        nonPending.push(c);
      } else {
        pending.push(c);
      }
    });

    if (pending[0] && nonPending[0]) {
      // if (!memoVals.mob) {
      setFinalAwaitingClockIndex(pending.length - 1);
      // }
    } else {
      if (finalAwaitingClockIndex !== -1) {
        setFinalAwaitingClockIndex(-1);
      }
    }

    if (pending[0] && nonPending[0] && !memoVals.mob) {
      pending.push({ split: true });
      setPendingExists(true);
    } else {
      setPendingExists(false);
    }

    setClockOns([...pending, ...nonPending]);

    // console.log("NEW CLOCKS", [...pending, ...nonPending]);
  }, [memoVals.deletedClockOnID]);

  let generateMonthOptions = useMemo(() => {
    return memoVals.availableMonths.map((month) => {
      if (dateStringer.getTsFromMy(month) <= new Date().getTime()) {
        let formatMonth = "Jan";
        let formatYear = month.substr(2, 2);
        if (month.substr(0, 2) === "00") {
          formatMonth = "January";
        }
        if (month.substr(0, 2) === "01") {
          formatMonth = "February";
        }
        if (month.substr(0, 2) === "02") {
          formatMonth = "March";
        }
        if (month.substr(0, 2) === "03") {
          formatMonth = "April";
        }
        if (month.substr(0, 2) === "04") {
          formatMonth = "May";
        }
        if (month.substr(0, 2) === "05") {
          formatMonth = "June";
        }
        if (month.substr(0, 2) === "06") {
          formatMonth = "July";
        }
        if (month.substr(0, 2) === "07") {
          formatMonth = "August";
        }
        if (month.substr(0, 2) === "08") {
          formatMonth = "September";
        }
        if (month.substr(0, 2) === "09") {
          formatMonth = "October";
        }
        if (month.substr(0, 2) === "10") {
          formatMonth = "November";
        }
        if (month.substr(0, 2) === "11") {
          formatMonth = "December";
        }

        return (
          <option key={month} value={month}>
            {formatMonth} 20{formatYear}
          </option>
        );
      }
    });
  }, [memoVals.availableMonths]);

  let clockScrollBox = useRef();

  useEffect(() => {
    // clean not needed
    clockScrollBox.current.scrollTop = 0;
  }, [my]);

  useEffect(() => {
    // clean not needed
    document.title = "My clock cards - FlowRota";

    memoVals.setCalPage((x) => "shifts");
    memoVals.setPage((x) => "clock-ons");
  }, []);

  let [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    // clean not needed
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params && dateStringer.cleanParam(params.clockOnID)) {
      memoVals.setClockOnID((x) => {
        return {
          clockOnID: dateStringer.cleanParam(params.clockOnID),
          isMgr: false,
        };
      });
      // clear the params
      window.history.pushState({}, document.title, window.location.pathname);
    }
  }, []);

  let generateClockOns = useMemo(() => {
    let calcClockedMins = (ds1, ds2, brkMins) => {
      if (ds1 && ds2) {
        let ds1Ts = dateStringer.createTimestampFromString(ds1);
        let ds2Ts = dateStringer.createTimestampFromString(ds2);

        let msDiff = ds2Ts - ds1Ts;

        let brkMs = brkMins * 60 * 1000;
        let totMs = msDiff - brkMs;

        return totMs / 1000;
      }
    };
    return clockOns.map((clock, i) => {
      if (clock.split && approvedExist && !memoVals.mob) {
        return <div className="pendingTsSplitter ccSplitter"></div>;
      } else {
        if (clock.startDs && clock.endDs) {
          let dataRows = 3;

          if (
            (showShifts && clock.type === "shift") ||
            (showTils && clock.type === "til") ||
            (showOvertimes && clock.type === "overtime")
          )
            return (
              <div
                className={`dvStateHighlight dvStateHighlightShift ${
                  !memoVals.mob ? "absShiftItemDesktop" : "absShiftItemMob"
                } newMyAbsShFlex ccItemUnit ${
                  memoVals.mob && finalAwaitingClockIndex === i
                    ? "finalPreSh"
                    : ""
                } ${!memoVals.mob ? "ccItemUnitDesktop" : ""} ${
                  dataRows === 0
                    ? "dataRows0"
                    : dataRows === 1
                    ? "dataRows1"
                    : dataRows === 2
                    ? "dataRows2"
                    : dataRows === 3
                    ? "dataRows3"
                    : dataRows === 4
                    ? "dataRows4"
                    : ""
                }`}
                key={clock.clockOnID}
                onClick={() => {
                  memoVals.setClockOnID((x) => {
                    return { clockOnID: clock.clockOnID, isMgr: false };
                  });
                }}
              >
                <div
                  className={`newMyAbsShLeft ${
                    memoVals.mob ? "newMyAbsShLeftMob" : ""
                  }`}
                >
                  {/* <img
                    src={clockcard}
                    alt="User shift"
                    className={`openShiftBlockDayViewImg openShiftBlockDayViewImgShift x3289478222 rotaClockCardsImg ${
                      memoVals.mob ? "rotaClockCardsImgMob" : ""
                    }`}
                  /> */}

                  <div className="dvStateHighlightedNote">
                    <p
                      className={`dvStateHighlightedNoteName userShItemTxtTitle ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img
                        src={clockcard}
                        alt="Rota clock inline"
                        className="rotaClockOpenDvInlineImg"
                      />
                      Clocked{" "}
                      {clock.type === "til"
                        ? "overtime"
                        : clock.type === "overtime"
                        ? "overtime"
                        : "shift"}
                    </p>
                    <p
                      className={`dvStateHighlightedNoteName ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img
                        src={rotaClock}
                        alt="Rota clock inline"
                        className="rotaClockOpenDvInlineImg"
                      />
                      {dateStringer.dsToTimeStrip(clock.startDs)} -{" "}
                      {dateStringer.dsToTimeStrip(clock.endDs)}
                      {clock.tmw ? ` +${clock.tmw}d` : ""}
                    </p>
                    {/* {open.tags[0] && (
            <div className="dayViewOpenTagsRow">
              <div className="dvOpenTag lilacColours_">
                {open.tags[0]}
              </div>
              {open.tags.length > 1 && (
                <div className="dvOpenTag lilacColours_">
                  +{open.tags.length - 1}
                </div>
              )}
            </div>
          )} */}

                    <div
                      className={`dvShiftNameRow ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img src={rotaDate} className="dvShPP" alt="Date" />{" "}
                      <p className="dvShName colour143051">
                        {dateStringer.printedDateFromDs(clock.startDs)}
                      </p>
                    </div>
                    <div
                      className={`dvShiftNameRow ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img src={shiftBlockNavy} className="dvShPP" alt="Date" />{" "}
                      <p className="dvShName colour143051">
                        {clock.type === "til"
                          ? "Overtime (time in lieu)"
                          : clock.type === "shift"
                          ? "Shift"
                          : "Overtime"}
                      </p>
                    </div>
                    <div
                      className={`dvShiftNameRow ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img
                        src={rotaCoffee}
                        className="dvShPP"
                        alt="Clocked break"
                      />{" "}
                      <p className="dvShName">
                        {dateStringer.formatMinsDurationToHours(
                          clock.totBrkMins
                        )}
                      </p>
                    </div>
                    <div
                      className={`dvShiftNameRow ${
                        clock.declined ? "greyscale" : ""
                      }`}
                    >
                      <img
                        src={rotaTeam}
                        className="dvShPP"
                        alt={clock.teamName}
                      />{" "}
                      <p
                        className={`dvShName ${
                          clock.declined ? "greyscale" : "bbcada"
                        }`}
                      >
                        {dateStringer.shorten(clock.teamName, 24) ||
                          "Unknown team"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="myShRightDiv">
                  <div
                    className={`newMyAbsShRightSec tilShiftDurationTxtTeam  absentButPaid ${
                      memoVals.mob ? "newMyAbsShRightSecMob" : ""
                    } shiftItemDurMins ${clock.declined ? "greyscale" : ""}`}
                  >
                    {dateStringer.formatMinsDurationToHours(
                      calcClockedMins(
                        clock.startDs,
                        clock.endDs,
                        clock.totBrkMins
                      ) / 60
                    )}
                  </div>
                  <p
                    className={`ccApproveStatus ${
                      clock.approved ? "ccApp" : clock.declined ? "ccDec" : ""
                    }`}
                  >
                    {clock.approved
                      ? "Approved"
                      : clock.declined
                      ? "Declined"
                      : "Awaiting approval"}
                  </p>
                </div>
              </div>
            );
          return (
            <div
              className={`clockUnit ${mob ? "borderRadius10" : ""} ${
                clock.approved
                  ? "leftBorderGreen"
                  : `${
                      clock.declined
                        ? "leftBorderRed"
                        : `mgrReqItemPendingBorder pendClock ${
                            memoVals.mob ? "mgrReqItemPendingBorderMob" : ""
                          }`
                    }`
              }
                 ${
                   clock.oneOfTwo === "one"
                     ? `oneOfTwoOneClock_ ${
                         clock.approved || clock.declined ? "x937179343_" : ""
                       }`
                     : ""
                 } 
                ${clock.oneOfTwo === "two" ? "oneOfTwoTwoClock_" : ""}`}
              key={clock.clockOnID}
              onClick={() => {
                memoVals.setClockOnID((x) => {
                  return { clockOnID: clock.clockOnID, isMgr: false };
                });
              }}
            >
              <div className="clockSectionLeftWrapper">
                <img
                  src={clockcard}
                  alt="Clock card"
                  className="clockCardIcon"
                />
                <div
                  className={`reqColBarDiv reqColBarAdjust4 ${
                    clock.approved ? "" : "divPend"
                  } ${clock.declined ? "divDec" : ""}`}
                ></div>
                <div
                  className={`tilShiftSection1Clock ${
                    clock.declined
                      ? "declinedClockBar"
                      : clock.approved
                      ? ""
                      : "pendingClockBar"
                  }`}
                >
                  <p className={`tilShiftDs `}>
                    <p className="tilShiftDateAndNth">
                      {dateStringer.tsToDayAndDate(
                        dateStringer.createTimestampFromString(clock.startDs),
                        memoVals.mob || memoVals.fullButNarrow ? true : true
                      )}
                      <span
                        className="ndStRdSpan"
                        onMouseEnter={() => {
                          console.log(
                            clock.startDs.substr(9, 2),
                            dateStringer.nth(
                              parseInt(clock.startDs.substr(9, 2))
                            )
                          );
                        }}
                      >
                        {dateStringer.nth(parseInt(clock.startDs.substr(9, 2)))}
                      </span>
                    </p>
                    <span className="shiftTilNameTxt">
                      {clock.type === "shift"
                        ? "Shift"
                        : `${
                            clock.type === "overtime"
                              ? memoVals.mob || memoVals.fullButNarrow
                                ? "Over"
                                : "Overtime"
                              : "Lieu"
                          }`}
                    </span>
                  </p>
                  <div className="timesAndTagContainer">
                    <div
                      className={`clockOnOffTimesFlex ${
                        clock.approved ? "" : "x3832874827"
                      }`}
                    >
                      <div className="clockOnOffSection">
                        <p className="clockOnSpan">On: </p>
                        <p className="clockOnValTxt">{`${clock.startDs.substr(
                          12,
                          2
                        )}:${clock.startDs.substr(15, 2)}`}</p>
                      </div>
                      <div className="clockOnOffSection clockOnOffSection2">
                        <p className="clockOnSpan">Off: </p>
                        <p className="clockOnValTxt">{`${clock.endDs.substr(
                          12,
                          2
                        )}:${clock.endDs.substr(15, 2)}`}</p>
                      </div>
                      <div className="clockOnOffSection clockOnOffSection3">
                        <p className="clockOnSpan">Break: </p>
                        <p className="clockOnValTxt x274831">
                          {clock.totBrkMins === 60 ||
                          clock.totBrkMins === 120 ||
                          clock.totBrkMins === 180 ||
                          clock.totBrkMins === 240
                            ? dateStringer.formatMinsDurationToHours(
                                clock.totBrkMins
                              )
                            : `${clock.totBrkMins}m`}
                        </p>
                      </div>
                      {/* &nbsp;&nbsp;&nbsp;
                      <span className="clockOnSpan">Off: </span>
                      {clock.endDs.substr(12, 2)}:{clock.endDs.substr(15, 2)} */}
                    </div>
                  </div>
                </div>{" "}
              </div>

              <div
                className={`clockSection2 ${
                  !memoVals.mob ? "desktopClockSection2" : ""
                }`}
              >
                <p className="tilShiftTypeTxt">
                  {dateStringer.formatMinsDurationToHours(
                    calcClockedMins(
                      clock.startDs,
                      clock.endDs,
                      clock.totBrkMins
                    ) / 60
                  )}
                </p>
                <p
                  className={`absenceUnitDurationString teamNameColour marginTop0`}
                >
                  {dateStringer.shorten(
                    clock.teamName,
                    memoVals.mob || memoVals.fullButNarrow ? 22 : 22
                  )}{" "}
                  <img src={teamIcon} alt="Team" className="teamIconHours" />{" "}
                  {/* {clock.declined || clock.approved ? "" : "break"} */}
                </p>
              </div>
              <div
                className={`itemTongue clockTongue ${
                  !clock.approved && !clock.declined ? "x019481" : ""
                }`}
              >
                <p className="tongueTxt clockTongueTxt">Clock card</p>
              </div>
            </div>
          );
        }
      }
    });
  }, [clockOns, reverse, memoVals.mob, memoVals.fullButNarrow, approvedExist]);

  return (
    <div
      className={`userShiftsPage ${memoVals.mob ? "" : "pageLeft240pxDesktop"}`}
    >
      <ShiftsHeader my={my} setMy={setMy} />

      <div className={`desktopMaster ${memoVals.mob ? "desktopWhiteBg" : ""}`}>
        <div
          className={`desktopMasterMain  ${
            memoVals.mob ? "mob100Witdth" : ""
          } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
        >
          <div
            className={`shiftsPageFilterBar ${
              memoVals.mob ? "userHoursShoulderBar2" : ""
            } ${!memoVals.mob && "borderLeftGrey"} noBorderBottom ${
              memoVals.full ? "" : "noBorderRight"
            }`}
          >
            <div className="flexStartUserSh">
              <select
                className={`myReqsYearDropdown ${
                  memoVals.mob
                    ? "myReqsYearDropdownMob myClockCardsMobMnthDrop"
                    : ""
                } myCalMonthsDropSize ${
                  dateStringer.getMyFromTs(new Date().getTime()) === my
                    ? "colour00aaff"
                    : ""
                } `}
                value={my}
                onChange={(e) => {
                  setDataLoaded(false);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>{" "}
              {/* <img
                    src={arrGrey}
                    alt="Right"
                    className={`rightMonWeek ${
                      memoVals.mob ? "rightMonWeekMob" : ""
                    } ${
                      loading
                        ? "fadeSelMonWeekBtn"
                        : my ===
                          memoVals.availableMonths[
                            memoVals.availableMonths.length - 1
                          ]
                        ? "none"
                        : ""
                    } ${showChanges ? "invis" : ""}`}
                    onClick={() => {
                      // code here
                      let ind = memoVals.availableMonths.indexOf(my);
                      if (memoVals.availableMonths[ind + 1]) {
                        setMy(memoVals.availableMonths[ind + 1]);
                        memoVals.setUpdateShifts((x) => !memoVals.updateShifts);
                      }
                    }}
                  /> */}
            </div>
            {/* <div className="shiftsPageFilterBarLeft">
              <select
                className="shiftsTilMonthDropdown"
                value={my}
                onChange={(e) => {
                  setDataLoaded(false);
                  setMy(e.target.value);
                }}
              >
                {generateMonthOptions}
              </select>
            </div>{" "} */}
            <div className="shiftsPageFilterBarRight">
              {/* <div
                className={`${
                  showFilter ? "shiftTilFilterBtnActive" : "shiftTilFilterBtn"
                }`}
                onClick={() => {
                  setShowFilter(!showFilter);
                  console.log(clockOns);
                }}
              >
                Filter{" "}
                <img
                  className={`${
                    showFilter ? "closeFilterImgTilShifts" : "none"
                  }`}
                  alt="close"
                  src={whitecross}
                />
              </div> */}
            </div>
          </div>

          {/* <div className="shiftTilFilterHolder">
            <div
              className={`${
                showFilter ? "shfitTilFilterBtnsContainer" : "none"
              }`}
            >
              <div
                className={`shiftsOrTilFilter ${
                  showShifts ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowShifts(!showShifts);
                }}
              >
                Shifts{" "}
                <img
                  src={showShifts ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showOvertimes ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowOvertimes(!showOvertimes);
                }}
              >
                Overtime{" "}
                <img
                  src={showOvertimes ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
              <div
                className={`shiftsOrTilFilter ${
                  showTils ? "" : "unselectedShiftOrTil"
                }`}
                onClick={() => {
                  setShowTils(!showTils);
                }}
              >
                Time in Lieu{" "}
                <img
                  src={showTils ? checkbox2tick : checkbox1}
                  alt="Select"
                  className="shiftTilSelectImg"
                />
              </div>
            </div>
          </div> */}

          {!memoVals.mob && (
            <div
              className={`filterSplitterContainer ccFilterSplitter ${
                !memoVals.full ? "noBorderRight" : ""
              }`}
            >
              <div className="filterSplitter"></div>
            </div>
          )}
          <div
            className={`availabilityBodyContainer ${
              !memoVals.full ? "noBorderRight" : ""
            } ${memoVals.mob ? "whiteBg" : ""}`}
            onClick={() => {}}
          >
            {/* render here */}
            <img
              src={horizontalBalls}
              className={`${loading ? "loadingHoriztonalImg" : "none"}`}
              alt="Loading"
            />
            <div
              ref={clockScrollBox}
              className={`${!loading ? "clockOnsContainer" : "none"} ${
                memoVals.mob
                  ? `mobClockOnsContainer ${
                      memoVals.device.ios ? "mobClockOnsContainerIos" : ""
                    }`
                  : "borderLeftGrey"
              } ${
                showFilter
                  ? `${
                      memoVals.mob
                        ? "showFilterClockHeight"
                        : "showFilterClockHeightDesktop"
                    }`
                  : ""
              } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
                !memoVals.full ? "noBorderRight" : ""
              }`}
            >
              {/* {!loading && pendingsExist ? (
                <p className="clocksAwaitingTitles notYetSubmitTitleArr">
                  Not yet approved
                </p>
              ) : (
                ""
              )} */}
              {clockOns[0] ? (
                generateClockOns || loading
              ) : (
                <div
                  className={`noShiftsTxtContainer noUpcomingReqs ${
                    memoVals.mob ? "x1984971" : ""
                  }`}
                >
                  <div className="noShiftsSideBar"></div>

                  <p
                    className={`noShiftsTxt x19873 ${
                      memoVals.mob ? "noShiftsTxtMobClocks" : ""
                    }`}
                  >
                    No clock cards in{" "}
                    {dateStringer.printMonthAndYearFromMy(my, true, true)}
                  </p>

                  <div className="noShiftsSideBar"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            memoVals.mob || !memoVals.full ? "none" : "desktopMasterSecondary"
          }`}
        >
          <HoursDatabox sideBoxData={sideBoxData} my={my} setMy={setMy} />{" "}
        </div>
      </div>

      {imgPreload}
    </div>
  );
};

export default ClockOns;
