import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import navyClose from "../img/general/navyClose.svg";
import navyTick from "../img/general/navyTick.svg";
import blueLoader from "../img/general/blueLoader.gif";

import "../styles/calendar.css";

const CreateFormModal = () => {
  const {
    setShowCreateFormModal,
    setIndicate,
    setPushForm,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowCreateFormModal,
      setIndicate,
      setPushForm,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setShowCreateFormModal, //
      setIndicate, //
      setPushForm, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="1"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={navyTick}
        alt="1"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={blueLoader}
        alt="1"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
    </div>
  );
  let [fName, setFName] = useState("");

  let [saveLoading, setSaveLoading] = useState(false);

  let [titleInput, setTitleInput] = useState("");
  let [formInfoInput, setFormInfoInput] = useState("");
  let [addQuestionInput, setAddQuestionInput] = useState("");

  let [toggleAddQuestion, setToggleAddQuestion] = useState(false);

  let [success, setSuccess] = useState(false);

  let shortenAbsName = (val) => {
    if (val) {
      if (val.length > 13) {
        return `${val.substr(0, 11)}...`;
      } else {
        return val;
      }
    }
  };

  let [items, setItems] = useState([]);
  let [addedAbsentItems, setAddedAbsentItems] = useState([]);
  let [addedAbsentIndexes, setAddedAbsentIndexes] = useState([]);
  let [users, setUsers] = useState([]);
  let [selectedUser, setSelectedUser] = useState("null");
  let [questions, setQuestions] = useState([]);
  let [firstDateInput, setFirstDateInput] = useState(
    `${dateStringer.createDateInputFromDateString(
      `${dateStringer
        .createStringFromTimestamp(new Date().getTime())
        .substr(0, 11)}H00M00`
    )}`
  );
  let [lastDateInput, setLastDateInput] = useState(
    `${dateStringer.createDateInputFromDateString(
      `${dateStringer
        .createStringFromTimestamp(new Date().getTime())
        .substr(0, 11)}H00M00`
    )}`
  );

  let [toggleAddAbsenceItems, setToggleAddAbsenceItems] = useState(false);
  let [firstDs, setFirstDs] = useState("");
  let [lastDs, setLastDs] = useState("");
  let [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    // clean not needed
    if (addedAbsentIndexes[0] || addedAbsentIndexes[0] === 0) {
      let arr = [];
      items.forEach((item, i) => {
        if (addedAbsentIndexes.includes(i)) {
          arr.push(item);
        }
      });

      setSelectedItems(arr);

      arr.sort((a, b) => {
        return a.startTs - b.startTs;
      });

      setFirstDs(arr[0].startDs);
      setLastDs(arr[arr.length - 1].endDs);

      let isTheSame =
        arr[0].startDs.substr(0, 11) ===
        arr[arr.length - 1].endDs.substr(0, 11);
      let ds1 = dateStringer.printedDateWithYrFromDWithoutDay(arr[0].startDs);
      let ds2 = dateStringer.printedDateWithYrFromDWithoutDay(
        arr[arr.length - 1].endDs
      );

      if (isTheSame) {
        setTitleInput(ds1);
      } else {
        setTitleInput(`${ds1} - ${ds2}`);
      }
    } else {
      setTitleInput("");
      setSelectedItems([]);
      setFirstDs("");
      setLastDs("");
    }
  }, [addedAbsentIndexes]);

  let generateUsers = users
    .sort((a, b) => {
      return a.fName.localeCompare(b.fName);
    })
    .map((user) => {
      return (
        <option value={user.userID}>
          {user.fName} {user.lName}
        </option>
      );
    });

  useEffect(() => {
    // clean not needed
    if (selectedUser === "null") {
      setFName("");
    } else {
      let n = "";
      users.forEach((item) => {
        if (item.userID === selectedUser) {
          n = item.fName;
        }
      });

      setFName(n);
    }
  }, [selectedUser]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-users-user-can-manage`,
        {
          getAbsenceQuestions: true,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setUsers(response.data.usersArr);
          setQuestions(response.data.rtwQuestions);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowCreateFormModal((x) => false);
  };

  // master return
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        closeModal();
      }}
    >
      {success ? (
        <div
          className="formCreatedModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="overlapsRenTxt">
            Form successfully created.
            <br />
            <br />
            An email has been sent to {fName} requesting them to complete it.
          </p>
          <p
            className="formCreatedModalClose"
            onClick={() => {
              memoVals.setShowCreateFormModal((x) => false);
            }}
          >
            Close
          </p>
        </div>
      ) : (
        <div
          className={`addLeaveModalBox ${
            mobModal
              ? `addLeaveModalBoxMob mobModalShoulder slideUp ${
                  memoVals.device.ios ? "paddingBottom20" : ""
                }`
              : "zoomIn"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {mobModal && (
            <div className="modalSwiper modalSwiperCreateForm"></div>
          )}
          <div
            className="addLeaveModalBoxHeader"
            onTouchStart={handleDown}
            onTouchMove={mobModal ? handleMove : null}
            onTouchEnd={mobModal ? handleUp : null}
            onMouseDown={mobModal ? handleDown : null}
            onMouseMove={mobModal ? handleMove : null}
            onMouseUp={mobModal ? handleUp : null}
          >
            <img
              src={navyClose}
              alt="Close"
              className="addLeaveModalHeaderboxCloseImg"
              onClick={() => {
                closeModal();
              }}
            />
            <p
              className="addLeaveModalHeaderBoxTitle"
              onClick={() => {
                console.log({ selectedItems, firstDs, lastDs });
              }}
            >
              Create a new absence form
            </p>
            <img
              src={navyClose}
              alt="Close"
              className="addLeaveModalHeaderboxCloseImg invis"
            />{" "}
          </div>
          <div
            className={`editShiftModalBody ${
              !mobModal ? "desktopAddLeaveModalBody formRespBody" : ""
            }`}
            onClick={() => {}}
          >
            <div
              className={`addLeaveDatesRow ${
                selectedUser === "null" || !selectedUser ? "noBorder" : ""
              }`}
            >
              <p className="addLeaveDatesTitle">Employee</p>
              <select
                className="addLeaveModalLeaveSelectorDropdown"
                value={selectedUser}
                onChange={(e) => {
                  setItems([]);
                  setAddedAbsentIndexes([]);
                  setTitleInput("");
                  setFormInfoInput("");
                  setFirstDs("");
                  setLastDs("");
                  setSelectedUser(e.target.value);
                  setToggleAddAbsenceItems(false);
                }}
              >
                <option value="null">-- Select employee</option>
                {generateUsers}
              </select>
            </div>
            {selectedUser !== "null" && selectedUser ? (
              <div className="addLeaveDatesRow">
                <p className="addLeaveDatesTitle">
                  Add absent shifts or periods to form
                </p>
                <div className="addedAbsItems">
                  {addedAbsentItems.map((item) => {
                    return <div className="addedAbsItem">{item.name}</div>;
                  })}
                </div>
                {toggleAddAbsenceItems ? (
                  <div className="addAbsenceItemsContainer">
                    {items && items[0] ? (
                      items.map((item, i) => {
                        return (
                          <div
                            className={`addAbsItemUnit ${
                              !mobModal ? "addAbsItemUnitDesktop" : ""
                            } ${
                              item.type === "shift"
                                ? "extendBlueLeftBorder"
                                : item.type === "period"
                                ? "extendPurpleLeftBorder"
                                : "extendGreyLeftBorder"
                            } ${
                              addedAbsentIndexes.includes(i)
                                ? "selectedAddAbsItem"
                                : ""
                            }`}
                            onClick={() => {
                              if (!addedAbsentIndexes.includes(i)) {
                                let newArr = addedAbsentIndexes;
                                setAddedAbsentIndexes([...newArr, i]);
                              } else {
                                let newArr2 = [];
                                addedAbsentIndexes.forEach((ii) => {
                                  if (ii !== i) {
                                    newArr2.push(ii);
                                  }
                                });

                                setAddedAbsentIndexes(newArr2);
                              }
                            }}
                          >
                            <div className="addAbsItemCheckBox">
                              <img
                                src={navyTick}
                                alt=""
                                className={
                                  addedAbsentIndexes.includes(i)
                                    ? "addAbsItemCheckBoxImg"
                                    : "addAbsItemCheckBoxImg invis"
                                }
                              />
                            </div>
                            <div className="addAbsItemLeft">
                              <p
                                className={`addAbsDate ${
                                  addedAbsentIndexes.includes(i)
                                    ? "colourWhite_"
                                    : ""
                                }`}
                              >
                                {dateStringer.printedDateWithYrFromDWithoutDay(
                                  item.startDs
                                )}{" "}
                                {item.endDs.substr(0, 11) !==
                                item.startDs.substr(0, 11)
                                  ? `- ${dateStringer.printedDateWithYrFromDWithoutDay(
                                      item.endDs
                                    )}`
                                  : ""}
                              </p>
                              <p className="addAbsPeriod">
                                {item.type === "period"
                                  ? `${item.days} day${
                                      item.days === 1 ? "" : "s"
                                    }`
                                  : `${dateStringer.dsToTimeStrip(
                                      item.startDs
                                    )} - ${dateStringer.dsToTimeStrip(
                                      item.endDs
                                    )}`}
                              </p>
                            </div>
                            <div className="addAbsItemRight">
                              <p
                                className={`addAbsTitle  ${
                                  addedAbsentIndexes.includes(i)
                                    ? "colourWhite_"
                                    : ""
                                }`}
                              >
                                {item.type === "shift"
                                  ? "Shift"
                                  : item.type === "til"
                                  ? "Time in Lieu"
                                  : item.type === "overtime"
                                  ? "Overtime"
                                  : "Period"}
                              </p>
                              <p className="addAbsName">
                                {item.type === "period"
                                  ? shortenAbsName(item.absenceName)
                                  : dateStringer.formatMinsDurationToHours(
                                      item.mins
                                    )}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="noAbsItemsToAdd">No absences to add</p>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {toggleAddAbsenceItems ? (
                  ""
                ) : (
                  <div
                    className="addAbsenceItemsBtn"
                    onClick={() => {
                      if (!items[0]) {
                        // axios here
                        axios
                          .post(
                            `${serverURL}/get-available-items-to-add-to-form`,
                            {
                              userID: selectedUser,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setItems(response.data.arr || []);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                      setToggleAddAbsenceItems(true);
                    }}
                  >
                    Add items
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {selectedUser !== "null" && selectedUser && selectedItems[0] ? (
              <div
                className={`addLeaveDatesRow ${
                  selectedUser === "null" || !selectedUser ? "noBorder" : ""
                } noPaddingBottom`}
              >
                <p className="addLeaveDatesTitle">Period</p>
                <p className="formPeriod">
                  {dateStringer.printedDateWithYrFromDs(firstDs)}
                  {"  "}-{"  "}
                  {dateStringer.printedDateWithYrFromDs(lastDs)}
                </p>
              </div>
            ) : (
              ""
            )}

            {selectedUser !== "null" && selectedUser ? (
              <div className={`addLeaveDatesRow`}>
                <p className="addLeaveDatesTitle">Form title</p>
                <input
                  value={titleInput}
                  type="text"
                  className="addFormTitleInput"
                  onChange={(e) => {
                    setTitleInput(e.target.value);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {selectedUser !== "null" && selectedUser && titleInput ? (
              <div className={`addLeaveDatesRow`}>
                <p className="addLeaveDatesTitle">Form note</p>
                <textarea
                  value={formInfoInput}
                  // type="text"
                  className="addFormTitleInput formInfoInput"
                  onChange={(e) => {
                    setFormInfoInput(e.target.value);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {selectedUser !== "null" && selectedUser && titleInput ? (
              <div className={`addLeaveDatesRow noBorder`}>
                <p className="addLeaveDatesTitle">Form questions</p>
                {questions && questions[0] ? (
                  <div className="questionsContainer">
                    {questions.map((q) => {
                      return (
                        <div className="rtwQitem">
                          {q.question}
                          <img
                            src={navyClose}
                            alt="Remove question"
                            className="removeRtwQimg"
                            onClick={() => {
                              let newArr = [];
                              questions.forEach((qu) => {
                                if (qu.questionID !== q.questionID) {
                                  newArr.push(qu);
                                }
                              });

                              setQuestions(newArr);
                            }}
                          />
                        </div>
                      );
                    })}
                    {toggleAddQuestion ? (
                      <textarea
                        value={addQuestionInput}
                        className="addQuestionInput"
                        onChange={(e) => {
                          setAddQuestionInput(e.target.value);
                        }}
                      ></textarea>
                    ) : (
                      ""
                    )}
                    {toggleAddQuestion ? (
                      <div className="addQuestionContainer">
                        <p
                          className="addQuestCanx"
                          onClick={() => {
                            setToggleAddQuestion(false);
                            setAddQuestionInput("");
                          }}
                        >
                          Cancel
                        </p>
                        <p
                          className={`addQuestAdd ${
                            !addQuestionInput ? "disable" : ""
                          }`}
                          onClick={() => {
                            setQuestions([
                              ...questions,
                              {
                                questionID: Math.random() * Math.random(),
                                question: addQuestionInput
                                  .replace("{", "")
                                  .replace("}", ""),
                              },
                            ]);
                            setToggleAddQuestion(false);
                            setAddQuestionInput("");
                          }}
                        >
                          Add
                        </p>
                      </div>
                    ) : (
                      <p
                        className={`addRTWqToFormBtn ${
                          questions.length > 29 ? "none" : ""
                        }`}
                        onClick={() => {
                          if (questions.length < 30) {
                            setToggleAddQuestion(true);
                          }
                        }}
                      >
                        Add question
                      </p>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="addLeaveToUserModalFooter">
            <p
              className="cancelAddLeaveModalBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </p>
            <p
              className={`createFormSubmitBtn ${
                !titleInput ||
                !selectedUser ||
                selectedUser === "null" ||
                !questions ||
                saveLoading
                  ? "invis"
                  : ""
              }`}
              onClick={() => {
                if (selectedUser && titleInput) {
                  console.log({
                    userID: selectedUser,
                    items: selectedItems,
                    nowDs: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),
                    title: titleInput,
                    info: formInfoInput,
                    questions,
                  });
                  setSaveLoading(true);
                  axios
                    .post(
                      `${serverURL}/create-new-form`,
                      {
                        userID: selectedUser,
                        items: selectedItems,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        title: titleInput,
                        info: formInfoInput,
                        questions,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setTimeout(() => {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Form created",
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }, 400);

                        setSuccess(true);
                        setSaveLoading(false);
                        memoVals.setPushForm((x) => {
                          return {
                            isMgr: true,
                            fName: response.data.fName,
                            lName: response.data.lName,
                            formID: response.data.formID,
                            questionsLength: response.data.questionsLength,
                            responsesLength: response.data.responsesLength,
                            title: response.data.title,
                            itemsLength: response.data.itemsLength,
                            completedDs: "",
                            createdDs: response.data.createdDs,
                            filledIn: false,
                            signedByMgr: false,
                            mins: response.data.mins,
                            deleting: false,
                            updating: false,
                            creating: true,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }
              }}
            >
              Create
            </p>

            {saveLoading ? (
              <img
                src={blueLoader}
                alt="Loading"
                className="addLeaveLoadingImg"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default CreateFormModal;
