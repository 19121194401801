import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import appURL from "../appURL";

import four from "../img/general/404.svg";

const FourOhFour = () => {
  // MASTER RETURN
  window.location.href = `${appURL}/calendar`;
  return (
    <div className="fourContainer invis">
      <img src={four} alt="404" className="fourImg" />
      <p className="fourTxt">Oops!</p>
      <p className="fourTxtSub">This page is unavailable. </p>
      <NavLink to="/" className="fourCalBtn">
        Go to your calendar
      </NavLink>
    </div>
  );
};

export default FourOhFour;
