import React, { useState, useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";
import { CalendarContext } from "../../contexts/CalendarContext";

import { NavLink } from "react-router-dom";
import "../../styles/calendar.css";
import CheckUser from "../../tools/CheckUser";
import dateStringer from "../../tools/dateStringer";

import serverURL from "../../serverURL";

import CalendarSubBar from "./CalendarSubBar";
import UserSchedule from "./UserSchedule";
import TeamSchedule from "./TeamSchedule";
import StaffCalendar from "./StaffCalendar";

import Navbar from "../Navbar";

const Schedule = ({ isTeam, isStaffCal }) => {
  const { setShowAddAvailModal, standalone, setSelectedDesktop } =
    useContext(DataContext);
  const { calPage, setCalPage } = useContext(CalendarContext);
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      setShowAddAvailModal, //
      standalone, //
      calPage, //
      setCalPage, //
      mob, //
      setSelectedDesktop,
    }),
    [
      setShowAddAvailModal, //
      standalone, //
      calPage, //
      setCalPage, //
      mob, //
      setSelectedDesktop,
    ]
  );

  CheckUser(false, false, "Schedule.js");

  useEffect(() => {
    if (window.location.href.includes("submit")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (
        params &&
        dateStringer.cleanParam(params.teamID) &&
        dateStringer.cleanParam(params.monWeek)
      ) {
        setShowAddAvailModal((x) => {
          return {
            show: true,
            monWeek: dateStringer.cleanParam(params.monWeek),
            loadTeamID: dateStringer.cleanParam(params.teamID),
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    // clean not needed
    if (isTeam) {
      memoVals.setCalPage((x) => "team-rota");
      memoVals.setSelectedDesktop("team-rota");
    } else if (isStaffCal) {
      memoVals.setCalPage((x) => "staff-calendar");
    } else {
      memoVals.setCalPage((x) => "schedule");
      memoVals.setSelectedDesktop("rota");
    }
  }, [isTeam]);

  let renderSchedulePage = () => {
    if (isStaffCal) {
      return <StaffCalendar />;
    } else {
      if (memoVals.calPage === "schedule") {
        return <UserSchedule />;
      }
      if (memoVals.calPage === "team-rota") {
        return <TeamSchedule />;
      }
    }
  };

  return (
    <div
      className={`calendarComponent  ${memoVals.mob ? "mobCalComponent" : ""} ${
        memoVals.standalone && !memoVals.mob ? "deskopStandaloneRota" : ""
      }`}
    >
      {!memoVals.standalone && <Navbar />}
      {!memoVals.standalone && memoVals.mob && <CalendarSubBar />}
      {renderSchedulePage()}
    </div>
  );
};

export default Schedule;
