// This is a functional component (does not render anything)
// that checks user logged in based on jwt (verfies it too)
// and will update the userContext state

// Use this function on every component that needs general data to be fetches/updated

import { useContext, useEffect, useMemo } from "react";
// cleaned wef 24 jul 23
// axios caught 21 sep 23

import { UserContext } from "../contexts/UserContext";
import { DataContext } from "../contexts/DataContext";

import axios from "axios";
//
import serverURL from "../serverURL";
import dateStringer from "../tools/dateStringer";
import * as serviceWorker from "./../serviceWorker"; // Import service worker module

const CheckUser = async (terminal, signin, calledFrom) => {
  const { setCheckUserState, checkUserState } = useContext(UserContext);
  const { setShowTrialExpiredModal } = useContext(DataContext);

  // Memoizing this does not work - no need to do it.

  // checkUser currently only runs on NavBar.js

  const cancelSourceCheckUser = axios.CancelToken.source();

  const loadHandler = (
    userID,
    fName,
    lName,
    permissions,
    email,
    clientID,
    companyName
  ) => {
    console.log("LOADING ", userID, " TO PROGREESIER");
    progressier.add({
      userID: userID,
      name: `${fName} ${lName}`,
    });

    window.fcSettings = {
      onInit: function () {
        window.fcWidget.setExternalId(userID);
        window.fcWidget.user.setFirstName(fName);
        window.fcWidget.user.setLastName(lName);
        window.fcWidget.user.setEmail(email);

        window.fcWidget.user.setProperties({
          permissions: permissions, // meta property 1
          companyName: companyName, // meta property 2
          clientID: clientID,
        });
      },
    };

    // Copy the below lines under window.fcWidget.init inside fw_chat_widget function in the above snippet

    // // To set unique user id in your system when it is available
    // window.fcWidget.setExternalId(userID);
    // // To set user name
    // window.fcWidget.user.setFirstName(fName);
    // window.fcWidget.user.setLastName(lName);
    // window.fcWidget.user.setPermissions(permissions);
    // window.fcWidget.user.setEmail(email);

    // // To set user email

    // // To set user properties
    // // Note that any other fields passed as properties which are not in the CRM Contact properties already, they will be ignored.
    // window.fcWidget.user.setProperties({
    //   cf_plan: "Pro", // meta property 1
    //   cf_status: "Active", // meta property 2
    // });
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    console.log("Checked user from: ", calledFrom);
    const fetchData = async () => {
      const now = new Date();
      const localDs = dateStringer.createStringFromTimestamp(now.getTime());

      try {
        const response = await axios.post(
          `${serverURL}/checkuser`,
          { localDs: localDs },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSourceCheckUser.token,
          }
        );

        if (response.status === 200) {
          if (response.data.message === "archived") {
            window.location.href = "/signin";
          }

          if (response.data.validUser === false && !signin) {
            if (terminal) {
              axios
                .post(
                  `${serverURL}/logout`,
                  { someData: 1 },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    window.location.href = "/signin";
                  } else {
                    window.location.href = "/signin";
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              setTimeout(() => {
                window.location.href = "/signin";
              }, 1000);
            }
          }

          if (response.data.validUser === true && signin) {
            window.location.href = "/account";
          }

          if (response.data.message === "success") {
            console.log("!!-----------");
            if (response.data.updateSW) {
              console.log("NEED TO UPDATE SW");
              serviceWorker.unregister();
              axios
                .post(
                  `${serverURL}/sw-updated`,
                  {
                    someData: 1,
                  },
                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((res) => {
                  if (res.data.message === "success") {
                    window.location.reload();
                  }
                });
            }
            let fName = response.data.fName;
            let lName = response.data.lName;
            let userID = response.data.userID;
            setCheckUserState({
              validUser: response.data.validUser,
              fName: response.data.fName,
              lName: response.data.lName,
              permissions: response.data.permissions,
              tilEnabled: response.data.tilEnabled,
              clockOnAllowed: response.data.clockOnAllowed,
              userID: response.data.userID,
              tilEnabled: response.data.tilEnabled,
              currClientYear: response.data.currClientYear,
              showCosts: response.data.usersCanSeeCost,
              todayCheckUserRequests: response.data.todayCheckUserRequests, // this is to be removed
              version: response.data.v || "Unknown version",
              initTodo: response.data.initTodo, // to show the todo modal when initial user has created the account
              checklistDone: response.data.checklistDone,
              expired: response.data.expired,
              hideChat: response.data.hideChat,
              active: response.data.active,
            });

            // if (response.data.hideChat) {
            //   var elements =
            //     document.getElementsByClassName("fc-widget-normal");

            //   // Check if any modal is open

            //   for (var i = 0; i < elements.length; i++) {
            //     elements[i].style.visibility = "hidden";
            //   }
            //   //  hide the widget
            //   for (var i = 0; i < elements.length; i++) {
            //     elements[i].style.visibility = "hidden";
            //   }
            // }

            if (
              response.data.expired &&
              !window.location.href.includes("bill")
            ) {
              setShowTrialExpiredModal(true);
            }

            window.addEventListener(
              "load",
              loadHandler(
                userID,
                fName,
                lName,
                response.data.permissions,
                response.data.email,
                response.data.clientID,

                response.data.companyName
              )
            );

            console.log("checkUser fct run");
            console.log("validuser", response.data.validUser);
            if (response.data.validUser === "false" && !signin) {
              window.location.href = "/signin";
            }

            // Return the event listener cleanup function
            return () => {
              window.removeEventListener(
                "load",
                loadHandler(userID, fName, lName)
              );
              cancelSourceCheckUser.cancel("Component unmounted");
            };
          } else {
            console.log("Failed to get data");
          }
        } else {
          console.log("invalid user");
        }
      } catch (error) {
        console.error(error);
      } finally {
        cancelSourceCheckUser.cancel("Component unmounted");
      }
    };

    if (!params || !params.code) {
      fetchData();
    }

    return () => {
      // Cleanup function
      cancelSourceCheckUser.cancel("Component unmounted");
      window.removeEventListener("load", loadHandler);
    };
  }, []);
};

export default CheckUser;
