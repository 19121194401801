import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";

import dateStringer from "../../tools/dateStringer";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";

import whitecross from "../../img/general/whitecross.svg";
import close from "../../img/modals/close.svg";

import LeaveReqModal from "./LeaveReqModal";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

const ViewTeamOnRequest = ({ reqID, isMgr }) => {
  const { showViewTeamOnRequest, setShowViewTeamOnRequest } =
    useContext(RequestContext);
  const { mobModal } = useContext(StyleContext);
  const {
    setOpenManagerShiftModal,
    setShowReqModalInViewer,
    showReqModalInViewer,
    setShowEditUser,
    showMyShiftModal,
    setShowMyShiftModal,
    modalOpen,
    setModalOpen,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showViewTeamOnRequest, //
      setShowViewTeamOnRequest, //
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showViewTeamOnRequest, //
      setShowViewTeamOnRequest, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(reqID ? true : false);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 2) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={whitecross}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={close}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [showTip, setShowTip] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  let [mob, setMob] = useState(false);
  let mobileWidthBreak = 820;

  let renderLeaveReqModal = () => {
    if (memoVals.showReqModalInViewer) {
      return (
        <LeaveReqModal
          unitReqID={memoVals.showReqModalInViewer}
          unitIsUserReq={false}
          loadedFromTeamViewer={true}
        />
      );
    }
  };

  let shortenName = (name) => {
    if (name.length > 10) {
      return `${name.substr(0, 9)}...`;
    } else {
      return name;
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);

    if (window.innerWidth < mobileWidthBreak) {
      setMob(true);
    } else {
      setMob(false);
    }
  }
  useEffect(() => {
    // clean not needed
    if (window.innerWidth < mobileWidthBreak) {
      setMob(true);
    } else {
      setMob(false);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let [userData, setUserData] = useState([]);
  let [dayData, setDayData] = useState([]);
  let [teamName, setTeamName] = useState("");
  let [firstName, setFirstName] = useState("");

  let [limitsEnabled, setLimitsEnabled] = useState(false);
  let [limitsExceeded, setLimitsExceeded] = useState(false);
  let [loadingLong, setLoadingLong] = useState(false);
  let [isPending, setIsPending] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (reqID) {
      let initTime = new Date();

      let intervaller = setInterval(() => {
        let newTime = new Date();

        if (newTime.getTime() - initTime.getTime() > 2999) {
          setLoadingLong(true);
          console.log("loading long is true!");
          clearInterval(intervaller);
        }
      }, 1000);

      axios
        .post(
          `${serverURL}/view-team-calendar-on-leave-request`,
          { reqID: reqID },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // resp here
            setIsPending(response.data.isPending);
            setShowTip(response.data.showTip);
            setUserData(response.data.users);
            setDayData(response.data.dayData);
            setTeamName(response.data.teamName);
            setFirstName(response.data.fName);
            response.data.dayData.forEach((day) => {
              if (day.maxOff !== 5555) {
                setLimitsEnabled(true);
              }
            });

            if (response.data.dsArrOfExceeded.length > 0) {
              setLimitsExceeded(true);
            }
            setLoadingLong(false);
            setDataLoaded(true);
            // setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let formatName = (val) => {
    if (val.length > 8) {
      return `${val.substr(0, 6)}...`;
    } else {
      return val;
    }
  };

  let [fullName, setFullName] = useState("");
  let [fName2, setFName2] = useState("");
  let [jobTitle, setJobTitle] = useState("");
  let [showNameModal, setShowNameModal] = useState(false);
  let [vtowUserID, setVtowUserID] = useState("");

  let [permissionsToViewProfile, setPermissionsToViewProfile] = useState(false);

  if (userData) {
    userData.sort((a, b) => {
      return a.index - b.index;
    });
  }

  let generateUserNames = useMemo(() => {
    return userData
      ? userData.map((user, i) => {
          let howManyItems = (userID) => {
            let highestQty = 0;
            dayData.forEach((obj) => {
              obj.users.forEach((userObj) => {
                if (
                  userObj.userID === userID &&
                  userObj.shifts.length > highestQty
                ) {
                  highestQty = userObj.shifts.length;
                }
              });
            });
            return 0;
            // return highestQty;
          };

          return (
            <div
              key={user.index}
              onClick={() => {
                console.log(user.userID, howManyItems(user.userID));
                console.log(dayData);
              }}
            >
              <div
                className={`reqTeamCalName ${
                  user.index === 0 ? "selectedReqTeamCalInitials" : ""
                } ${
                  howManyItems(user.userID) === 2 && "reqTeamCalNameTwoItems"
                } ${
                  howManyItems(user.userID) === 3 && "reqTeamCalNameThreeItems"
                } ${
                  howManyItems(user.userID) === 4 && "reqTeamCalNameFourItems"
                }  ${
                  howManyItems(user.userID) === 5 && "reqTeamCalNameFiveItems"
                }`}
                onClick={() => {
                  setFullName(`${user.fName} ${user.lName}`);
                  setFName2(user.fName);
                  setJobTitle(user.jobTitle);
                  setPermissionsToViewProfile(true);
                  setVtowUserID(user.userID);

                  setShowNameModal(true);
                }}
              >
                {shortenName(user.fName)}
                <br />
                {shortenName(user.lName)}
              </div>
              <div
                className={`${
                  i !== userData.length - 1 ? "dsOnNameBarName" : "none"
                }`}
              ></div>
            </div>
          );
        })
      : "";
  }, [userData]);

  let showLimitSubBox = () => {
    let returner = false;
    if (dayData) {
      dayData.forEach((day) => {
        if (day.limitActive) {
          returner = true;
        }
      });
    }

    return returner;
  };

  let generateDays = useMemo(() => {
    return dayData
      ? dayData.map((day) => {
          day.users.sort((a, b) => {
            return a.index - b.index;
          });
          let subGenerateDayUsers = day.users.map((usr, i) => {
            //   let isEven = (value) => {
            //     if (value % 2 == 0) return true;
            //     else return false;
            //   };

            let generateItems = usr.shifts.map((sh) => {
              return (
                <p
                  key={Math.random()}
                  className={`reqTeamShifts ${isMgr ? "cursPointerMgr" : ""}`}
                  onClick={() => {
                    if (isMgr) {
                      console.log("--- ", {
                        itemID: sh.itemID,
                        shiftID: sh.type === "shift" ? sh.itemID : "",
                        tilID: sh.type !== "shift" ? sh.itemID : "",
                        itemType: sh.type,
                        fName: sh.fName,
                        lName: sh.lName,
                        userID: sh.userID,
                      });
                      memoVals.setShowViewTeamOnRequest((x) => false);
                      memoVals.setOpenManagerShiftModal((x) => {
                        return {
                          itemID: sh.itemID,
                          shiftID: sh.type === "shift" ? sh.itemID : "",
                          tilID: sh.type !== "shift" ? sh.itemID : "",
                          itemType: sh.type,
                          fName: sh.fName,
                          lName: sh.lName,
                          userID: sh.userID,
                        };
                      });
                    } else {
                      console.log("!!!!!", {
                        unitType: sh.type,
                        unitID: sh.itemID,
                        otherUser: sh.me ? false : true,
                      });
                      setShowMyShiftModal((x) => {
                        return {
                          unitType: sh.type,
                          unitID: sh.itemID,
                          otherUser: sh.me ? false : true,
                        };
                      });
                    }
                  }}
                >
                  {sh.start} - {sh.end}
                </p>
              );
            });
            return (
              <div key={usr.index}>
                <div
                  className={`reqTeamDayColUnit colBorder ${
                    usr.index === 0 ? "thisUserColUnit" : ""
                  }`}
                >
                  <p
                    className={`${
                      usr.off
                        ? `reqTeamOffBox ${
                            usr.halfOffEnd || usr.halfOffStart
                              ? "halfDayBgColour"
                              : ""
                          } ${usr.isAbsence ? "x248981" : ""}`
                        : "none"
                    }
                  ${isMgr && usr.index !== 0 ? "cursPointerMgr" : ""}
                 `}
                    onClick={() => {
                      if (isMgr && usr.index !== 0) {
                        console.log("opeining req: ", usr.rID);
                        setShowReqModalInViewer(usr.rID);
                      }
                    }}
                  >
                    {usr.isAbsence ? "Absent:" : "Leave:"}{" "}
                    <span
                      className={`${
                        usr.halfOffStart || usr.halfOffEnd
                          ? "leaveDurReqCalSpan"
                          : "none"
                      }`}
                    >
                      half day
                    </span>
                    <span
                      className={`${
                        usr.halfOffStart || usr.halfOffEnd
                          ? "none"
                          : "leaveDurReqCalSpan"
                      }`}
                    >
                      full day
                    </span>
                  </p>
                  {/* <p
              className={`${!usr.off ? "invisibleOffBoxPlaceholder" : "none"}`}
            >
              &amp;
            </p> */}
                  <p
                    className={`${
                      !usr.shifts[0] && !usr.off ? "notWorkingToday" : "none"
                    }`}
                  >
                    No shifts
                  </p>

                  <div className={`reqTeamShiftsContainer`}>
                    {generateItems}
                  </div>
                </div>
                <div
                  className={`${
                    i !== day.users.length - 1 ? "dsOnNameBar" : "none"
                  }`}
                >
                  <span className="barDsViewTeamCalOnReqSpan">
                    {dateStringer.printedDateFromDs(day.ds)}
                  </span>
                </div>
              </div>
            );
          });

          let newCurrOff = day.currOff;
          if (isPending) {
            // newCurrOff = day.currOff + 1;
          }
          return (
            <div className="colWithSplitter" key={day.ds}>
              <div className="reqTeamDayCol">
                <div className="reqTeamDayTitle">
                  {dateStringer.printedDateFromDs(day.ds)}
                </div>
                <div
                  className="reqTeamDayLimitContainer"
                  onClick={() => {
                    console.log(day.maxOff, newCurrOff);
                  }}
                >
                  <div
                    className={`${
                      day.maxOff <= newCurrOff &&
                      day.maxOff < 5555 &&
                      day.maxOff !== newCurrOff
                        ? "limitBox1 exceedsLimitBg"
                        : "none"
                    }`}
                  >
                    Exceeds limit (+{newCurrOff - day.maxOff})
                  </div>
                  <div
                    className={`${
                      day.maxOff > newCurrOff &&
                      day.maxOff < 5555 &&
                      day.maxOff !== newCurrOff
                        ? "limitBox1 withinLimitBg"
                        : "none"
                    }`}
                  >
                    Within limit
                  </div>
                  <div
                    className={`${
                      day.maxOff === 5555 && day.maxOff !== newCurrOff
                        ? "limitBox1"
                        : "none"
                    }`}
                  >
                    No limit
                  </div>

                  <div
                    className={`${
                      day.maxOff === newCurrOff
                        ? "limitBox1 withinLimitBg"
                        : "none"
                    }`}
                  >
                    {/* limit reached */}
                    Within limit
                  </div>
                  <div
                    className={`limitDisplayInfo ${
                      day.maxOff === 5555 && day.maxOff !== newCurrOff
                        ? "hideLimitDisplayInfo"
                        : ""
                    } ${showLimitSubBox() ? "" : "none"}`}
                  >
                    <div className="limitDisplayInfoRow">
                      <p className="limitDisplayInfoTitle">Limit by:</p>
                      <p className="limitDisplayInfoValue">
                        {day.jtOrTeam === "team" ? "Whole team" : day.name}
                      </p>
                    </div>
                    <div className="limitDisplayInfoRowInline">
                      <div className="limitDisplayInlineWrapper">
                        <p className="limitDisplayInfoTitle2">
                          On Leave
                          {/* {isPending
                        ? ` incl. ${firstName}${
                            firstName[firstName.length - 1] === "s" ? "'" : "'s"
                          }`
                        : ""} */}
                          :
                        </p>
                        <p className="limitDisplayInfoValueCurrMax">
                          {newCurrOff}
                        </p>
                      </div>
                    </div>
                    <div className="limitDisplayInfoRowInline">
                      {" "}
                      <div className="limitDisplayInlineWrapper">
                        <p className="limitDisplayInfoTitle2">Leave limit:</p>
                        <p className="limitDisplayInfoValueCurrMax">
                          {day.maxOff}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>

                {subGenerateDayUsers}

                <div className="reqTeamLowerDay colBorder">
                  {dateStringer.printedDateFromDs(day.ds)}
                </div>
                {/* <p className={`${day.maxOff < 5555 ? "limitTxtBelow" : "none"}`}>
              Limit: {day.currOff}/{day.maxOff}
            </p> */}
              </div>
              <div className="viewTeamCalSplitter"></div>
            </div>
          );
        })
      : "";
  }, [dayData]);

  let formatTeamName = (val) => {
    if (val) {
      if (val.length > 16) {
        let output = `${val.substr(0, 14)}`;
        if (output[output.length - 1] === " ") {
          return `${val.substr(0, 13)}...`;
        } else {
          return `${output}...`;
        }
      } else {
        return val;
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      //     memoVals.setShowViewTeamOnRequest(x =>false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowViewTeamOnRequest((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // MASTER RETURN
  return (
    <div
      className={`${
        memoVals.showViewTeamOnRequest &&
        reqID &&
        !memoVals.showMyShiftModal.unitID
          ? //  &&
            // !openManagerShiftModal.shiftID &&
            // !openManagerShiftModal.tilID
            "viewReqTeamUnderlay"
          : "none"
      } `}
      onClick={(e) => {
        e.stopPropagation();
        memoVals.setShowViewTeamOnRequest((x) => false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`viewReqTeamModalBox ${
            memoVals.mobModal && !loading
              ? `mobModalShoulder ${
                  memoVals.device.ios ? "viewReqTeamModalBoxIos" : ""
                }`
              : ""
          } ${loading ? "loadingViewTeamOnReqWidth" : ""} `}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {renderLeaveReqModal()}
          {/* - - - - -  */}
          <div className={`${showTip && !mob ? "tipBox" : "none"}`}>
            <img
              src={whitecross}
              alt="close"
              className="tipBoxClose"
              onClick={() => {
                setShowTip(false);
              }}
            />
            <div className="tipBoxBody">
              <p className="tipBoxTxt">
                <span className="tipBoxTipSpan">Tip: </span>
                Click anywhere on the schedule and use your arrow keys to move
                around.
              </p>
              <p
                className="dontShowTipBtn"
                onClick={() => {
                  setShowTip(false);
                  axios.post(
                    `${serverURL}/disable-tip`,
                    {
                      tip: "viewTeamOnCalReqArrows",
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  );
                }}
              >
                Don't show again
              </p>
            </div>
          </div>
          {/* - - - - - - */}
          {memoVals.mobModal && !loading && (
            <div
              className="modalSwiper modalSwiperTeamOnRequest"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`${loading ? "none" : "reqTeamCalHeader"}`}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={memoVals.mobModal ? close : close}
              className={`closeTeamCalModalImg ${
                memoVals.mobModal ? "closeTeamCalModalImgMob" : ""
              }`}
              onClick={() => {
                memoVals.setShowViewTeamOnRequest((x) => false);
              }}
            />
            <p
              className="teamCalModalHeaderTxt"
              onClick={() => {
                console.log("loadingLong", loadingLong);
                console.log("isPending: ", isPending);
              }}
            >
              {formatTeamName(teamName)} calendar
            </p>
            <div className="closeTeamCalModalImg"></div>
          </div>
          <p
            className={`${
              limitsEnabled && !limitsExceeded ? "limitStatus" : "none"
            }`}
          >
            {formatTeamName(teamName)} will not be under-staffed
          </p>
          <p
            className={`${
              limitsEnabled && limitsExceeded ? "limitStatusExceeded" : "none"
            }`}
          >
            {formatTeamName(teamName)} will be under-staffed
          </p>

          <img
            src={horizontalBalls}
            className={`${loading ? "loadingReqCalTeamPreview" : "none"}`}
            alt="Loading"
          />
          <p
            className={`${
              loadingLong && loading ? "reqTeamLoadingLong" : "none"
            }`}
          >
            Still working on it...
          </p>
          <div
            className={`${loading ? "none" : "reqTeamCalContainer"} ${
              memoVals.mobModal ? "reqTeamCalContainerMobModalHeight" : ""
            }`}
          >
            <div className="reqTeamCalHolder">
              <div className="reqTeamCalLeft">
                <div className="vv1void"></div>
                <div className="vv1void2"></div>
                <div
                  className={`${showLimitSubBox() ? "vv1void3" : "none"}`}
                ></div>
                {generateUserNames}
                <div className="reqTeamCalNameLastVoid"></div>
              </div>
              <div
                className={`${loading ? "none" : "viewTeamCalSplitterInitial"}`}
              ></div>

              <div className="reqTeamCalRight">{generateDays}</div>
              {/*  */}
            </div>
          </div>
        </div>
      )}

      <div
        className={`${showNameModal ? "viewNameModalUnderlay" : "none"}`}
        onClick={(e) => {
          e.stopPropagation();

          setShowNameModal(false);
        }}
      >
        <div
          className="viewNameModalBox"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="viewTeamCalNameTxtItem">{fullName}</p>
          <p className="viewTeamCalNameTxtItem italicJobTitle"> {jobTitle}</p>
          <p
            className={`${
              !permissionsToViewProfile ? "closeNameModalBtn" : "none"
            }`}
            onClick={() => {
              setShowNameModal(false);
              setFName2("");
              setPermissionsToViewProfile(false);
            }}
          >
            Close
          </p>
          <div
            className={`${
              permissionsToViewProfile ? "closeAndViewProfileBox" : "none"
            }`}
          >
            <p
              className="closeVTORuserBtn"
              onClick={() => {
                setShowNameModal(false);
                setFName2("");
                setPermissionsToViewProfile(false);
              }}
            >
              Close
            </p>
            <p
              className="viewUserVTORuserBtn"
              onClick={() => {
                memoVals.setShowViewTeamOnRequest((x) => false);
                memoVals.setShowEditUser((x) => vtowUserID);
              }}
            >
              {fName2}
            </p>
          </div>
        </div>
      </div>
      {imgPreload}
    </div>
  );
};

export default ViewTeamOnRequest;
