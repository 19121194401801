import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 aug 24

import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import dateStringer from "../../tools/dateStringer";
import serverURL from "../../serverURL";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import "../../styles/calendar.css";

import dayLimitPower1 from "../../img/general/dayLimitPower1.svg";
import dayLimitPower2 from "../../img/general/dayLimitPower2.svg";
import bulkPlus from "../../img/general/bulkPlus.svg";
import bulkMinus from "../../img/general/bulkMinus.svg";
import bulkPower from "../../img/general/bulkPower.svg";
import navyClose from "../../img/general/navyClose.svg";
import bulkTick from "../../img/general/bulkTick.svg";

// let hoverButtonsAtScreenWidth = 820;

const BulkEditLimitsModal = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    bulkEditLimitObj,
    setBulkEditLimitObj,
    updateLimits,
    setUpdateLimits,
  } = useContext(CalendarContext);
  const { setIndicate, modalOpen, setModalOpen, device } =
    useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      bulkEditLimitObj,
      setBulkEditLimitObj,
      updateLimits,
      setUpdateLimits,
      setIndicate,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      bulkEditLimitObj, //
      setBulkEditLimitObj, //
      updateLimits, //
      setUpdateLimits, //
      setIndicate, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [teamName, setTeamName] = useState("");
  let [jobTitles, setJobTitles] = useState([]);

  let [startDs, setStartDs] = useState("");
  let [endDs, setEndDs] = useState("");

  let [startDi, setStartDi] = useState("");
  let [endDi, setEndDi] = useState("");

  let [today00ts, setToday00ts] = useState("");
  let [maxTs, setMaxTs] = useState(0);

  let [startDsValid, setStartDsValid] = useState(true);
  let [endDsValid, setEndDsValid] = useState(true);

  let [bulkPage, setBulkPage] = useState(1);

  let [limitBy, setLimitBy] = useState("team");

  let [monTeamVal, setMonTeamVal] = useState(2);
  let [tueTeamVal, setTueTeamVal] = useState(2);
  let [wedTeamVal, setWedTeamVal] = useState(2);
  let [thuTeamVal, setThuTeamVal] = useState(2);
  let [friTeamVal, setFriTeamVal] = useState(2);
  let [satTeamVal, setSatTeamVal] = useState(2);
  let [sunTeamVal, setSunTeamVal] = useState(2);

  let [monTeamOn, setMonTeamOn] = useState(true);
  let [tueTeamOn, setTueTeamOn] = useState(true);
  let [wedTeamOn, setWedTeamOn] = useState(true);
  let [thuTeamOn, setThuTeamOn] = useState(true);
  let [friTeamOn, setFriTeamOn] = useState(true);
  let [satTeamOn, setSatTeamOn] = useState(true);
  let [sunTeamOn, setSunTeamOn] = useState(true);

  let [jtData, setJtData] = useState([]);
  let [selectedJt, setSelectedJt] = useState("");

  let [closedDayNums, setClosedDayNums] = useState([]);

  let [editedJts, setEditedJts] = useState([]);

  let [jtObjects, setJtObjects] = useState([]); // arr of objs of jt data

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 7) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={dayLimitPower1}
        alt="Day Limit Power 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={dayLimitPower2}
        alt="Day Limit Power 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bulkPlus}
        alt="Bulk Plus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bulkMinus}
        alt="Bulk Minus"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bulkPower}
        alt="Bulk Power"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bulkTick}
        alt="Bulk Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  useEffect(() => {
    // clean not needed
    // check startDs
    if (
      startDs &&
      startDs.length === 17 &&
      startDs[0] === "Y" &&
      dateStringer.createTimestampFromString(startDs) < maxTs &&
      dateStringer.createTimestampFromString(startDs) >= today00ts &&
      startDs[1] === "2"
    ) {
      setStartDsValid(true);
    } else {
      setStartDsValid(false);
    }

    // check endDs
    if (
      endDs &&
      endDs.length === 17 &&
      endDs[0] === "Y" &&
      dateStringer.createTimestampFromString(endDs) <= maxTs &&
      dateStringer.createTimestampFromString(endDs) >= today00ts &&
      endDs[1] === "2"
    ) {
      setEndDsValid(true);
    } else {
      setEndDsValid(false);
    }
  }, [startDs, endDs]);
  let [companyName, setCompanyName] = useState("the company");
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    let today00 = new Date();
    today00.setHours(0, 0, 0, 0);
    setToday00ts(today00.getTime());
    setStartDs(dateStringer.createStringFromTimestamp(today00.getTime()));
    setStartDi(
      dateStringer.createDateInputFromDateString(
        dateStringer.createStringFromTimestamp(today00.getTime())
      )
    );
    axios
      .post(
        `${serverURL}/get-team-data-for-bulk-limit-edit-modal`,
        {
          teamID: memoVals.bulkEditLimitObj.teamID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setJobTitles(response.data.jobTitles);
          setSelectedJt(response.data.jobTitles[0]);
          setTeamName(response.data.teamName);
          setMaxTs(response.data.maxTs);
          setCompanyName(response.data.companyName);
          setClosedDayNums(response.data.closedDayNums);

          let jtDataArr = [];

          let jtObs = [];

          response.data.jobTitles.forEach((jobT) => {
            jtObs.push({
              jt: jobT,
              tempMonOn: response.data.closedDayNums.includes(1) ? false : true,
              tempTueOn: response.data.closedDayNums.includes(2) ? false : true,
              tempWedOn: response.data.closedDayNums.includes(3) ? false : true,
              tempThuOn: response.data.closedDayNums.includes(4) ? false : true,
              tempFriOn: response.data.closedDayNums.includes(5) ? false : true,
              tempSatOn: response.data.closedDayNums.includes(6) ? false : true,
              tempSunOn: response.data.closedDayNums.includes(0) ? false : true,

              tempMonVal: 2,
              tempTueVal: 2,
              tempWedVal: 2,
              tempThuVal: 2,
              tempFriVal: 2,
              tempSatVal: 2,
              tempSunVal: 2,
            });

            jtDataArr.push({
              jt: jobT,
              monActivate: response.data.closedDayNums.includes(1)
                ? false
                : true,
              monVal: 2,
              tueActivate: response.data.closedDayNums.includes(2)
                ? false
                : true,
              tueVal: 2,
              wedActivate: response.data.closedDayNums.includes(3)
                ? false
                : true,
              wedVal: 2,
              thuActivate: response.data.closedDayNums.includes(4)
                ? false
                : true,
              thuVal: 2,
              friActivate: response.data.closedDayNums.includes(5)
                ? false
                : true,
              friVal: 2,
              satActivate: response.data.closedDayNums.includes(6)
                ? false
                : true,
              satVal: 2,
              sunActivate: response.data.closedDayNums.includes(0)
                ? false
                : true,
              sunVal: 2,
            });
          });

          setJtObjects(jtObs);

          setJtData(jtDataArr);

          if (response.data.closedDayNums.includes(0)) {
            setSunTeamOn(false);
            setTempSunOn(false);
          }
          if (response.data.closedDayNums.includes(1)) {
            setMonTeamOn(false);
            setTempMonOn(false);
          }
          if (response.data.closedDayNums.includes(2)) {
            setTueTeamOn(false);
            setTempTueOn(false);
          }
          if (response.data.closedDayNums.includes(3)) {
            setWedTeamOn(false);
            setTempWedOn(false);
          }
          if (response.data.closedDayNums.includes(4)) {
            setThuTeamOn(false);
            setTempThuOn(false);
          }
          if (response.data.closedDayNums.includes(5)) {
            setFriTeamOn(false);
            setTempFriOn(false);
          }
          if (response.data.closedDayNums.includes(6)) {
            setSatTeamOn(false);
            setTempSatOn(false);
          }

          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, []);

  let [selectedJtIndex, setSelectedJtIndex] = useState(0);

  let generateJtMenu = jtData.map((obj, i) => {
    return (
      <div
        key={i}
        className={`bulkJtMenuItem ${
          selectedJt === obj.jt ? "selectedBulkJt" : ""
        } ${i === selectedJtIndex - 1 ? "noBorderRightJt" : ""}`}
        onClick={() => {
          // save data to jtObjects array
          let newArr = [];
          jtObjects.forEach((obje) => {
            if (obje.jt !== selectedJt) {
              newArr.push(obje);
            } else {
              newArr.push({
                jt: selectedJt,
                tempMonOn,
                tempTueOn,
                tempWedOn,
                tempThuOn,
                tempFriOn,
                tempSatOn,
                tempSunOn,
                tempMonVal,
                tempTueVal,
                tempWedVal,
                tempThuVal,
                tempFriVal,
                tempSatVal,
                tempSunVal,
              });
            }
          });

          setJtObjects(newArr);

          // load new data
          jtObjects.forEach((ob) => {
            if (ob.jt === obj.jt) {
              setTempMonOn(ob.tempMonOn);
              setTempTueOn(ob.tempTueOn);
              setTempWedOn(ob.tempWedOn);
              setTempThuOn(ob.tempThuOn);
              setTempFriOn(ob.tempFriOn);
              setTempSatOn(ob.tempSatOn);
              setTempSunOn(ob.tempSunOn);

              setTempMonVal(ob.tempMonVal);
              setTempTueVal(ob.tempTueVal);
              setTempWedVal(ob.tempWedVal);
              setTempThuVal(ob.tempThuVal);
              setTempFriVal(ob.tempFriVal);
              setTempSatVal(ob.tempSatVal);
              setTempSunVal(ob.tempSunVal);
            }
          });

          // change over

          setSelectedJt(obj.jt);
          setSelectedJtIndex(i);
        }}
      >
        {editedJts.includes(obj.jt) ? (
          <div className="editedJtLimBubble"></div>
        ) : (
          ""
        )}{" "}
        {/* <span
          className={`${editedJts.includes(obj.jt) ? "jtEdited" : "none"}`}
        ></span>{" "} */}
        {obj.jt || "No job title"}
      </div>
    );
  });

  //  - - - -

  let dayArr = [1, 2, 3, 4, 5, 6, 0];

  let generateTeamDayCols = dayArr.map((day, i) => {
    let dayName = "Mon";
    if (day === 0) {
      dayName = "Sun";
    }

    if (day === 2) {
      dayName = "Tue";
    }
    if (day === 3) {
      dayName = "Wed";
    }
    if (day === 4) {
      dayName = "Thu";
    }
    if (day === 5) {
      dayName = "Fri";
    }
    if (day === 6) {
      dayName = "Sat";
    }

    let powerIcon = dayLimitPower1;
    let val = "-";
    let fadePlusMinus = true;

    if (day === 0 && sunTeamOn) {
      powerIcon = dayLimitPower2;
      val = sunTeamVal;
      fadePlusMinus = false;
    }
    if (day === 1 && monTeamOn) {
      powerIcon = dayLimitPower2;
      val = monTeamVal;
      fadePlusMinus = false;
    }
    if (day === 2 && tueTeamOn) {
      powerIcon = dayLimitPower2;
      val = tueTeamVal;
      fadePlusMinus = false;
    }
    if (day === 3 && wedTeamOn) {
      powerIcon = dayLimitPower2;
      val = wedTeamVal;
      fadePlusMinus = false;
    }
    if (day === 4 && thuTeamOn) {
      powerIcon = dayLimitPower2;
      val = thuTeamVal;
      fadePlusMinus = false;
    }
    if (day === 5 && friTeamOn) {
      powerIcon = dayLimitPower2;
      val = friTeamVal;
      fadePlusMinus = false;
    }
    if (day === 6 && satTeamOn) {
      powerIcon = dayLimitPower2;
      val = satTeamVal;
      fadePlusMinus = false;
    }

    return (
      <div className="bulkDayCol" key={day}>
        <p className="bulkDayTitle">{dayName}</p>
        <div className="bulkButtons">
          <img
            src={bulkPlus}
            className={`bulkPlusMinus ${
              fadePlusMinus ? "fadeBulkPlusMinus" : ""
            }`}
            alt="Add 1"
            onClick={() => {
              if (day === 0) {
                setSunTeamVal(sunTeamVal + 1);
              }
              if (day === 1) {
                setMonTeamVal(monTeamVal + 1);
              }
              if (day === 2) {
                setTueTeamVal(tueTeamVal + 1);
              }
              if (day === 3) {
                setWedTeamVal(wedTeamVal + 1);
              }
              if (day === 4) {
                setThuTeamVal(thuTeamVal + 1);
              }
              if (day === 5) {
                setFriTeamVal(friTeamVal + 1);
              }
              if (day === 6) {
                setSatTeamVal(satTeamVal + 1);
              }
            }}
          />

          <p className="bulkDayVal">{val}</p>
          <p
            className={`${
              val !== "-" ? "offTxtBelowVal" : "offTxtBelowVal offVisZero"
            }`}
          >
            OFF
          </p>
          <img
            src={bulkMinus}
            className={`bulkPlusMinus ${
              fadePlusMinus ? "fadeBulkPlusMinus" : ""
            }`}
            alt="Minus 1"
            onClick={() => {
              if (day === 0) {
                if (sunTeamVal > 0) {
                  setSunTeamVal(sunTeamVal - 1);
                }
              }
              if (day === 1) {
                if (monTeamVal > 0) {
                  setMonTeamVal(monTeamVal - 1);
                }
              }
              if (day === 2) {
                if (tueTeamVal > 0) {
                  setTueTeamVal(tueTeamVal - 1);
                }
              }
              if (day === 3) {
                if (wedTeamVal > 0) {
                  setWedTeamVal(wedTeamVal - 1);
                }
              }
              if (day === 4) {
                if (thuTeamVal > 0) {
                  setThuTeamVal(thuTeamVal - 1);
                }
              }
              if (day === 5) {
                if (friTeamVal > 0) {
                  setFriTeamVal(friTeamVal - 1);
                }
              }
              if (day === 6) {
                if (satTeamVal > 0) {
                  setSatTeamVal(satTeamVal - 1);
                }
              }
            }}
          />

          <div className="bulkDayPowerDivider"></div>
          <img
            src={bulkPower}
            alt="Enable limit"
            className={`bulkDayPowerIcon ${
              fadePlusMinus ? "fadeBulkPower" : ""
            }`}
            onClick={() => {
              if (day === 0) {
                setSunTeamOn(!sunTeamOn);
                if (!sunTeamOn) {
                  setSunTeamVal(2);
                }
              }

              if (day === 1) {
                setMonTeamOn(!monTeamOn);
                if (!monTeamOn) {
                  setMonTeamVal(2);
                }
              }

              if (day === 2) {
                setTueTeamOn(!tueTeamOn);
                if (!tueTeamOn) {
                  setTueTeamVal(2);
                }
              }

              if (day === 3) {
                setWedTeamOn(!wedTeamOn);
                if (!wedTeamOn) {
                  setWedTeamVal(2);
                }
              }

              if (day === 4) {
                setThuTeamOn(!thuTeamOn);
                if (!thuTeamOn) {
                  setThuTeamVal(2);
                }
              }

              if (day === 5) {
                setFriTeamOn(!friTeamOn);
                if (!friTeamOn) {
                  setFriTeamVal(2);
                }
              }

              if (day === 6) {
                setSatTeamOn(!satTeamOn);
                if (!satTeamOn) {
                  setSatTeamVal(2);
                }
              }
            }}
          />
        </div>
      </div>
    );
  });

  let [tempMonOn, setTempMonOn] = useState(true);
  let [tempTueOn, setTempTueOn] = useState(true);
  let [tempWedOn, setTempWedOn] = useState(true);
  let [tempThuOn, setTempThuOn] = useState(true);
  let [tempFriOn, setTempFriOn] = useState(true);
  let [tempSatOn, setTempSatOn] = useState(true);
  let [tempSunOn, setTempSunOn] = useState(true);

  let [tempMonVal, setTempMonVal] = useState(2);
  let [tempTueVal, setTempTueVal] = useState(2);
  let [tempWedVal, setTempWedVal] = useState(2);
  let [tempThuVal, setTempThuVal] = useState(2);
  let [tempFriVal, setTempFriVal] = useState(2);
  let [tempSatVal, setTempSatVal] = useState(2);
  let [tempSunVal, setTempSunVal] = useState(2);

  let generateJtData = (selJt) => {
    let dayArr = [1, 2, 3, 4, 5, 6, 0];

    let genJtDayCols = dayArr.map((day, i) => {
      let dayName = "Mon";
      if (day === 0) {
        dayName = "Sun";
      }

      if (day === 2) {
        dayName = "Tue";
      }
      if (day === 3) {
        dayName = "Wed";
      }
      if (day === 4) {
        dayName = "Thu";
      }
      if (day === 5) {
        dayName = "Fri";
      }
      if (day === 6) {
        dayName = "Sat";
      }

      let powerIcon = dayLimitPower1;
      let val = "-";
      let fadePlusMinus = true;

      if (day === 0 && tempSunOn) {
        powerIcon = dayLimitPower2;
        val = tempSunVal;
        fadePlusMinus = false;
      }
      if (day === 1 && tempMonOn) {
        powerIcon = dayLimitPower2;
        val = tempMonVal;
        fadePlusMinus = false;
      }
      if (day === 2 && tempTueOn) {
        powerIcon = dayLimitPower2;
        val = tempTueVal;
        fadePlusMinus = false;
      }
      if (day === 3 && tempWedOn) {
        powerIcon = dayLimitPower2;
        val = tempWedVal;
        fadePlusMinus = false;
      }
      if (day === 4 && tempThuOn) {
        powerIcon = dayLimitPower2;
        val = tempThuVal;
        fadePlusMinus = false;
      }
      if (day === 5 && tempFriOn) {
        powerIcon = dayLimitPower2;
        val = tempFriVal;
        fadePlusMinus = false;
      }
      if (day === 6 && tempSatOn) {
        powerIcon = dayLimitPower2;
        val = tempSatVal;
        fadePlusMinus = false;
      }

      return (
        <div className="bulkDayCol" key={day}>
          <p className="bulkDayTitle">{dayName}</p>
          <div className="bulkButtons">
            <img
              src={bulkPlus}
              className={`bulkPlusMinus ${
                fadePlusMinus ? "fadeBulkPlusMinus" : ""
              }`}
              alt="Add 1"
              onClick={() => {
                if (day === 0) {
                  setTempSunVal(tempSunVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 1) {
                  setTempMonVal(tempMonVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 2) {
                  setTempTueVal(tempTueVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 3) {
                  setTempWedVal(tempWedVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 4) {
                  setTempThuVal(tempThuVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 5) {
                  setTempFriVal(tempFriVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
                if (day === 6) {
                  setTempSatVal(tempSatVal + 1);
                  setEditedJts([...editedJts, selectedJt]);
                }
              }}
            />

            <p className="bulkDayVal">{val}</p>
            <p
              className={`${
                val !== "-" ? "offTxtBelowVal" : "offTxtBelowVal offVisZero"
              }`}
            >
              OFF
            </p>
            <img
              src={bulkMinus}
              className={`bulkPlusMinus ${
                fadePlusMinus ? "fadeBulkPlusMinus" : ""
              }`}
              alt="Minus 1"
              onClick={() => {
                if (day === 0) {
                  if (tempSunVal > 0) {
                    setTempSunVal(tempSunVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 1) {
                  if (tempMonVal > 0) {
                    setTempMonVal(tempMonVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 2) {
                  if (tempTueVal > 0) {
                    setTempTueVal(tempTueVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 3) {
                  if (tempWedVal > 0) {
                    setTempWedVal(tempWedVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 4) {
                  if (tempThuVal > 0) {
                    setTempThuVal(tempThuVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 5) {
                  if (tempFriVal > 0) {
                    setTempFriVal(tempFriVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
                if (day === 6) {
                  if (tempSatVal > 0) {
                    setTempSatVal(tempSatVal - 1);
                    setEditedJts([...editedJts, selectedJt]);
                  }
                }
              }}
            />

            <div className="bulkDayPowerDivider"></div>
            <img
              src={bulkPower}
              alt="Enable limit"
              className={`bulkDayPowerIcon ${
                fadePlusMinus ? "fadeBulkPower" : ""
              }`}
              onClick={() => {
                if (day === 0) {
                  setTempSunOn(!tempSunOn);
                  if (!sunTeamOn) {
                    setSunTeamVal(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 1) {
                  setTempMonOn(!tempMonOn);
                  if (!tempMonOn) {
                    setMonTeamVal(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 2) {
                  setTempTueOn(!tempTueOn);
                  if (!tempTueOn) {
                    setTueTeamVal(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 3) {
                  setTempWedOn(!tempWedOn);
                  if (!tempWedOn) {
                    setWedTeamOn(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 4) {
                  setTempThuOn(!tempThuOn);
                  if (!tempThuOn) {
                    setThuTeamOn(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 5) {
                  setTempFriOn(!tempFriOn);
                  if (!tempFriOn) {
                    setFriTeamOn(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }

                if (day === 6) {
                  setTempSatOn(!tempSatOn);
                  if (!tempSatOn) {
                    setSatTeamOn(2);
                  }
                  setEditedJts([...editedJts, selectedJt]);
                }
              }}
            />
          </div>
        </div>
      );
    });

    return genJtDayCols;
  };
  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setBulkEditLimitObj((x) => {
        return {};
      });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [showJtMisMatch, setShowJtMisMatch] = useState(false);

  let fireReq = () => {
    // submit axios request here to submit
    setLoading(true);

    let newArr = [];
    jtObjects.forEach((obje) => {
      if (obje.jt !== selectedJt) {
        newArr.push(obje);
      } else {
        newArr.push({
          jt: selectedJt,
          tempMonOn,
          tempTueOn,
          tempWedOn,
          tempThuOn,
          tempFriOn,
          tempSatOn,
          tempSunOn,
          tempMonVal,
          tempTueVal,
          tempWedVal,
          tempThuVal,
          tempFriVal,
          tempSatVal,
          tempSunVal,
        });
      }
    });

    axios
      .post(
        `${serverURL}/bulk-edit-limits`,
        {
          teamID: memoVals.bulkEditLimitObj.teamID,
          startDs: startDs,
          endDs: endDs,
          jtOrTeam: limitBy === "jt" ? "jt" : "team",
          jtObjects: newArr,
          data: [
            {
              dayNum: 0,
              activate: sunTeamOn,
              val: sunTeamVal,
            },
            {
              dayNum: 1,
              activate: monTeamOn,
              val: monTeamVal,
            },
            {
              dayNum: 2,
              activate: tueTeamOn,
              val: tueTeamVal,
            },
            {
              dayNum: 3,
              activate: wedTeamOn,
              val: wedTeamVal,
            },
            {
              dayNum: 4,
              activate: thuTeamOn,
              val: thuTeamVal,
            },
            {
              dayNum: 5,
              activate: friTeamOn,
              val: friTeamVal,
            },
            {
              dayNum: 6,
              activate: satTeamOn,
              val: satTeamVal,
            },
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setTimeout(() => {
            setDataLoaded(true);
            memoVals.setBulkEditLimitObj((x) => {
              return {};
            });
            memoVals.setUpdateLimits((x) => {
              !memoVals.updateLimits;
            });
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Limits saved",
                colour: "blue",
                duration: 4000,
              };
            });
          }, 250);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // master return

  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        if (!loading) {
          memoVals.setBulkEditLimitObj((x) => {
            return {};
          });
        }
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`bulkEditLimitModalBox ${teamName ? "" : "none"} ${
            memoVals.mobModal
              ? `mobModalShoulder ${
                  memoVals.device.ios ? "paddingBottom20" : ""
                }`
              : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div className="modalSwiper modalSwiperBulkLimits"></div>
          )}
          <div
            className={`${
              loading
                ? "bulkEditLimitModalHeader invisFooter"
                : "bulkEditLimitModalHeader"
            }`}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon"
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {};
                });
              }}
            />
            <p className="bulkEditLimitModalHeaderTitle">
              Bulk-edit leave limits
            </p>
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />{" "}
          </div>
          <div
            className={`${
              loading
                ? "bulkLimitEditModalKeyDiv invisFooter"
                : "bulkLimitEditModalKeyDiv"
            }`}
          >
            <div className="bulkKeyContainer">
              <div
                className={`${bulkPage === 1 ? "bulkKeyOn" : "bulkKeyOff"}`}
              ></div>
              <div
                className={`${bulkPage === 2 ? "bulkKeyOn" : "bulkKeyOff"}`}
              ></div>
            </div>
          </div>
          <div
            className={`${
              loading ? "bulkEditTextDiv invisFooter" : "bulkEditTextDiv"
            }`}
          >
            <p className="bulkEditingTeamTxt">
              Set leave limits{" "}
              {bulkPage === 1 ? " across a period of time " : " "}
              for <span className="bulkEditTeamNameSpan">{teamName}</span>{" "}
              <span className={bulkPage === 2 ? "" : "none"}>
                between{" "}
                <span className="bulkEditTeamNameSpan">
                  {dateStringer.createShortStripFromDateString(startDs)}
                </span>{" "}
                and{" "}
                <span className="bulkEditTeamNameSpan">
                  {dateStringer.createShortStripFromDateString(endDs)}
                </span>
              </span>
            </p>
          </div>
          <div
            className={`${
              bulkPage === 1 ? "bulkLimStartEndDateContainer" : "none"
            }`}
          >
            <div className="selectDateRangeDiv">
              <p className="selectDateRange">Select a date range</p>
            </div>
            <div className="bulkStartContainer">
              <div className="bulkDateTitleAndInput">
                <p className="bulkStartDateTitle">From</p>
                <input
                  type="date"
                  className={`addShiftDateInput ${
                    memoVals.device.ios ? "iosDateInput" : ""
                  }`}
                  id="addShiftDateInput"
                  value={startDi}
                  onChange={(e) => {
                    setStartDs(
                      dateStringer.createStringFromDateInput(e.target.value)
                    );
                    setStartDi(e.target.value);
                  }}
                ></input>
              </div>
              <p
                className={`${
                  dateStringer.createTimestampFromString(startDs) > maxTs &&
                  startDs[1] === "2"
                    ? "bulkStartWarn"
                    : "none"
                }`}
              >
                Too far ahead
              </p>{" "}
              <p
                className={`${
                  dateStringer.createTimestampFromString(startDs) < today00ts &&
                  startDs[1] === "2"
                    ? "bulkStartWarn"
                    : "none"
                }`}
              >
                Cannot be before today
              </p>{" "}
              <div
                className={`${
                  startDsValid && endDsValid ? "bulkTickDiv" : "none"
                }`}
              >
                <img
                  src={bulkTick}
                  className={`${
                    startDsValid && endDsValid ? "bulkTick" : "none"
                  }`}
                  alt="Valid dates tick"
                />
              </div>
            </div>
            <br />
            <div className="bulkStartContainer">
              <div className="bulkDateTitleAndInput">
                <p className="bulkStartDateTitle">Until</p>
                <input
                  type="date"
                  className={`addShiftDateInput ${
                    memoVals.device.ios ? "iosDateInput" : ""
                  }`}
                  id="addShiftDateInput"
                  value={endDi}
                  onChange={(e) => {
                    setEndDs(
                      dateStringer.createStringFromDateInput(e.target.value)
                    );
                    setEndDi(e.target.value);
                  }}
                ></input>
              </div>
              <p
                className={`${
                  dateStringer.createTimestampFromString(endDs) > maxTs &&
                  endDs[1] === "2"
                    ? "bulkStartWarn"
                    : "none"
                }`}
              >
                Too far ahead
              </p>
              <p
                className={`${
                  dateStringer.createTimestampFromString(endDs) < today00ts &&
                  endDs[1] === "2"
                    ? "bulkStartWarn"
                    : "none"
                }`}
              >
                Not before today
              </p>{" "}
              <div
                className={`${
                  startDsValid && endDsValid ? "bulkTickDiv" : "none"
                }`}
              >
                <img
                  src={bulkTick}
                  className={`${
                    startDsValid && endDsValid ? "bulkTick" : "none"
                  }`}
                  alt="Valid dates tick"
                />
              </div>
              <p
                className={`${
                  !endDsValid && !endDs
                    ? // dateStringer.createTimestampFromString(endDs) >= today00ts &&
                      // dateStringer.createTimestampFromString(endDs) <= maxTs
                      "makeBulkEndDsMaxTs"
                    : "none"
                }`}
                onClick={() => {
                  if (!endDsValid && !endDs) {
                    setEndDs(dateStringer.createStringFromTimestamp(maxTs));
                    setEndDi(
                      dateStringer.createDateInputFromDateString(
                        dateStringer.createStringFromTimestamp(maxTs)
                      )
                    );
                    setEndDsValid(true);
                    //   setStartDsValid(true);
                  }
                }}
              >
                Maximum
              </p>
            </div>
          </div>{" "}
          <div className={`${bulkPage === 2 ? "bulkPageDiv" : "none"}`}>
            <div className={` ${!loading ? "bulkLimitByDiv" : "none"}`}>
              <p className="bulkLimitByTitle">Limit by</p>
              <select
                className="selectTeamOrJtLimitDropdownBulk"
                value={limitBy}
                onChange={(e) => {
                  setLimitBy(e.target.value);
                }}
              >
                <option value="team">Whole team</option>
                <option value="jt">Job title</option>
              </select>
            </div>

            <div
              className={`${
                limitBy === "jt" && !loading ? "jtDataInputDiv" : "none"
              }`}
            >
              <p className="limitByJtInstruct">
                Set limits for how many people who share the same job title can
                take a day off.
              </p>
              <div className="jtSelectBulkMenu">{generateJtMenu}</div>

              <div className="jtEditDivContainer">
                {/* EDIT HERE */}
                {generateJtData(selectedJt)}
              </div>
            </div>

            <div
              className={`${
                limitBy === "team" && !loading ? "teamDataInputDiv" : "none"
              }`}
            >
              {generateTeamDayCols}
            </div>
          </div>
          {/* <div className="bulkLimitEditModalKeyDiv">
          <div className="bulkKeyContainer">
            <div
              className={`${bulkPage === 1 ? "bulkKeyOn" : "bulkKeyOff"}`}
            ></div>
            <div
              className={`${bulkPage === 2 ? "bulkKeyOn" : "bulkKeyOff"}`}
            ></div>
          </div>
        </div> */}
          <div
            className={`${
              loading ? "bulkModalFooter invisFooter" : "bulkModalFooter"
            }`}
          >
            <div className="bulkModalFooterLeft">
              {(!startDsValid || !endDsValid) && bulkPage === 1 ? (
                <p
                  className="disableLimsBtn"
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/toggle-hide-sched-limits-for-team`,
                        {
                          data: false,
                          teamID: memoVals.bulkEditLimitObj.teamID,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Limits are now disabled",
                              colour: "blue",
                              duration: 4000,
                              ts: new Date().getTime(),
                            };
                          });

                          memoVals.setBulkEditLimitObj((x) => {
                            return {
                              disable: true,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Disable limits
                </p>
              ) : (
                ""
              )}
              <p
                className={`saveBulkModalBtn ${
                  bulkPage === 1 ? "navyBgNextBtnBulk" : ""
                } ${startDsValid && endDsValid ? "" : "fadeBulkSaveBtn none"}`}
                onClick={() => {
                  if (bulkPage === 1 && startDsValid && endDsValid) {
                    setBulkPage(2);
                  }

                  if (bulkPage === 2 && startDsValid && endDsValid) {
                    if (
                      editedJts.length !== jtData.length &&
                      limitBy === "jt"
                    ) {
                      setShowJtMisMatch(true);
                    } else {
                      fireReq();
                    }
                  }
                }}
              >
                {bulkPage === 1 ? "Next page" : "Save"}
              </p>{" "}
              <p
                className={`cancelBulkModalBtn x24897277101 ${
                  bulkPage === 1 ? "invis" : ""
                }`}
                onClick={() => {
                  setBulkPage(1);
                }}
              >
                Back
              </p>
            </div>
            <p
              className="cancelBulkModalBtn"
              onClick={() => {
                memoVals.setBulkEditLimitObj((x) => {
                  return {};
                });

                if (bulkPage === 1) {
                  memoVals.setBulkEditLimitObj((x) => {
                    return {};
                  });
                }
                if (bulkPage === 2) {
                  setBulkPage(1);
                }
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {showJtMisMatch ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              You have amended the limits for only {editedJts.length} out of{" "}
              {jtData.length} job title{jtData.length === 1 ? "" : "s"}.
              <br />
              <br />
              The remaining {jtData.length - editedJts.length} job title
              {jtData.length - editedJts.length === 1 ? "" : "s"} you have not
              amended will default to limit two staff off for each of the{" "}
              {7 - closedDayNums.length} days {companyName} is open each week.
              <br />
              <br />
              Do you want to continue?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setShowJtMisMatch(false);
                  fireReq();
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowJtMisMatch(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default BulkEditLimitsModal;
